import _ from 'lodash';
import * as consts from '../../constants';
import { containsObject } from '../../shared/utility';
import authenticationApi from '../../api/authenticationApi';
import MarketplacesApi from '../../api/MarketplacesApi';
import Axios from 'axios';

// Add a 401 response interceptor
Axios.interceptors.response.use(function (response) {
   // console.log("intercept response", response);
   return response;
}, function (error) {
   // console.log("intercept response error", error);
   if (401 === error.response.status) {
      console.log("Intercept response error 401", error);
      setSession(null);
      window.location = '/login';
      // logout();
      //  swal({
      //      title: "Session Expired",
      //      text: "Your session has expired. Would you like to be redirected to the login page?",
      //      type: "warning",
      //      showCancelButton: true,
      //      confirmButtonColor: "#DD6B55",
      //      confirmButtonText: "Yes",
      //      closeOnConfirm: false
      //  }, function(){
      //   window.location = '/login';
      //  });
   } else {
      // console.log(error.response.data)
      return Promise.reject(error.response.data);
   }
});

/**
 *
 * @param userData
 * @returns {function(*): Promise<unknown>}
 */
export function login(userData) {
   return dispatch => {

      return authenticationApi.login(userData)
         .then(response => {
            // console.log(response);
            // debugger;
            if (response.success === true) {
               dispatch(loadUserConfiguration(response.data));
               dispatch(authSuccess(response.data));
            }
            else {
               setSession(null);
               console.log('Invalid credentials provided');
               throw new Error('Invalid credentials provided');
            }
         })
         .catch(error => {
            console.log('Login fail');
            // deleteUserConfiguration();
            // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
            throw error;
            //se error è 401 logout
         });
   };
}

const setSession = authData => {
   if (authData) {
      localStorage.setItem('authData', JSON.stringify(authData));
      // localStorage.setItem('jwt_access_token', authData.access_token);
      Axios.defaults.headers.common['Authorization'] = 'Bearer ' + authData.access_token;
   }
   else {
      localStorage.removeItem('authData');
      // localStorage.removeItem('jwt_access_token');
      delete Axios.defaults.headers.common['Authorization'];
   }
};

const loadUserConfiguration = (authData) => {
   setSession(authData);
   return function (dispatch) {
      dispatch(loadActiveMarketplacesData());
   }
}

const deleteUserConfiguration = () => {
   console.log('User logout');
   return authenticationApi.logout()
      .then(response => {
         setSession(null);
         console.log('Backend logout');
      })
      .catch(error => {
         setSession(null);
         throw error;
      });
}

export function loadActiveMarketplacesData() {
   return dispatch => {
      return MarketplacesApi.getActiveList()
         .then(response => {
            console.log(response);
            if (response.data.success === true) {
               let aux = {};
               let languages = [];
               response.data.data.forEach(el => {
                  aux = {};
                  aux.label = el.language.code;
                  aux.value = el.language.id;
                  if (!containsObject(aux, languages, 'value')) {
                     languages.push(aux);
                  }
               });
               dispatch(loadMarketplacesLanguagesSuccess(languages));
               dispatch(getActiveMarketplacesSuccess(response.data.data));
               // return response.data;
            }
            else {
               throw new Error('Client error');
            }
         })
         .catch(error => {
            dispatch(systemFatalError(consts.ERROR_CODES.HTTP.USER_CONFIGURATION_LOADING));
            // throw error;
         });
   }
}

export function loadMarketplacesLanguagesSuccess(languages) {
   return { type: consts.ACTIONS.LOAD_MARKETPLACES_LANGUAGES_SUCCESS, languages };
}

export function getActiveMarketplacesSuccess(marketplaces) {
   console.log(marketplaces)
   return { type: consts.ACTIONS.GET_ACTIVE_MARKETPLACES_SUCCESS, marketplaces };
}


/**
 *
 * @param authData
 * @returns {{authData: *, type: *}}
 */
export const authSuccess = (authData) => {
   return {
      type: consts.ACTIONS.AUTH_SUCCESS,
      payload: authData
   };
};

export const authFail = (error) => {
   return {
      type: consts.ACTIONS.AUTH_FAIL,
      error: error
   };
};

export const logout = () => {
   deleteUserConfiguration();
   return {
      type: consts.ACTIONS.AUTH_LOGOUT
   };
};

export const authCheckState = () => {
   console.log('Check authentication');
   return dispatch => {
      const authData = JSON.parse(localStorage.getItem('authData'));
      // console.log(authData);
      if (authData && authData.access_token) {
         //CHECK TOKEN EXPIRATION DATE
         // const expirationDate = new Date(authData.expirationDate);
         // if (expirationDate <= new Date()) {
         //     dispatch(logout());
         // } else {
         //     dispatch(authSuccess(authData));
         //     // dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000 ));
         // }
         //IF EXPIRED REFRESH TOKEN
         //TODO: PURGE EXPIRED TOKENS
         dispatch(loadUserConfiguration(authData));
         dispatch(authSuccess(authData));
      }
      else {
         setSession(null);
      }
   };
};

/**
 *
 * @param errorCode
 * @param error
 * @returns {{errorCode: *, type: *}}
 */
export function systemFatalError(errorCode, error) {

   if (_.toNumber(error) > 0) {
      return { type: consts.ACTIONS.SYSTEM_FATAL_ERROR, errorCode: _.toNumber(error) };
   }
   else {
      return { type: consts.ACTIONS.SYSTEM_FATAL_ERROR, errorCode: errorCode };
   }
}