import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
   changeSidebarType
} from "../../store/actions/layoutAction";

// import RightSidebar from "../common/RightSidebar";
import TopBar from "./TopBar";
// Other Layout related Component
import Sidebar from "./Sidebar";
import Footer from "./Footer";

class Layout extends Component {
   constructor(props) {
      super(props);
      this.state = {
         isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
      };

      this.toggleMenuCallback = this.toggleMenuCallback.bind(this);
   }

   capitalizeFirstLetter = string => {
      return string.charAt(1).toUpperCase() + string.slice(2);
   };

   componentDidMount() {
      // Scroll Top to 0
      window.scrollTo(0, 0);
      let currentage = this.capitalizeFirstLetter(this.props.location.pathname);

      document.title =
         currentage ? "NZT Food | " + currentage : "NZT Food";

      if (this.props.leftSideBarType) {
         console.log('leftSideBarType', this.props.leftSideBarType)
         this.props.changeSidebarType(this.props.leftSideBarType);
      }
   }

   toggleMenuCallback = () => {
      if (this.props.leftSideBarType === "default") {
         this.props.changeSidebarType("condensed", this.state.isMobile);
      } else if (this.props.leftSideBarType === "condensed") {
         this.props.changeSidebarType("default", this.state.isMobile);
      }
   };

   render() {
      // if (!this.props.authState.isLoggedIn) {
      //    return (
      //       <div>
      //          <a href="/">Loggati di nuovo</a></div>
      //    )
      // }
      return (
         <React.Fragment>
            <div id="layout-wrapper">
               <TopBar
                  theme={this.props.topBarTheme}
                  toggleMenuCallback={this.toggleMenuCallback}
               />

               <div className="vertical-menu">
                  <div data-simplebar className="h-100">
                     <Sidebar
                        theme={this.props.leftSideBarTheme}
                        type={this.props.leftSideBarType}
                        isMobile={this.state.isMobile}
                        commonState={this.props.commonState}
                        authState={this.props.authState}
                     />
                  </div>
               </div>
               <div className="main-content">
                  <div className="page-content">{this.props.children}</div>
               </div>
               <Footer />

            </div>
         </React.Fragment>
      );
   }
}

const mapStatetoProps = state => {
   //   console.log(state.layoutState);
   //   console.log(state.commonState);
   return {
      ...state.layoutState,
      commonState: state.commonState,
      authState: state.authenticationState,
   };
};

export default connect(mapStatetoProps, {
   changeSidebarType
})(withRouter(Layout));
