import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Row, Col, Space, Form, Input, InputNumber, Switch, Button, notification } from 'antd';
import { InfoCircleOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';

import languageManager from '../../../services/languageManager';
import * as storeActions from './store/actions/marketplaces';

import Loader from '../../common/PageElements/Loader';
import PageHeader from '../../common/PageElements/PageHeader'

class MarketplacesConfig extends Component {

   state = {
      action: undefined,
      saveAndClose: false,
      loader: true,
      settingsBase: [],
      settingsValues: {},
      language: 'it',

      testButtonLoader: false,
   };

   componentDidMount() {
      if (this.props.match.params.id) {
         const itemId = this.props.match.params.id;
         this.setState({ action: 'edit' });
         // asyncronous calls
         storeActions.getConfig(itemId)
            .then(response => {
               console.log(response);
               try {
                  this.setState({
                     settingsBase: response.data.settings_fields,
                     settingsValues: response.data.settings_values,
                     loader: false
                  });
               } catch (e) {
                  notification.open({
                     message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                     description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                     icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                  })
               }

            }).catch((e) => {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e);
            });
      }
      else {
         const formValues = {
            percentage: 0.00
         }
         this.setState({ formValues: formValues, action: 'insert' });
      }

   }

   handleSaveAndClose = () => {
      this.setState({ saveAndClose: true });
      console.log(this.state);
   }

   onFinish = values => {
      this.setState({ loader: true });
      storeActions.setConfig(this.props.match.params.id, values)
         .then(response => {
            this.setState({
               settingsBase: response.data.settings_fields,
               settingsValues: response.data.settings_values,
               loader: false
            });
            console.log(response);
            try {
               if (this.state.saveAndClose) {
                  this.props.history.push('/marketplaces/list');
               }

               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
                  description: languageManager.getMessage(this.props.commonState, this.state.action === 'edit' ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {

            }

         }).catch((e) => {
            this.setState({ loader: false });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
         });
   };

   onFinishFailed = errorInfo => {
      notification.open({
         message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
         description: languageManager.getMessage(this.props.commonState, 'notification.error.formInvalid'),
         icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
      })
   };

   handleConnectionTest = (test) => {
      this.setState({ testButtonLoader: true });
      storeActions.connectionTest(this.props.match.params.id, test)
         .then(response => {
            this.setState({ testButtonLoader: false });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.confirm.testOk'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })

         }).catch((e) => {
            this.setState({ testButtonLoader: false });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
         });
   }

   render() {
      const layout = {
         labelCol: { span: 8 },
         wrapperCol: { span: 16 },
      };

      let form = (
         <Form
            {...layout}
            name="marketplace_config"
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
            initialValues={this.state.settingsValues}
         >

            {
               this.state.settingsBase.map((field) => {
                  // console.log(field.key, this.state.settingsValues[field.key]);
                  return (

                     <Form.Item
                        label={field.name}
                        name={field.key}
                        key={field.key}
                        valuePropName={field.type === "bool" ? "checked" : 'value'}
                     >
                        {
                           field.type === "string" ?
                              <Input />
                              : field.type === "integer" ?
                                 <InputNumber />
                                 : field.type === "bool" ?
                                    <Switch
                                       checkedChildren={<CheckOutlined />}
                                       unCheckedChildren={<CloseOutlined />}
                                    />
                                    : ''

                        }
                     </Form.Item>)
               })
            }
         </Form>
      )

      // const testConnection = (
      //    <Row>
      //       <Col span={16} offset={8}>
      //          <Space>
      //             <Button
      //                type="primary"
      //                loading={this.state.testButtonLoader}
      //                onClick={() => this.handleConnectionTest('receive')}>
      //                Test Ricezione
      //             </Button>
      //             <Button
      //                type="primary"
      //                loading={this.state.testButtonLoader}
      //                onClick={() => this.handleConnectionTest('send')}>
      //                Test Invio
      //             </Button>
      //          </Space>
      //       </Col>
      //    </Row>
      // )

      let mainContent = (
         <div>
            {form}
            {/* {testConnection} */}
         </div>
      )


      const pageContent = (
         <div className="card">
            <div className="card-header">
               <div className="align-items-center row">
                  <div className="col-sm-6">{languageManager.getMessage(this.props.commonState, 'component.marketplaces.title')}</div>
                  <div className="col-sm-6"></div>
               </div>
            </div>
            <div className="card-body">
               {this.state.loader ? <Loader /> : mainContent}
            </div>
            <div className="card-footer">
               <div className="align-items-center row">
                  <div className="col-sm-6">
                     <Space>
                        <Button
                           size='large'
                           onClick={() => { this.props.history.goBack() }}
                        >
                           <i className="mdi mdi-arrow-left-drop-circle-outline mr-1"></i>
                           {languageManager.getMessage(this.props.commonState, 'common.back')}
                        </Button>
                     </Space>
                  </div>

                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">

                        <Space>
                           <Button form="marketplace_config" htmlType="button">{languageManager.getMessage(this.props.commonState, 'common.reset')}</Button>
                           <Button form="marketplace_config" type="primary" htmlType="submit">{languageManager.getMessage(this.props.commonState, 'common.save')}</Button>
                           <Button form="marketplace_config" type="primary" htmlType="submit" onClick={this.handleSaveAndClose}>{languageManager.getMessage(this.props.commonState, 'common.saveAndClose')}</Button>
                        </Space>

                     </div>
                  </div>
               </div>
            </div>
         </div>
      );

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>

      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(MarketplacesConfig);