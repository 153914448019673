import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col, Progress, Image } from 'antd';

class SplashScreen extends React.Component {
    render() {
        if (this.props.commonState.cfgLoading > 0) {
            // return <div className='splash-screen'>
            //     <Row type='flex' justify='center' align='middle' className='splash-screen-icon'>
            //         <Col>

            //         </Col>
            //     </Row>
            //     <Row type='flex' justify='center' align='middle' className='splash-screen-message'>
            //         <Col>
            //             <Progress
            //                 strokeColor={{
            //                     from: '#000000',
            //                     to: '#fff300',
            //                 }}
            //                 percent={100}
            //                 status="active"
            //             />
            //         </Col>
            //     </Row>
            // </div >;
            return (
                <Row justify='center' align='middle' className='splash-screen'>
                    <Col span={2}>
                        <Image preview='false' src='/nzt_logo_splash.png' />
                        <Progress
                            strokeColor={{
                                from: '#000000',
                                to: '#F3E600',
                            }}
                            percent={100}
                            format={() => ''}
                            status="active"
                            showInfo={false}
                        />
                    </Col>
                </Row>
            )
        }
        else
            return this.props.children;
    }
}

SplashScreen.propTypes = {
    commonState: PropTypes.object.isRequired
};


export default SplashScreen;
