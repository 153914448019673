import React, { useState, useEffect, useCallback, useRef } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';

import { Image, Button } from 'antd';
import { Row, Col } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

const Card = ({ id, text, index, moveCard }) => {
   const ref = useRef(null);
   const [, drop] = useDrop({
      accept: 'card',
      hover(item, monitor) {
         if (!ref.current) {
            return;
         }
         const dragIndex = item.index;
         const hoverIndex = index;
         // Don't replace items with themselves
         if (dragIndex === hoverIndex) {
            return;
         }
         // Determine rectangle on screen
         const hoverBoundingRect = ref.current?.getBoundingClientRect();
         // Get vertical middle
         const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
         // Determine mouse position
         const clientOffset = monitor.getClientOffset();
         // Get pixels to the top
         const hoverClientY = clientOffset.y - hoverBoundingRect.top;
         // Only perform the move when the mouse has crossed half of the items height
         // When dragging downwards, only move when the cursor is below 50%
         // When dragging upwards, only move when the cursor is above 50%
         // Dragging downwards
         if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            return;
         }
         // Dragging upwards
         if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            return;
         }
         // Time to actually perform the action
         moveCard(dragIndex, hoverIndex);
         // Note: we're mutating the monitor item here!
         // Generally it's better to avoid mutations,
         // but it's good here for the sake of performance
         // to avoid expensive index searches.
         item.index = hoverIndex;
      },
   });
   const [{ isDragging }, drag] = useDrag({
      item: { type: 'card', id, index },
      collect: (monitor) => ({
         isDragging: monitor.isDragging(),
      }),
   });
   const opacity = isDragging ? 0 : 1;
   drag(drop(ref));
   return (<div ref={ref} style={{ opacity }}>
      {text}
   </div>);
};

const imageBoxStyle = {
   marginBottom: '5px',
   border: '1px solid gray',
   backgroundColor: 'white',
   cursor: 'move',
};
const ImageBox = ({ index, image, moveImage, deleteImage, loaderImageDelete }) => {
   const ref = useRef(null);
   const [, drop] = useDrop({
      accept: 'image',
      hover(item, monitor) {
         if (!ref.current) {
            return;
         }
         const dragIndex = item.index;
         const hoverIndex = index;
         // Don't replace items with themselves
         if (dragIndex === hoverIndex) {
            return;
         }
         // Determine rectangle on screen
         const hoverBoundingRect = ref.current?.getBoundingClientRect();
         // Get vertical middle
         const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
         // Determine mouse position
         const clientOffset = monitor.getClientOffset();
         // Get pixels to the top
         const hoverClientY = clientOffset.y - hoverBoundingRect.top;
         // Only perform the move when the mouse has crossed half of the items height
         // When dragging downwards, only move when the cursor is below 50%
         // When dragging upwards, only move when the cursor is above 50%
         // Dragging downwards
         if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            return;
         }
         // Dragging upwards
         if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            return;
         }
         // Time to actually perform the action
         moveImage(dragIndex, hoverIndex);
         // Note: we're mutating the monitor item here!
         // Generally it's better to avoid mutations,
         // but it's good here for the sake of performance
         // to avoid expensive index searches.
         item.index = hoverIndex;
      },
   });
   const imageId = image.id;
   const [{ isDragging }, drag] = useDrag({
      item: { type: 'image', imageId, index },
      collect: (monitor) => ({
         isDragging: monitor.isDragging(),
      }),
   });
   const opacity = isDragging ? 0 : 1;
   drag(drop(ref));
   return (
      <div style={{ ...imageBoxStyle, opacity }} ref={ref}>
         <table width='100%' cellPadding='5'>
            <tbody>
               <tr>
                  <td className='product_gallery_thumb'><Image width={75} src={image.thumb_1000} /></td>
                  <td className='product_gallery_name'>{image.name} - {image.id}</td>
                  <td className='product_gallery_action'>
                     <Button type="danger"
                        size="small"
                        icon={<DeleteOutlined />}
                        onClick={() => deleteImage(imageId)}
                        loading={loaderImageDelete}
                     />
                  </td>
               </tr>
            </tbody>
         </table>


         {/* <Col>Delete</Col> */}
      </div>
   );
};

const Container = (props) => {
   {
      const [images, setImages] = useState(props.gallery);
      useEffect(() => {
         setImages(props.gallery);
      }, [props.gallery])

      const moveImage = useCallback((dragIndex, hoverIndex) => {
         const dragImage = images[dragIndex];
         setImages(update(images, {
            $splice: [
               [dragIndex, 1],
               [hoverIndex, 0, dragImage],
            ],
         }));
      }, [images]);
      const renderImage = (image, index) => {
         return (
            <ImageBox
               key={index}
               index={index}
               image={image}
               moveImage={moveImage}
               deleteImage={props.handleImageDelete}
               loaderImageDelete={props.loaderImageDelete}
            />);
      };
      return (<>
         <div >
            {
               images.length === 0 ? (
                  <p>
                     Nessuna immagine caricata
                  </p>
               ) : images.map((image, i) => renderImage(image, i))
            }
            <Button
               type="primary"
               onClick={() => { props.handleImagesOrderSave(images) }}
               loading={props.loaderOrderSave}
               disabled={images.length<2}
            >
               Salva ordinamento
            </Button>
         </div>
      </>);
   }
};

const DragSortingUpload = (props) => {
   const [gallery, setGallery] = useState(props.images);
   useEffect(() => {
      setGallery(props.images);
   }, [props.images])

   return (
      <DndProvider backend={HTML5Backend}>
         <Container
            gallery={gallery}
            handleImagesOrderSave={props.imagesOrderSave}
            loaderOrderSave={props.loaderOrderSave}
            handleImageDelete={props.imageDelete}
            loaderImageDelete={props.loaderImageDelete}
         />
      </DndProvider>
   );
};


export default DragSortingUpload;

