import PriceRulesSetsApi from '../../../../../api/PriceRulesSetsApi';
import { prepareTree } from '../../../../catalogue/categories/store/actions/categories';
import _ from 'lodash';

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function getList() {
   return PriceRulesSetsApi.getList()
      .then(response => {
         // if (response.success === true) {
         if (response.data.success === true) {
            response.data.data.forEach(el => {
               el.key = el.id;
            });
            console.log(response);
            // return response;
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function getInitList() {
   return PriceRulesSetsApi.getInitList()
      .then(response => {
         console.log(response);
         const responseData = {}
         _.forEach(response, (el, i) => {
            if (el.data.success !== true) {
               const error = el.data.error ? el.data.error : 'Server error'
               throw new Error(error)
            }
            responseData[i] = el.data.data;
         });
         return responseData;
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function getComplements() {
   return PriceRulesSetsApi.getComplements()
      .then(response => {
         console.log(response);
         const responseData = {}
         _.forEach(response, (el, i) => {
            if (el.data.success !== true) {
               const error = el.data.error ? el.data.error : 'Server error'
               throw new Error(error)
            }
            responseData[i] = (i === 'categoriesTree') ? prepareTree(el.data.data) : el.data.data;
         });
         return responseData;
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function getItem(itemId) {
   return PriceRulesSetsApi.getItem(itemId)
      .then(response => {
         if (response.data.success === true) {
            console.log(response);
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function createOrUpdate(itemData) {
   console.log(itemData);
   return PriceRulesSetsApi.createOrUpdate(itemData)
      .then(response => {
         // if (response.success === true) {
         if (response.data.success === true) {
            console.log(response);
            // return response;
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function toggleActive(itemId) {
   return PriceRulesSetsApi.toggleActive({ itemId: itemId })
      .then(response => {
         // if (response.success === true) {
         if (response.data.success === true) {
            // return response;
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function deleteItem(itemId, return_list = false, list_type = null, marketplace_id = null) {
   return PriceRulesSetsApi.deleteItem(itemId, return_list, list_type, marketplace_id)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}