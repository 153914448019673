import React from 'react';
import { Row, Col, Image } from 'antd';

/**
 * Renders the preloader
 */
const Reloader = () => {
  return (
    <Row justify='center' align='middle' className='splash-screen'>
      <Col span={2}>
        <Image preview='false' src='/nzt_logo_splash.png' />
      </Col>
    </Row>
  );
};

export default Reloader;