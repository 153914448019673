import Axios from 'axios';
import API_BASE from './apiBase';
import API_CONFIG from './apiConfig';

class ProductsApi {

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getList(parentId) {

      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.products.getList, {
            params: {
               parent_id: parentId
            }
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getListExtended(page, pageSize, filters) {

      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.products.getListExtended, {
            page: page,
            page_size: pageSize,
            filters_values: JSON.stringify(filters)
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getListForMarketplace(marketplace_id) {
      console.log(marketplace_id);
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.products.getListForMarketplace, {
            marketplace_id: marketplace_id
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getItem(itemId) {

      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.products.getItem, {
            id: itemId
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }
   static getItemForGroup(itemId, groupId) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.products.getItemForGroup, {
            id: itemId,
            group_id: groupId,
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static createOrUpdate(itemData,) {
      console.log(itemData)
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.products.createOrUpdate, {
            ...itemData
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               console.log(error);
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static update(itemData,) {
      console.log(itemData)
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.products.update, {
            ...itemData
         })
            .then(response => {
               // console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static deleteItem(itemId, return_list) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.products.deleteItem, {
            id: itemId,
            return_list: return_list
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static deleteMultipleItems(productIds, return_list) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.products.deleteMultipleItems, {
            product_ids: productIds,
            return_list: return_list
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static saveCustomPrice(itemId, marketplaceId, variationOperation, variationAmount, basePrice, salePrice) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.productPrices.update, {
            product_id: itemId,
            marketplace_id: marketplaceId,
            variation_operator: variationOperation,
            variation_amount: variationAmount,
            base_price: basePrice,
            sell_price: salePrice,
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   //VARIATIONS
   static createOrUpdateVariation(itemData, return_list = false) {
      console.log(itemData, return_list)
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.productVariations.createOrUpdate, {
            ...itemData,
            return_list: return_list
         })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               console.log(error);
               reject(error);
            });
      });
   }

   static deleteProductVariation(itemId, return_list = false) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.productVariations.deleteItem, {
            id: itemId,
            return_list: return_list
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static saveVariationImage(itemId, imageId) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.products.saveVariationImage, {
            id: itemId,
            image_id: imageId
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static deleteVariationImage(itemId) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.products.deleteVariationImage, {
            id: itemId,
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   //STOCK
   static saveProductStock(formData) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.productStocks.createOrUpdate, {
            ...formData
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static deleteProductStock(itemId) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.productStocks.deleteItem, {
            id: itemId
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static addFiles(itemId, files, file_type_id) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.products.addFiles, {
            id: itemId,
            media: files,
            media_internal_type_id: file_type_id,
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   static deleteFile(itemId, fileId) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.products.deleteFile, {
            id: itemId,
            file_id: fileId
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static saveImagesOrder(itemId, images) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.products.saveImagesOrder, {
            id: itemId,
            images: images
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static addImages(itemId, images) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.products.addImages, {
            id: itemId,
            images: images
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static deleteImage(itemId, imageId) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.products.deleteImage, {
            id: itemId,
            image_id: imageId
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static toggleValue(itemData) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.products.toggleValue, {
            ...itemData
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getProductComplements() {
      return new Promise((resolve, reject) => {
         Axios.all([
            Axios.post(API_BASE.url + API_CONFIG.appUrls.categories.getTree),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.taxRates.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.marketplaces.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.tags.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.specifics.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.mediaInternalTypes.getListForFiles),
         ])
            // .then(responseArr => {
            //    console.log(responseArr);
            //    resolve(responseArr);
            // })
            .then(Axios.spread((categoriesTree, taxRatesList, marketplacesList, tagsList, specificsList, fileTypesList) => {
               const response = {
                  categoriesTree: categoriesTree,
                  taxRatesList: taxRatesList,
                  marketplacesList: marketplacesList,
                  tagsList: tagsList,
                  specificsList: specificsList,
                  fileTypesList: fileTypesList,
               }
               resolve(response);
            }))
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getProductListComplements() {
      return new Promise((resolve, reject) => {
         Axios.all([
            Axios.post(API_BASE.url + API_CONFIG.appUrls.marketplaces.getActiveList),
         ])
            .then(Axios.spread((marketplacesList) => {
               const response = {
                  marketplacesList: marketplacesList,
               }
               resolve(response);
            }))
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static syncProductsWithMarketplace(marketplace_id) {
      console.log(marketplace_id);
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.products.syncProductsWithMarketplace, {
            marketplace_id: marketplace_id
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static importProductsFromCSVWithMap(formData) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.products.importProductsFromCSV, { ...formData })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
   *
   * @param
   * @returns {Promise<unknown>}
   */
   static importFileCSV(formData) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.products.importFileCSV, { ...formData })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static exportProductsCSV(filename, productIds) {

      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.products.exportCSV, { filename: filename, product_ids: productIds })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static exportProductsForPrivalia(filename, productIds, action, params) {

      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.products.exportCSVPrivalia, {
            filename: filename,
            product_ids: productIds,
            action: action,
            ...params,
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static exportProductsForWoocommerce(filename, productIds, action, params) {

      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.products.exportCSVWoocommerce, {
            filename: filename,
            product_ids: productIds,
            action: action,
            ...params,
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   static activateForMarketplace(productIds, params) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.products.activateForMarketplace, {
            product_ids: productIds,
            ...params,
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   static deactivateForMarketplace(productIds, params) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.products.deactivateForMarketplace, {
            product_ids: productIds,
            ...params,
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static sendToMarketplace(productIds, params) {

      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.products.sendToMarketplace, {
            product_ids: productIds,
            ...params,
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }



}

export default ProductsApi;