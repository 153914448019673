import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import languageManager from '../../../../services/languageManager'

import { Form, Input, InputNumber, Select, Switch, Divider } from 'antd'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'

const SupplierForm = (props) => {
    const [layout, setLayout] = useState(props.layout)
    const [values, setValues] = useState(props.values)

    const [form] = Form.useForm()
    // const { Option } = Select

    useEffect(() => {
        setValues(props.values)
        if (_.isEmpty(props.values)) {
            form.resetFields()
        } else {
            form.setFieldsValue(props.values)
        }
    }, [props.values])

    const submitForm = (values) => {
        props.submitForm(values)
    }

    return (
        <Form
            form={form}
            {...layout}
            id="supplierForm"
            name="supplierForm"
            onFinish={submitForm}
        >
            <Form.Item
                label="Codice interno"
                name="internal_code"
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Azienda"
                name="company_name"
                rules={[
                    {
                        required: true,
                        message: 'Inserisci il nome dell\'azienda',
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Partita IVA"
                name="vat"
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Codice Fiscale"
                name="fiscal_code"
                rules={[
                    {
                        required: true,
                        message: 'Inserisci il codice fiscale',
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="SDI"
                name="sdi"
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Indirizzo email"
                name="email"
                rules={[
                    {
                        type: 'email',
                        required: true,
                        message: 'Inserisci un indirizzo email valido',
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="PEC"
                name="pec"
                rules={[
                    {
                        type: 'email',
                        required: true,
                        message: 'Inserisci un indirizzo PEC valido',
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Telefono"
                name="phone"
            >
                <Input />
            </Form.Item>
            <Divider />
            <Form.Item
                label="Indirizzo"
                name="address_1"
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Indirizzo (opz.)"
                name="address_2"
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="CAP"
                name="postal_code"
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Città"
                name="city"
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Provincia"
                name="state"
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Nazione"
                name="country"
            >
                <Input />
            </Form.Item>
            <Form.Item name="id" initialValue={values.itemId} noStyle><Input type="hidden" /></Form.Item>
            {/* <Form.Item name="language" initialValue={values.language} noStyle><Input type="hidden" /></Form.Item> */}
        </Form>
    )
}

const mapStateToProps = state => {
    return {
        commonState: state.commonState,
    }
}

export default connect(mapStateToProps)(SupplierForm)