import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import languageManager from '../../../../services/languageManager'

import { Form, Input, Select, Switch, Divider } from 'antd'
import { PhoneOutlined, MailOutlined } from '@ant-design/icons'

const InvoicingAddressForm = (props) => {
    const [layout, setLayout] = useState(props.layout)
    const [customerId, setCustomerId] = useState(props.customerId)
    const [values, setValues] = useState(props.values)

    const [form] = Form.useForm()
    const { Option } = Select
    const { TextArea } = Input;

    useEffect(() => {
        setCustomerId(props.customerId)
        setValues(props.values)
        if (_.isEmpty(props.values)) {
            form.resetFields()
        } else {
            form.setFieldsValue(props.values)
        }
    }, [props.values, props.customerId])

    const submitForm = (values) => {
        values.customer_id = customerId
        props.submitForm(values)
    }

    return (
        <Form
            form={form}
            {...layout}
            id="InvoicingAddressForm"
            onFinish={submitForm}
        >
            <Form.Item
                name="name"
                rules={[{ required: true, message: 'Inserisci un nome per la fatturazione' }]}
            >
                <Input placeholder="Nome" />
            </Form.Item>
            <Form.Item
                name="address_1"
                rules={[{ required: true, message: 'Inserisci l\'indirizzo per la fatturazione' }]}
            >
                <Input placeholder="Indirizzo" />
            </Form.Item>
            <Form.Item
                name="address_2"
            >
                <Input placeholder="Indirizzo (opz.)" />
            </Form.Item>
            <Form.Item
                name="city"
                rules={[{ required: true, message: 'Inserisci la città' }]}
            >
                <Input placeholder="Città" />
            </Form.Item>
            <Form.Item
                name="postal_code"
            >
                <Input placeholder="CAP" />
            </Form.Item>
            <Form.Item
                name="state"
            >
                <Input placeholder="Provincia" />
            </Form.Item>
            <Form.Item
                name="country"
                rules={[{ required: true, message: 'Inserisci la nazione' }]}
            >
                <Input placeholder="Nazione" />
            </Form.Item>
            {/* <Form.Item
                name="email"
            >
                <Input placeholder="Email" addonAfter={<MailOutlined />} />
            </Form.Item>
            <Form.Item
                name="phone"
            >
                <Input placeholder="Telefono" addonAfter={<PhoneOutlined />} />
            </Form.Item>
            <Form.Item
                label="Note"
                name="note"
            >
                <TextArea />
            </Form.Item> */}
            <Form.Item name="id" initialValue={values.itemId} noStyle><Input type="hidden" /></Form.Item>
        </Form>
    )
}

const mapStateToProps = state => {
    return {
        commonState: state.commonState,
    }
}

export default connect(mapStateToProps)(InvoicingAddressForm)