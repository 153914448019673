import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Form, Input, Select, Radio, Button, Space, notification, InputNumber } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, CheckOutlined, CloseOutlined, InfoCircleOutlined, LeftCircleOutlined } from '@ant-design/icons';

import languageManager from '../../../services/languageManager';
import * as storeActions from './store/actions/priceRules';

import PriceRulesForm from './components/PriceRulesForm';

import Loader from '../../common/PageElements/Loader';

class PriceRulesEdit extends Component {

   state = {
      saveAndClose: false,
      pageLoader: true,
      formValues: {},
      originalFormValues: {},
      componentLanguage: this.props.authState.defaultLanguage,
   };

   setForm = (response, lang = this.state.componentLanguage) => {
      const values = {
         name: response.name,
         description: response.description,
         variation_type: response.variation_type,
         margin_percentage: response.margin_percentage,
         variation_percentage: response.variation_percentage,
         variation_amount: response.variation_amount,
         discount_percentage: response.discount_percentage,
         discount_amount: response.discount_amount,
         id: response.id,
         reset: 0,
      }
      return values;
   }

   componentDidMount() {
      if (this.props.match.params.id) {
         const itemId = this.props.match.params.id
         Promise.all([
            this.getItem(itemId),
            // this.getComplements()
         ]).then(() => {
            this.setState({
               action: 'edit',
               itemId: itemId,
               pageLoader: false
            });
         });
      }
      else {
         const formValues = {
            language: this.state.componentLanguage,
            reset: 0
         }
         Promise.all([
            // this.getComplements()
         ]).then(() => {
            this.setState({
               formValues: formValues,
               originalFormValues: formValues,
               action: 'insert',
               pageLoader: false
            });
         });
      }
   }

   getItem(itemId) {
      return storeActions.getItem(itemId)
         .then(response => {
            console.log(response);
            try {
               const updatedValues = this.setForm(response.data);
               this.setState({
                  action: 'edit',
                  getItemResponse: response.data,
                  formValues: updatedValues,
                  originalFormValues: updatedValues,
                  loader: false
               });
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e);
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   handleSaveAndClose = () => {
      this.setState({ saveAndClose: true });
   }

   onFinish = values => {
      console.log(values);
      this.setState({ saveLoader: true });
      storeActions.createOrUpdate(values)
         .then(response => {
            console.log(response);
            try {
               if (this.state.saveAndClose) {
                  this.props.history.push('/price_rules/list')
               } else {
                  const updatedValues = this.setForm(response.data)
                  this.setState({ formValues: updatedValues, originalFormValues: updatedValues, saveLoader: false })
               }

               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
                  description: languageManager.getMessage(this.props.commonState, this.state.action === 'edit' ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
                  icon: <CheckCircleOutlined className="color-green-I" />,
               })
            } catch (e) {
               this.setState({ saveLoader: false });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <CloseCircleOutlined className="color-red-I" />,
               })
            }

         }).catch((e) => {
            this.setState({ saveLoader: false });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <CloseCircleOutlined className="color-red-I" />,
            })
         });
   };

   resetForm = () => {
      var newValues = { ...this.state.originalFormValues }
      newValues.reset++
      this.setState({ formValues: newValues })
   }

   render() {
      if (this.state.pageLoader) return <Loader />;
      // console.log(this.props.authState.userData.languages);
      const formLayout = {
         labelCol: { span: 8 },
         wrapperCol: { span: 14 },
      };

      const pageContent = (
         <div className="card">
            <div className="card-header">
               <div className="align-items-center row">
                  <div className="col-sm-6">
                     {languageManager.getMessage(this.props.commonState, 'component.price_rules.title')}
                  </div>
                  <div className="col-sm-6"></div>
               </div>
            </div>
            <div className="card-body">
               <PriceRulesForm
                  layout={formLayout}
                  submitForm={this.onFinish}
                  values={this.state.formValues}
               />
            </div>
            <div className="card-footer">
               <div className="align-items-center row">
                  <div className="col-sm-6">
                     <Space>
                        <Button
                           size='large'
                           onClick={() => { this.props.history.goBack() }}
                        >
                           <i className="mdi mdi-arrow-left-drop-circle-outline mr-1"></i>
                           {languageManager.getMessage(this.props.commonState, 'common.back')}
                        </Button>
                     </Space>
                  </div>

                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">

                        <Space>
                           <Button form="formPriceRules" htmlType="button" onClick={this.resetForm} disabled={this.state.saveLoader}>Annulla</Button>
                           <Button form="formPriceRules" type="primary" htmlType="submit" loading={this.state.saveLoader}>Salva</Button>
                           <Button form="formPriceRules" type="primary" htmlType="submit" onClick={this.handleSaveAndClose} loading={this.state.saveLoader}>Salva e chiudi</Button>
                        </Space>

                     </div>
                  </div>
               </div>
            </div>
         </div>
      );

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>

      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(PriceRulesEdit);