import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import Moment from 'moment'

import { Divider, Modal, Table, Form, Radio, Input, InputNumber, Select, DatePicker, Button, Space, notification, Tabs, Typography, Row, Col, Descriptions } from 'antd'
import { InfoCircleOutlined, InboxOutlined, DoubleLeftOutlined, PlusOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'

import languageManager from '../../../services/languageManager'
import * as storeActions from './store/actions/orders'
import * as customersActions from '../../personal_data/customers/store/actions/customers'

import Loader from '../../common/PageElements/Loader'
import { CustomerTypeEnum, OrderDocumentTypeEnum } from '../../../shared/enums'
import { startLoading, endLoading, download } from '../../../shared/utility'

// import SelectCategory from '../categories/widget/SelectCategory'
// import CreateCategoryDrawer from '../categories/widget/CreateCategoryDrawer'

import ModalAddOrderLine from './widget/ModalAddOrderLine'
import ModalAddOrderProduct from './widget/ModalAddOrderProduct'
import ModalChooseShippingAddress from '../../personal_data/customers/widget/ModalChooseShippingAddress'
// import CreateStockDrawer from './widget/CreateStockDrawer'
// import ProductMarketplaceCard from './widget/ProductMarketplaceCard'

// import DragSortingUpload from './store/utilities/dragSorting.js'
// import SpecificsSelect from './store/utilities/specificsSelect'
// import TabPrices from './store/utilities/tabPrices'


class OrderCreate extends Component {

   state = {
      action: undefined,
      itemId: null,
      saveAndClose: false,
      pageLoader: true,
      formLoader: false,
      loadersLines: [],
      formValues: {},

      loaderOrderSave: false,
      componentLanguage: this.props.authState.defaultLanguage,

      customerList: [],
      shippingMethodList: [],
      paymentMethodList: [],
      bankList: [],
      taxRateList: [],
      productCascaderList: [],

      orderLines: [],
      orderTotalQuantity: 0,
      orderTotalWeight: 0.00,
      orderTotalVolume: 0.00,
      orderTotalProducts: 0.00,
      orderTotalTax: 0.00,
      orderTotal: 0.00,
      shippingTotal: 0.00,

      actualCustomerGroup: null,
      showModalChooseShippingAddress: false,
      showModalChooseInvoicingAddress: false,
      showModalAddProduct: false,
      showModalAddOrderLine: false,

   };
   formRef = React.createRef();

   // static getDerivedStateFromProps(props, state) {
   //    if (props.action === 'edit') {
   //       const formValues = {
   //          id: props.itemData.id,
   //          name: props.itemData.name.it,
   //          percentage: props.itemData.percentage,
   //          language: 'it'
   //       }
   //       return updateObject(state, { formValues: formValues });
   //       // state.initial_values.name = props.itemData.t_name;
   //       // state.initial_values.percentage = props.itemData.percentage;
   //    }

   //    return state;
   // }

   setForm = (response, lang = this.state.componentLanguage) => {
      let tag_ids = response.tag_ids
      _.forEach(tag_ids, (tag_id, i) => {
         tag_ids[i] = tag_id.toString()
      });

      const values = {
         parent_id: response.parent_id,
         parent_name: response.parent ? response.parent.name[lang] : 'Prodotto padre',
         product_type: response.is_parent ? 'variable' : 'simple',
         price_on_variation: response.price_on_variation ? true : false,
         price_on_request: response.price_on_request ? true : false,
         sku: response.sku,
         ean: response.ean,
         upc: response.upc,
         gtin: response.gtin,
         name: response.translations[0].title,
         brand_id: response.brand_id,
         category_ids: response.category_ids,
         categories: response.categories,
         main_category_id: response.main_category_id,
         // intro: response.intro[lang],
         description: response.translations[0].description,
         file_list: response.file_list,
         gallery: response.gallery,
         manage_quantity: response.manage_quantity ? true : false,
         always_available: response.always_available ? true : false,
         use_stocks: response.use_stocks ? true : false,
         stocks: response.stocks,
         quantity: response.actual_quantity,
         price_buy: response.translations[0].price_buy,
         price_retail: response.translations[0].price_retail,
         tax_rate_id: response.translations[0].tax_rate_id,
         weight: response.weight,
         length: response.length,
         height: response.height,
         width: response.width,
         tags: tag_ids,
         specifics: response.specific_values,
         is_parent: response.is_parent,
         variations: response.variations,
         marketplace_prices: response.marketplace_prices,
         marketplace_published: response.marketplace_published,
         marketplace_sync: response.marketplace_sync,
         // is_visible: response.visible[lang],
         language: lang,
         image: response.image
      }
      return values;
   }

   getComplements = () => {
      return storeActions.getCreateOrderComplements()
         .then(response => {
            console.log(response);
            try {
               this.setState({
                  response: response,
                  customerList: response.customerList,
                  shippingMethodList: response.shippingMethodList,
                  paymentMethodList: response.paymentMethodList,
                  bankList: response.bankList,
                  taxRateList: response.taxRateList,
                  productCascaderList: response.productCascaderList,
               });

            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e);
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   componentDidMount() {
      Promise.all([
         this.getComplements()
      ]).then(() => {
         this.setState({
            pageLoader: false
         });
      });
   }

   handleDocumentTypeChange = (e) => {
      console.log('handleDocumentTypeChange', e.target.value)
      if (_.isUndefined(e.target.value) || e.target.value !== OrderDocumentTypeEnum.INVOICE) {
         this.setState({ orderInvoice: false })
      } else {
         this.setState({ orderInvoice: true })
      }
   }
   handleCustomerChange = (value) => {
      console.log('handleCustomerChange', value)
      this.setState({ loaderCustomer: true })
      if (_.isUndefined(value)) {
         this.formRef.current.setFieldsValue({
            customer_id: null,
            customer_type: null,
            customer_group: null,
            customer_email: null,
            customer_phone: null,
            customer_fiscal_code: null,
            customer_vat: null,
            customer_sdi: null,
            customer_pec: null,
            customer_note: null,
         })
         this.setState({
            actualCustomer: null,
            actualCustomerGroup: null,
            actualShippingAddress: null,
            actualBillingAddress: null,
            loaderCustomer: false
         })
      } else {
         return customersActions.getItem(value)
            .then(response => {
               console.log(response);
               const customerData = response.data
               this.formRef.current.setFieldsValue({
                  customer_id: customerData.id,
                  customer_type: customerData.is_company ? CustomerTypeEnum.COMPANY : CustomerTypeEnum.USER,
                  customer_group: !_.isNil(customerData.group) ? customerData.group.name : null,
                  customer_email: customerData.email,
                  customer_phone: customerData.phone,
                  customer_fiscal_code: customerData.fiscal_code,
                  customer_vat: customerData.vat,
                  customer_sdi: customerData.sdi,
                  customer_pec: customerData.pec,
                  customer_note: customerData.notes,
               })
               this.setState({
                  actualCustomer: customerData,
                  actualCustomerGroup: !_.isNil(customerData.group) ? customerData.group.id : null,
                  loaderCustomer: false
               })
            }).catch((e) => {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e)
               this.setState({ loaderCustomer: false })
            })
      }
   }
   showModalChooseShippingAddress = (value) => {
      if(_.isUndefined(value)) {
         value = false
      }
      this.setState({ showModalChooseShippingAddress: value })
   }
   useShippingAddress = (address) => {
      this.formRef.current.setFieldsValue({
         shipping_ship_to: address.name,
         shipping_address_1: address.address_1,
         shipping_address_2: address.address_2,
         shipping_city: address.city,
         shipping_postal_code: address.postal_code,
         shipping_state: address.state,
         shipping_country: address.country,
         shipping_email: address.email,
         shipping_phone: address.phone,
         shipping_note: address.note,
         
         invoicing_invoice_to: address.name,
         invoicing_address_1: address.address_1,
         invoicing_address_2: address.address_2,
         invoicing_city: address.city,
         invoicing_postal_code: address.postal_code,
         invoicing_state: address.state,
         invoicing_country: address.country,
      })
   }
   //OLD
   // handleShippingChange = (value) => {
   //    console.log(value)
   //    this.setState({ loaderShipping: true })
   //    if (_.isUndefined(value)) {
   //       this.formRef.current.setFieldsValue({
   //          shipping_address_1: null,
   //          shipping_address_2: null,
   //          shipping_city: null,
   //          shipping_postal_code: null,
   //          shipping_state: null,
   //          shipping_country: null,
   //          shipping_email: null,
   //          shipping_phone: null,
   //          shipping_note: null,
   //       })
   //       this.setState({
   //          actualShippingAddress: null,
   //          loaderShipping: false
   //       })
   //    } else {
   //       let actualShippingAddress = this.state.actualCustomer.shipping_addresses.find(obj => { return obj.id === value })
   //       this.formRef.current.setFieldsValue({
   //          shipping_address_1: actualShippingAddress.address_1,
   //          shipping_address_2: actualShippingAddress.address_2,
   //          shipping_city: actualShippingAddress.city,
   //          shipping_postal_code: actualShippingAddress.postal_code,
   //          shipping_state: actualShippingAddress.state,
   //          shipping_country: actualShippingAddress.country,
   //          shipping_email: actualShippingAddress.email,
   //          shipping_phone: actualShippingAddress.phone,
   //          shipping_note: actualShippingAddress.note,
   //       })
   //       this.setState({ loaderShipping: false, actualShippingAddress: actualShippingAddress })
   //    }
   // }

   handlePaymentMethodChange = value => {
      if (_.isUndefined(value)) {
         this.formRef.current.setFieldsValue({ bank_id: null })
         this.setState({ actualPayment: null })
      } else {
         let actualPayment = this.state.paymentMethodList.find(obj => { return obj.id === value })
         this.setState({ actualPayment: actualPayment })
      }
   }
   handleShippingMethodChange = value => {
      if (_.isUndefined(value)) {
         this.formRef.current.setFieldsValue({ shipping_cost: null, shipping_tax_rate: null })
         this.setState({ actualShipping: null })
      } else {
         let actualShipping = this.state.shippingMethodList.find(obj => { return obj.id === value })
         this.setState({ actualPayment: actualShipping })
      }
   }
   handleShippingCostChange = () => {
      const shipping_cost = parseFloat(this.formRef.current.getFieldValue('shipping_cost'))
      const tax_rate_id = this.formRef.current.getFieldValue('shipping_tax_rate_id')
      if (!_.isNil(shipping_cost) && !_.isNil(tax_rate_id)) {
         const tax_rate_index = this.state.taxRateList.findIndex((obj => obj.id == tax_rate_id))
         const shipping_tax = shipping_cost / 100 * parseFloat(this.state.taxRateList[tax_rate_index].percentage)
         const shipping_total = shipping_cost + shipping_tax
         const order_total = parseFloat(this.state.orderTotal) + shipping_total
         this.setState({ shippingCost: shipping_cost, shippingTax: shipping_tax, shippingTotal: shipping_total, orderTotal: order_total })
      } else {
         this.setState({ shippingCost: 0.00, shippingTax: 0.00, shippingTotal: 0.00 })
      }
   }

   showModalAddProduct = () => {
      this.setState({ showModalAddProduct: true })
   }
   hideModalAddProduct = () => {
      this.setState({ showModalAddProduct: false })
   }
   handleAddProduct = (line) => {
      console.log('handleAddProduct', line)
      this.setState({ loaderOrderLines: true })
      let actualLines = this.state.orderLines.map(a => { return { ...a } })
      if (_.isNil(line.index)) {
         line.index = actualLines.length
         line.key = line.index
         actualLines.push(line)
      } else {
         // const lineIndex = actualLines.findIndex((obj => obj.id == line.id))
         actualLines[line.index] = line
      }
      const totalProducts = this.state.orderTotalProducts += line.total_price
      const totalTax = this.state.orderTotalTax += line.total_tax
      const total = this.state.orderTotal += (line.total_price + line.total_tax)

      const totalQuantity = this.state.orderTotalQuantity += line.quantity
      const totalWeight = this.state.orderTotalWeight += line.weight
      const totalVolume = this.state.orderTotalVolume += line.volume
      this.setState({
         orderLines: actualLines,
         orderTotalProducts: totalProducts,
         orderTotalTax: totalTax,
         orderTotal: total,
         orderTotalQuantity: totalQuantity,
         orderTotalWeight: totalWeight,
         orderTotalVolume: totalVolume,
         loaderOrderLines: false
      });
   }

   showModalAddOrderLine = () => {
      this.setState({ showModalAddOrderLine: true })
   }
   hideModalAddOrderLine = () => {
      this.setState({ showModalAddOrderLine: false })
   }
   handleAddOrderLine = (line) => {
      console.log('handleAddOrderLine', line)
      this.setState({ loaderOrderLines: true })
      let actualLines = this.state.orderLines.map(a => { return { ...a } })
      if (_.isNil(line.index)) {
         line.index = actualLines.length
         line.key = line.index
         actualLines.push(line)
      } else {
         // const lineIndex = actualLines.findIndex((obj => obj.id == line.id))
         actualLines[line.index] = line
      }
      const totalProducts = this.state.orderTotalProducts += line.total_price
      const totalTax = this.state.orderTotalTax += line.total_tax
      const total = this.state.orderTotal += (line.total_price + line.total_tax)

      const totalQuantity = this.state.orderTotalQuantity += line.quantity
      const totalWeight = this.state.orderTotalWeight += line.weight
      const totalVolume = this.state.orderTotalVolume += line.volume
      this.setState({
         orderLines: actualLines,
         orderTotalProducts: totalProducts,
         orderTotalTax: totalTax,
         orderTotal: total,
         orderTotalQuantity: totalQuantity,
         orderTotalWeight: totalWeight,
         orderTotalVolume: totalVolume,
         loaderOrderLines: false
      });
   }

   editOrderLine = (line) => {
      if (_.isNil(line.id)) {
         this.setState({
            modalEditLineIndex: line.index,
            modalEditLineItem: line,
            showModalAddOrderLine: true,
         })
      } else {
         this.setState({
            modalEditLineIndex: line.index,
            modalEditLineItem: line,
            showModalAddProduct: true,
         })
      }
   }


   onFinish = values => {
      values.source = 'NZT Food'
      values.source_id = 3
      values.marketplace_id = Moment().unix().toString()
      values.order_number = _.isNil(values.internal_code) ? Moment().unix().toString() : values.internal_code
      values.status = 'Received'
      values.order_status_id = 2
      values.order_lines = this.state.orderLines
      values.total_quantity = this.state.orderTotalQuantity
      values.total_weight = this.state.orderTotalWeight
      values.total_volume = this.state.orderTotalVolume
      values.total_products = this.state.orderTotalProducts
      values.total_tax = this.state.orderTotalTax
      values.total = this.state.orderTotal
      values.shipping_cost = this.state.shippingCost
      values.shipping_tax = this.state.shippingTax
      values.shipping_total = this.state.shippingTotal
      // console.log(this.state.formValues.specifics);
      // console.log(this.state);
      // values.id = this.state.itemId
      // values.specifics = this.state.formValues.specifics
      // values.categories = this.state.formValues.category_ids
      // console.log(values);
      this.setState({ formLoader: true });
      storeActions.createOrUpdate(values)
         .then(response => {
            console.log(response);
            try {
               if (this.state.saveAndClose) {
                  this.props.history.goBack();
               }
               // const updatedValues = this.setForm(response.data);
               // this.setState({ response: response.data, itemId: response.data.id, action: 'edit', formValues: updatedValues, formLoader: false });
               this.setState({ formLoader: false });

               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
                  description: languageManager.getMessage(this.props.commonState, this.state.action === 'edit' ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {

            }

         }).catch((e) => {
            this.setState({ formLoader: false });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
         })
   }
   onFinishFailed = errorInfo => {
      notification.open({
         message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
         description: languageManager.getMessage(this.props.commonState, 'notification.error.formInvalid'),
         icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
      })
   };


   render() {
      if (this.state.pageLoader) return <Loader />
      console.log(this.state.orderLines)
      const { Title, Text } = Typography

      const mainLayout = {
         labelCol: { span: 3 },
         wrapperCol: { span: 21 },
         layout: "horizontal",
      };
      const sideLayout = {
         labelCol: { span: 10 },
         wrapperCol: { span: 14 },
         layout: "horizontal",
      };
      const cardLayout = {
         labelCol: { span: 6 },
         wrapperCol: { span: 18 },
         layout: "horizontal",
      };
      const { TextArea } = Input
      const { Option } = Select
      const { TabPane } = Tabs

      const scope = this;

      const radioStyle = {
         display: 'block',
         height: '30px',
         lineHeight: '30px',
      }

      const orderProductsTableLayout = {
         bordered: true,
         size: "small",
         pagination: false,
         loading: this.state.loaderOrderLines,
      }
      const orderProductsColumns = [
         {
            title: 'SKU',
            width: 150,
            dataIndex: 'sku',
            key: 'sku',
         },
         {
            title: 'Prodotto',
            key: 'name',
            render: (record) => (
               record.name
            ),
         },
         {
            title: 'Lotto',
            key: 'batch',
            width: 150,
            render: (record) => (
               record.batch
            ),
         },
         {
            title: languageManager.getMessage(this.props.commonState, 'component.order_summary.products.table.unit_price'),
            width: 100,
            key: 'unit_price',
            render: (record) => (
               record.unit_price ? record.unit_price.toFixed(2) + '€' : ''
            ),
         },
         {
            title: languageManager.getMessage(this.props.commonState, 'component.order_summary.products.table.quantity'),
            width: 100,
            key: 'quantity',
            render: (record) => (
               record.quantity
            ),
         },
         {
            title: languageManager.getMessage(this.props.commonState, 'component.order_summary.products.table.total_price'),
            width: 100,
            key: 'total_price',
            render: (record) => (
               record.total_price ? record.total_price.toFixed(2) + '€' : ''
            ),
         },
         {
            title: languageManager.getMessage(this.props.commonState, 'component.order_summary.products.table.total_tax'),
            width: 100,
            key: 'total_tax',
            render: (record) => (
               record.total_tax ? record.total_tax.toFixed(2) + '€' : ''
            ),
         },
         {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            align: 'right',
            width: 200,
            render: (text, record) => (
               <Space size='small'>
                  <Button type="primary" size='small'
                     icon={<EditOutlined />}
                     onClick={() => this.editOrderLine(record)}
                  />
                  <Button type="danger" size='small'
                     loading={this.state.loadersLines[record.index]}
                     icon={<DeleteOutlined />}
                     onClick={() =>
                        Modal.confirm({
                           title: languageManager.getMessage(this.props.commonState, 'component.orders.delete_order_line'),
                           icon: <ExclamationCircleOutlined />,
                           content: languageManager.getMessage(this.props.commonState, 'common.askOperationConfirm'),
                           okText: languageManager.getMessage(this.props.commonState, 'common.confirm'),
                           onOk: () => this.confirmHandler('deleteOrderLine', record.index)
                        })
                     }
                  />
               </Space>
            ),
         },
      ]

      const OrderForm = (
         <Form
            ref={this.formRef}
            {...mainLayout}
            id="OrderCreateForm"
            onFinish={this.onFinish}
         >
            <Row gutter={32}>
               <Col span={8}>
                  <Form.Item
                     {...sideLayout}
                     label="Codice interno"
                     name="internal_code"
                  >
                     <Input />
                  </Form.Item>
                  <Form.Item
                     {...sideLayout}
                     label="Tipo documento"
                     name="document_type"
                  >
                     <Radio.Group onChange={this.handleDocumentTypeChange}>
                        <Radio.Button value={OrderDocumentTypeEnum.RECEIPT}>Ricevuta fiscale</Radio.Button>
                        <Radio.Button value={OrderDocumentTypeEnum.INVOICE}>Fattura</Radio.Button>
                     </Radio.Group>
                  </Form.Item>
                  <Divider />
                  <Form.Item
                     {...sideLayout}
                     label="Data ordine"
                     name="order_date"
                     initialValue={Moment()}
                     rules={[{ required: true, message: 'Inserisci la data dell\'ordine' }]}
                  >
                     <DatePicker showTime 
                        placeholder="Data ordine"
                        format="DD-MM-YYYY HH:mm:ss"
                        allowClear={true}
                     />
                  </Form.Item>
                  <Form.Item
                     {...sideLayout}
                     label="Data evasione"
                     name="shipping_request_date"
                  >
                     <DatePicker
                        placeholder="Data evasione"
                        format="DD-MM-YYYY"
                        allowClear={true}
                     />
                  </Form.Item>
                  <Divider />
                  <Form.Item
                     {...sideLayout}
                     label="Metodo di pagamento"
                     name="payment_method_id"
                  >
                     <Select
                        showSearch
                        placeholder="Scegli il metodo di pagamento"
                        optionFilterProp="children"
                        allowClear={true}
                        onChange={this.handlePaymentMethodChange}
                     >
                        {
                           this.state.paymentMethodList.map((payment) => {
                              return <Option key={payment.id} value={payment.id}>{payment.name}</Option>
                           })
                        }
                     </Select>
                  </Form.Item>
                  <Form.Item
                     {...sideLayout}
                     label="Banca di appoggio"
                     name="bank_id"
                  >
                     <Select
                        showSearch
                        placeholder="Scegli la banca di appoggio"
                        optionFilterProp="children"
                        allowClear={true}
                        disabled={_.isNil(this.state.actualPayment) || !this.state.actualPayment.use_bank}
                     >
                        {
                           this.state.bankList.map((bank) => {
                              return <Option key={bank.id} value={bank.id}>{bank.name}</Option>
                           })
                        }
                     </Select>
                  </Form.Item>
                  <Divider />
                  <Form.Item
                     {...sideLayout}
                     label="Note"
                     name="note"
                  >
                     <TextArea />
                  </Form.Item>
               </Col>
               <Col span={16}>
                  <Form.Item
                     label="Cliente"
                     name="customer_id"
                     rules={[{ required: true, message: 'Scegli il cliente' }]}
                  >
                     <Row gutter={8}>
                        <Col span={20}>
                           <Select
                              showSearch
                              placeholder="Scegli il cliente"
                              optionFilterProp="children"
                              allowClear={true}
                              onChange={this.handleCustomerChange}
                              loading={this.state.loaderCustomer}
                           // onSearch={onSearch}
                           // filterOption={(input, option) =>
                           //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                           // }
                           >
                              {
                                 this.state.customerList.map((customer) => {
                                    return <Option key={customer.id} value={customer.id}>{customer.full_name}</Option>
                                 })
                              }
                           </Select>
                        </Col>
                        <Col>
                           <Button
                              type='success'
                              icon={<PlusOutlined />}
                           // onClick={this.showCreateCategoryDrawer}
                           />
                        </Col>
                     </Row>
                  </Form.Item>
                  {
                     this.state.actualCustomer ? (
                        <Row gutter={8}>
                           <Col span={12}>
                              <Form.Item
                                 {...cardLayout}
                                 label="Tipo cliente"
                                 name="customer_type"
                              >
                                 <Radio.Group disabled>
                                    <Radio.Button value={CustomerTypeEnum.USER}>Privato</Radio.Button>
                                    <Radio.Button value={CustomerTypeEnum.COMPANY}>Azienda</Radio.Button>
                                 </Radio.Group>
                              </Form.Item>
                              <Form.Item
                                 {...cardLayout}
                                 label="Gruppo"
                                 name="customer_group"
                              >
                                 <Input disabled />
                              </Form.Item>
                              <Form.Item
                                 {...cardLayout}
                                 label="Email"
                                 name="customer_email"
                              >
                                 <Input disabled />
                              </Form.Item>
                              <Form.Item
                                 {...cardLayout}
                                 label="Telefono"
                                 name="customer_phone"
                              >
                                 <Input disabled />
                              </Form.Item>
                           </Col>
                           <Col span={12}>
                              <Form.Item
                                 {...cardLayout}
                                 label="C.F."
                                 name="customer_fiscal_code"
                              >
                                 <Input disabled />
                              </Form.Item>
                              <Form.Item
                                 {...cardLayout}
                                 label="P.IVA"
                                 name="customer_vat"
                              >
                                 <Input disabled />
                              </Form.Item>
                              <Form.Item
                                 {...cardLayout}
                                 label="SDI"
                                 name="customer_sdi"
                              >
                                 <Input disabled />
                              </Form.Item>
                              <Form.Item
                                 {...cardLayout}
                                 label="PEC"
                                 name="customer_pec"
                              >
                                 <Input disabled />
                              </Form.Item>
                           </Col>
                           <Col span={24}>
                              <Form.Item
                                 {...mainLayout}
                                 label="Note"
                                 name="customer_note"
                              >
                                 <TextArea disabled />
                              </Form.Item>
                           </Col>
                        </Row>
                     ) : null
                  }
                  {
                     this.state.actualCustomer ? (
                        <Row gutter={8}>
                           <Col span={12}>
                              <ModalChooseShippingAddress
                                 addressList={this.state.actualCustomer.shipping_addresses}
                                 visible={this.state.showModalChooseShippingAddress}
                                 onClose={() => this.showModalChooseShippingAddress(false)}
                                 onSelect={(item) => this.useShippingAddress(item)} />
                              <Form.Item
                                 {...cardLayout}
                                 label="Spedizione"
                                 name="customer_shipping_address"
                              >
                                 <Button type='success'
                                    icon={<PlusOutlined />}
                                    disabled={_.isEmpty(this.state.actualCustomer.shipping_addresses)}
                                    onClick={() => this.showModalChooseShippingAddress(true)}>Scegli indirizzo salvato</Button>
                              </Form.Item>
                              <Form.Item
                                 {...cardLayout}
                                 label="Spedisci a"
                                 name="shipping_ship_to"
                                 key="shipping_ship_to"
                              >
                                 <Input />
                              </Form.Item>
                              <Form.Item
                                 {...cardLayout}
                                 label="Indirizzo"
                                 name="shipping_address_1"
                                 key="shipping_address_1"
                              >
                                 <Input />
                              </Form.Item>
                              <Form.Item
                                 {...cardLayout}
                                 label="Indirizzo (opz.)"
                                 name="shipping_address_2"
                                 key="shipping_address_2"
                              >
                                 <Input />
                              </Form.Item>
                              <Form.Item
                                 {...cardLayout}
                                 label="Città"
                                 name="shipping_city"
                                 key="shipping_city"
                              >
                                 <Input />
                              </Form.Item>
                              <Form.Item
                                 {...cardLayout}
                                 label="Cap"
                                 name="shipping_postal_code"
                                 key="shipping_postal_code"
                              >
                                 <Input />
                              </Form.Item>
                              <Form.Item
                                 {...cardLayout}
                                 label="Provincia"
                                 name="shipping_state"
                                 key="shipping_state"
                              >
                                 <Input />
                              </Form.Item>
                              <Form.Item
                                 {...cardLayout}
                                 label="Nazione"
                                 name="shipping_country"
                                 key="shipping_country"
                              >
                                 <Input />
                              </Form.Item>
                              <Form.Item
                                 {...cardLayout}
                                 label="Email"
                                 name="shipping_email"
                                 key="shipping_email"
                              >
                                 <Input type='email' />
                              </Form.Item>
                              <Form.Item
                                 {...cardLayout}
                                 label="Telefono"
                                 name="shipping_phone"
                                 key="shipping_phone"
                              >
                                 <Input type='tel' />
                              </Form.Item>
                              <Form.Item
                                 {...cardLayout}
                                 label="Note"
                                 name="shipping_note"
                                 key="shipping_note"
                              >
                                 <TextArea />
                              </Form.Item>
                           </Col>
                                 <Col span={12}>
                                    <Form.Item
                                       {...cardLayout}
                                       label="Fatturazione"
                                       name="customer_billing_address"
                                    >
                                       <Row gutter={8}>
                                          <Col span={20}>
                                             <Select
                                                showSearch
                                                placeholder="Scegli l'indirizzo di fatturazione"
                                                optionFilterProp="children"
                                                allowClear={true}
                                                onChange={this.handleBillingChange}
                                                loading={this.state.loaderBilling}
                                                disabled
                                             // onSearch={onSearch}
                                             // filterOption={(input, option) =>
                                             //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                             // }
                                             >
                                                {/* {
                                                   this.state.actualCustomer.invoicing_address.map((address) => {
                                                      return <Option key={address.id} value={address.id}>{address.name}</Option>
                                                   })
                                                } */}
                                             </Select>
                                          </Col>
                                          <Col>
                                             {/* <Button
                                                type='success'
                                                icon={<PlusOutlined />}
                                             // onClick={this.showCreateCategoryDrawer}
                                             /> */}
                                          </Col>
                                       </Row>
                                    </Form.Item>
                                    <Form.Item
                                       {...cardLayout}
                                       label="Fattura a"
                                       name="invoicing_invoice_to"
                                       key="invoicing_invoice_to"
                                    >
                                       <Input />
                                    </Form.Item>
                                    <Form.Item
                                       {...cardLayout}
                                       label="Indirizzo"
                                       name="invoicing_address_1"
                                       key="invoicing_address_1"
                                    >
                                       <Input />
                                    </Form.Item>
                                    <Form.Item
                                       {...cardLayout}
                                       label="Indirizzo (opz.)"
                                       name="invoicing_address_2"
                                       key="invoicing_address_2"
                                    >
                                       <Input />
                                    </Form.Item>
                                    <Form.Item
                                       {...cardLayout}
                                       label="Città"
                                       name="invoicing_city"
                                       key="invoicing_city"
                                    >
                                       <Input />
                                    </Form.Item>
                                    <Form.Item
                                       {...cardLayout}
                                       label="Cap"
                                       name="invoicing_postal_code"
                                       key="invoicing_postal_code"
                                    >
                                       <Input />
                                    </Form.Item>
                                    <Form.Item
                                       {...cardLayout}
                                       label="Provincia"
                                       name="invoicing_state"
                                       key="invoicing_state"
                                    >
                                       <Input />
                                    </Form.Item>
                                    <Form.Item
                                       {...cardLayout}
                                       label="Nazione"
                                       name="invoicing_country"
                                       key="invoicing_country"
                                    >
                                       <Input />
                                    </Form.Item>
                                 </Col>
                        </Row>
                     ) : null
                  }
               </Col>
            </Row>
            <Divider />
            <Space direction='vertical' size='small'>
               <Space direction='horizontal' size='small'>
                  <Button type='success'
                     icon={<PlusOutlined />}
                     onClick={this.showModalAddProduct}>Aggiungi prodotto</Button>
                  <Button type='success'
                     icon={<PlusOutlined />}
                     onClick={this.showModalAddOrderLine}>Aggiungi riga manuale</Button>
               </Space>
               <Table {...orderProductsTableLayout}
                  columns={orderProductsColumns}
                  dataSource={this.state.orderLines}
               />
               <Row gutter={32}>
                  <Col span={8}>
                     <Descriptions size='small' column={1} bordered contentStyle={{ fontWeight: 'bold' }}>
                        <Descriptions.Item label="Quantità">{this.state.orderTotalQuantity + ' prodotti'}</Descriptions.Item>
                        <Descriptions.Item label="Peso">{this.state.orderTotalWeight.toFixed(2) + ' Kg'}</Descriptions.Item>
                        <Descriptions.Item label="Volume">{this.state.orderTotalVolume.toFixed(6) + ' m³'}</Descriptions.Item>
                     </Descriptions>
                  </Col>
                  <Col span={8}>
                     <Form.Item
                        {...sideLayout}
                        label="Metodo di spedizione"
                        name="shipping_method_id"
                     >
                        <Select
                           showSearch
                           placeholder="Scegli il metodo di spedizione"
                           optionFilterProp="children"
                           allowClear={true}
                           onChange={this.handleShippingMethodChange}
                        >
                           {
                              this.state.shippingMethodList.map((shipping) => {
                                 return <Option key={shipping.id} value={shipping.id}>{shipping.name}</Option>
                              })
                           }
                        </Select>
                     </Form.Item>
                     <Form.Item
                        {...sideLayout}
                        label="Costo spedizione"
                        name="shipping_cost"
                     >
                        <InputNumber decimalSeparator=',' precision={2} addonAfter='€' onChange={this.handleShippingCostChange} />
                     </Form.Item>
                     <Form.Item
                        {...sideLayout}
                        label="Regime IVA"
                        name="shipping_tax_rate_id"
                     >
                        <Select
                           showSearch
                           placeholder="Seleziona il regime IVA per la spedizione"
                           optionFilterProp="children"
                           allowClear={true}
                           onChange={this.handleShippingCostChange}
                        >
                           {
                              this.state.taxRateList.map((tax) => {
                                 return <Option key={tax.id} value={tax.id}>{tax.name[this.state.componentLanguage]}</Option>
                              })
                           }
                        </Select>
                     </Form.Item>
                  </Col>
                  <Col span={8}>
                     <Descriptions size='small' column={1} bordered labelStyle={{ width: '200px' }} contentStyle={{ fontWeight: 'bold' }}>
                        <Descriptions.Item label="Subtotale prodotti">{this.state.orderTotalProducts.toFixed(2) + '€'}</Descriptions.Item>
                        <Descriptions.Item label="Totale IVA">{this.state.orderTotalTax.toFixed(2) + '€'}</Descriptions.Item>
                        <Descriptions.Item label="Spedizione">{this.state.shippingTotal.toFixed(2) + '€'}</Descriptions.Item>
                        <Descriptions.Item label="Totale ordine">{this.state.orderTotal.toFixed(2) + '€'}</Descriptions.Item>
                     </Descriptions>
                  </Col>
               </Row>
            </Space>
            <Form.Item name="id" initialValue={this.state.formValues.itemId} noStyle><Input type="hidden" /></Form.Item>
         </Form >
      )

      // const modalAddProduct = (
      //    <Modal
      //       visible={this.state.showModalAddProduct}
      //       title="Aggiungi prodotto"
      //       width={800}
      //       onCancel={() => this.setState({ showModalAddProduct: false })}
      //       footer={[
      //          <Button key="back" onClick={() => this.setState({ showModalAddProduct: false })}>
      //             Annulla
      //          </Button>,
      //          <Button key='submitForm' form="formAddProduct" type="primary" htmlType="submit" loading={this.state.formAddProductLoader}>
      //             Aggiungi
      //          </Button>
      //       ]}
      //    >
      //       <Form
      //          id="formAddProduct"
      //          layout='horizontal'
      //          onFinish={this.onAddProductFinish}
      //          onFinishFailed={this.onAddProductFinishFailed}
      //       >
      //          <Form.Item
      //             label='Cerca prodotto'
      //             name='product_id'
      //             rules={[{ required: true }]}
      //          >
      //             <Cascader
      //                options={this.state.productCascaderList}
      //                showSearch={{ filter: this.addProductFilter }}
      //                placeholder="Please select" />
      //          </Form.Item>
      //          <Divider />
      //          <Form.Item
      //             {...mainLayout}
      //             label="SKU"
      //             name="addProduct_sku"
      //          >
      //             <Input disabled />
      //          </Form.Item>
      //          <Form.Item
      //             {...mainLayout}
      //             label="Prezzo di listino"
      //             name="addProduct_listPrice"
      //          >
      //             <Input addonAfter='€' disabled />
      //          </Form.Item>
      //          <Form.Item
      //             {...mainLayout}
      //             label="IVA"
      //             name="addProduct_taxRatePercentage"
      //          >
      //             <Input addonAfter='%' disabled />
      //          </Form.Item>
      //          <Form.Item
      //             {...mainLayout}
      //             label="Quantity"
      //             name="addProduct_quantity"
      //          >
      //             <InputNumber step={1} min={1} />
      //          </Form.Item>
      //          <Form.Item
      //             {...mainLayout}
      //             label="Prezzo di vendita (iva esclusa)"
      //             name="addProduct_actualPrice"
      //          >
      //             <Input addonAfter='€' />
      //          </Form.Item>
      //          <Form.Item
      //             {...mainLayout}
      //             label="Prezzo totale"
      //             name="addProduct_totalPrice"
      //          >
      //             <Input addonAfter='€' disabled />
      //          </Form.Item>
      //          {/* <Form.Item
      //             label='Corriere'
      //             name='carrier_id'
      //             rules={[{ required: true }]}
      //          >
      //             <Select
      //                showSearch
      //                style={{ width: 200 }}
      //                placeholder="Seleziona il corriere"
      //                optionFilterProp="children"
      //             >
      //                {
      //                   this.state.carrierList.map((carrier) => {
      //                      return <Option key={carrier.id} value={carrier.id}>{carrier.name}</Option>
      //                   })
      //                }
      //             </Select>
      //          </Form.Item> */}
      //       </Form>
      //    </Modal>
      // );




      const pageContent = (
         <div className="card">
            <div className="card-header">
               <div className="row">
                  <div className="col-sm-6">
                     <Title level={5}>{languageManager.getMessage(this.props.commonState, 'component.orders.create_new')}</Title>
                  </div>
                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">
                        <Space>
                           <Select
                              showSearch
                              className="width-70px-I"
                              placeholder="Seleziona la lingua"
                              optionFilterProp="children"
                              onChange={this.setDefaultLanguageHandler}
                           >
                              {
                                 this.props.authState.userData.languages.map((lang) => {
                                    return <Option key={lang.value} value={lang.value}>{lang.label}</Option>
                                 })
                              }
                           </Select>
                        </Space>
                     </div>
                  </div>
               </div>
            </div>
            <div className="card-body">
               {OrderForm}
               <ModalAddOrderProduct
                  productCascaderList={this.state.productCascaderList}
                  visible={this.state.showModalAddProduct}
                  actualCustomerGroup={this.state.actualCustomerGroup}
                  index={this.state.modalEditLineIndex}
                  item={this.state.modalEditLineItem}
                  onClose={this.hideModalAddProduct}
                  onSubmit={(item) => this.handleAddProduct(item)} />
               <ModalAddOrderLine
                  visible={this.state.showModalAddOrderLine}
                  taxRateList={this.state.taxRateList}
                  index={this.state.modalEditLineIndex}
                  item={this.state.modalEditLineItem}
                  onClose={this.hideModalAddOrderLine}
                  onSubmit={(item) => this.handleAddOrderLine(item)} />
               {/* <OrderForm
                  submitForm={this.onFinish}
                  customerList={this.state.customerList}
                  values={this.state.formValues}
               /> */}
            </div>
            <div className="card-footer">
               <div className="align-items-center row">
                  <div className="col-sm-6">
                     <Space size='small'>
                        <Link to={{ pathname: "/orders/list" }} >
                           <Button icon={<DoubleLeftOutlined />}>
                              {languageManager.getMessage(this.props.commonState, 'common.back')}
                           </Button>
                        </Link>
                     </Space>
                  </div>

                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">
                        <Space>
                           <Button onClick={this.handleResetForm} disabled={this.state.formLoader}>Annulla</Button>
                           <Button form="OrderCreateForm" type="primary" htmlType="submit" loading={this.state.formLoader}>Salva</Button>
                           <Button form="OrderCreateForm" type="primary" htmlType="submit" onClick={this.handleSaveAndClose} loading={this.state.formLoader}>Salva e chiudi</Button>
                        </Space>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      );

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>

      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
};

export default connect(mapStateToProps)(OrderCreate);