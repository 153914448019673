export const URLs = {
   // This must be used only in loadProductConfiguration of commonActions
   DEFAULT_HOME_PAGE: '/login',
   LOGIN: '/login',
   ADMIN_PANEL: '/dashboard',
};

export const LAYOUT = {
   CHANGE_SIDEBAR_TYPE: "CHANGE_SIDEBAR_TYPE"
}

export const ACTIONS = {
   INIT_APP: 'INIT_APP',
   LOAD_LANGUAGES_SUCCESS: 'LOAD_LANGUAGES_SUCCESS',
   LOAD_PRODUCT_LANGUAGES_SUCCESS: 'LOAD_PRODUCT_LANGUAGES_SUCCESS',
   UPDATE_LANGUAGES_SUCCESS: 'UPDATE_LANGUAGES_SUCCESS',
   GET_COUNTRIES_SUCCESS: 'GET_COUNTRIES_SUCCESS',
   GET_ACTIVE_MARKETPLACES_SUCCESS: 'GET_ACTIVE_MARKETPLACES_SUCCESS',
   LOAD_MARKETPLACES_LANGUAGES_SUCCESS: 'LOAD_MARKETPLACES_LANGUAGES_SUCCESS',

   AUTH_SUCCESS: 'AUTH_SUCCESS',
   AUTH_FAIL: 'AUTH_FAIL',
   AUTH_LOGOUT: 'AUTH_LOGOUT',

   SCREEN_LOCK_REQUEST: 'SCREEN_LOCK_REQUEST',
   SCREEN_UNLOCK_REQUEST: 'SCREEN_UNLOCK_REQUEST',
   CFG_LOADING: 'CFG_LOADING',
   CFG_LOADED: 'CFG_LOADED',
   SYSTEM_FATAL_ERROR: 'SYSTEM_FATAL_ERROR'
};

export const ICON = {
   ADD: 'fas fa-plus',
   ON_OFF: 'fal fa-toggle-on',
   ANIMAL: 'fas fa-paw',
   ARCHIVE: 'fas fa-archive',
   AFTER_SELL: 'fas fa-history',
   AGENCY: 'fas fa-briefcase',
   ANGLE_UP: 'fas fa-angle-up',
   ANGLE_DOWN: 'fas fa-angle-down',
   AUTHORIZE: 'fas fa-ban',
   BANK: 'fas fa-university',
   BUILDING: 'fas fa-building',
   CANCELLED: 'fas fa-ban',
   CALCULATE: 'fas fa-calculator',
   CALENDAR: 'far fa-calendar-plus',
   CAR: 'fas fa-car-side',
   CHANGE_ACCOUNT: 'far fa-exchange-alt',
   CLAIM: 'fas fa-exclamation-triangle',
   CONTRACT: 'far fa-file-alt',
   CONFIRM: 'far fa-gavel fa-flip-horizontal',
   COPY: 'far fa-copy',
   COMPANY: 'far fa-building',
   CONNECTION: 'fas fa-plug',
   DOCUMENT_LIST: 'far fa-copy',
   DONE: 'far fa-check-circle',
   DELETE: 'far fa-trash-alt',
   EDIT: 'fas fa-pencil',
   EMISSION: 'fas fa-gavel fa-flip-horizontal',
   EXIT: 'fas fa-times-circle',
   EXTERNAL_LINK: 'fas fa-external-link-square',
   FAST_QUOTATION: 'fas fa-calculator',
   FILTER: 'fas fa-filter',
   INFO: 'fal fa-info-circle',
   LEDGER: 'far fa-calendar-alt',
   LIST: 'far fa-align-justify',
   LINK: 'far fa-link',
   MORTGAGE: 'fal fa-award',
   MESSAGE: 'far fa-envelope',
   NEXT: 'fas fa-chevron-circle-right',
   NOTE: 'fas fa-quote-right',
   NOTIFICATION: 'fas fa-bell',
   ON_GOING: 'far fa-clock',
   PREV: 'fas fa-chevron-circle-left',
   PAYMENT: 'far fa-credit-card',
   PDF: 'fas fa-file-pdf fa-2x',
   PRINT: 'fas fa-file-pdf',
   PREMIUM: 'fas fa-euro-sign',
   PREMIUM_DETAIL: 'far fa-list',
   QUESTIONNAIRE: 'far fa-clipboard-list',
   REFRESH: 'fas fa-sync-alt',
   REMOVE: 'fas fa-minus',
   RESET: 'fas fa-eraser',
   SAVE_PROPOSAL: 'far fa-save',
   SAVE_POLICY: 'far fa-check-circle',
   SEARCH: 'fas fa-search',
   UPLOAD: 'fa-upload',
   USER: 'fas fa-user',
   USERS: 'fas fa-users',
   USER_GIURIDIC: 'fas fa-user-tie',
   USER_HOLDERS: 'far fa-users',
   WARRANTY_MALATTIA: 'far fa-ambulance',
   WARRANTY_INFORTUNIO: 'far fa-dolly',
   WARRANTY: 'far fa-file-certificate'
};

export const ERROR_CODES = {
   HTTP: {
      UNAUTHORIZED: 401
   },
   CONFIGURATION_LOADING: 1001,
   PRODUCT_CONFIGURATION_LOADING: 1002,
   USER_CONFIGURATION_LOADING: 1003
};