import MarketplaceSaleRulesApi from '../../../../../api/MarketplaceSaleRulesApi'

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
 export function getList() {
   return MarketplaceSaleRulesApi.getList()
      .then(response => {
         if (response.data.success === true) {
            return response.data
         }
         else
            throw new Error('Server error')
      })
      .catch(error => {
         throw error
      })
}
/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function getListByMarketplace() {
   return MarketplaceSaleRulesApi.getListByMarketplace()
      .then(response => {
         if (response.data.success === true) {
            return response.data
         }
         else
            throw new Error('Server error')
      })
      .catch(error => {
         throw error
      })
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function getItem(itemId) {
   return MarketplaceSaleRulesApi.getItem(itemId)
      .then(response => {
         if (response.data.success === true) {
            console.log(response)
            return response.data
         }
         else
            throw new Error('Server error')
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED))
         throw error
      })
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function createOrUpdate(itemData, return_list = false) {
   return MarketplaceSaleRulesApi.createOrUpdate(itemData, return_list)
      .then(response => {
         // if (response.success === true) {
         if (response.data.success === true) {
            console.log(response)
            // return response
            return response.data
         }
         else
            throw new Error('Server error')
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED))
         throw error
      })
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function toggleActive(itemId, return_list = false) {
   return MarketplaceSaleRulesApi.toggleActive(itemId, return_list)
      .then(response => {
         if (response.data.success === true) {
            return response.data
         }
         else
            throw new Error('Server error')
      })
      .catch(error => {
         throw error
      })
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function deleteItem(itemId, return_list = false) {
   return MarketplaceSaleRulesApi.deleteItem(itemId, return_list)
      .then(response => {
         if (response.data.success === true) {
            return response.data
         }
         else
            throw new Error('Server error')
      })
      .catch(error => {
         throw error
      })
}