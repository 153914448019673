import Axios from 'axios';
import API_BASE from './apiBase';
import API_CONFIG from './apiConfig';

class PriceRulesApi {

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
    static getList() {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.priceRules.getList)
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   
   static getDiscountRulesList() {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.priceRules.getDiscountRulesList)
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getInitList() {
      return new Promise((resolve, reject) => {
         Axios.all([
            Axios.post(API_BASE.url + API_CONFIG.appUrls.priceRules.getListByMarketplace),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.marketplaces.getList),
         ])
            // .then(responseArr => {
            //    console.log(responseArr);
            //    resolve(responseArr);
            // })
            .then(Axios.spread((priceRulesList, marketplacesList) => {
               const response = {
                  priceRulesList: priceRulesList,
                  marketplacesList: marketplacesList,
               }
               resolve(response);
             }))
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getItem(itemId) {

      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.priceRules.getItem, {
            id: itemId
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getPriceRulesComplements() {
      return new Promise((resolve, reject) => {
         Axios.all([
            Axios.post(API_BASE.url + API_CONFIG.appUrls.categories.getTree),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.brands.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.taxRates.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.marketplaces.getList)
         ])
            // .then(responseArr => {
            //    console.log(responseArr);
            //    resolve(responseArr);
            // })
            .then(Axios.spread((categoriesTree, brandsList, taxRatesList, marketplacesList) => {
               const response = {
                  categoriesTree: categoriesTree,
                  brandsList: brandsList,
                  taxRatesList: taxRatesList,
                  marketplacesList: marketplacesList
               }
               resolve(response);
             }))
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static createOrUpdate(itemData, ) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.priceRules.createOrUpdate, {
            ...itemData
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static deleteItem(itemId, return_list, list_type,marketplace_id ) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.priceRules.deleteItem, {
            id: itemId,
            return_list: return_list,
            list_type: list_type,
            marketplace_id: marketplace_id,
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static toggleActive(itemData, ) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.priceRules.toggleActive, {
            ...itemData
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }
   
   static printListings(filename) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.priceRules.printListings, {
            filename: filename
         },
            {
               responseType: 'arraybuffer'
            })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
}

export default PriceRulesApi;