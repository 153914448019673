import fetchFunctions from '../services/fetchFunctions';

class commonApi {


    static loadLanguage(language) {
        return fetchFunctions.get('/languages/' + language + '-lang.json');
      //   return fetchFunctions.get('https://sync.nzttool.it/languages/' + language + '-lang.json');
    }

    static loadProductLanguage(pcode, language) {
        return fetchFunctions.get('./products/' + pcode + '/languages/' + language + '-lang.json');
    }

    static loadProductConfiguration(pcode) {
        return fetchFunctions.get('./products/' + pcode + '/config.json');
    }

    static getCountries() {
        const data = [
            { name: 'Italia', iso: '086' }
        ];

        return new Promise((resolve, reject) => {
            setTimeout(() => resolve(data), 500);
        });
    }


    static getProductStatus() {

        const data = [
            { label: 'Nuovo', value: '1' },
            { label: 'Usato', value: '2' }
        ];

        return new Promise((resolve, reject) => {
            setTimeout(() => resolve(data), 500);
        });
    }
}

export default commonApi;