import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import languageManager from '../../../../services/languageManager'

import { Form, Input, Select, Switch, Divider } from 'antd'
import { PhoneOutlined, MailOutlined } from '@ant-design/icons'

const CustomerContactForm = (props) => {
    const [layout, setLayout] = useState(props.layout)
    const [customerId, setCustomerId] = useState(props.customerId)
    const [values, setValues] = useState(props.values)

    const [form] = Form.useForm()
    const { TextArea } = Input;

    useEffect(() => {
        setCustomerId(props.customerId)
        setValues(props.values)
        if (_.isEmpty(props.values)) {
            form.resetFields()
        } else {
            form.setFieldsValue(props.values)
        }
    }, [props.values, props.customerId])

    const submitForm = (values) => {
        values.customer_id = customerId
        props.submitForm(values)
    }

    return (
        <Form
            form={form}
            {...layout}
            id="CustomerContactForm"
            onFinish={submitForm}
        >
            <Form.Item
                label="Nome"
                name="name"
                rules={[{ required: true, message: 'Inserisci il nome del contatto' }]}
            >
                <Input placeholder="Nome" />
            </Form.Item>
            <Form.Item
                label="Email"
                name="email"
            >
                <Input placeholder="Email" addonAfter={<MailOutlined />} />
            </Form.Item>
            <Form.Item
                label="Telefono"
                name="phone"
            >
                <Input placeholder="Telefono" addonAfter={<PhoneOutlined />} />
            </Form.Item>
            <Form.Item
                label="Note"
                name="note"
            >
                <TextArea placeholder="Note" />
            </Form.Item>
            <Form.Item name="id" initialValue={values.itemId} noStyle><Input type="hidden" /></Form.Item>
        </Form>
    )
}

const mapStateToProps = state => {
    return {
        commonState: state.commonState,
    }
}

export default connect(mapStateToProps)(CustomerContactForm)