import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Table, Select, Button, Space, notification, Popconfirm } from 'antd';
import { InfoCircleOutlined, PlusSquareOutlined, LeftCircleOutlined, DownloadOutlined, UploadOutlined } from '@ant-design/icons';
// import Highlighter from 'react-highlight-words';
import { SearchOutlined, CheckOutlined, CloseOutlined, EditTwoTone, DeleteTwoTone } from '@ant-design/icons';

import languageManager from '../../../services/languageManager';

import * as storeActions from './store/actions/marketplaceProducts';

import Loader from '../../common/PageElements/Loader';
import PageHeader from '../../common/PageElements/PageHeader'

class MarketplaceProductsListProducts extends Component {
   state = {
      pageInit: true,
      loader: true,
      marketplaceId: null,
      itemList: [],
      searchText: '',
      searchedColumn: '',
      componentLanguage: this.props.authState.defaultLanguage
   };

   getData = (marketplace_id) => {
      // asyncronous calls
      console.log(this.state);
      storeActions.getListForMarketplace(marketplace_id)
         .then(response => {
            this.setState({ loader: false });
            console.log(response.data);
            try {

               this.setState({ itemList: response.data });

            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            this.setState({ loader: false });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   componentDidMount() {
      if (this.props.match.params.marketplace) {
         this.setState({ marketplaceId: this.props.match.params.marketplace });
      }
      // console.log(this.state);
      // console.log(this.props.authState);

      this.getData(this.props.match.params.marketplace);
   }

   // shouldComponentUpdate(nextProps, nextState) {
   //    console.log('Should component update', this.state.parentId, nextState.parentId);
   //    // return true;
   //    return this.state.pageInit || (this.state.parentId !== nextState.parentId);
   // }

   // componentDidUpdate() {
   //    console.log('Component update');
   //    this.setState({ pageInit: false, loader: true });

   //    this.getData();
   // }

   setDefaultLanguageHandler = (value) => {
      // console.log(value);
      this.setState({ componentLanguage: value })
   }

   syncWithMarketplaceHandler = (marketplaceId) => {
      this.setState({ loader: true });
      storeActions.syncProductsWithMarketplace(marketplaceId)
         .then(response => {
            console.log(response);
            try {
               this.setState({ loader: false });
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   // closeActionHandler = () => {
   //    alert("ciao");
   // }

   render() {
      if (!this.props.authState.userData.languages) return '';
      const { Option } = Select;
      console.log('Rendering...');
      const table_columns = [
         {
            title: 'SKU', width: 150, key: 'sku', fixed: 'left',
            render: (record) => (
               record.product.sku
            )
         },
         { title: 'Codice Marketplace', width: 150, dataIndex: 'marketplace_code', key: 'marketplace_code', fixed: 'left' },
         {
            title: 'Nome',
            key: 'name',
            fixed: 'left',
            render: (record) => (
               <a onClick={() => this.changeLevelHandler(record.id)}>
                  {record.product.name[this.state.componentLanguage]}
               </a>
            ),
            sorter: (a, b) => a.product.name[this.state.componentLanguage].length - b.product.name[this.state.componentLanguage].length,
         },
         { title: 'Prezzo marketplace', dataIndex: 'standard_price', key: 'standard_price', fixed: 'left' },
         { title: 'Prezzo marketplace scontato', dataIndex: 'sell_price', key: 'sell_price', fixed: 'left' },
         { title: 'Prezzo attuale', dataIndex: 'actual_standard_price', key: 'actual_standard_price', fixed: 'left' },
         { title: 'Prezzo attuale scontato', dataIndex: 'actual_sell_price', key: 'actual_sell_price', fixed: 'left' },
         {
            title: 'Prezzo di acquisto', key: 'buy_price', fixed: 'left',
            render: (record) => (
               record.product.price_buy
            )
         },
         {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            align: 'right',
            width: 100,
            render: (text, record) => (
               <Space>
                  <a onClick={() => this.editItemHandler(record.id)} title={languageManager.getMessage(this.props.commonState, 'common.edit')}>
                     <EditTwoTone />
                  </a>
                  <Popconfirm
                     placement="topRight"
                     title={languageManager.getMessage(this.props.commonState, 'common.askDeleteConfirm')}
                     onConfirm={() => this.confirmHandler('deleteItem', record.id)}
                     okText={languageManager.getMessage(this.props.commonState, 'common.yes')}
                     cancelText={languageManager.getMessage(this.props.commonState, 'common.no')}
                  >
                     <a href="#" title={languageManager.getMessage(this.props.commonState, 'common.delete')}><DeleteTwoTone /></a>
                  </Popconfirm>
               </Space>),
         },
      ];

      const tableLayout = {
         bordered: true
      }

      const pageContent = (
         <div className="card">
            <div className="card-header">
               <div className="align-items-center row">
                  <div className="col-sm-12">
                     <div className="float-right d-none d-md-block">
                        <Space>
                           <Button
                              size='large'
                              type='primary'
                              icon={<DownloadOutlined />}
                              onClick={() => this.props.history.push('/products/export/')}
                           >
                              Esporta CSV
                        </Button>
                           <Button
                              size='large'
                              type='primary'
                              icon={<UploadOutlined />}
                              onClick={() => this.syncWithMarketplaceHandler(this.state.marketplaceId)}
                           >
                              SYNC
                        </Button>
                           {/* <Button
                              size='large'
                              type='primary'
                              icon={<PlusSquareOutlined />}
                              onClick={() => this.props.history.push('/products/edit/')}
                           >
                              Crea prodotto
                        </Button> */}
                           <Select
                              showSearch
                              size="large"
                              className="width-70px-I"
                              placeholder="Seleziona la lingua"
                              defaultValue={this.state.componentLanguage.toUpperCase()}
                              optionFilterProp="children"
                              onChange={this.setDefaultLanguageHandler}
                           //  onFocus={onFocus}
                           //  onBlur={onBlur}
                           //  onSearch={onSearch}
                           //  filterOption={(input, option) =>
                           //    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                           //  }
                           >
                              {
                                 this.props.authState.userData.languages.map((lang) => {
                                    return <Option key={lang.value} value={lang.value}>{lang.label}</Option>
                                 })
                              }
                           </Select>
                        </Space>
                     </div>
                  </div>
               </div>
            </div>
            <div className="card-body">
               {this.state.loader ? <Loader /> : (
                  <Table {...tableLayout} columns={table_columns} dataSource={this.state.itemList} onChange={onChange} scroll={{ x: 1000 }} />
               )}
            </div>
            <div className="card-footer">
               <Button
                  size='large'
                  onClick={() => { this.props.history.goBack() }}
               >
                  <i className="mdi mdi-arrow-left-drop-circle-outline mr-1"></i>
                  {languageManager.getMessage(this.props.commonState, 'common.back')}
               </Button>
            </div>
         </div>
      );

      function onChange(pagination, filters, sorter, extra) {
         console.log('params', pagination, filters, sorter, extra);
      }

      const helpButton = {
         title: "Aiuto"
      }

      const buttons = [];
      buttons.push(helpButton);

      return (
         <div className="container-fluid">
            <PageHeader
               title={languageManager.getMessage(this.props.commonState, 'component.products.title')}
               buttons={buttons}
            />
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>
      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState,
   };
};

export default connect(mapStateToProps)(MarketplaceProductsListProducts);