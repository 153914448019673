import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { Form, Input, Select, Switch, Button, Space, notification } from 'antd';
import { InfoCircleOutlined, LeftCircleOutlined, CheckOutlined, StopOutlined } from '@ant-design/icons';

import languageManager from '../../../services/languageManager';
import { getSpecificComplements } from './store/actions/specifics';
import * as storeActions from './store/actions/specificValues';

import Loader from '../../common/PageElements/Loader';
import PageHeader from '../../common/PageElements/PageHeader'

class SpecificValuesEdit extends Component {

   state = {
      action: undefined,
      saveAndClose: false,
      pageLoader: true,
      saveLoader: false,
      formValues: {},
      marketplacesList: [],
      componentLanguage: this.props.authState.defaultLanguage
   };

   // static getDerivedStateFromProps(props, state) {
   //    if (props.action === 'edit') {
   //       const formValues = {
   //          id: props.itemData.id,
   //          name: props.itemData.name.it,
   //          percentage: props.itemData.percentage,
   //          language: 'it'
   //       }
   //       return updateObject(state, { formValues: formValues });
   //       // state.initial_values.name = props.itemData.t_name;
   //       // state.initial_values.percentage = props.itemData.percentage;
   //    }

   //    return state;
   // }

   getItem = (id) => {
      return storeActions.getItem(id)
         .then(response => {
            console.log(response);
            try {
               const updatedValues = {
                  ...response.data,
                  name: response.data.name[this.state.componentLanguage],
                  description: !_.isNil(response.data.description) ? response.data.description[this.state.componentLanguage] : '',
                  is_active: response.data.active[this.state.componentLanguage],
                  language: this.state.componentLanguage,
               }
               this.setState({ formValues: updatedValues });

            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   getComplements = () => {
      return getSpecificComplements()
         .then(response => {
            console.log(response);
            try {
               this.setState({
                  marketplacesList: response.marketplacesList,
               });

            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e);
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   componentDidMount() {
      const parentId = this.props.match.params.parent;
      if (this.props.match.params.id) {
         const itemId = this.props.match.params.id
         Promise.all([
            this.getItem(itemId),
            this.getComplements()
         ]).then(() => {
            this.setState({
               action: 'edit',
               pageLoader: false
            });
         });
      }
      else {
         const formValues = {
            is_active: false,
            language: this.state.componentLanguage,
            specific_id: parentId
         }
         Promise.all([
            this.getComplements()
         ]).then(() => {
            this.setState({
               formValues: formValues,
               action: 'insert',
               pageLoader: false
            });
         });
      }

   }

   handleSaveAndClose = () => {
      this.setState({ saveAndClose: true });
      console.log(this.state);
   }

   onFinish = values => {
      this.setState({ loader: true });
      storeActions.createOrUpdate(values)
         .then(response => {
            this.setState({ loader: false });
            console.log(response);
            try {
               if (this.state.saveAndClose) {
                  this.props.history.push('/specifics/values/list/' + this.props.match.params.parent);
               }

               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
                  description: languageManager.getMessage(this.props.commonState, this.state.action === 'edit' ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {

            }

         }).catch((e) => {
            this.setState({ loader: false });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
         });
   };

   onFinishFailed = errorInfo => {
      notification.open({
         message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
         description: languageManager.getMessage(this.props.commonState, 'notification.error.formInvalid'),
         icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
      })
   };

   onActiveSwitchChange = (checked) => {
      let newFormValues = { ...this.state.formValues }
      newFormValues.is_active = checked;
      this.setState({ formValues: newFormValues });
   }

   // onReset = () => {
   //   form.resetFields();
   // };

   render() {
      if (!this.props.authState.userData.languages) return '';
      const { TextArea } = Input;
      const { Option } = Select;

      const layout = {
         size: 'small',
         labelCol: { span: 8 },
         wrapperCol: { span: 16 },
      };
      const tailLayout = {
         wrapperCol: { offset: 8, span: 16 },
      };

      let form = (
         <Form
            {...layout}
            id="createSpecific"
            name="basic"
            initialValues={this.state.formValues}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
         >
            <Form.Item
               label="Nome"
               name="name"
               rules={[{ required: true, message: 'Inserisci il nome' }]}
            >
               <Input />
            </Form.Item>
            <Form.Item
               label="Descrizione"
               name="description"
               rules={[{ message: 'Descrizione estesa' }]}
            >
               <TextArea rows={4} />
            </Form.Item>

            {
               this.state.marketplacesList.map((marketplace) => {
                  return (
                     <Form.Item
                        key={marketplace.id}
                        label={marketplace.name}
                        name={'marketplace_value_' + marketplace.id}
                     >
                        <Input style={{ width: '300px' }} placeholder='Valore sul marketplace' />
                     </Form.Item>
                  )
               })
            }

            <Form.Item label="id" name="id" noStyle><Input type="hidden" /></Form.Item>
            <Form.Item label="language" name="language" noStyle><Input type="hidden" /></Form.Item>
            <Form.Item label="specific_id" name="specific_id" noStyle><Input type="hidden" /></Form.Item>

         </Form>
      );


      const pageContent = (
         <div className="card">
            <div className="card-header">
               <div className="align-items-center row">
                  <div className="col-sm-6">{languageManager.getMessage(this.props.commonState, 'component.specific_values.title')}</div>
                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">
                        <Space>
                           <Select
                              showSearch
                              className="width-70px-I"
                              placeholder="Seleziona la lingua"
                              defaultValue={this.state.componentLanguage.toUpperCase()}
                              optionFilterProp="children"
                              onChange={this.setDefaultLanguageHandler}
                           //  onFocus={onFocus}
                           //  onBlur={onBlur}
                           //  onSearch={onSearch}
                           //  filterOption={(input, option) =>
                           //    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                           //  }
                           >
                              {
                                 this.props.authState.userData.languages.map((lang) => {
                                    return <Option key={lang.value} value={lang.value}>{lang.label}</Option>
                                 })
                              }
                           </Select>
                        </Space>
                     </div>
                  </div>
               </div>
            </div>
            <div className="card-body">
               {this.state.pageLoader ? <Loader /> : form}
            </div>
            <div className="card-footer">
               <div className="align-items-center row">
                  <div className="col-sm-6">
                     <Space>
                        <Button
                           size='large'
                           onClick={() => { this.props.history.goBack() }}
                        >
                           <i className="mdi mdi-arrow-left-drop-circle-outline mr-1"></i>
                           {languageManager.getMessage(this.props.commonState, 'common.back')}
                        </Button>
                     </Space>
                  </div>

                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">

                        <Space>
                           <Button form="createSpecific" htmlType="button" loading={this.state.saveLoader}>Annulla</Button>
                           <Button form="createSpecific" type="primary" htmlType="submit" loading={this.state.saveLoader}>Salva</Button>
                           <Button form="createSpecific" type="primary" htmlType="submit" onClick={this.handleSaveAndClose} loading={this.state.saveLoader}>Salva e chiudi</Button>
                        </Space>

                     </div>
                  </div>
               </div>
            </div>
         </div>
      );

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>

      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(SpecificValuesEdit);