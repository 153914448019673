import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import Moment from 'moment'

import { OrderStatusEnum, OrderStatusActionEnum } from '../../../shared/enums.ts'

import { Table, Button, Tooltip, Space, notification, DatePicker, Row, Col, Collapse } from 'antd'
import { InfoCircleOutlined, EyeOutlined, VerticalAlignTopOutlined, VerticalAlignBottomOutlined, EditOutlined, PrinterOutlined, CheckOutlined, PlusOutlined } from '@ant-design/icons'

import languageManager from '../../../services/languageManager'

import * as storeActions from './store/actions/warehouse_movements'

import ModalChargeMovement from './widget/ModalChargeMovement'
import ModalDischargeMovement from './widget/ModalDischargeMovement'

import Loader from '../../common/PageElements/Loader'
import TableFilters from '../../common/components/TableFilters'
import DropdownButton from '../../common/components/DropdownButton'
import DropdownMenu from '../../common/components/DropdownMenu'

class WarehouseSituation extends Component {
   state = {
      localPath: this.props.history.location.pathname,
      pageLoader: true,
      tableLoader: false,
      multipleActionLoader: false,
      orderStatusActionLoader: false,
      printListDateLoader: false,

      itemList: [],
      fullListIds: [],
      componentLanguage: this.props.authState.defaultLanguage,

      current_page: this.props.location.state ? this.props.location.state.previousState.current_page : 1,
      page_size: this.props.location.state ? this.props.location.state.previousState.page_size : 50,
      total_result: 0,

      selectedRowKeys: [],
      selectedAll: false,
      multipleActionList: [
         {
            action: 'exportCsv',
            name: 'Esporta selezionati in CSV'
         },
         {
            action: 'printList',
            name: 'Stampa selezionati in PDF'
         },
      ],
      selectedWaitingAcceptance: true,
      action: "",

      showTableFilters: false,
      filters: this.props.location.state ? this.props.location.state.previousState.filters : [],
      filtersValues: this.props.location.state ? this.props.location.state.previousState.filtersValues : {},
      appliedFilters: this.props.location.state ? this.props.location.state.previousState.appliedFilters : [],

      activeMarketplaces: [],

      printSituationDate: null

   };

   componentDidMount() {
      // console.log(this.props.location.state, this.state);
      Promise.all([
         this.getStocksList(this.state.current_page, this.state.page_size, this.state.filtersValues),
         // this.getComplements()
      ]).then(() => {
         this.setState({
            pageLoader: false
         });
      });
   }

   getStocksList = (page, pageSize, filters) => {
      // asyncronous calls
      this.setState({ tableLoader: true })
      return storeActions.getProductStocksListExtended(page, pageSize, filters)
         .then(response => {
            console.log(response);
            try {
               this.setState({
                  itemList: response.data.data,
                  fullListIds: response.full_list_ids,
                  current_page: page,
                  page_size: pageSize,
                  total_result: response.data.total,
                  filters: response.list_filters,
                  appliedFilters: response.applied_filters,
                  tableLoader: false
               });
            } catch (e) {
               this.setState({ tableLoader: false })
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            this.setState({ tableLoader: false })
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   // getComplements = () => {
   //    return storeActions.getComplements()
   //       .then(response => {
   //          console.log(response);
   //          try {
   //             this.setState({
   //                response: response,
   //                customerList: response.customerList,
   //                supplierList: response.supplierList,
   //                productCascaderList: response.productCascaderList,
   //             });

   //          } catch (e) {
   //             notification.open({
   //                message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
   //                description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
   //                icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
   //             })
   //             if (this.props.commonState.debug) console.log(e);
   //          }

   //       }).catch((e) => {
   //          notification.open({
   //             message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
   //             description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
   //             icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
   //          })
   //          if (this.props.commonState.debug) console.log(e);
   //       });
   // }

   exportCsvHandler = () => {
      // console.log(this.state.selectedRowKeys);
      this.setState({ multipleActionLoader: true });
      let now = Moment().format('YYYY.MM.DD-HH.mm.ss');
      let filename = now + '_WAREHOUSE_SITUATION.csv';
      storeActions.exportProductStocksList(filename, this.state.selectedRowKeys)
         .then(response => {
            this.setState({ multipleActionLoader: false });
            notification.success({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.confirm.exportOk'),
            })
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            this.setState({ multipleActionLoader: false });
            if (this.props.commonState.debug) console.log(e);
         });
   }
   printListHandler = () => {
      // console.log(this.state.selectedRowKeys);
      this.setState({ multipleActionLoader: true });
      let now = Moment().format('YYYY.MM.DD-HH.mm.ss');
      let filename = now + '_WAREHOUSE_SITUATION.pdf';
      storeActions.printProductStocksList(filename, this.state.selectedRowKeys)
         .then(response => {
            this.setState({ multipleActionLoader: false });
            notification.success({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.confirm.exportOk'),
            })
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            this.setState({ multipleActionLoader: false });
            if (this.props.commonState.debug) console.log(e);
         });
   }
   printListBeforeDateHandler = () => {
      // console.log(this.state.selectedRowKeys);
      this.setState({ printListDateLoader: true });
      let now = Moment().format('YYYY.MM.DD-HH.mm.ss');
      let filename = now + '_WAREHOUSE_SITUATION.pdf';
      storeActions.printListBeforeDate(filename, this.state.printSituationDate)
         .then(response => {
            this.setState({ printListDateLoader: false });
            notification.success({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.confirm.exportOk'),
            })
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            this.setState({ printListDateLoader: false });
            if (this.props.commonState.debug) console.log(e);
         });
   }

   listPaginationChangeHandler = (page, pageSize) => {
      console.log(page, pageSize, this.state.filtersValues);
      this.getStocksList(page, pageSize, this.state.filtersValues);
   }

   handleTableChange = (pagination, filters, sorter) => {
      console.log(pagination, filters, sorter)
      // this.fetch({
      //   sortField: sorter.field,
      //   sortOrder: sorter.order,
      //   pagination,
      //   ...filters,
      // });
   };

   onSelectChange = (selectedRowKeys, selection) => {
      this.setState({ selectedRowKeys: selectedRowKeys })
   };
   selectAllHandler = () => {
      if (this.state.selectedAll) {
         this.setState({
            selectedAll: false,
            selectedRowKeys: [],
         });
      }
      else {
         this.setState({
            selectedAll: true,
            selectedRowKeys: this.state.fullListIds,
         });
      }
   }
   multipleActionHandler = (value) => {
      const props = value.item.props
      switch (props.action) {
         case 'exportCsv':
            this.exportCsvHandler();
            break;
         case 'printList':
            this.printListHandler();
            break;
         default:
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
            })
            break;

      }
   }

   setPrintSituationDate = (date, dateString) => {
      const endDate = !_.isNil(date) && Moment(date.isValid()) ? Moment(date).add(1, 'd').format('YYYY-MM-DD') : null;
      // console.log(date, dateString, endDate);
      this.setState({ printSituationDate: endDate })
   }

   submitFilters = (values) => {
      // console.log("Selected filters", values)
      Object.keys(values).forEach(function (key) {
         if (Moment.isMoment(values[key])) {
            values[key] = Moment(values[key]).format('YYYY-MM-DD');
         }
      });
      this.setState({ filtersValues: values })
      this.getStocksList(1, this.state.page_size, values);
   }
   resetFilters = () => {
      console.log("Reset filters")
      this.getStocksList(1, this.state.page_size, []);
   }

   render() {
      if (this.state.pageLoader) return <Loader />;
      const { Panel } = Collapse;

      const table_columns = [
         // { title: 'ID', width: 100, dataIndex: 'id', key: 'id', fixed: 'left' },
         // {
         //    title: 'ID Marketplace',
         //    width: 150,
         //    dataIndex: 'marketplace_id',
         //    key: 'marketplace_id',
         //    fixed: 'left',
         //    render: (text, record) => (
         //       <a onClick={() => this.viewItemHandler(record.id)} >{text}</a>
         //    ),
         // },
         {
            title: 'SKU',
            width: 120,
            key: 'sku',
            fixed: 'left',
            render: (text, record) => (
               record.product_sku
            ),
            sorter: (a, b) => a.product_sku.localeCompare(b.product_sku),
         },
         {
            title: 'Prodotto',
            dataIndex: 'product_name',
            key: 'product_name',
            fixed: 'left',
            // render: (text, record) => (
            //    <Link to={'/purchase_orders/details/' + record.id}>{text}</Link>
            // ),
            sorter: (a, b) => a.product_name.localeCompare(b.product_name),
         },
         {
            title: 'Lotto',
            width: 150,
            dataIndex: 'batch',
            key: 'batch',
            // render: (text, record) => (
            //    <Link to={'/purchase_orders/details/' + record.id}>{text}</Link>
            // ),
            sorter: (a, b) => a.batch.localeCompare(b.batch),
         },
         {
            title: 'Scadenza',
            width: 100,
            key: 'expiration_date',
            render: (text, record) => (
               record.expiration_date ? Moment(record.expiration_date).format('L') : null
            ),
            sorter: (a, b) => {
               a = a.expiration_date || ''
               b = b.expiration_date || ''
               return a.localeCompare(b)
            },
         },
         // {
         //    title: 'Fornitore',
         //    width: 150,
         //    key: 'supplier_name',
         //    render: (text, record) => (
         //       record.supplier_name || null
         //    ),
         //    sorter: (a, b) => {
         //       a = a.supplier_name || ''
         //       b = b.supplier_name || ''
         //       return a.localeCompare(b)
         //    },
         // },
         {
            title: 'Giacenza',
            width: 75,
            key: 'warehouse_quantity',
            render: (text, record) => (
               record.warehouse_quantity
            ),
            // sorter: (a, b) => a-b,
         },
         {
            title: 'Impegnato',
            width: 75,
            key: 'reserved',
            render: (text, record) => (
               record.reserved
            ),
            // sorter: (a, b) => a-b,
         },
         {
            title: 'Disponibile',
            width: 75,
            key: 'available',
            render: (text, record) => (
               (record.warehouse_quantity - record.reserved).toFixed(2)
            ),
            // sorter: (a, b) => a-b,
         },
         {
            title: 'In arrivo',
            width: 75,
            dataIndex: 'ordered',
            key: 'ordered',
            render: (text, record) => (
               record.ordered
            ),
            // sorter: (a, b) => a-b,
         },
         // {
         //    title: 'Arrivo previsto',
         //    width: 120,
         //    key: 'deliver_request_date',
         //    render: (record) => (
         //       record.deliver_request_date ? Moment(record.deliver_request_date).format('L') : null
         //    ),
         //    sorter: (a, b) => {
         //       a = a.deliver_request_date || ''
         //       b = b.deliver_request_date || ''
         //       return a.localeCompare(b)
         //    },
         // },
      ];

      const { selectedRowKeys } = this.state;
      const tableLayout = {
         bordered: true,
         className: "table-striped-rows",
         size: "small",
         loading: this.state.tableLoader,
         onChange: this.handleTableChange,
         pagination: {
            pageSize: this.state.page_size,
            onChange: this.listPaginationChangeHandler,
            total: this.state.total_result
         },
         rowSelection: {
            selectedRowKeys,
            onChange: this.onSelectChange,
            preserveSelectedRowKeys: true,
         }
      }


      const pageFilters = (
         <Collapse defaultActiveKey={['filters']}>
            <Panel header="Filtri" key="filters">
               <TableFilters submitFilters={this.submitFilters} resetFilters={this.resetFilters} filters={this.state.filters} selectValues={this.state.filtersValues} />
            </Panel>
         </Collapse>
      );

      const hasSelected = selectedRowKeys.length > 0;
      const singleSelected = selectedRowKeys.length > 1;
      const pageContent = (
         <div className="card">
            <div className="card-header">
               <div className="align-items-center row">
                  <div className="col-sm-6">{languageManager.getMessage(this.props.commonState, 'component.warehouse_situation.title')}</div>
                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block"></div>
                  </div>
               </div></div>
            <div className="card-body">
               <Row>
                  <Col span={24}>{pageFilters}</Col>
               </Row>
               <Row>
                  <Col span={24}>
                     <div className="align-items-center row" style={{ marginTop: 15, marginBottom: 5 }}>
                        <div className="col-sm-6">
                           <div className="d-none d-md-block">
                              <Space>
                                 <Button
                                    onClick={this.selectAllHandler}
                                 >{this.state.selectedAll ? 'Deseleziona' : 'Seleziona tutto'}
                                 </Button>
                                 <span>
                                    {hasSelected ? singleSelected ? `${selectedRowKeys.length} elementi selezionati` : `${selectedRowKeys.length} elemento selezionato` : ''}
                                 </span>
                                 <DropdownMenu
                                    type='primary'
                                    title='Azioni multiple'
                                    action={this.multipleActionHandler}
                                    values={this.state.multipleActionList}
                                    disabled={!hasSelected}
                                    loading={this.state.multipleActionLoader}
                                 />
                              </Space>
                           </div>
                        </div>
                        <div className="col-sm-6">
                           <div className="float-right d-none d-md-block">
                              <Space size='small'>

                                 {/* <Button type="primary" loading={this.state.loader}>
                                    Mostra solo righe con differenze
                                 </Button> */}
                                 <DatePicker onChange={this.setPrintSituationDate} format={'DD/MM/YYYY'} />
                                 <Button
                                    type='primary'
                                    onClick={this.printListBeforeDateHandler}
                                    loading={this.state.printListDateLoader}
                                    disabled={_.isNil(this.state.printSituationDate)}
                                 >Stampa situazione in data
                                 </Button>
                              </Space>
                           </div>
                        </div>
                     </div>
                  </Col>
               </Row>

               <Row>
                  <Col span={24}>
                     <Table {...tableLayout}
                        columns={table_columns}
                        dataSource={this.state.itemList}
                        // rowSelection={rowSelection}
                        rowKey={(record) => (record.id)}
                        scroll={{ x: 'max-content' }}
                     />
                  </Col>
               </Row>
            </div>
         </div >
      );

      // const helpButton = {
      //    title: "Aiuto"
      // }

      // const buttons = [];
      // buttons.push(helpButton);

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>
      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState,
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(WarehouseSituation);