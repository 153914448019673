import authenticationApi from '../../../../api/authenticationApi';
import _ from 'lodash';

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function editPassword(data) {
   console.log(data);
   return authenticationApi.editPassword(data)
      .then(response => {
         // if (response.success === true) {
         if (response.data.success === true) {
            console.log(response);
            // return response;
            return response.data;
         }
         else {
            const error = response.data.data.error ? response.data.data.error : 'Server error';
            throw new Error(error);
         }
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}