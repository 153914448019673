import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Form, Input, Select, Radio, Button, Space, notification, InputNumber } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, CheckOutlined, CloseOutlined, InfoCircleOutlined, LeftCircleOutlined } from '@ant-design/icons';

import languageManager from '../../../services/languageManager';
import * as storeActions from './store/actions/priceRulesSets';

import Loader from '../../common/PageElements/Loader';
import PageHeader from '../../common/PageElements/PageHeader'

import SelectCategorySingle from '../../catalogue/categories/widget/SelectCategorySingle'

class PriceRulesSetsEdit extends Component {

   state = {
      saveAndClose: false,
      loader: true,
      formValues: {
         variationsType: []
      },
      componentLanguage: this.props.authState.defaultLanguage,
      priceRulesList: [],
      categoriesTree: [],
      brandsList: [],
      taxRatesList: [],
      marketplacesList: [],
      showCategorySelect: false,
      showBrandSelect: false,
   };

   setForm = (response, lang = this.state.componentLanguage) => {
      const values = {
         name: response.name,
         description: response.description,
         base_price: response.base_price,
         price_rules_ids: response.price_rules_ids,
         marketplace_id: response.marketplace_id,
         on_category: response.on_category,
         category_id: response.category_id,
         on_brand: response.on_brand,
         brand_id: response.brand_id,
         variationsType: [],
         id: response.id,
         language: lang
      }
      return values;
   }

   componentDidMount() {
      storeActions.getComplements()
         .then(response => {
            console.log(response);
            try {
               this.setState({
                  complementsResponse: response,
                  priceRulesList: response.priceRulesList,
                  categoriesTree: response.categoriesTree,
                  brandsList: response.brandsList,
                  taxRatesList: response.taxRatesList,
                  marketplacesList: response.marketplacesList
               });
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e);
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });

      if (this.props.match.params.id) {
         const itemId = this.props.match.params.id;
         // asyncronous calls
         storeActions.getItem(itemId)
            .then(response => {
               console.log(response);
               try {
                  const updatedValues = this.setForm(response.data);
                  this.setState({
                     action: 'edit',
                     getItemResponse: response.data,
                     formValues: updatedValues,
                     loader: false
                  });
               } catch (e) {
                  notification.open({
                     message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                     description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                     icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                  })
                  if (this.props.commonState.debug) console.log(e);
               }

            }).catch((e) => {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e);
            });
      }
      else {
         const formValues = {
            language: this.state.componentLanguage
         }
         this.setState({
            formValues: formValues,
            action: 'insert',
            loader: false
         });
      }
   }

   handleSaveAndClose = () => {
      this.setState({ saveAndClose: true });
   }

   onFinish = values => {
      console.log(values);
      this.setState({ loader: true });
      storeActions.createOrUpdate(values)
         .then(response => {
            console.log(response);
            try {
               const updatedValues = this.setForm(response.data);
               this.setState({
                  formValues: updatedValues,
                  loader: false
               });
               if (this.state.saveAndClose) {
                  this.props.history.push('/price_rules/list');
               }

               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
                  description: languageManager.getMessage(this.props.commonState, this.state.action === 'edit' ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
                  icon: <CheckCircleOutlined className="color-green-I" />,
               })
            } catch (e) {
               this.setState({ loader: false });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <CloseCircleOutlined className="color-red-I" />,
               })
            }

         }).catch((e) => {
            this.setState({ loader: false });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <CloseCircleOutlined className="color-red-I" />,
            })
         });
   };

   onFinishFailed = errorInfo => {
      notification.open({
         message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
         description: languageManager.getMessage(this.props.commonState, 'notification.error.formInvalid'),
         icon: <CloseCircleOutlined className="color-red-I" />,
      })
   };

   toggleCategorySelect = checked => {
      this.setState({ showCategorySelect: checked });
   }

   toggleBrandSelect = checked => {
      this.setState({ showBrandSelect: checked });
   }
   
   handleVariationTypeChange = (e) => {
      console.log(e.target.value)
      let newValues = { ...this.state.formValues }
      newValues.price_on_variation = e.target.value
      this.setState({ formValues: newValues })
   }

   render() {
      if (!this.props.authState.userData.languages) return '';
      // console.log(this.props.authState.userData.languages);
      const layout = {
         labelCol: { span: 8 },
         wrapperCol: { span: 14 },
      };
      const tailLayout = {
         wrapperCol: { offset: 8, span: 16 },
      };
      const { TextArea } = Input;
      const { Option } = Select;


      let form = (
         <Form
            {...layout}
            id="formPriceRulesSet"
            name="basic"
            initialValues={this.state.formValues}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
         >
            <Form.Item
               label="Nome del set"
               name="name"
               placeholder="Inserisci il nome"
               rules={[{ required: true, message: 'Inserisci il nome' }]}
            >
               <Input />
            </Form.Item>
            <Form.Item
               label="Descrizione"
               name="description"
               rules={[{ message: 'Inserisci la descrizione' }]}
            >
               <TextArea
                  rows={4}
               />
            </Form.Item>
            <Form.Item
               label="Tipo di variazione"
               name="variation_type"
               rules={[{ required: true, message: 'Scegli il tipo di variazione' }]}>
               <Radio.Group
                  onChange={this.handleVariationTypeChange}>
                  <Radio.Button value='percentage'>Percentuale</Radio.Button>
                  <Radio.Button value='fixed'>Fissa</Radio.Button>
               </Radio.Group>
            </Form.Item>
            <Form.Item
               label="Tipo di variazione"
               name="variation_type"
               rules={[{ required: true, message: 'Scegli il tipo di variazione' }]}>
               <Radio.Group
                  onChange={this.handleVariationTypeChange}>
                  <Radio.Button value='discount'>Sconto</Radio.Button>
                  <Radio.Button value='increment'>Aumento</Radio.Button>
               </Radio.Group>
            </Form.Item>
            <Form.Item
               label="Variazione percentuale"
               name="variation_percentage"
               rules={[{ message: 'Inserisci il valore' }]}
            >
               <Input
                  type="number"
                  addonAfter="%"
                  style={{ width: 150 }}
                  min={0}
                  max={90}
                  step={0.1}
                  precision={2}
               />
            </Form.Item>
            <Form.Item
               label="Variazione fissa"
               name="variation_amount"
               rules={[{ message: 'Inserisci il valore' }]}
            >
               <Input
                  type="number"
                  addonAfter="€"
                  style={{ width: 150 }}
                  step={0.1}
                  precision={2}
               />
            </Form.Item>

            <Form.Item label="id" name="id" noStyle><Input type="hidden" /></Form.Item>
            <Form.Item label="language" name="language" noStyle><Input type="hidden" /></Form.Item>

         </Form>
      );

      const pageContent = (
         <div className="card">
            <div className="card-header">
               <div className="align-items-center row">
                  <div className="col-sm-6">
                     {languageManager.getMessage(this.props.commonState, 'component.price_rules_sets.title')}
                  </div>
                  <div className="col-sm-6"></div>
               </div>
            </div>
            <div className="card-body">
               {this.state.loader ? <Loader /> : form}
            </div>
            <div className="card-footer">
               <div className="align-items-center row">
                  <div className="col-sm-6">
                     <Space>
                        <Button
                           size='large'
                           onClick={() => { this.props.history.goBack() }}
                        >
                           <i className="mdi mdi-arrow-left-drop-circle-outline mr-1"></i>
                           {languageManager.getMessage(this.props.commonState, 'common.back')}
                        </Button>
                     </Space>
                  </div>

                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">

                        <Space>
                           <Button form="formPriceRulesSet" htmlType="button" onClick={this.onReset}>Annulla</Button>
                           <Button form="formPriceRulesSet" type="primary" htmlType="submit">Salva</Button>
                           <Button form="formPriceRulesSet" type="primary" htmlType="submit" onClick={this.handleSaveAndClose}>Salva e chiudi</Button>
                        </Space>

                     </div>
                  </div>
               </div>
            </div>
         </div>
      );

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>

      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(PriceRulesSetsEdit);