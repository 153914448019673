import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import languageManager from '../../../../services/languageManager'
import { startLoading } from '../../../../shared/utility'

import { Descriptions, Space, notification, Typography, Card, Button, Modal } from 'antd'
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons'

import * as storeActions from '../store/actions/customerContacts'

import ShippingAddressDrawer from './ShippingAddressDrawer'

class CustomerContactCard extends Component {
    state = {
        ...this.props,
        loaderDelete: false,
        componentLoader: true,
    }

    componentDidMount() {
        console.log(this.props)
        Promise.all([
        ]).then(() => {
            this.setState({
                componentLoader: false,
                // rangeChangeLoader: false,
            });
        });
    }

    handleDelete(itemId) {
        this.setState({ loaderDelete: true });
        storeActions.deleteItem(itemId, true)
           .then(response => {
              console.log(response);
              try {
                 this.props.onDelete(response.data)
                 notification.success({
                    message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
                    description: languageManager.getMessage(this.props.commonState, 'notification.confirm.deleteOk'),
                 })
              } catch (e) {
                 notification.error({
                    message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                    description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                 })
              } finally {
                this.setState({ loaderDelete: false });
              }
           }).catch((e) => {
            this.setState({ loaderDelete: false });
              notification.error({
                 message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                 description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
              })
              if (this.props.commonState.debug) console.log(e);
           });
    }

    render() {
        const { Title } = Typography
        const contact = this.props.item
        return (
            <Card
                title={
                    <Title level={5}>{contact.name}</Title>
                }
                size='small'
                bordered={true}
                key={contact.id}
                extra={
                    <Space size='small'>
                        <Button type="primary" size='small'
                            icon={<EditOutlined />}
                            onClick={this.props.onEdit}
                        />
                        <Button type="danger" size='small'
                            loading={this.state.loaderDelete}
                            icon={<DeleteOutlined />}
                            onClick={() =>
                                Modal.confirm({
                                    title: languageManager.getMessage(this.props.commonState, 'component.customers.delete_shipping_address'),
                                    icon: <ExclamationCircleOutlined />,
                                    content: languageManager.getMessage(this.props.commonState, 'common.askOperationConfirm'),
                                    okText: languageManager.getMessage(this.props.commonState, 'common.confirm'),
                                    onOk: () => this.handleDelete(contact.id)
                                })
                            }
                        />
                    </Space>
                }
            >
                <Descriptions
                    bordered={true}
                    // title={languageManager.getMessage(this.props.commonState, 'component.analysis.category_amazon_data.title')}
                    size='small'
                    column={1}
                >
                    <Descriptions.Item label="Nome">{contact.name}</Descriptions.Item>
                    <Descriptions.Item label="Email">{contact.email}</Descriptions.Item>
                    <Descriptions.Item label="Telefono">{contact.phone}</Descriptions.Item>
                    <Descriptions.Item label="Note">{contact.note}</Descriptions.Item>
                </Descriptions>
            </Card>
        )
    }
}

function mapStateToProps(state) {
    return {
        authState: state.authenticationState,
        commonState: state.commonState
    };
}

export default connect(mapStateToProps)(CustomerContactCard)