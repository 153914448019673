import React from "react";
import { Route, Redirect } from "react-router-dom";

//AUTH related methods
// import { getFirebaseBackend } from "../helpers/authUtils";
// import { getNZTBackend } from "../nzt_helpers/authUtils";

const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  authenticationState,
  ...rest
}) => {
//   console.log("IN ROUTE");
//   console.log(isAuthProtected);
//   console.log(authenticationState);
//   console.log(rest);
  return (
    <Route
      {...rest}
      render={props => {
      //   console.log(props);

        if (isAuthProtected && (!authenticationState.userData || !authenticationState.userData.access_token) ) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }

        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

export default AppRoute;
