import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import Moment from 'moment'

import { Drawer, Form, Input, InputNumber, Button, Space, DatePicker, Select, notification } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons';

import languageManager from '../../../../services/languageManager';
import * as storeActions from '../store/actions/suppliers';

class CreateSupplierDrawer extends Component {
   state = {
      ...this.props,
      componentLoader: true,
      formLoader: false,
      formValues: {},
      componentLanguage: this.props.authState.defaultLanguage
   }
   formRef = React.createRef();

   componentDidMount() {
      // console.log('Component did mount')
      Promise.all([
         // this.getSuppliersList()
      ]).then(() => {
         this.setState({
            componentLoader: false
         });
      });
   }

   shouldComponentUpdate = (nextProps, nextState) => {
      // console.log('Component did update', nextProps, this.state)
      if (_.isEqual(nextProps.visible, this.state.visible)) {
         return false
      }
      return true
   }

   componentDidUpdate = (prevProps, prevState) => {
      if (!_.isEqual(prevState.visible, this.props.visible)) {
         if (!_.isNil(this.props.item)) {
            var newFormValues = { ...this.props.item }
            this.setFormValues(newFormValues)
         }
      }
   }
   setFormValues = (item) => {
      if (this.formRef.current) {
         this.formRef.current.setFieldsValue(item)
      }
   }

   onFinish = values => {
      this.setState({ formLoader: true });
      values.return_list = true;
      storeActions.createOrUpdate(values)
         .then(response => {
            try {
               this.formRef.current.resetFields()
               this.state.onSubmit(response.data)
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
                  description: languageManager.getMessage(this.props.commonState, this.state.action === 'edit' ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               this.setState({ formLoader: false });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            this.setState({ formLoader: false });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
         });
   };

   onClose = () => {
      this.formRef.current.resetFields()
      this.state.onClose()
   }

   render() {
      console.log('Render supplier drawer', this.state)
      const { Option } = Select

      return (
         <Drawer
            title="Gestisci cliente"
            width={500}
            onClose={this.onClose}
            visible={this.props.visible}
            drawerStyle={{ paddingTop: 70 }}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
               <div className='float-right'>
                  <Space>
                     <Button onClick={this.onClose} disabled={this.state.formLoader}>Chiudi</Button>
                     <Button form="createSupplierDrawer" type="primary" htmlType="submit" loading={this.state.formLoader}>Salva</Button>
                  </Space>
               </div>
            }
         >
            <Form layout="horizontal"
               ref={this.formRef}
               id="createSupplierDrawer"
               initialValues={this.state.formValues}
               onFinish={this.onFinish}
               onFinishFailed={this.onFinishFailed}>
               {/* <Form.Item
                  label="Fornitore"
                  name="supplier_id"
                  rules={[{ required: true, message: 'Scegli un valore' }]}
               >
                  <Select
                     showSearch
                     placeholder="Seleziona il fornitore"
                     optionFilterProp="children"
                  >
                     {
                        this.state.suppliersList.map((supplier) => {
                           return <Option key={supplier.id} value={supplier.id}>{supplier.name}</Option>
                        })
                     }
                  </Select>
               </Form.Item> */}
               <Form.Item
                  label="Nome"
                  name="first_name"
               >
                  <Input />
               </Form.Item>
               <Form.Item
                  label="Cognome"
                  name="last_name"
               >
                  <Input />
               </Form.Item>
               <Form.Item
                  label="Azienda"
                  name="company_name"
               >
                  <Input />
               </Form.Item>
               <Form.Item
                  label="Indirizzo email"
                  name="email"
                  rules={[
                     {
                        type: 'email',
                        message: 'Inserisci un indirizzo email valido',
                     },
                     // {
                     //    required: true,
                     //    message: 'Please input your E-mail!',
                     // },
                  ]}
               >
                  <Input />
               </Form.Item>
               <Form.Item name="supplier_id" initialValue={this.props.itemId} noStyle><Input type="hidden" /></Form.Item>
               <Form.Item label="language" name="language" initialValue={this.state.componentLanguage} noStyle><Input type="hidden" /></Form.Item>
            </Form>
         </Drawer>
      )
   }
}

function mapStateToProps(state) {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
}

export default connect(mapStateToProps)(CreateSupplierDrawer)