import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Divider, Drawer, Form, Input, InputNumber, Button, Space, notification, Select, Switch } from 'antd'
import { InfoCircleOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons'

import languageManager from '../../../../services/languageManager'
import * as storeActions from '../store/actions/marketplaceListRules'
import * as priceActions from '../store/actions/prices'
import * as priceRulesActions from '../../../configuration/priceRules/store/actions/priceRules'

import ListRuleForm from '../components/ListRuleForm'
import Loader from '../../../common/PageElements/Loader'

class ListRuleDrawer extends Component {
   state = {
      ...this.props,
      componentLoader: true,
      saveLoader: false,
      formValues: {},
      rulesList: [],
      customerGroupsList: [],
      categoriesTree: [],
      brandsList: [],
      tagsList: [],
      componentLanguage: this.props.authState.defaultLanguage
   }

   componentDidMount() {
      Promise.all([
         this.getPriceRules(),
         this.getComplements(),
      ]).then(() => {
         this.setState({
            componentLoader: false
         });
      });
   }

   shouldComponentUpdate = (nextProps, nextState) => {
      // console.log('Component should update', nextProps, this.props, nextState, this.state)
      if (
         _.isEqual(nextProps.visible, this.props.visible)
         && _.isEqual(nextState, this.state)
      ) {
         console.log('DO NOT UPDATE')
         return false
      }
      console.log('DO UPDATE')
      return true
   }

   componentDidUpdate = (prevProps, prevState) => {
      if (!_.isEqual(prevProps.visible, this.props.visible)) {
         if (_.isNil(this.props.item)) {
            // console.log('clear form')
            this.setState({ formValues: {} });
         } else {
            // console.log('update form', this.props.item)
            this.setState({ formValues: { ...this.props.item } });
         }
      }
   }

   getPriceRules = () => {
      return priceRulesActions.getList()
         .then(response => {
            console.log(response);
            try {
               this.setState({
                  rulesList: response.data,
               });
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e);
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }
   getComplements = () => {
      return priceActions.getComplements()
         .then(response => {
            console.log(response);
            try {
               this.setState({
                  complementsResponse: response,
                  customerGroupsList: response.customerGroupsList,
                  categoriesTree: response.categoriesTree,
                  brandsList: response.brandsList,
                  tagsList: response.tagsList,
               });
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e);
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   onFinish = values => {
      // console.log('on finish', values)
      this.setState({ saveLoader: true });
      storeActions.createOrUpdate(values, true)
         .then(response => {
            try {
               console.log('response', response.data)
               this.setState({ saveLoader: false });
               this.state.onSubmit(response.data)
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
                  description: languageManager.getMessage(this.props.commonState, this.state.action === 'edit' ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               this.setState({ saveLoader: false });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               console.log(e)
            }

         }).catch((e) => {
            this.setState({ saveLoader: false });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            console.log(e)
         })
   };

   render() {
      // console.log('Create edit sale rule drawer', this.state)
      const formLayout = {
         layout: 'vertical'
      };

      return (
         <Drawer
            title={!_.isNil(this.props.item) ? 'Modifica regola' : "Aggiungi una nuova regola"}
            width={500}
            onClose={this.props.onClose}
            visible={this.props.visible}
            drawerStyle={{ paddingTop: 70 }}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
               <div className='float-right'>
                  <Space>
                     <Button onClick={this.props.onClose} disabled={this.state.saveLoader}>Chiudi</Button>
                     <Button form="listRuleDrawer" type="primary" htmlType="submit" loading={this.state.saveLoader}>Salva</Button>
                  </Space>
               </div>
            }
         >
            {
               this.state.componentLoader ? <Loader /> : (
                  <ListRuleForm
                     layout={formLayout}
                     submitForm={this.onFinish}
                     marketplaceId={this.props.marketplaceId}
                     values={this.state.formValues}
                     rulesList={this.state.rulesList}
                     customerGroupsList={this.state.customerGroupsList}
                     categoriesTree={this.state.categoriesTree}
                     brandsList={this.state.brandsList}
                     tagsList={this.state.tagsList}
                  />
               )
            }
         </Drawer>
      )
   }
}

function mapStateToProps(state) {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
}

export default connect(mapStateToProps)(ListRuleDrawer)