import React from 'react';
import _, { concat } from 'lodash';
import { Select,Space,DatePicker, Row, Col, Divider, Typography, Card,Layout} from 'antd';
import { CheckCircleTwoTone , CloseCircleTwoTone, WarningTwoTone } from '@ant-design/icons';
import PasswordEdit from './PasswordEdit';
import PageHeader from '../common/PageElements/PageHeader'
import EditDataBanner from './EditDataBanner'


import userDefault from '../../assets/images/users/user-default.jpg';

class Profile extends React.Component {
    state={
        userData:{
            name:"Alain",
            surname:" Mantovan",
            taxCode:"MNTLNA94H25A479O",
            birthDate:"25/06/1994",
            birthCity:"Asti",
            sex:"M",
        },
        address: {
            street:"via Buffetto, 24",
            city:"Portacomaro",
            province:"AT",
            code:14037
        },
        hideModify : true,
        key:null
    }

    userModify = ()=>{
        this.setState({
            hideModify : false,
            key:'user'
        })
    }

    addressModify = ()=>{
        this.setState({
            hideModify : false,
            key:'address'
        })
    }

    passwordModify = ()=>{
        this.setState({
            hideModify : false,
            key:'password'
        })
    }
    
    closeModify = ()=>{
        this.setState({
            hideModify:true
        })
    }


    render(){
        const {Content, Header, Footer, Sider} = Layout;
        const {Meta} = Card;
        let fullName= concat(this.state.userData.name, ' ', this.state.userData.surname);
        return(
            <>
            <PageHeader
               title='Il tuo profilo'
            />
            <Layout>
                <Content>
                    <Row>
                        <Col span={7}>
                            <Card
                                title={fullName}
                            >
                                <img src={userDefault} />
                            </Card>
                            <Card
                                title= "Dati Anagrafici"
                                extra = {<a onClick = {this.userModify} > Modifica </a>}
                            >
                                <p><b>Nome: </b> {this.state.userData.name} </p>
                                <p><b>Cognome: </b> {this.state.userData.surname} </p>
                                <p><b>Codice Fiscale: </b> {this.state.userData.taxCode} </p>
                                <p><b>Sesso: </b> {this.state.userData.sex} </p>
                                <p><b>Data di nascita: </b> {this.state.userData.birthDate} </p>
                                <p><b>Luogo di nascita: </b> {this.state.userData.birthCity} </p>
                            </Card>
                            <Card
                                title= "Indirizzo di Fatturazione"
                                extra = {<a onClick = {this.addressModify} > Modifica </a>}
                            >
                                <p><b>Via: </b> {this.state.address.street} </p>
                                <p><b>Città: </b> {this.state.address.city} </p>
                                <p><b>Provincia: </b> {this.state.address.province} </p>
                                <p><b>CAP: </b> {this.state.address.code} </p>
                            </Card>
                            <Card>
                                <Meta
                                    title = {<button className="btn btn-primary" onClick={this.passwordModify}>Modifica Password</button> }
                                />
                            </Card>
                        </Col>
                        <Col span={2} />
                        <Col span={15}>
                            <EditDataBanner 
                                userData={this.state.userData} 
                                address={this.state.address} 
                                bannerKey={this.state.key} 
                                hideModify={this.state.hideModify} 
                                closeModify={this.closeModify}
                            />
                        </Col>
                    </Row>
                </Content>
            </Layout>        
            </> 
        )
    }
}

export default Profile;