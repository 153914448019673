import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Moment from 'moment';

import { Form, Input, Select, DatePicker, Button, Space, notification, List } from 'antd';
import { InfoCircleOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

import languageManager from '../../../services/languageManager';
import * as storeActions from './store/actions/batches';

import Loader from '../../common/PageElements/Loader';
import PageHeader from '../../common/PageElements/PageHeader'

class BatchesEdit extends Component {

   state = {
      action: undefined,
      saveAndClose: false,
      pageLoader: true,
      actionLoader: false,
      products_list: [],
      formValues: {},
      componentLanguage: this.props.authState.defaultLanguage
   };

   getItem = (id) => {
      return storeActions.getItem(id)
         .then(response => {
            console.log(response);
            try {
               const updatedValues = this.setForm(response.data);
               console.log(updatedValues)
               this.setState({ formValues: updatedValues });
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }
         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   getComplements = () => {
      return storeActions.getComplements()
         .then(response => {
            console.log(response);
            try {
               this.setState({
                  // response: response,
                  products_list: response.productsList,
               });

            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e);
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   componentDidMount() {
      if (this.props.match.params.id) {
         const itemId = this.props.match.params.id
         Promise.all([
            this.getItem(itemId),
            this.getComplements()
         ]).then(() => {
            this.setState({
               action: 'edit',
               itemId: itemId,
               pageLoader: false
            });
         });
      }
      else {
         const formValues = {
            language: this.state.componentLanguage,
         }
         Promise.all([
            this.getComplements()
         ]).then(() => {
            this.setState({
               formValues: formValues,
               action: 'insert',
               pageLoader: false
            });
         });
      }
   }

   setForm = (responseValues, lang = this.state.componentLanguage) => {
      let values = { ...responseValues }
      // values.id = responseValues.id
      // values.parent_id = responseValues.parent_id
      // values.batch = responseValues.batch
      if (!_.isNil(values.expiration_date)) {
         const newDate = Moment(values.expiration_date)
         values.expiration_date = newDate
      }

      return values;
   }

   handleSaveAndClose = () => {
      this.setState({ saveAndClose: true });
   }

   onFinish = values => {
      this.setState({ actionLoader: true });
      values.expiration_date = values.expiration_date ? values.expiration_date.format('YYYY-MM-DD') : null;
      console.log(values);
      storeActions.createOrUpdate(values)
         .then(response => {
            this.setState({ actionLoader: false });
            console.log(response);
            try {
               if (this.state.saveAndClose) {
                  this.props.history.push('/batches/list');
               }

               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
                  description: languageManager.getMessage(this.props.commonState, this.state.action === 'edit' ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
                  icon: <CheckCircleOutlined className="color-green-I" />,
               })
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <CloseCircleOutlined className="color-red-I" />,
               })
               if (this.props.commonState.debug) console.log(e);
            }

         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.human ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.human}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
            if (this.props.commonState.debug) console.log(e);
            this.setState({ actionLoader: false });
         });
   };

   onFinishFailed = errorInfo => {
      notification.open({
         message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
         description: languageManager.getMessage(this.props.commonState, 'notification.error.formInvalid'),
         icon: <CloseCircleOutlined className="color-red-I" />,
      })
   };

   render() {
      if (this.state.getError) return <Loader />;
      if (this.state.pageLoader) return <Loader />;
      const { Option } = Select;

      const layout = {
         labelCol: { span: 8 },
         wrapperCol: { span: 16 },
      };

      let form = (
         <Form
            {...layout}
            id="editBatch"
            name="basic"
            initialValues={this.state.formValues}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
         >

            <Form.Item
               label="Prodotto"
               name="parent_id"
               rules={[{ required: true, message: 'Seleziona il prodotto' }]}
            >
               <Select
                  showSearch
                  placeholder="Seleziona il prodotto"
                  optionFilterProp="children"
                  allowClear={true}
               >
                  {
                     this.state.products_list.map((product) => {
                        return <Option key={product.id} value={product.id}>{product.title}</Option>
                     })
                  }
               </Select>
            </Form.Item>
            <Form.Item
               label="Lotto"
               name="batch"
               rules={[{ required: true, message: 'Inserisci il numero di lotto' }]}
            >
               <Input />
            </Form.Item>
            <Form.Item
               label="Data di scadenza"
               name="expiration_date"
            >
               <DatePicker
                  placeholder="Data di scadenza del lotto"
                  format="DD/MM/YYYY"
                  allowClear={true}
               />
            </Form.Item>

            <Form.Item label="id" name="id" noStyle><Input type="hidden" /></Form.Item>
            <Form.Item label="language" name="language" noStyle><Input type="hidden" /></Form.Item>

         </Form>
      );


      const pageContent = (
         <div className="card">
            <div className="card-header">
               <div className="align-items-center row">
                  <div className="col-sm-6">{languageManager.getMessage(this.props.commonState, 'component.batches.title')}</div>
                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">
                        <Space>
                           <Select
                              showSearch
                              className="width-70px-I"
                              placeholder="Seleziona la lingua"
                              defaultValue={this.state.componentLanguage.toUpperCase()}
                              optionFilterProp="children"
                              onChange={this.setDefaultLanguageHandler}
                           //  onFocus={onFocus}
                           //  onBlur={onBlur}
                           //  onSearch={onSearch}
                           //  filterOption={(input, option) =>
                           //    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                           //  }
                           >
                              {
                                 this.props.authState.userData.languages.map((lang) => {
                                    return <Option key={lang.value} value={lang.value}>{lang.label}</Option>
                                 })
                              }
                           </Select>
                        </Space>
                     </div>
                  </div>
               </div>
            </div>
            <div className="card-body">
               {form}
            </div>
            <div className="card-footer">
               <div className="align-items-center row">
                  <div className="col-sm-6">
                     <Space>
                        <Button
                           size='large'
                           onClick={() => { this.props.history.goBack() }}
                        >
                           <i className="mdi mdi-arrow-left-drop-circle-outline mr-1"></i>
                           {languageManager.getMessage(this.props.commonState, 'common.back')}
                        </Button>
                     </Space>
                  </div>

                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">

                        <Space>
                           <Button form="editBatch" loading={this.state.actionLoader} htmlType="reset">Annulla</Button>
                           <Button form="editBatch" loading={this.state.actionLoader} type="primary" htmlType="submit">Salva</Button>
                           <Button form="editBatch" loading={this.state.actionLoader} type="primary" htmlType="submit" onClick={this.handleSaveAndClose}>Salva e chiudi</Button>
                        </Space>

                     </div>
                  </div>
               </div>
            </div>
         </div>
      );

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>

      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(BatchesEdit);