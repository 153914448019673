import React from 'react';
import _, { concat } from 'lodash';
import { Select,Space,DatePicker, Row, Col, Divider, Typography, Card,Layout} from 'antd';
import { CheckCircleTwoTone , CloseCircleTwoTone, WarningTwoTone } from '@ant-design/icons';
import PasswordEdit from './PasswordEdit';
import AddressEdit from './AddressEdit';
import UserDataEdit from './UserDataEdit';

const tabList = [
    {
        key: 'user',
        tab: 'Dati Anagrafici',
    },
    {
        key: 'address',
        tab: 'Indirizzo di Fatturazione',
    },
    {
        key: 'password',
        tab: 'Password',
    }
];



class EditDataBanner extends React.Component{
    state = {
        key: 'user'
    }

    contentList = {
        user: <UserDataEdit userData={this.props.userData} closeModify = {this.props.closeModify}/>,
        address: <AddressEdit address={this.props.address} closeModify = {this.props.closeModify}/>,
        password: <PasswordEdit closeModify = {this.props.closeModify}/>
    }

    onTabChange = (newKey) => {
        this.setState({
            key: newKey
        });
    };
    render(){

        return(
            <>
                <div className="col-md-12" hidden={this.props.hideModify}>
                    <Card
                        title = 'Modifica i tuoi dati personali'
                    >
                        {this.contentList[this.props.bannerKey]}
                    </Card>
                </div>
            </>
        );
    }
}

export default EditDataBanner;