import Axios from 'axios';
import API_BASE from './apiBase';
import API_CONFIG from './apiConfig';

class WarehouseMovementsApi {

   static createOrUpdate(itemData) {
      console.log(itemData)
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.warehouseMovements.createOrUpdate, {
            ...itemData
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               console.log(error);
               reject(error);
            });

      });
   }

   static getList() {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.warehouseMovements.getList)
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static getListExtended(page, pageSize, filters) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.warehouseMovements.getListExtended, { page: page, page_size: pageSize, filters_values: JSON.stringify(filters) })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static getItem(itemId) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.warehouseMovements.getItem, {
            id: itemId
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static getComplements() {
      return new Promise((resolve, reject) => {
         Axios.all([
            Axios.post(API_BASE.url + API_CONFIG.appUrls.warehouses.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.customers.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.suppliers.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.products.getListCascader, { allow_select_parent: true }),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.productBatches.getList),
         ])
            .then(Axios.spread((warehouseList, customerList, supplierList, productCascaderList, batchList) => {
               const response = {
                  warehouseList: warehouseList,
                  customerList: customerList,
                  supplierList: supplierList,
                  productCascaderList: productCascaderList,
                  batchList: batchList,
               }
               resolve(response);
            }))
            .catch(error => {
               reject(error);
            });
      });
   }

   static deleteItems(itemIds) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.warehouseMovements.deleteItems, {
            item_ids: itemIds
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static importCSV(formData) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.warehouseMovements.importFromCSV, { ...formData })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   static exportCsv(filename, itemIds) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.warehouseMovements.exportCsv, {filename: filename, item_ids: itemIds})
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   static printList(filename, itemIds) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.warehouseMovements.printList, {filename: filename, item_ids: itemIds}, {responseType: 'blob'})
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   

   static getProductStocksListExtended(page, pageSize, filters) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.productStocks.getListExtended, { page: page, page_size: pageSize, filters_values: JSON.stringify(filters) })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   static exportProductStocksList(filename, itemIds) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.productStocks.exportCsv, {filename: filename, item_ids: itemIds})
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   static printProductStocksList(filename, itemIds) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.productStocks.printList, {filename: filename, item_ids: itemIds}, {responseType: 'blob'})
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   static printListBeforeDate(filename, end_date) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.productStocks.printListBeforeDate, {filename: filename, end_date: end_date}, {responseType: 'blob'})
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

}

export default WarehouseMovementsApi;