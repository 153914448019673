import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Table, Form, Input, Button, Space, notification, Popconfirm, Tree } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
// import Highlighter from 'react-highlight-words';
import { SearchOutlined, CheckOutlined, CloseOutlined, LeftCircleOutlined } from '@ant-design/icons';

import languageManager from '../../../services/languageManager';

import * as storeActions from './store/actions/categories';

import Loader from '../../common/PageElements/Loader';
import PageHeader from '../../common/PageElements/PageHeader'

class CategoriesOrder extends Component {
   state = {
      loader: true,
      parentId: null,
      itemList: [],
      expandedKeys: [],
      componentLanguage: this.props.authState.defaultLanguage

   };

   componentDidMount() {
      console.log(this.state);

      // asyncronous calls
      storeActions.getTree()
         .then(response => {
            console.log(response.data);
            try {

               this.setState({ itemList: response.data, loader: false });

            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   saveTreeHandler = () => {
      // console.log(this.state.itemList);
      // return;
      this.setState({ loader: true });
      storeActions.rebuildTree(this.state.itemList, true)
         .then(response => {
            console.log(response);
            try {
               // let list = [...this.state.itemList];
               // list = list.filter(item => item.id !== itemId);

               this.setState({ itemList: response.data, loader: false });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.orderOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            this.setState({ loader: false });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   confirmHandler = (onConfirm) => {
      switch (onConfirm) {
         case 'orderTree':
            this.saveTreeHandler();
            break;
         default:
            return;
            break;
      }
   }

   onDragEnter = info => {
      console.log(info);
      // expandedKeys 需要受控时设置
      // this.setState({
      //   expandedKeys: info.expandedKeys,
      // });
   }

   onDrop = info => {
      console.log(info);
      const dropKey = info.node.props.eventKey;
      const dragKey = info.dragNode.props.eventKey;
      const dropPos = info.node.props.pos.split('-');
      const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

      const loop = (data, key, callback) => {
         for (let i = 0; i < data.length; i++) {
            if (data[i].key === key) {
               return callback(data[i], i, data);
            }
            if (data[i].children) {
               loop(data[i].children, key, callback);
            }
         }
      };
      const data = [...this.state.itemList];

      // Find dragObject
      let dragObj;
      loop(data, dragKey, (item, index, arr) => {
         arr.splice(index, 1);
         dragObj = item;
      });

      if (!info.dropToGap) {
         // Drop on the content
         loop(data, dropKey, item => {
            item.children = item.children || [];
            // where to insert
            item.children.push(dragObj);
         });
      } else if (
         (info.node.props.children || []).length > 0 && // Has children
         info.node.props.expanded && // Is expanded
         dropPosition === 1 // On the bottom gap
      ) {
         loop(data, dropKey, item => {
            item.children = item.children || [];
            // where to insert
            item.children.unshift(dragObj);
         });
      } else {
         let ar;
         let i;
         loop(data, dropKey, (item, index, arr) => {
            ar = arr;
            i = index;
         });
         if (dropPosition === -1) {
            ar.splice(i, 0, dragObj);
         } else {
            ar.splice(i + 1, 0, dragObj);
         }
      }

      this.setState({
         itemList: data,
      });
   }

   render() {

      const pageContent = (
         <div className="card">
            <div className="card-header">
               <div className="align-items-center row">
                  <div className="col-sm-6">
                     {languageManager.getMessage(this.props.commonState, 'component.categories.title')}
                  </div>
                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">
                     </div>
                  </div>
               </div>
            </div>
            <div className="card-body">
               {this.state.loader ? <Loader /> : (
                  <Tree
                     className="draggable-tree"
                     defaultExpandedKeys={this.state.expandedKeys}
                     draggable
                     blockNode
                     onDragEnter={this.onDragEnter}
                     onDrop={this.onDrop}
                     treeData={this.state.itemList}
                  />
               )}
            </div>
            <div className="card-footer">
               {this.state.loader ? '' : (
                  <div className="row">
                     <div className="col-sm-6">
                        <Space>
                           <Button
                              size='large'
                              onClick={() => { this.props.history.goBack() }}
                           >
                              <i className="mdi mdi-arrow-left-drop-circle-outline mr-1"></i>
                              {languageManager.getMessage(this.props.commonState, 'common.back')}
                           </Button>
                        </Space>
                     </div>

                     <div className="col-sm-6">
                        <div className="float-right d-none d-md-block">

                           <Popconfirm
                              placement="topRight"
                              title="Confermi?"
                              onConfirm={() => this.confirmHandler('orderTree')}
                              okText="Yes"
                              cancelText="No"
                           >
                              <Button
                                 size='large'
                                 type='primary'
                              >Salva ordinamento</Button>
                           </Popconfirm>

                        </div>
                     </div>
                  </div>
               )}
            </div>
         </div>
      );

      return (
         <div className="container-fluid" >
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>
      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState,
   };
};

export default connect(mapStateToProps)(CategoriesOrder);