import API_BASE from './apiBase'
import API_CONFIG from './apiConfig'

import Axios from 'axios'

class MarketplaceListRulesApi {

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getList() {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.marketplaceListRules.getList)
            .then(response => {
               resolve(response)
            })
            .catch(error => {
               reject(error)
            })
      })
   }
   static getListByMarketplace() {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.marketplaceListRules.getListByMarketplace)
            .then(response => {
               resolve(response)
            })
            .catch(error => {
               reject(error)
            })
      })
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getItem(itemId) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.marketplaceListRules.getItem, {
            id: itemId
         })
            .then(response => {
               resolve(response)
            })
            .catch(error => {
               reject(error)
            })
      })
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static createOrUpdate(itemData, return_list) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.marketplaceListRules.createOrUpdate, {
            ...itemData,
            return_list: return_list
         })
            .then(response => {
               resolve(response)
            })
            .catch(error => {
               reject(error)
            })

      })
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static deleteItem(itemId, return_list) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.marketplaceListRules.deleteItem, {
            id: itemId,
            return_list: return_list
         })
            .then(response => {
               resolve(response)
            })
            .catch(error => {
               reject(error)
            })
      })
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static toggleActive(itemId, return_list) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.marketplaceListRules.toggleActive, {
            id: itemId,
            return_list: return_list
         })
            .then(response => {
               resolve(response)
            })
            .catch(error => {
               reject(error)
            })
      })
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getComplements() {
      return new Promise((resolve, reject) => {
         Axios.all([
            Axios.post(API_BASE.url + API_CONFIG.appUrls.marketplaces.getList),
         ])
            .then(Axios.spread((marketplacesList) => {
               const response = {
                  marketplacesList: marketplacesList,
               }
               resolve(response)
            }))
            .catch(error => {
               reject(error)
            })
      })
   }
}

export default MarketplaceListRulesApi