import CustomerContactsApi from '../../../../../api/CustomerContactsApi'
import _ from 'lodash'
import languageManager from '../../../../../services/languageManager'

export function getItem(itemId) {
   return CustomerContactsApi.getItem(itemId)
      .then(response => {
         if (response.data.success === true) {
            console.log(response);
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function createOrUpdate(itemData, returnList) {
   console.log(itemData);
   return CustomerContactsApi.createOrUpdate(itemData, returnList)
      .then(response => {
         if (response.data.success === true) {
            console.log(response);
            return response.data;
         }
         else {
            throw new Error(response.data.exception ? response.data.exception :
               response.data.error ? response.data.error : 'serverError');
         }
      })
      .catch(error => {
         throw new Error(error.data.exception ? error.data.exception :
            error.data.error ? error.data.error : 'serverError');
      });
}

export function deleteItem(itemId, return_list = false) {
   return CustomerContactsApi.deleteItem(itemId, return_list)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}