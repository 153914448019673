import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { Table, Input, Select, Button, Tooltip, Space, notification, Popconfirm } from 'antd';
import { InfoCircleOutlined, PlusSquareOutlined, LeftCircleOutlined } from '@ant-design/icons';
import { EditOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';

import languageManager from '../../../services/languageManager';
import { startLoading, endLoading } from '../../../shared/utility';

import * as storeActions from './store/actions/specifics';

import Loader from '../../common/PageElements/Loader';
import PageHeader from '../../common/PageElements/PageHeader'

class SpecificsList extends Component {
   state = {
      loader: true,
      loadersArray: [],
      itemList: [],
      searchText: '',
      searchedColumn: '',
      componentLanguage: this.props.authState.defaultLanguage
   };

   componentDidMount() {
      console.log(this.state);

      // asyncronous calls
      storeActions.getList()
         .then(response => {
            console.log(response);
            try {
               this.setState({ itemList: response.data, loader: false });

            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   editItemHandler = (itemId) => {
      this.props.history.push('/specifics/edit/' + itemId);
   }

   deleteItemHandler = (itemId) => {
      this.setState({ loadersArray: startLoading(this.state.loadersArray, itemId) });
      storeActions.deleteItem(itemId, true)
         .then(response => {
            console.log(response);
            try {
               // let list = [...this.state.itemList];
               // list = list.filter(item => item.id !== itemId);

               this.setState({ itemList: response.data, loadersArray: endLoading(this.state.loadersArray, itemId) });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.deleteOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   confirmHandler = (onConfirm, itemId) => {
      switch (onConfirm) {
         case 'deleteItem':
            if (itemId) {
               this.deleteItemHandler(itemId);
            }
            break;
         default:
            return;
            break;
      }
   }

   toggleValueHandler = (itemId, value) => {
      this.setState({ loader: true });
      storeActions.toggleValue(itemId, value)
         .then(response => {
            try {
               const newState = { ...this.state };
               const itemIndex = newState.itemList.findIndex((obj => obj.id == response.data.id));
               newState.itemList[itemIndex] = response.data;
               this.setState({ itemList: newState.itemList, loader: false });

               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.success.updateOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               this.setState({ loader: false });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            this.setState({ loader: false });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
         });
   }

   getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
         <div style={{ padding: 8 }}>
            <Input
               ref={node => {
                  this.searchInput = node;
               }}
               placeholder={`Cerca ${dataIndex}`}
               value={selectedKeys[0]}
               onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
               onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
               style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
               <Button
                  type="primary"
                  onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}
               >
                  Search
               </Button>
               <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                  Reset
               </Button>
            </Space>
         </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
         record[dataIndex]
            ? record[dataIndex][this.state.componentLanguage].toString().toLowerCase().includes(value.toLowerCase())
            : '',
      onFilterDropdownVisibleChange: visible => {
         if (visible) {
            setTimeout(() => this.searchInput.select(), 100);
         }
      },
      render: (text, record) =>
         <a onClick={() => this.props.history.push('/specifics/values/list/' + record.id)}>
            {!_.isNil(record[dataIndex][this.state.componentLanguage]) ? record[dataIndex][this.state.componentLanguage].toString() : ''}
         </a>
   });

   handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      this.setState({
         searchText: selectedKeys[0],
         searchedColumn: dataIndex,
      });
   };

   handleReset = clearFilters => {
      clearFilters();
      this.setState({ searchText: '' });
   };

   render() {
      if (!this.props.authState.userData.languages) return '';
      const { Option } = Select;
      const table_columns = [
         {
            title: 'Nome',
            key: 'name',
            fixed: 'left',
            sorter: (a, b) => (a?.name[this.state.componentLanguage] ?? "").localeCompare(b?.name[this.state.componentLanguage] ?? ""),
            ...this.getColumnSearchProps('name'),
         },
         {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            align: 'right',
            width: 200,
            render: (text, record) => (
               <Space>
                  <Tooltip title={languageManager.getMessage(this.props.commonState, 'common.edit')}>
                     <Button type="primary"
                        size='small'
                        icon={<EditOutlined />}
                        disabled={this.state.loadersArray[record.id]}
                        onClick={() => this.editItemHandler(record.id)}
                     />
                     {/* <Dropdown.Button type="primary" onClick={() => this.editItemHandler(record.id)} overlay={actionsMenu(record.id)}><EditOutlined /></Dropdown.Button> */}
                  </Tooltip>

                  <Popconfirm
                     placement="topRight"
                     title={languageManager.getMessage(this.props.commonState, 'common.askDeleteConfirm')}
                     onConfirm={() => this.confirmHandler('deleteItem', record.id)}
                     okText={languageManager.getMessage(this.props.commonState, 'common.yes')}
                     cancelText={languageManager.getMessage(this.props.commonState, 'common.no')}
                  >
                     {/* <a href="#" title={languageManager.getMessage(this.props.commonState, 'common.delete')}><DeleteTwoTone /></a> */}

                     <Tooltip title={languageManager.getMessage(this.props.commonState, 'common.delete')}>
                        <Button type="danger"
                           size='small'
                           icon={<DeleteOutlined />}
                           loading={this.state.loadersArray[record.id]}
                        />
                     </Tooltip>
                  </Popconfirm>
               </Space>
            ),
         },
      ];

      const tableLayout = {
         bordered: true,
         size: "small",
         pagination: {
            total: this.state.total_result,
            showSizeChanger: true,
            defaultPageSize: 50,
            size: "default",
            showTotal: total => total + (total != 1 ? ' valori' : ' valore')
         },
      }

      const pageContent = (
         <div className="card">
            <div className="card-header">
               <div className="align-items-center row">
                  <div className="col-sm-6">{languageManager.getMessage(this.props.commonState, 'component.specifics.title')}</div>
                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">
                        <Space>
                           <Button
                              type='primary'
                              icon={<PlusSquareOutlined />}
                              onClick={() => this.props.history.push('/specifics/edit/')}
                           >{languageManager.getMessage(this.props.commonState, 'component.specifics.create_new')}</Button>
                           <Select
                              showSearch
                              className="width-70px-I"
                              placeholder="Seleziona la lingua"
                              defaultValue={this.state.componentLanguage.toUpperCase()}
                              optionFilterProp="children"
                              onChange={this.setDefaultLanguageHandler}
                           //  onFocus={onFocus}
                           //  onBlur={onBlur}
                           //  onSearch={onSearch}
                           //  filterOption={(input, option) =>
                           //    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                           //  }
                           >
                              {
                                 this.props.authState.userData.languages.map((lang) => {
                                    return <Option key={lang.value} value={lang.value}>{lang.label}</Option>
                                 })
                              }
                           </Select>
                        </Space>
                     </div>
                  </div>
               </div>
            </div>
            <div className="card-body">
               {this.state.loader ? <Loader /> : <Table {...tableLayout} columns={table_columns} dataSource={this.state.itemList} scroll={{ x: 1000 }} />}
            </div>
            <div className="card-footer">
               {/* <Button
                  size='large'
                  onClick={() => { this.props.history.goBack() }}
               >
                  <i className="mdi mdi-arrow-left-drop-circle-outline mr-1"></i>
                  {languageManager.getMessage(this.props.commonState, 'common.back')}
               </Button> */}
            </div>
         </div>
      );

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>
      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState,
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getSpecificsList() )

//     };
// };

export default connect(mapStateToProps)(SpecificsList);