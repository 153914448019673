import React from 'react';
import { connect } from 'react-redux';

import { Menu, Dropdown, Button, Modal } from 'antd';
import { DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import languageManager from '../../../services/languageManager';

const DropdownMenu = (props) => {
   const { confirm } = Modal;
   // console.log(props);
   const menu = (
      <Menu onClick={showConfirm}>
         {
            props.values.map((value, index) => {
               return (
                  <Menu.Item
                     key={index}
                     name={value.name}
                     action={value.action}
                     needconfirmation={value.needconfirmation}
                     params={value.params}
                  >
                     {value.name}
                  </Menu.Item>
               )
            })
         }
      </Menu>
   );

   function showConfirm(e) {
      // console.log(e.item)
      const item = e.item.props
      if(item.needconfirmation === 'yes') {
         confirm({
            title: item.name,
            icon: <ExclamationCircleOutlined />,
            content: languageManager.getMessage(props.commonState, 'common.askOperationConfirm'),
            okText: languageManager.getMessage(props.commonState, 'common.confirm'),
            onOk: () => props.action(e)
         });
      } else {
         props.action(e)
      }
   }

   return (
      <>
         <Dropdown
            overlay={menu}
            disabled={props.disabled}
         >
            <Button
               overlay={menu}
               size={props.size}
               type={props.type}
               icon={<DownOutlined />}
               loading={props.loading}
            >
               {props.title}
            </Button>
         </Dropdown>
      </>
   )
}


const mapStateToProps = state => {
   return {
      commonState: state.commonState,
   };
};

export default connect(mapStateToProps)(DropdownMenu);