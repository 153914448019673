import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadActiveMarketplacesData } from '../../../store/actions/authenticationAction';

import { Form, Input, Select, Button, Radio, Space, notification } from 'antd';
import { InfoCircleOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

import languageManager from '../../../services/languageManager';
import * as storeActions from './store/actions/marketplaces';
import { SellPriceTypeEnum } from '../../../shared/enums.ts'

import Loader from '../../common/PageElements/Loader';
import PageHeader from '../../common/PageElements/PageHeader'

class MarketplacesEdit extends Component {

   state = {
      saveAndClose: false,
      pageLoader: true,
      formLoader: false,
      formValues: {},
      language: 'it',
      componentLanguage: this.props.authState.defaultLanguage,
      marketplacesBaseList: [],
      countriesList: [],
      languagesList: [],
      currenciesList: [],
   };

   setForm = (response, lang = this.state.componentLanguage) => {
      const values = {
         marketplace_base_id: response.marketplace_base_id,
         name: response.name,
         country_id: response.country_id,
         language_id: response.language_id,
         currency_id: response.currency_id,
         sell_price_type: response.sell_price_type,
         description: response.description,
         id: response.id,
         language: lang
      }
      return values;
   }

   componentDidMount() {
      storeActions.getMarketplaceComplements()
         .then(response => {
            console.log(response);
            try {
               this.setState({
                  response: response,
                  marketplacesBaseList: response.marketplacesBaseList,
                  countriesList: response.countriesList,
                  languagesList: response.languagesList,
                  currenciesList: response.currenciesList,
               });

            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e);
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });

      if (this.props.match.params.id) {
         const itemId = this.props.match.params.id;
         // asyncronous calls
         storeActions.getItem(itemId)
            .then(response => {
               console.log(response);
               try {
                  const updatedValues = this.setForm(response.data);
                  this.setState({
                     action: 'edit',
                     getItemResponse: response.data,
                     formValues: updatedValues,
                     pageLoader: false
                  });
               } catch (e) {
                  notification.open({
                     message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                     description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                     icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                  })
                  if (this.props.commonState.debug) console.log(e);
               }

            }).catch((e) => {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e);
            });
      }
      else {
         const formValues = {
            language: this.state.componentLanguage
         }
         this.setState({
            formValues: formValues,
            action: 'insert',
            pageLoader: false
         });
      }

   }

   handleSaveAndClose = () => {
      this.setState({ saveAndClose: true });
   }

   onFinish = values => {
      this.setState({ formLoader: true });
      storeActions.createOrUpdate(values)
         .then(response => {
            console.log(response);
            try {
               this.props.updateActiveMarketplacesList()
               if (this.state.saveAndClose) {
                  this.props.history.push('/marketplaces/list');
               }
               this.setState({ formLoader: false });

               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
                  description: languageManager.getMessage(this.props.commonState, this.state.action === 'edit' ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
                  icon: <CheckCircleOutlined className="color-green-I" />,
               })
            } catch (e) {
               this.setState({ formLoader: false });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <CloseCircleOutlined className="color-red-I" />,
               })
            }

         }).catch((e) => {
            this.setState({ formLoader: false });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <CloseCircleOutlined className="color-red-I" />,
            })
         });
   };

   onFinishFailed = errorInfo => {
      notification.open({
         message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
         description: languageManager.getMessage(this.props.commonState, 'notification.error.formInvalid'),
         icon: <CloseCircleOutlined className="color-red-I" />,
      })
   };

   render() {
      // console.log(this.props.authState.userData.languages);
      const layout = {
         labelCol: { span: 8 },
         wrapperCol: { span: 14 },
      };
      const { TextArea } = Input;
      const { Option } = Select;

      let form = (
         <Form
            {...layout}
            id="formMarketplace"
            name="basic"
            initialValues={this.state.formValues}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
         >
            <Form.Item
               label="Tipo Marketplace"
               name="marketplace_base_id"
               rules={[{ required: true }]}
            >
               <Select
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Seleziona il Marketplace"
                  optionFilterProp="children"
               >
                  {
                     this.state.marketplacesBaseList.map((base) => {
                        return <Option key={base.id} value={base.id}>{base.name}</Option>
                     })
                  }
               </Select>
            </Form.Item>
            <Form.Item
               label="Name"
               name="name"
               rules={[{ required: true, message: 'Inserisci il nome' }]}
            >
               <Input />
            </Form.Item>
            <Form.Item
               label="Nazione"
               name="country_id"
               rules={[{ required: true }]}
            >
               <Select
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Seleziona la nazione"
                  optionFilterProp="children"
               >
                  {
                     this.state.countriesList.map((country) => {
                        return <Option key={country.id} value={country.id}>{country.name[this.state.componentLanguage]}</Option>
                     })
                  }
               </Select>
            </Form.Item>
            <Form.Item
               label="Lingua"
               name="language_id"
               rules={[{ required: true }]}
            >
               <Select
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Seleziona la lingua di default"
                  optionFilterProp="children"
               >
                  {
                     this.state.languagesList.map((lang) => {
                        return <Option key={lang.id} value={lang.id}>{lang.name[this.state.componentLanguage] + ' (' + lang.iso_code + ')'}</Option>
                     })
                  }
               </Select>
            </Form.Item>
            <Form.Item
               label="Valuta"
               name="currency_id"
               rules={[{ required: true }]}
            >
               <Select
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Seleziona la valuta"
                  optionFilterProp="children"
               >
                  {
                     this.state.currenciesList.map((currency) => {
                        return <Option key={currency.id} value={currency.id}>{currency.name[this.state.componentLanguage] + ' (' + currency.symbol + ')'}</Option>
                     })
                  }
               </Select>
            </Form.Item>
            <Form.Item
               label="Prezzo standard di vendita"
               name="sell_price_type"
               rules={[{ required: true }]}
            >
               <Radio.Group>
                  <Radio.Button value={SellPriceTypeEnum.LIST_PRICE}>Usa prezzi di listino</Radio.Button>
                  <Radio.Button value={SellPriceTypeEnum.USE_RULES}>Applica regole</Radio.Button>
               </Radio.Group>
            </Form.Item>

            <Form.Item
               label="Descrizione"
               name="description"
               rules={[{ message: 'Inserisci la descrizione' }]}
            >
               <TextArea
                  rows={4}
               />
            </Form.Item>

            <Form.Item label="id" name="id" noStyle><Input type="hidden" /></Form.Item>
            <Form.Item label="language" name="language" noStyle><Input type="hidden" /></Form.Item>

         </Form>
      );

      const pageContent = (
         <div className="card">
            <div className="card-header">
               <div className="align-items-center row">
                  <div className="col-sm-6">{languageManager.getMessage(this.props.commonState, 'component.marketplaces.title')}</div>
                  <div className="col-sm-6"></div>
               </div>
            </div>
            <div className="card-body">
               {this.state.pageLoader ? <Loader /> : form}
            </div>
            <div className="card-footer">
               <div className="align-items-center row">
                  <div className="col-sm-6">
                     <Space>
                        <Button
                           size='large'
                           onClick={() => { this.props.history.goBack() }}
                        >
                           <i className="mdi mdi-arrow-left-drop-circle-outline mr-1"></i>
                           {languageManager.getMessage(this.props.commonState, 'common.back')}
                        </Button>
                     </Space>
                  </div>

                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">

                        <Space>
                           <Button form="formMarketplace" htmlType="button" onClick={this.onReset} loading={this.state.formLoader}>Annulla</Button>
                           <Button form="formMarketplace" type="primary" htmlType="submit" loading={this.state.formLoader}>Salva</Button>
                           <Button form="formMarketplace" type="primary" htmlType="submit" onClick={this.handleSaveAndClose} loading={this.state.formLoader}>Salva e chiudi</Button>
                        </Space>

                     </div>
                  </div>
               </div>
            </div>
         </div>
      );

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>

      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
};
const mapDispatchToProps = dispatch => {
   return {
      updateActiveMarketplacesList: () => dispatch(loadActiveMarketplacesData())
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(MarketplacesEdit);