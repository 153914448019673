import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateObject } from '../../../shared/utility';

import { Form, Input, InputNumber, Button, Space, notification } from 'antd';
import { InfoCircleOutlined, LeftCircleOutlined } from '@ant-design/icons';

import languageManager from '../../../services/languageManager';
import * as storeActions from './store/actions/taxes';

import Loader from '../../common/PageElements/Loader';
import PageHeader from '../../common/PageElements/PageHeader'

class TaxRatesEdit extends Component {

   state = {
      action: undefined,
      saveAndClose: false,
      pageLoader: true,
      formValues: {},
      componentLanguage: this.props.authState.defaultLanguage
   };

   // static getDerivedStateFromProps(props, state) {
   //    if (props.action === 'edit') {
   //       const formValues = {
   //          id: props.itemData.id,
   //          name: props.itemData.name.it,
   //          percentage: props.itemData.percentage,
   //          language: 'it'
   //       }
   //       return updateObject(state, { formValues: formValues });
   //       // state.initial_values.name = props.itemData.t_name;
   //       // state.initial_values.percentage = props.itemData.percentage;
   //    }

   //    return state;
   // }

   componentDidMount() {
      if (this.props.match.params.id) {
         const itemId = this.props.match.params.id
         Promise.all([
            this.getItem(itemId),
            // this.getComplements()
         ]).then(() => {
            this.setState({
               action: 'edit',
               itemId: itemId,
               pageLoader: false
            });
         });
      }
      else {
         const formValues = {
            percentage: 0.00,
            language: this.state.componentLanguage,
         }
         this.setState({
            formValues: formValues,
            action: 'insert',
            pageLoader: false
         });
      }

   }

   getItem = (id) => {
      return storeActions.getItem(id)
         .then(response => {
            console.log(response);
            try {
               const updatedValues = {
                  ...response.data,
                  name: response.data.name[this.state.componentLanguage],
                  percentage: response.data.percentage,
                  id: response.data.id,
                  language: this.state.componentLanguage
               }
               this.setState({ formValues: updatedValues });

            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e);
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   handleSaveAndClose = () => {
      this.setState({ saveAndClose: true });
      console.log(this.state);
   }

   onFinish = values => {
      this.setState({ pageLoader: true });
      storeActions.createOrUpdate(values)
         .then(response => {
            this.setState({ pageLoader: false });
            console.log(response);
            try {
               if (this.state.saveAndClose) {
                  this.props.history.push('/taxes/taxrates/list');
               }

               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
                  description: languageManager.getMessage(this.props.commonState, this.state.action === 'edit' ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {

            }

         }).catch((e) => {
            this.setState({ pageLoader: false });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
         });
   };

   onFinishFailed = errorInfo => {
      notification.open({
         message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
         description: languageManager.getMessage(this.props.commonState, 'notification.error.formInvalid'),
         icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
      })
   };

   // onReset = () => {
   //   form.resetFields();
   // };

   render() {
      if (this.state.pageLoader) return <Loader />;
      const layout = {
         labelCol: { span: 8 },
         wrapperCol: { span: 16 },
      };
      const tailLayout = {
         wrapperCol: { offset: 8, span: 16 },
      };

      let form = (
         <Form
            {...layout}
            id="createTaxRate"
            name="basic"
            initialValues={this.state.formValues}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
         >
            <Form.Item
               label="Name"
               name="name"
               rules={[{ required: true, message: 'Inserisci il nome' }]}
            >
               <Input />
            </Form.Item>


            <Form.Item
               label="Percentage"
               name="percentage"
               rules={[{ required: true, message: 'Inserisci la percentuale' }]}
            >
               <InputNumber
                  min={0}
                  max={100}
                  step={0.10}
                  precision={2}
                  formatter={value => `${value}%`}
                  parser={value => value.replace('%', '')}
               />
            </Form.Item>

            {
               this.props.authState.userData.activeMarketplaces.map((marketplace, index) => {
                  if (marketplace.marketplace_base.alias === 'woocommerce') {
                     return (
                        <Form.Item
                           key={marketplace.id}
                           label={marketplace.name}
                           name={'marketplace_value_' + marketplace.id}
                        >
                           <Input style={{ width: '300px' }} placeholder='Valore sul marketplace' />
                        </Form.Item>
                     )
                  }
               })
            }

            <Form.Item label="id" name="id" noStyle><Input type="hidden" /></Form.Item>
            <Form.Item label="language" name="language" noStyle><Input type="hidden" /></Form.Item>

         </Form>
      );


      const pageContent = (
         <div className="card">
            <div className="card-header">
               <div className="align-items-center row">
                  <div className="col-sm-6">{languageManager.getMessage(this.props.commonState, 'component.tax_rates.title')}</div>
                  <div className="col-sm-6"></div>
               </div>
            </div>
            <div className="card-body">
               {form}
            </div>
            <div className="card-footer">
               <div className="align-items-center row">
                  <div className="col-sm-6">
                     <Space>
                        <Button
                           size='large'
                           onClick={() => { this.props.history.goBack() }}
                        >
                           <i className="mdi mdi-arrow-left-drop-circle-outline mr-1"></i>
                           {languageManager.getMessage(this.props.commonState, 'common.back')}
                        </Button>
                     </Space>
                  </div>

                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">

                        <Space>
                           <Button form="createTaxRate" htmlType="button" onClick={this.onReset}>Annulla</Button>
                           <Button form="createTaxRate" type="primary" htmlType="submit">Salva</Button>
                           <Button form="createTaxRate" type="primary" htmlType="submit" onClick={this.handleSaveAndClose}>Salva e chiudi</Button>
                        </Space>

                     </div>
                  </div>
               </div>
            </div>
         </div>
      );

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>

      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(TaxRatesEdit);