import Axios from 'axios';
import API_BASE from './apiBase';
import API_CONFIG from './apiConfig';

class PricesApi {

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getList() {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.prices.getList)
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getInitList() {
      return new Promise((resolve, reject) => {
         Axios.all([
            Axios.post(API_BASE.url + API_CONFIG.appUrls.prices.getListByMarketplace),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.marketplaces.getActiveList),
         ])
            // .then(responseArr => {
            //    console.log(responseArr);
            //    resolve(responseArr);
            // })
            .then(Axios.spread((priceRulesSetsList, marketplacesList) => {
               const response = {
                  priceRulesSetsList: priceRulesSetsList,
                  marketplacesList: marketplacesList,
               }
               resolve(response);
             }))
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getItem(itemId) {

      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.prices.getItem, {
            id: itemId
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getComplements() {
      return new Promise((resolve, reject) => {
         Axios.all([
            Axios.post(API_BASE.url + API_CONFIG.appUrls.customerGroups.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.categories.getTree),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.brands.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.tags.getList),
         ])
            .then(Axios.spread((customerGroupsList, categoriesTree, brandsList, tagsList) => {
               const response = {
                  customerGroupsList: customerGroupsList,
                  categoriesTree: categoriesTree,
                  brandsList: brandsList,
                  tagsList: tagsList,
               }
               resolve(response);
             }))
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static createOrUpdate(itemData, ) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.prices.createOrUpdate, {
            ...itemData
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static deleteItem(itemId, return_list, list_type,marketplace_id ) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.prices.deleteItem, {
            id: itemId,
            return_list: return_list,
            list_type: list_type,
            marketplace_id: marketplace_id,
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static toggleActive(itemData, ) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.prices.toggleActive, {
            ...itemData
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }
}

export default PricesApi;