import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import { Form, Input, Radio, Select, Divider, Switch, Button, Space, notification, Row, Col, Tabs } from 'antd'
import { InfoCircleOutlined, PlusOutlined, CloseOutlined, PlusSquareOutlined } from '@ant-design/icons'

import languageManager from '../../../services/languageManager'
import * as storeActions from './store/actions/customers'

import Loader from '../../common/PageElements/Loader'
import { CustomerTypeEnum } from '../../../shared/enums'
import CustomerContactCard from './widget/CustomerContactCard'
import CustomerMarketplaceCard from './widget/CustomerMarketplaceCard'
import CustomerShippingAddressCard from './widget/ShippingAddressCard'
import CustomerInvoicingAddressCard from './widget/InvoicingAddressCard'
import CustomerContactDrawer from './widget/CustomerContactDrawer'

class CustomersEdit extends Component {

   state = {
      action: undefined,
      itemId: undefined,
      saveAndClose: false,
      pageLoader: true,
      formLoader: false,
      formValues: {},
      paymentMethodsList: [],
      banksList: [],
      groupsList: [],
      componentLanguage: this.props.authState.defaultLanguage
   };

   // static getDerivedStateFromProps(props, state) {
   //    if (props.action === 'edit') {
   //       const formValues = {
   //          id: props.itemData.id,
   //          name: props.itemData.name.it,
   //          percentage: props.itemData.percentage,
   //          language: 'it'
   //       }
   //       return updateObject(state, { formValues: formValues });
   //       // state.initial_values.name = props.itemData.t_name;
   //       // state.initial_values.percentage = props.itemData.percentage;
   //    }

   //    return state;
   // }

   setForm = (response, lang = this.state.componentLanguage) => {
      const values = {
         is_company: response.is_company ? CustomerTypeEnum.COMPANY : CustomerTypeEnum.USER,
         first_name: response.first_name,
         last_name: response.last_name,
         company_name: response.company,
         vat: response.vat,
         fiscal_code: response.fiscal_code,
         email: response.email,
         pec: response.pec,
         sdi: response.sdi,
         phone: response.phone,
         group_id: response.group_id,
         payment_method_id: response.payment_method_id,
         bank_id: response.bank_id,
         notes: response.notes,
         contacts: response.contacts,
         shippingAddresses: response.shipping_addresses,
         // invoicingAddress: !_.isNil(response.invoicing_address) ? response.invoicing_address : {},
         invoicingAddress: response.invoicing_address,
         marketplace_sync: response.marketplace_sync,
         id: response.id,
         language: lang
      }
      return values;
   }

   getItem = (id) => {
      return storeActions.getItem(id)
         .then(response => {
            console.log(response);
            try {
               const updatedValues = this.setForm(response.data);
               // console.log(updatedValues)
               this.setState({ response: response.data, formValues: updatedValues });
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }
         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   getComplements = () => {
      return storeActions.getComplements()
         .then(response => {
            console.log(response);
            try {
               this.setState({
                  groupsList: response.groupsList,
                  paymentMethodsList: response.paymentMethodsList,
                  banksList: response.banksList,
               });
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }
         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   componentDidMount() {
      if (this.props.match.params.id) {
         const itemId = this.props.match.params.id
         Promise.all([
            this.getItem(itemId),
            this.getComplements()
         ]).then(() => {
            this.setState({
               action: 'edit',
               itemId: itemId,
               pageLoader: false
            });
         });
      }
      else {
         const formValues = {
            language: this.state.componentLanguage,
         }
         Promise.all([
            this.getComplements()
         ]).then(() => {
            this.setState({
               formValues: formValues,
               action: 'insert',
               pageLoader: false
            });
         });
      }
   }

   handleSaveAndClose = () => {
      this.setState({ saveAndClose: true });
      console.log(this.state);
   }

   handleCustomerTypeChange = (e) => {
      // console.log(e.target.value)
      let newValues = { ...this.state.formValues }
      switch (e.target.value) {
         case CustomerTypeEnum.USER:
            newValues.is_company = false
            break
         case CustomerTypeEnum.COMPANY:
            newValues.is_company = true
            break
      }
      this.setState({ formValues: newValues })
   }

   onFinish = values => {
      this.setState({ formLoader: true });
      values.visible = true;
      storeActions.createOrUpdate(values)
         .then(response => {
            console.log(response);
            try {
               if (this.state.saveAndClose) {
                  this.props.history.goBack();
               }
               const updatedValues = this.setForm(response.data);
               this.setState({ response: response.data, itemId: response.data.id, action: 'edit', formValues: updatedValues, formLoader: false });

               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
                  description: languageManager.getMessage(this.props.commonState, this.state.action === 'edit' ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               this.setState({ formLoader: false });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            this.setState({ formLoader: false });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.' + e.message),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
         });
   };

   onFinishFailed = errorInfo => {
      notification.open({
         message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
         description: languageManager.getMessage(this.props.commonState, 'notification.error.formInvalid'),
         icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
      })
   };

   onVisibleSwitchChange = (checked) => {
      let newFormValues = { ...this.state.formValues }
      newFormValues.is_visible = checked;
      this.setState({ formValues: newFormValues });
   }

   setDefaultLanguageHandler = (value) => {
      console.log(value);
      const updatedValues = this.setForm(this.state.response, value);
      this.setState({ formValues: updatedValues, componentLanguage: value })
   }

   showModalAddCustomer = (item) => {
      this.setState({ drawerItem: item, showDrawer: true })
   }
   hideDrawer = () => {
      this.setState({ drawerItemId: null, showDrawer: false })
   }
   handleEditAddress = (addresses_list) => {
      this.setState({ shippingAddresses: addresses_list, showDrawer: false })
   }

   showCustomerContactDrawer = (itemId) => {
      this.setState({ drawerCustomerContactItemId: itemId, showCustomerContactDrawer: true })
   }
   hideCustomerContactDrawer = () => {
      this.setState({ drawerCustomerContactItemId: null, showCustomerContactDrawer: false })
   }
   updateCustomerContactsList = (contactsList) => {
      let updatedValues = { ...this.state.formValues }
      updatedValues.contacts = contactsList
      this.setState({ formValues: updatedValues, showCustomerContactDrawer: false })
   }

   render() {
      if (this.state.pageLoader) return <Loader />;
      const layout = {
         labelCol: { span: 8 },
         wrapperCol: { span: 14 },
      };
      const tailLayout = {
         wrapperCol: { offset: 8, span: 16 },
      };
      const { TextArea } = Input;
      const { Option } = Select;
      const { TabPane } = Tabs;

      let form = (
         <Form
            {...layout}
            id="editCustomer"
            name="editCustomer"
            initialValues={this.state.formValues}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
         >
            <Row gutter={32}>
               <Col span={14}>
                  <Form.Item label="Tipo cliente" name="is_company"
                     rules={[{ required: true, message: 'Scegli il tipo di cliente' }]}>
                     <Radio.Group
                        onChange={this.handleCustomerTypeChange}>
                        <Radio.Button value={CustomerTypeEnum.USER}>Privato</Radio.Button>
                        <Radio.Button value={CustomerTypeEnum.COMPANY}>Azienda</Radio.Button>
                     </Radio.Group>
                  </Form.Item>
                  <Form.Item
                     label="Codice interno"
                     name="internal_code"
                     key="internal_code"
                  >
                     <Input />
                  </Form.Item>
                  {
                     this.state.formValues.is_company ?
                        [
                           <Form.Item
                              label="Azienda"
                              name="company_name"
                              key="company_name"
                           >
                              <Input />
                           </Form.Item>,
                           <Form.Item
                              label="Partita IVA"
                              name="vat"
                              key="vat"
                           >
                              <Input />
                           </Form.Item>
                        ] : [
                           <Form.Item
                              label="Nome"
                              name="first_name"
                              key="first_name"
                           >
                              <Input />
                           </Form.Item>,
                           <Form.Item
                              label="Cognome"
                              name="last_name"
                              key="last_name"
                           >
                              <Input />
                           </Form.Item>,
                        ]
                  }
                  <Form.Item
                     label="Codice Fiscale"
                     name="fiscal_code"
                  >
                     <Input />
                  </Form.Item>
                  <Form.Item
                     label="Indirizzo email"
                     name="email"
                     rules={[
                        {
                           type: 'email',
                           required: true,
                           message: 'Inserisci un indirizzo email valido',
                        },
                        // {
                        //    required: true,
                        //    message: 'Please input your E-mail!',
                        // },
                     ]}
                  >
                     <Input />
                  </Form.Item>
                  <Form.Item
                     label="Telefono"
                     name="phone"
                  >
                     <Input />
                  </Form.Item>
                  <Form.Item
                     label="PEC"
                     name="pec"
                     rules={[
                        {
                           type: 'email',
                           required: false,
                           message: 'Inserisci un indirizzo PEC valido',
                        },
                     ]}
                  >
                     <Input />
                  </Form.Item>
                  {
                     this.state.formValues.is_company ?
                        [
                           <Form.Item
                              label="SDI"
                              name="sdi"
                              key="sdi"
                           >
                              <Input />
                           </Form.Item>
                        ] : null
                  }
               </Col>
               <Col span={10}>
                  <Form.Item
                     label="Gruppo"
                     name="group_id"
                     rules={[]}
                  >
                     <Select
                        showSearch
                        placeholder="Seleziona il gruppo"
                        optionFilterProp="children"
                     >
                        {
                           this.state.groupsList.map((group) => {
                              return <Option key={group.id} value={group.id}>{group.name}</Option>
                           })
                        }
                     </Select>
                  </Form.Item>
                  <Form.Item
                     label="Metodo di pagamento"
                     name="payment_method_id"
                     rules={[]}
                  >
                     <Select
                        showSearch
                        placeholder="Seleziona il metodo di pagamento"
                        optionFilterProp="children"
                     >
                        {
                           this.state.paymentMethodsList.map((payment) => {
                              return <Option key={payment.id} value={payment.id}>{payment.name}</Option>
                           })
                        }
                     </Select>
                  </Form.Item>
                  <Form.Item
                     label="Banca di appoggio"
                     name="bank_id"
                     rules={[]}
                  >
                     <Select
                        showSearch
                        placeholder="Seleziona la banca di appoggio"
                        optionFilterProp="children"
                     >
                        {
                           this.state.banksList.map((bank) => {
                              return <Option key={bank.id} value={bank.id}>{bank.name}</Option>
                           })
                        }
                     </Select>
                  </Form.Item>
                  <Divider />
                  <Form.Item
                     label="Note"
                     name="notes"
                     key="notes"
                  >
                     <TextArea rows={5} />
                  </Form.Item>
               </Col>
            </Row>
            <Form.Item name="id" noStyle><Input type="hidden" /></Form.Item>
            <Form.Item label="language" name="language" noStyle><Input type="hidden" /></Form.Item>
         </Form>
      );

      const pageContent = (
         <div className="card">
            <div className="card-header">
               <div className="align-items-center row">
                  <div className="col-sm-6">
                     {languageManager.getMessage(this.props.commonState, 'component.customers.title')}
                  </div>
                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">
                        <Space>
                           {
                              this.props.authState.userData.languages ? (
                                 <Select
                                    showSearch
                                    className="width-70px-I"
                                    placeholder="Seleziona la lingua"
                                    defaultValue={this.state.componentLanguage.toUpperCase()}
                                    optionFilterProp="children"
                                    onChange={this.setDefaultLanguageHandler}
                                 >
                                    {
                                       this.props.authState.userData.languages.map((lang) => {
                                          return <Option key={lang.value} value={lang.value}>{lang.label}</Option>
                                       })
                                    }
                                 </Select>
                              ) : null
                           }
                        </Space>
                     </div>
                  </div>
               </div>
            </div>
            <div className="card-body">
               {form}
               {
                  this.state.itemId ? (
                     <div>
                        <Tabs size="large" className="margin-top-20px-I"
                        //  tabBarExtraContent={(
                        // <Button type="primary" size='small'
                        //    icon={<EditOutlined />}
                        //    onClick={() => this.showModalAddCustomer(record, index)}
                        // />
                        //  )}
                        >
                           <TabPane tab="Contatti" key="1">
                              <Space direction='vertical' size='small'>
                                 <Button type="primary"
                                    icon={<PlusOutlined />}
                                    onClick={() => this.showCustomerContactDrawer(null)}
                                 >Aggiungi contatto</Button>
                                 {
                                    !_.isEmpty(this.state.formValues.contacts) ? (
                                       <Row gutter={16}>
                                          {
                                             this.state.loadingCustomerContacts ? <Loader /> : (
                                                this.state.formValues.contacts.map((contact, index) => {
                                                   return (
                                                      <Col key={'contact_' + contact.id} span={6}>
                                                         <CustomerContactCard
                                                            item={contact}
                                                            onEdit={() => this.showCustomerContactDrawer(contact.id)}
                                                            onDelete={(contactsList) => this.updateCustomerContactsList(contactsList)}
                                                         // invoicingAddress={this.state.formValues.invoicingAddress}
                                                         />
                                                      </Col>
                                                   )
                                                })
                                             )
                                          }
                                       </Row>
                                    ) : null
                                 }
                              </Space>
                              <CustomerContactDrawer
                                 visible={this.state.showCustomerContactDrawer}
                                 onClose={this.hideCustomerContactDrawer}
                                 onSubmit={(contactsList) => this.updateCustomerContactsList(contactsList)}
                                 customerId={this.state.itemId}
                                 itemId={this.state.drawerCustomerContactItemId}
                              />
                           </TabPane>
                           <TabPane tab="Indirizzi" key="2">
                              <Row gutter={[16, 16]}>
                                 <Col span={12}>
                                    <CustomerShippingAddressCard
                                       customerId={this.state.itemId}
                                       shippingAddresses={this.state.formValues.shippingAddresses}
                                    />
                                 </Col>
                                 {
                                    this.state.formValues.is_company ?
                                       <Col span={12}>
                                          <CustomerInvoicingAddressCard
                                             customerId={this.state.itemId}
                                             invoicingAddress={this.state.formValues.invoicingAddress}
                                          />
                                       </Col> : null
                                 }
                              </Row>
                           </TabPane>
                           <TabPane tab="Marketplace" key="3">
                              {
                                 !_.isEmpty(this.props.authState.userData.activeMarketplaces) ? (
                                    <Row gutter={16}>
                                       {this.props.authState.userData.activeMarketplaces.map((marketplace, index) => {
                                          return (
                                             <Col key={'marketplace_' + marketplace.id} span={8}>
                                                <CustomerMarketplaceCard
                                                   marketplace={marketplace}
                                                   sync_data={_.isNil(this.state.formValues.marketplace_sync[marketplace.id]) ? undefined : this.state.formValues.marketplace_sync[marketplace.id]}
                                                   product_id={this.state.itemId}
                                                   product_data={this.state.formValues}
                                                />
                                             </Col>
                                          )
                                       })}
                                    </Row>
                                 ) : null
                              }
                           </TabPane>
                        </Tabs>
                     </div>
                  ) : null
               }
            </div>
            <div className="card-footer">
               <div className="align-items-center row">
                  <div className="col-sm-6">
                     <Space>
                        <Button
                           size='large'
                           onClick={() => { this.props.history.goBack() }}
                        >
                           <i className="mdi mdi-arrow-left-drop-circle-outline mr-1"></i>
                           {languageManager.getMessage(this.props.commonState, 'common.back')}
                        </Button>
                     </Space>
                  </div>

                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">

                        <Space>
                           <Button form="editCustomer" htmlType="button" disabled={this.state.formLoader}>Annulla</Button>
                           <Button form="editCustomer" type="primary" htmlType="submit" loading={this.state.formLoader}>Salva</Button>
                           <Button form="editCustomer" type="primary" htmlType="submit" onClick={this.handleSaveAndClose} loading={this.state.formLoader}>Salva e chiudi</Button>
                        </Space>

                     </div>
                  </div>
               </div>
            </div>
         </div>
      );

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>

      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(CustomersEdit);