import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import Moment from 'moment'

import { Modal, Form, Input, InputNumber, Button, Space, Divider, Cascader, Select, notification, DatePicker, Typography } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons';

import languageManager from '../../../../services/languageManager';
import * as storeActions from '../store/actions/warehouse_movements';
import * as productsActions from '../../../catalogue/products/store/actions/products';

class ModalDischargeMovement extends Component {
    state = {
        ...this.props,
        componentLoader: true,
        formLoader: false,
        formValues: {},
        itemId: null,
        actualProduct: { stocks: [] },
        actualProductId: null,
        productBatchList: [],
        componentLanguage: this.props.authState.defaultLanguage
    }
    formRef = React.createRef();

    componentDidMount() {
        // console.log('Component did mount')
        Promise.all([
            //  this.getSuppliersList()
        ]).then(() => {
            this.setState({
                componentLoader: false
            });
        });
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        // console.log('Component did update', nextProps, this.state)
        if (_.isEqual(nextProps.visible, this.props.visible) && _.isEqual(nextState, this.state)) {
            return false
        }
        return true
    }

    componentDidUpdate = (prevProps, prevState) => {
        // console.log('Component did update', prevState, this.props)
        if (!_.isEqual(prevProps.visible, this.props.visible)) {
            if (!_.isNil(this.props.item)) {
                const newFormValues = { ...this.props.item }
                Promise.all([
                    this.handleProductChange([this.props.item.product_id])
                ]).then(() => {
                    this.setFormValues(newFormValues)
                    this.setState({ itemId: this.props.item.id })
                })
            } else {
                const newFormValues = {
                    movement_date: Moment(),
                    movement_reason: 'Scarico manuale del ' + Moment().format('L')
                }
                this.setFormValues(newFormValues)
                this.setState({ itemId: null, actualProduct: { stocks: [] }, actualProductId: null, })
            }
            // this.setState({ ...this.props })
            // console.log('Component did update - update state', prevState, this.props)
        }
    }

    setFormValues = (item) => {
        console.log('Set form values')
        console.log(item)
        // console.log(this.formRef)
        if (this.formRef.current) {
            this.formRef.current.setFieldsValue({
                line_index: item.line_index,
                product_id: item.cascader_value,
                productId: item.product_id,
                sku: item.product_sku,
                productName: item.product_name,
                customerCode: item.supplier_id,
                quantity: item.quantity,
            })
        }
    }
    addProductFilter = (inputValue, path) => {
        return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    }

    handleProductChange = (value) => {
        console.log('handleProductChange', value)
        this.setState({ productLoader: true })
        if (_.isUndefined(value) || _.isEmpty(value)) {
            this.formRef.current.setFieldsValue({
                productId: null,
                sku: null,
                productName: null,
                batch: null,
                quantity: null,
            })
            this.setState({
                actualProduct: { stocks: [] },
                actualProductId: null,
                productLoader: false
            })
        } else {
            const selectedId = value.at(-1)
            return productsActions.getItemForGroup(selectedId)
                .then(response => {
                    console.log(response);
                    const productData = response.data
                    this.formRef.current.setFieldsValue({
                        productId: productData.id,
                        sku: productData.sku,
                        productName: productData.full_name,
                    })
                    if (productData.id !== selectedId) {
                        this.formRef.current.setFieldsValue({
                            batch: null,
                            quantity: null,
                        })
                    }
                    const productBatchList = this.props.batchList.filter((x) => x.parent_id === productData.parent_id)
                    this.setState({
                        actualProduct: productData,
                        actualProductId: productData.id,
                        productBatchList: productBatchList,
                        productLoader: false
                    })
                }).catch((e) => {
                    notification.open({
                        message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                        description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                        icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                    })
                    if (this.props.commonState.debug) console.log(e)
                    this.setState({ productLoader: false })
                })
        }
    }

    onFinish = values => {
        this.setState({ formLoader: true });
        values.return_list = false;
        values.movement_type = 1
        values.product_id = values.productId
        storeActions.createOrUpdate(values)
            .then(response => {
                try {
                    this.onClose()
                    this.state.onSubmit(response.data)
                    this.setState({ formLoader: false });
                    notification.open({
                        message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
                        description: languageManager.getMessage(this.props.commonState, this.state.action === 'edit' ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
                        icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                    })
                } catch (e) {
                    notification.open({
                        message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                        description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                        icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                    })
                    this.setState({ formLoader: false });
                }

            }).catch((e) => {
                notification.open({
                    message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                    description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                    icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                })
                this.setState({ formLoader: false });
            });


        // console.log(values)
        // const movement = {
        //     cascader_value: values.product_id,
        //     product_id: values.productId,
        //     product_sku: values.sku,
        //     product_name: values.productName,
        //     batch: values.batch,
        //     quantity: parseFloat(values.quantity),
        //     // unit_price: parseFloat(values.buyPrice),
        //     // unit_tax: parseFloat(values.buyPrice) / 100 * parseFloat(values.taxRatePercentage),
        //     // total_price: parseFloat(values.totalPrice),
        //     // total_tax: parseFloat(values.totalPrice) / 100 * parseFloat(values.taxRatePercentage),
        //     // tax_rate: parseFloat(values.taxRatePercentage),
        // }
        // this.state.onSubmit(movement)
        // this.formRef.current.resetFields()
        // this.state.onClose()
        // notification.open({
        //     message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
        //     description: languageManager.getMessage(this.props.commonState, this.state.action === 'edit' ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
        //     icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
        // })
    }

    onClose = () => {
        this.formRef.current.resetFields()
        this.state.onClose()
    }

    render() {
        console.log('Render modal add discharge movement', this.state)
        const { Text } = Typography
        const { Option } = Select

        const mainLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 18 },
            layout: "horizontal",
        };

        return (
            <Modal
                visible={this.props.visible}
                title="Crea movimentazione di magazzino - Scarico"
                width={800}
                onClose={this.onClose}
                onCancel={this.onClose}
                footer={[
                    <Button key="back" onClick={this.onClose} disabled={this.state.productLoader || this.state.formLoader}>Annulla</Button>,
                    <Button key='submitForm' form="formAddDischargeMovement" type="primary" htmlType="submit" disabled={_.isNil(this.state.actualProductId)} loading={this.state.formLoader}>Salva</Button>
                ]}
            >
                <Form
                    id="formAddDischargeMovement"
                    ref={this.formRef}
                    {...mainLayout}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                >
                    {
                        this.state.itemId ? null : ([
                            <Form.Item
                                label='Cerca prodotto'
                                name='product_id'
                                key='product_id'
                                rules={[{ required: true, message: 'Scegli un prodotto dal catalogo' }]}
                            >
                                <Cascader
                                    placeholder="Seleziona un prodotto"
                                    options={this.props.productCascaderList}
                                    showSearch={{ filter: this.addProductFilter }}
                                    changeOnSelect
                                    onChange={this.handleProductChange}
                                    loading={this.state.productLoader} />
                            </Form.Item>,
                            <Divider key='search_product_divider' />
                        ])
                    }
                    <Form.Item
                        label="SKU"
                        name="sku"
                    >
                        <Input disabled />
                    </Form.Item>
                    <Form.Item
                        label="Nome prodotto"
                        name="productName"
                    >
                        <Input disabled />
                    </Form.Item>
                    <Form.Item
                        label="Cliente"
                        name="customer_id"
                    >
                        <Select
                            showSearch
                            placeholder="Scegli il cliente"
                            optionFilterProp="children"
                            allowClear={true}
                            disabled={_.isNil(this.state.actualProductId)}
                        >
                            {
                                this.props.customerList.map((customer) => {
                                    return <Option key={customer.id} value={customer.id}>{customer.full_name}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Lotto"
                        name="batch_id"
                    >
                        <Select
                            showSearch
                            placeholder="Scegli il lotto"
                            optionFilterProp="children"
                            allowClear={true}
                            disabled={!this.state.actualProductId}
                        >
                            {
                                this.state.actualProduct.stocks.map((stock) => {
                                    const batchName = _.isNil(stock.batch) ? 'Lotto non identificato' : stock.batch
                                    const batchExpiration = _.isNil(stock.expiration_date) ? '' : ' (scadenza: ' + Moment(stock.expiration_date).format('L') + ')'
                                    return (
                                        <Option key={stock.id} value={stock.batch_id}>
                                            <Text strong>{batchName + batchExpiration}</Text><br />
                                            <Text italic>Magazzino: {stock.warehouse_quantity}, impegnato: {stock.reserved}, ordinato: {stock.ordered}</Text>
                                        </Option>
                                    )
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Quantità"
                        name="discharged"
                        rules={[{ required: true, message: 'Inserisci una quantità valida' }]}
                    >
                        <InputNumber decimalSeparator=',' precision={2} step={1} min={0.01}
                            disabled={_.isNil(this.state.actualProductId)} />
                    </Form.Item>
                    <Form.Item
                        label="Data scarico"
                        name="movement_date"
                        rules={[{ required: true, message: 'Inserisci una data valida' }]}
                        initialValue={Moment()}
                    >
                        <DatePicker
                            placeholder="Data di scarico"
                            format="DD/MM/YYYY"
                            allowClear={true}
                            disabled={_.isNil(this.state.actualProductId)} />
                    </Form.Item>
                    <Form.Item
                        label="Causale"
                        name="movement_reason"
                        rules={[{ required: true, message: 'Inserisci una causale valida' }]}
                    >
                        <Input disabled={_.isNil(this.state.actualProductId)} />
                    </Form.Item>
                    {/* <Form.Item
                        label="Prezzo di acquisto"
                        name="buyPrice"
                    >
                        <InputNumber decimalSeparator=',' precision={2} addonAfter='€' onChange={this.handleTotalChange}
                            disabled={_.isNil(this.state.actualProductId)} />
                    </Form.Item>
                    <Form.Item
                        label="IVA"
                        name="taxRatePercentage"
                    >
                        <InputNumber decimalSeparator=',' precision={2} addonAfter='%' disabled />
                    </Form.Item>
                    <Form.Item
                        label="Prezzo totale"
                        name="totalPrice"
                    >
                        <InputNumber decimalSeparator=',' precision={2} addonAfter='€' disabled />
                    </Form.Item> */}
                    <Form.Item name="productId" noStyle><Input type="hidden" /></Form.Item>
                    <Form.Item label="language" name="language" initialValue={this.state.componentLanguage} noStyle><Input type="hidden" /></Form.Item>
                </Form>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    return {
        authState: state.authenticationState,
        commonState: state.commonState
    };
}

export default connect(mapStateToProps)(ModalDischargeMovement)