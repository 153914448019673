import OrdersApi from '../../../../../api/OrdersApi'; 
import _ from 'lodash';

export function createOrUpdate(itemData) {
   console.log(itemData);
   return OrdersApi.createOrUpdate(itemData)
      .then(response => {
         if (response.data.success === true) {
            console.log(response);
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function getList() {
   return OrdersApi.getList()
      .then(response => {
         console.log(response);
         // if (response.success === true) {
         if (response.data.success === true) {
            response.data.data.forEach(el => {
               el.key = el.id;
            });
            console.log(response);
            // return response;
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

export function getListExtended(page, pageSize, filters) {
   return OrdersApi.getListExtended(page, pageSize, filters)
      .then(response => {
         console.log(response);
         // if (response.success === true) {
         if (response.data.success === true) {
            response.data.data.data.forEach(el => {
               el.key = el.id;
            });
            console.log(response);
            // return response;
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

export function getOrdersFilterComplements() {
   return OrdersApi.getOrdersFilterComplements()
      .then(response => {
         console.log(response);
         const responseData = {}
         _.forEach(response, (el, i) => {
            if (el.data.success !== true) {
               const error = el.data.error ? el.data.error : 'Server error'
               throw new Error(error)
            }
            responseData[i] = el.data.data;
         });
         return responseData;
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

export function getListFilters() {
   return OrdersApi.getListFilters()
      .then(response => {
         if (response.data.success === true) {
            console.log(response);
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function getItem(itemId) {
   return OrdersApi.getItem(itemId)
      .then(response => {
         // if (response.success === true) {
         if (response.data.success === true) {
            console.log(response);
            // return response;
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

export function getCreateOrderComplements() {
   return OrdersApi.getCreateOrderComplements()
      .then(response => {
         console.log(response);
         const responseData = {}
         _.forEach(response, (el, i) => {
            if (el.data.success !== true) {
               const error = el.data.error ? el.data.error : 'Server error'
               throw new Error(error)
            }
            responseData[i] = el.data.data;
         });
         return responseData;
      })
      .catch(error => {
         throw error;
      });
}
export function getOrderComplements() {
   return OrdersApi.getOrderComplements()
      .then(response => {
         console.log(response);
         const responseData = {}
         _.forEach(response, (el, i) => {
            if (el.data.success !== true) {
               const error = el.data.error ? el.data.error : 'Server error'
               throw new Error(error)
            }
            responseData[i] = el.data.data;
         });
         return responseData;
      })
      .catch(error => {
         throw error;
      });
}

export function getPrivaliaOrders(return_list = false) {
   return OrdersApi.getPrivaliaOrders(return_list)
      .then(response => {
         console.log(response);
         // if (response.success === true) {
         if (response.data.success === true) {
            // if(return_list) {
            //    response.data.data.list.forEach(el => {
            //       el.key = el.id;
            //    });
            // }
            console.log(response);
            // return response;
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

export function downloadOrders(marketplace_id, return_list = false) {
   return OrdersApi.downloadOrders(marketplace_id)
      .then(response => {
         console.log(response);
         if (response.data.success === true) {
            console.log(response);
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function updateOrderStatus(orderId, newStatus) {
   return OrdersApi.updateOrderStatus(orderId, newStatus)
      .then(response => {
         console.log(response);
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function acceptOrder(orderId) {
   return OrdersApi.acceptOrder(orderId,)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function acceptMultipleOrders(orderIds) {
   return OrdersApi.acceptMultipleOrders(orderIds,)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function acceptPartialOrder(orderId, refusedLines) {
   return OrdersApi.acceptPartialOrder(orderId, refusedLines)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function processOrder(orderId) {
   return OrdersApi.processOrder(orderId,)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function processMultipleOrders(orderIds) {
   return OrdersApi.processMultipleOrders(orderIds,)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function refuseOrder(orderId) {
   return OrdersApi.refuseOrder(orderId,)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function refuseMultipleOrders(orderIds) {
   return OrdersApi.refuseMultipleOrders(orderIds,)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function cancelOrder(orderId) {
   return OrdersApi.cancelOrder(orderId)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function refuseOrderLine(lineId) {
   return OrdersApi.refuseOrderLine(lineId)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function cancelOrderLine(orderId, lineId, params) {
   return OrdersApi.cancelOrderLine(orderId, lineId, params)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
         throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function refundOrderLine(orderId, lineId, params) {
   return OrdersApi.refundOrderLine(orderId, lineId, params)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
         throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function returnOrderLine(orderId, lineId, params) {
   return OrdersApi.returnOrderLine(orderId, lineId, params)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
         throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function shipOrder(orderId, params) {
   return OrdersApi.shipOrder(orderId, params)
      .then(response => {
         console.log(response);
         if (response.data.success === true) {
            console.log(response);
            return response.data;
         }
         else
         throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function sendToFattura24(orderId) {
   return OrdersApi.sendToFattura24(orderId)
      .then(response => {
         console.log(response);
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         throw error;
      });
}
export function sendToFattureincloud(orderId, documentType) {
   return OrdersApi.sendToFattureincloud(orderId, documentType)
      .then(response => {
         console.log(response);
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function exportOrders(filename, orderIds) {
   return OrdersApi.exportOrders(filename, orderIds)
      .then(response => {
         console.log(response);
         let blob = new Blob(['\ufeff' + response.data], { type: 'application/*' })
         let link = document.createElement('a')
         link.href = window.URL.createObjectURL(blob)
         link.download = filename;
         link.click();
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

export function exportOrdersSummary(filename, orderIds) {
   return OrdersApi.exportOrdersSummary(filename, orderIds)
      .then(response => {
         console.log(response);
         let blob = new Blob(['\ufeff' + response.data], { type: 'application/*' })
         let link = document.createElement('a')
         link.href = window.URL.createObjectURL(blob)
         link.download = filename;
         link.click();
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

export function printDetails(filename, itemId) {
   return OrdersApi.printDetails(filename, itemId)
      .then(response => {
         console.log(response);
         let blob = new Blob([response.data], { type: 'application/*' })
         let link = document.createElement('a')
         link.href = window.URL.createObjectURL(blob)
         link.download = filename;
         link.click();
      })
      .catch(error => {
         throw error;
      });
}

export function printMultipleDetails(filename, itemIds) {
   return OrdersApi.printMultipleDetails(filename, itemIds)
      .then(response => {
         console.log(response);
         let blob = new Blob([response.data], { type: 'application/*' })
         let link = document.createElement('a')
         link.href = window.URL.createObjectURL(blob)
         link.download = filename;
         link.click();
      })
      .catch(error => {
         throw error;
      });
}

export function sendMail(filename, itemId) {
   return OrdersApi.sendMail(filename, itemId)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function importFileCSV(formData) {
   return OrdersApi.importFileCSV(formData)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}