import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'

import languageManager from '../../../../services/languageManager'

import { Descriptions, Space, notification, Row, Col, Divider, Typography, Card, Button } from 'antd'
import { EditOutlined, CloseCircleTwoTone, WarningTwoTone, ContactsOutlined } from '@ant-design/icons'

import * as storeActions from '../store/actions/customers'

import InvoicingAddressDrawer from './InvoicingAddressDrawer'

class CustomerInvoicingAddressCard extends Component {
    state = {
        ...this.props,
        componentLoader: true,
    }

    componentDidMount() {
        console.log(this.props)
        Promise.all([
        ]).then(() => {
            this.setState({
                componentLoader: false,
                // rangeChangeLoader: false,
            });
        });
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        if (
            _.isEqual(nextProps.invoicingAddress, this.props.invoicingAddress)
            && _.isEqual(nextState, this.state)
        ) {
            return false
        }
        return true
    }

    // componentDidUpdate = (prevProps, prevState) => {
    //     console.log('componentDidUpdate')
    //     console.log(this.props.invoicingAddress)
    //     // console.log(prevProps.categoryId, this.props.categoryId)
    //     if (!_.isEqual(prevProps.invoicingAddress, this.props.invoicingAddress)) {
    //         // console.log('componentDidUpdate CONDITION OK')
    //         this.setState({ componentLoader: true });
    //         Promise.all([
    //             // this.getCategoryAmazonData(this.props.categoryId),
    //             // this.getCountOrdersBoxData(),
    //             // this.prepareRangeSelection(),
    //         ]).then(() => {
    //             this.setState({
    //                 componentLoader: false,
    //                 // rangeChangeLoader: false,
    //             });
    //         });
    //     }
    // }

    showDrawer = (itemId) => {
        this.setState({ drawerItemId: itemId, showDrawer: true })
    }
    hideDrawer = () => {
        this.setState({ drawerItemId: null, showDrawer: false })
    }
    handleEditAddress = (address) => {
        this.setState({ invoicingAddress: address, showDrawer: false })
    }

    render() {
        const { Title } = Typography;
        const address = this.state.invoicingAddress
        console.log(address)
        return (
            <Card loading={this.state.componentLoader || this.state.dataLoader}
                title={
                    <Title level={5}>{languageManager.getMessage(this.props.commonState, 'component.customers.invoicing')}</Title>
                }
                size='small'
                bordered={false}
                className="card-analysis-data"
            >
                <Space direction='vertical' size='small'>
                    <Card
                        title={
                            <Title level={5}>{languageManager.getMessage(this.props.commonState, 'component.customers.invoicing_address')}</Title>
                        }
                        size='small'
                        bordered={true}
                        extra={
                            <Space size='small'>
                                <Button type="primary" size='small'
                                    icon={<EditOutlined />}
                                    onClick={() => this.showDrawer(_.isNil(address) ? null : address.id)}
                                />
                            </Space>
                        }
                    >
                        {
                            !_.isNil(address) ? (
                                <Descriptions
                                    bordered
                                    // title={languageManager.getMessage(this.props.commonState, 'component.analysis.category_amazon_data.title')}
                                    size='small'
                                    column={1}
                                >
                                    <Descriptions.Item label="Nome">{address.name}</Descriptions.Item>
                                    <Descriptions.Item label="Indirizzo">{address.address_1}</Descriptions.Item>
                                    <Descriptions.Item label="Indirizzo (opz.)">{address.address_2}</Descriptions.Item>
                                    <Descriptions.Item label="Città">{address.city}</Descriptions.Item>
                                    <Descriptions.Item label="CAP">{address.postal_code}</Descriptions.Item>
                                    <Descriptions.Item label="Provincia">{address.state}</Descriptions.Item>
                                    <Descriptions.Item label="Nazione">{address.country}</Descriptions.Item>
                                    <Descriptions.Item label="Email">{address.email}</Descriptions.Item>
                                    <Descriptions.Item label="Telefono">{address.phone}</Descriptions.Item>
                                    <Descriptions.Item label="Note">{address.note}</Descriptions.Item>
                                </Descriptions>
                            ) : languageManager.getMessage(this.props.commonState, 'component.customers.no_invoicing_address')
                        }
                    </Card>
                </Space>
                <InvoicingAddressDrawer
                    visible={this.state.showDrawer}
                    customerId={this.state.customerId}
                    itemId={this.state.drawerItemId}
                    onClose={this.hideDrawer}
                    onSubmit={(address) => this.handleEditAddress(address)}
                />
            </Card>
        )
    }
}

function mapStateToProps(state) {
    return {
        authState: state.authenticationState,
        commonState: state.commonState
    };
}

export default connect(mapStateToProps)(CustomerInvoicingAddressCard)