import React from 'react';
import Moment from 'moment';

import { Table } from 'antd';

/**
 * Renders the notifications table
 */
const NotificationsTable = (props) => {
   console.log(props);
   const tableLayout = {
      bordered: true,
      size: "small",
      pagination: false
   }
   const table_columns = [
      {
         title: 'Data messaggio', width: 200, dataIndex: 'note_created_at', key: 'note_created_at', fixed: 'left',
         render: (record) => (
            Moment(record).format('L LTS')
         ),
      },
      {
         title: 'Data aggiornamento', width: 200, dataIndex: 'note_updated_at', key: 'note_updated_at', fixed: 'left',
         render: (record) => (
            Moment(record).format('L LTS')
         ),
      },
      { title: 'Oggetto', width: 300, dataIndex: 'subject', key: 'subject', fixed: 'left' },
      { title: 'Messaggio', dataIndex: 'message', key: 'message', fixed: 'left' },
      { title: 'Stato', width: 150, dataIndex: 'status', key: 'status', fixed: 'left' },
   ]
   return (
      <div className="card-body">
         {props.order_notes.length ?
            <Table {...tableLayout}
               columns={table_columns}
               dataSource={props.order_notes}
            /> : ''
         }
      </div>
   );
};

export default NotificationsTable;
