import API_BASE from './apiBase';
import API_CONFIG from './apiConfig';

import Axios from 'axios';

class DashboardApi {

    /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
    static getOrderData(startDate, endDate) {

        return new Promise((resolve, reject) => {
            Axios.post(API_BASE.url + API_CONFIG.appUrls.dashboard.getOrderData, {
                startDate: startDate,
                endDate: endDate
            })
                .then(response => {
                    console.log(response);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        });
    }

    /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
    static getChannelData(startDate, endDate) {

        return new Promise((resolve, reject) => {
            Axios.post(API_BASE.url + API_CONFIG.appUrls.dashboard.getChannelData, {
                startDate: startDate,
                endDate: endDate
            })
                .then(response => {
                    console.log(response);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        });
    }

    /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
    static getProductData(startDate, endDate) {

        return new Promise((resolve, reject) => {
            Axios.post(API_BASE.url + API_CONFIG.appUrls.dashboard.getProductData, {
                startDate: startDate,
                endDate: endDate
            })
                .then(response => {
                    console.log(response);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        });
    }

    /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
    static getOrderTotals() {

        return new Promise((resolve, reject) => {
            Axios.post(API_BASE.url + API_CONFIG.appUrls.dashboard.get_order_totals)
                .then(response => {
                    console.log(response);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        });
    }

    /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
    static getOrderTotalsDetails(startDate, endDate) {

        return new Promise((resolve, reject) => {
            Axios.post(API_BASE.url + API_CONFIG.appUrls.dashboard.get_order_totals_details, {
                startDate: startDate,
                endDate: endDate
            })
                .then(response => {
                    console.log(response);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        });
    }

    /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
    static getOrdersChartData(year) {
        return new Promise((resolve, reject) => {
            Axios.post(API_BASE.url + API_CONFIG.appUrls.dashboard.get_orders_chart_data, {
                year: year,
            })
                .then(response => {
                    console.log(response);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
}

export default DashboardApi;