import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import Moment from 'moment'

import { Modal, Form, Input, InputNumber, Button, Space, Divider, Cascader, Select, notification, DatePicker } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons';

import languageManager from '../../../../services/languageManager';
import * as storeActions from '../store/actions/warehouse_movements';
import * as productsActions from '../../../catalogue/products/store/actions/products';
import * as batchesActions from '../../../catalogue/batches/store/actions/batches';

class ModalChargeMovement extends Component {
    state = {
        ...this.props,
        componentLoader: true,
        formLoader: false,
        formValues: {},
        itemId: null,
        actualProduct: null,
        productBatchList: [],
        componentLanguage: this.props.authState.defaultLanguage
    }
    formRef = React.createRef();

    componentDidMount() {
        // console.log('Component did mount')
        Promise.all([
            // this.getBatchList()
        ]).then(() => {
            this.setState({
                componentLoader: false
            });
        });
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        // console.log('Component did update', nextProps, this.state)
        if (_.isEqual(nextProps.visible, this.props.visible) && _.isEqual(nextState, this.state)) {
            return false
        }
        return true
    }

    componentDidUpdate = (prevProps, prevState) => {
        // console.log('Component did update', prevState, this.props)
        if (!_.isEqual(prevProps.visible, this.props.visible)) {
            if (!_.isNil(this.props.item)) {
                const newFormValues = { ...this.props.item }
                this.setFormValues(newFormValues)
                this.setState({ itemId: this.props.item.id, actualProduct: this.props.item.product_id })
            } else {
                const newFormValues = {
                    movement_date: Moment(),
                    movement_reason: 'Carico manuale del ' + Moment().format('L')
                }
                this.setFormValues(newFormValues)
            }
            // this.setState({ ...this.props })
            // console.log('Component did update - update state', prevState, this.props)
        }
    }

    setFormValues = (item) => {
        console.log('Set form values')
        console.log(item)
        // console.log(this.formRef)
        if (this.formRef.current) {
            this.formRef.current.setFieldsValue({
                line_index: item.line_index,
                product_id: item.cascader_value,
                productId: item.product_id,
                sku: item.product_sku,
                productName: item.product_name,
                supplierCode: item.supplier_id,
                quantity: item.quantity,
                buyPrice: item.unit_price,
                taxRatePercentage: item.tax_rate,
                totalPrice: item.total_price,
            })
        }
    }

    addProductFilter = (inputValue, path) => {
        return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    }

    handleProductChange = (value) => {
        console.log('handleProductChange', value)
        this.setState({ productLoader: true })
        if (_.isUndefined(value) || _.isEmpty(value)) {
            this.formRef.current.setFieldsValue({
                productId: null,
                sku: null,
                productName: null,
                batch: null,
                quantity: null,
            })
            this.setState({
                actualProduct: null,
                productBatchList: [],
                productLoader: false
            })
        } else {
            const selectedId = value.at(-1)
            return productsActions.getItemForGroup(selectedId, this.props.actualCustomerGroup)
                .then(response => {
                    console.log(response);
                    const productData = response.data
                    this.formRef.current.setFieldsValue({
                        productId: productData.id,
                        sku: productData.sku,
                        productName: productData.full_name,
                    })
                    if (productData.id !== selectedId) {
                        this.formRef.current.setFieldsValue({
                            batch_id: null,
                            batch: null,
                            quantity: null,
                        })
                    }
                    // console.log(this.props.batchList);
                    const productBatchList = this.props.batchList.filter((x) => x.parent_id === productData.parent_id)
                    // console.log(productData.parent_id, productBatchList)
                    this.setState({
                        actualProduct: productData.id,
                        productBatchList: productBatchList,
                        productLoader: false
                    })
                }).catch((e) => {
                    notification.open({
                        message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                        description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                        icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                    })
                    if (this.props.commonState.debug) console.log(e)
                    this.setState({ productLoader: false })
                })
        }
    }

    handleTotalChange = () => {
        const quantity = this.formRef.current.getFieldValue('quantity')
        const buy_price = this.formRef.current.getFieldValue('buyPrice')
        if (!_.isNil(quantity) && !_.isNil(buy_price)) {
            const total_price = parseFloat(quantity) * parseFloat(buy_price)
            this.formRef.current.setFieldsValue({ totalPrice: total_price })
        }
    }

    onFinish = values => {
        this.setState({ formLoader: true });
        values.return_list = false;
        values.movement_type = 0
        values.product_id = values.productId
        storeActions.createOrUpdate(values)
            .then(response => {
                try {
                    this.onClose()
                    this.state.onSubmit(response.data)
                    this.setState({ formLoader: false });
                    notification.open({
                        message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
                        description: languageManager.getMessage(this.props.commonState, this.state.action === 'edit' ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
                        icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                    })
                } catch (e) {
                    notification.open({
                        message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                        description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                        icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                    })
                    this.setState({ formLoader: false });
                }

            }).catch((e) => {
                notification.open({
                    message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                    description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                    icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                })
                this.setState({ formLoader: false });
            });


        // console.log(values)
        // const movement = {
        //     cascader_value: values.product_id,
        //     product_id: values.productId,
        //     product_sku: values.sku,
        //     product_name: values.productName,
        //     batch: values.batch,
        //     quantity: parseFloat(values.quantity),
        //     // unit_price: parseFloat(values.buyPrice),
        //     // unit_tax: parseFloat(values.buyPrice) / 100 * parseFloat(values.taxRatePercentage),
        //     // total_price: parseFloat(values.totalPrice),
        //     // total_tax: parseFloat(values.totalPrice) / 100 * parseFloat(values.taxRatePercentage),
        //     // tax_rate: parseFloat(values.taxRatePercentage),
        // }
        // this.state.onSubmit(movement)
        // this.formRef.current.resetFields()
        // this.state.onClose()
        // notification.open({
        //     message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
        //     description: languageManager.getMessage(this.props.commonState, this.state.action === 'edit' ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
        //     icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
        // })
    }

    onClose = () => {
        this.formRef.current.resetFields()
        this.state.onClose()
    }

    render() {
        console.log('Render modal add charge movement', this.state)
        const { Option } = Select

        const mainLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 18 },
            layout: "horizontal",
        };

        return (
            <Modal
                visible={this.props.visible}
                title="Crea movimentazione di magazzino - Carico"
                width={800}
                onClose={this.onClose}
                onCancel={this.onClose}
                footer={[
                    <Button key="back" onClick={this.onClose} disabled={this.state.productLoader || this.state.formLoader}>Annulla</Button>,
                    <Button key='submitForm' form="formAddChargeMovement" type="primary" htmlType="submit" disabled={_.isNil(this.state.actualProduct)} loading={this.state.formLoader}>Salva</Button>
                ]}
            >
                <Form
                    id="formAddChargeMovement"
                    ref={this.formRef}
                    {...mainLayout}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                >
                    {
                        this.state.itemId ? null : ([
                            <Form.Item
                                label='Cerca prodotto'
                                name='product_id'
                                key='product_id'
                                rules={[{ required: true, message: 'Scegli un prodotto dal catalogo' }]}
                            >
                                <Cascader
                                    placeholder="Seleziona un prodotto"
                                    options={this.props.productCascaderList}
                                    showSearch={{ filter: this.addProductFilter }}
                                    changeOnSelect
                                    onChange={this.handleProductChange}
                                    loading={this.state.productLoader} />
                            </Form.Item>,
                            <Divider key='search_product_divider' />
                        ])
                    }
                    <Form.Item
                        label="SKU"
                        name="sku"
                    >
                        <Input disabled />
                    </Form.Item>
                    <Form.Item
                        label="Nome prodotto"
                        name="productName"
                    >
                        <Input disabled />
                    </Form.Item>
                    <Form.Item
                        label="Fornitore"
                        name="supplier_id"
                    >
                        <Select
                            showSearch
                            placeholder="Scegli il fornitore"
                            optionFilterProp="children"
                            allowClear={true}
                            disabled={_.isNil(this.state.actualProduct)}
                        >
                            {
                                this.props.supplierList.map((supplier) => {
                                    return <Option key={supplier.id} value={supplier.id}>{supplier.company}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Lotto"
                        name="batch_id"
                    >
                        <Select
                            showSearch
                            placeholder="Scegli il lotto"
                            optionFilterProp="children"
                            allowClear={true}
                            disabled={_.isNil(this.state.actualProduct)}
                        >
                            {
                                this.state.productBatchList.map((batch) => {
                                    return <Option key={batch.id} value={batch.id}>{!_.isNil(batch.expiration_date) ? batch.batch + ' (scadenza: ' + Moment(batch.expiration_date).format('DD/MM/YYYY') + ')': batch.batch}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    {/* <Form.Item
                        label="Lotto"
                        name="batch"
                    >
                        <Input disabled={_.isNil(this.state.actualProduct)} />
                    </Form.Item>
                    <Form.Item
                        label="Data di scadenza"
                        name="expiration_date"
                    >
                        <DatePicker
                            placeholder="Data di scadenza"
                            format="DD/MM/YYYY"
                            allowClear={true}
                            disabled={_.isNil(this.state.actualProduct)} />
                    </Form.Item> */}
                    <Form.Item
                        label="Quantità"
                        name="charged"
                        rules={[{ required: true, message: 'Inserisci una quantità valida' }]}
                    >
                        <InputNumber decimalSeparator=',' precision={2} step={1} min={0.01}
                            disabled={_.isNil(this.state.actualProduct)} />
                    </Form.Item>
                    <Form.Item
                        label="Data carico"
                        name="movement_date"
                        rules={[{ required: true, message: 'Inserisci una data valida' }]}
                        initialValue={Moment()}
                    >
                        <DatePicker
                            placeholder="Data di carico"
                            format="DD/MM/YYYY"
                            allowClear={true}
                            disabled={_.isNil(this.state.actualProduct)} />
                    </Form.Item>
                    <Form.Item
                        label="Magazzino"
                        name="warehouse_to"
                        rules={[{ required: true, message: 'Scegli il magazzino utilizzato' }]}
                        initialValue={this.props.warehouseList.length ? this.props.warehouseList[0].id : null}
                    >
                        <Select
                            showSearch
                            placeholder="Scegli il magazzino"
                            optionFilterProp="children"
                            allowClear={true}
                            disabled={_.isNil(this.state.actualProduct)}
                        >
                            {
                                this.props.warehouseList.map((warehouse) => {
                                    return <Option key={warehouse.id} value={warehouse.id}>{warehouse.name}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Causale"
                        name="movement_reason"
                        rules={[{ required: true, message: 'Inserisci una causale valida' }]}
                    >
                        <Input disabled={_.isNil(this.state.actualProduct)} />
                    </Form.Item>
                    {/* <Form.Item
                        label="Prezzo di acquisto"
                        name="buyPrice"
                    >
                        <InputNumber decimalSeparator=',' precision={2} addonAfter='€' onChange={this.handleTotalChange}
                            disabled={_.isNil(this.state.actualProduct)} />
                    </Form.Item>
                    <Form.Item
                        label="IVA"
                        name="taxRatePercentage"
                    >
                        <InputNumber decimalSeparator=',' precision={2} addonAfter='%' disabled />
                    </Form.Item>
                    <Form.Item
                        label="Prezzo totale"
                        name="totalPrice"
                    >
                        <InputNumber decimalSeparator=',' precision={2} addonAfter='€' disabled />
                    </Form.Item> */}
                    <Form.Item name="productId" noStyle><Input type="hidden" /></Form.Item>
                    <Form.Item label="language" name="language" initialValue={this.state.componentLanguage} noStyle><Input type="hidden" /></Form.Item>
                </Form>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    return {
        authState: state.authenticationState,
        commonState: state.commonState
    };
}

export default connect(mapStateToProps)(ModalChargeMovement)