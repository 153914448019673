import Axios from 'axios';
import API_BASE from './apiBase';
import API_CONFIG from './apiConfig';

class WarehousesApi {

   static createOrUpdate(itemData) {
      console.log(itemData)
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.warehouses.createOrUpdate, {
            ...itemData
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               console.log(error);
               reject(error);
            });

      });
   }

   static getItem(itemId) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.warehouses.getItem, {
            id: itemId
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static getList() {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.warehouses.getList)
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static getListExtended(page, pageSize, filters) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.warehouses.getListExtended, { page: page, page_size: pageSize, filters_values: JSON.stringify(filters) })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static getComplements() {
      return new Promise((resolve, reject) => {
         Axios.all([
            Axios.post(API_BASE.url + API_CONFIG.appUrls.suppliers.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.products.getListCascader, { allow_select_parent: true }),
         ])
            .then(Axios.spread((supplierList, productCascaderList) => {
               const response = {
                  supplierList: supplierList,
                  productCascaderList: productCascaderList,
               }
               resolve(response);
            }))
            .catch(error => {
               reject(error);
            });
      });
   }

   static deleteItems(itemIds) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.warehouses.deleteItems, {
            item_ids: itemIds
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static exportCSV(filename, orderIds) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.warehouses.exportCSV, { filename: filename, order_ids: orderIds })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

}

export default WarehousesApi;