import Axios from 'axios';
import API_BASE from './apiBase';
import API_CONFIG from './apiConfig';

class PurchaseOrdersApi {

   static createOrUpdate(itemData) {
      console.log(itemData)
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.purchaseOrders.createOrUpdate, {
            ...itemData
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               console.log(error);
               reject(error);
            });

      });
   }

   static getList() {

      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.purchaseOrders.getList)
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getListExtended(page, pageSize, filters) {

      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.purchaseOrders.getListExtended, { page: page, page_size: pageSize, filters_values: JSON.stringify(filters) })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getListFilters() {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.purchaseOrders.getListFilters, {})
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getOrdersFilterComplements() {
      return new Promise((resolve, reject) => {
         Axios.all([
            Axios.post(API_BASE.url + API_CONFIG.appUrls.purchaseOrderstatuses.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.marketplaces.getList),
         ])
            .then(Axios.spread((orderStatusesList, marketplacesList) => {
               const response = {
                  orderStatusesList: orderStatusesList,
                  marketplacesList: marketplacesList
               }
               resolve(response);
            }))
            .catch(error => {
               reject(error);
            });
      });
   }

   static getItem(itemId) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.purchaseOrders.getItem, {
            id: itemId
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   static getItemByCode(itemCode, codeType) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.purchaseOrders.getItemByCode, {
            code: itemCode,
            code_type: codeType
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static getComplements() {
      return new Promise((resolve, reject) => {
         Axios.all([
            Axios.post(API_BASE.url + API_CONFIG.appUrls.suppliers.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.shippingMethods.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.products.getListCascader, { allow_select_parent: true }),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.productBatches.getList),
         ])
            .then(Axios.spread((supplierList, shippingMethodList, productCascaderList, batchList) => {
               const response = {
                  supplierList: supplierList,
                  shippingMethodList: shippingMethodList,
                  productCascaderList: productCascaderList,
                  batchList: batchList,
               }
               resolve(response);
            }))
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static updateOrderStatus(orderId, newStatus) {

      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.purchaseOrders.updateStatus, {
            order_id: orderId,
            new_status: newStatus,
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static acceptOrder(orderId) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.purchaseOrders.acceptOrder, { order_id: orderId })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static acceptMultipleOrders(orderIds) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.purchaseOrders.acceptMultipleOrders, { order_ids: orderIds })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static acceptPartialOrder(orderId, refusedLines) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.purchaseOrders.acceptPartialOrder, { order_id: orderId, refused_lines: refusedLines })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static processOrder(orderId) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.purchaseOrders.processOrder, { order_id: orderId })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static processMultipleOrders(orderIds) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.purchaseOrders.processMultipleOrders, { order_ids: orderIds })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static refuseOrder(orderId) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.purchaseOrders.refuseOrder, { order_id: orderId })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static refuseMultipleOrders(orderIds) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.purchaseOrders.refuseMultipleOrders, { order_ids: orderIds })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static cancelOrder(orderId) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.purchaseOrders.cancelOrder, { order_id: orderId })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static refuseOrderLine(lineId) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.purchaseOrders.refuseOrderLine, { line_id: lineId })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static cancelOrderLine(orderId, lineId, params) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.purchaseOrders.cancelOrderLine, {
            order_id: orderId,
            line_id: lineId,
            ...params
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static refundOrderLine(orderId, lineId, params) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.purchaseOrders.refundOrderLine, {
            order_id: orderId,
            line_id: lineId,
            ...params
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static returnOrderLine(orderId, lineId, params) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.purchaseOrders.returnOrderLine, {
            order_id: orderId,
            line_id: lineId,
            ...params
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static shipOrder(orderId, params) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.purchaseOrders.shipOrder, {
            order_id: orderId,
            ...params
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getPrivaliaOrders(return_list) {

      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.purchaseOrders.getPrivaliaOrders, { return_list: return_list })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static downloadOrders(marketplace_id, return_list) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.purchaseOrders.downloadOrders, { marketplace_id: marketplace_id, return_list: return_list })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static exportOrders(filename, orderIds) {

      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.purchaseOrders.exportOrders, { filename: filename, order_ids: orderIds })
            .then(response => {
               // window.open(API_BASE.url + API_CONFIG.appUrls.purchaseOrders.exportOrders);
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }
   static printDetails(filename, itemId) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.purchaseOrders.printDetails, {filename: filename, item_id: itemId}, {responseType: 'blob'})
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
   *
   * @param
   * @returns {Promise<unknown>}
   */
   static importFileCSV(formData) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.purchaseOrders.importFileCSV, { ...formData })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

}

export default PurchaseOrdersApi;