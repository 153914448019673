import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import Moment from 'moment'

import { MultipleActionEnum } from '../../../shared/enums.ts'

import { Table, Button, List, Space, notification, Row, Col, Collapse, Typography, Modal } from 'antd'
import { InfoCircleOutlined, ExclamationCircleOutlined, UploadOutlined, DeleteOutlined, EditOutlined, PrinterOutlined, CheckOutlined, PlusOutlined } from '@ant-design/icons'

import languageManager from '../../../services/languageManager'

import * as storeActions from './store/actions/warehouses'
import { startLoading, endLoading } from '../../../shared/utility'

import Loader from '../../common/PageElements/Loader'

import TableFilters from '../../common/components/TableFilters'
import DropdownMenu from '../../common/components/DropdownMenu'

import ModalEditWarehouse from './widget/ModalEditWarehouse'

class WarehousesList extends Component {
   state = {
      pageLoader: true,
      tableLoader: false,
      loadersLines: [],

      itemList: [],
      fullListIds: [],
      componentLanguage: this.props.authState.defaultLanguage,

      current_page: this.props.location.state ? this.props.location.state.previousState.current_page : 1,
      page_size: this.props.location.state ? this.props.location.state.previousState.page_size : 50,
      total_result: 0,

      selectedRowKeys: [],
      selectedAll: false,
      multipleActionLoader: false,
      multipleActionList: [
         // {
         //    action: MultipleActionEnum.EXPORT.toString(),
         //    name: 'Esporta selezionati in CSV'
         // },
         {
            action: MultipleActionEnum.DELETE.toString(),
            name: 'Cancella selezionati',
            needconfirmation: 'yes'
         },
      ],

      showTableFilters: false,
      filters: this.props.location.state ? this.props.location.state.previousState.filters : [],
      filtersValues: this.props.location.state ? this.props.location.state.previousState.filtersValues : {},
      appliedFilters: this.props.location.state ? this.props.location.state.previousState.appliedFilters : [],

      activeMarketplaces: [],

      showModalEditWarehouse: false,
      modalEditWarehouseItemId: null,

   };

   getList = (page, pageSize, filters) => {
      // asyncronous calls
      this.setState({ tableLoader: true })
      return storeActions.getListExtended(page, pageSize, filters)
         .then(response => {
            if (this.props.commonState.debug) console.log(response);
            try {
               let updatedFilters = []
               for (let filter of response.list_filters) {
                  if (filter.type === 'date') {
                     if (filter.value) {
                        filter.value = Moment(filter.value, 'YYYY-MM-DD');
                     }
                  }
                  updatedFilters.push(filter);
               }

               this.setState({
                  itemList: response.data.data,
                  fullListIds: response.full_list_ids,
                  current_page: page,
                  page_size: pageSize,
                  total_result: response.data.total,
                  filters: updatedFilters,
                  appliedFilters: response.applied_filters,
                  tableLoader: false
               });
            } catch (e) {
               this.setState({ tableLoader: false })
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e);
            }

         }).catch((e) => {
            this.setState({ tableLoader: false })
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   componentDidMount() {
      Promise.all([
         this.getList(this.state.current_page, this.state.page_size, this.state.filtersValues),
      ]).then(() => {
         this.setState({
            pageLoader: false
         });
      });
   }

   exportToCSVHandler = () => {
      // if (this.props.commonState.debug) console.log(this.state.selectedRowKeys);
      this.setState({ multipleActionLoader: true });
      let now = Moment().format('YYYY.MM.DD-HH.mm.ss');
      let filename = now + '_WAREHOUSES.csv';
      storeActions.exportCSV(filename, this.state.selectedRowKeys)
         .then(response => {
            if (this.props.commonState.debug) console.log(response);
            this.setState({ multipleActionLoader: false });
            notification.success({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.confirm.exportOk'),
            })
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.human ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.human}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
            this.setState({ multipleActionLoader: false });
            if (this.props.commonState.debug) console.log(e);
         });
   }

   listPaginationChangeHandler = (page, pageSize) => {
      if (this.props.commonState.debug) console.log(page, pageSize, this.state.filtersValues);
      this.getList(page, pageSize, this.state.filtersValues);
   }
   handleTableChange = (pagination, filters, sorter) => {
      if (this.props.commonState.debug) console.log(pagination, filters, sorter)
      // this.fetch({
      //   sortField: sorter.field,
      //   sortOrder: sorter.order,
      //   pagination,
      //   ...filters,
      // });
   }
   onSelectChange = (selectedRowKeys, selection) => {
      if (this.props.commonState.debug) console.log(selection)
      this.setState({ selectedRowKeys: selectedRowKeys })
   };

   submitFilters = (values) => {
      // if (this.props.commonState.debug) console.log("Selected filters", values)
      Object.keys(values).forEach(function (key) {
         if (Moment.isMoment(values[key])) {
            values[key] = Moment(values[key]).format('YYYY-MM-DD');
         }
      });
      this.setState({ filtersValues: values })
      this.getList(this.state.current_page, this.state.page_size, values);
   }
   resetFilters = () => {
      if (this.props.commonState.debug) console.log("Reset filters")
      this.getList(this.state.current_page, this.state.page_size, []);
   }

   selectAllHandler = () => {
      if (this.state.selectedAll) {
         this.setState({
            selectedAll: false,
            selectedRowKeys: [],
         });
      }
      else {
         this.setState({
            selectedAll: true,
            selectedRowKeys: this.state.fullListIds,
         });
      }
   }
   multipleActionHandler = (value) => {
      const props = value.item.props
      switch (props.action) {
         case MultipleActionEnum.EXPORT.toString():
            this.exportToCSVHandler(this.state.selectedRowKeys);
            break;
         case MultipleActionEnum.DELETE.toString():
            this.deleteItems(this.state.selectedRowKeys)
            break;
         default:
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.operationNotValid'),
            })
            break;

      }
   }

   deleteItems = (values) => {
      let itemIds = []
      if (Array.isArray(values)) {
         itemIds = values
      } else {
         itemIds.push(values)
      }
      this.setState({ loadersLines: startLoading(this.state.loadersLines, itemIds) });
      storeActions.deleteItems(itemIds)
         .then(response => {
            if (this.props.commonState.debug) console.log(response)
            this.setState({ loadersLines: endLoading(this.state.loadersLines, itemIds) })
            try {
               this.getList(this.state.current_page, this.state.page_size, this.state.filtersValues)
               notification.success({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.deleteOk'),
               })
            } catch (e) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  duration: 0
               })
            }
         }).catch((e) => {
            this.setState({ loadersLines: endLoading(this.state.loadersLines, itemIds) })
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.human ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.human}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
            if (this.props.commonState.debug) console.log(e)
         })
   }

   showModalEditWarehouse = (value) => {
      let toShow = false
      let itemId = null
      if (value === false) {
         toShow = false
         itemId = null
      } else {
         toShow = true
         itemId = value
      }
      this.setState({ modalEditWarehouseItemId: itemId, showModalEditWarehouse: toShow })
   }
   handleEditWarehouse = () => {
      this.getList(this.state.current_page, this.state.page_size, this.state.filtersValues)
   }

   render() {
      if (this.state.pageLoader) return <Loader />
      const { Panel } = Collapse
      const { Link } = Typography

      const table_columns = [
         // { title: 'ID', width: 100, dataIndex: 'id', key: 'id', fixed: 'left' },
         // {
         //    title: 'ID Marketplace',
         //    width: 150,
         //    dataIndex: 'marketplace_id',
         //    key: 'marketplace_id',
         //    fixed: 'left',
         //    render: (text, record) => (
         //       <a onClick={() => this.viewItemHandler(record.id)} >{text}</a>
         //    ),
         // },
         {
            title: 'Codice interno',
            width: 120,
            key: 'internal_code',
            dataIndex: 'internal_code',
            fixed: 'left',
            sorter: (a, b) => {
               a = a.internal_code || ''
               b = b.internal_code || ''
               return a.localeCompare(b)
            },
         },
         {
            title: 'Magazzino',
            width: 200,
            key: 'warehouse_name',
            fixed: 'left',
            render: (text, record) => (
               <Link onClick={() => this.showModalEditWarehouse(record.id)}
                  disabled={this.state.loadersLines[record.id]}>{record.name}</Link>
            ),
            sorter: (a, b) => a.name.localeCompare(b.name),
         },
         {
            title: 'Indirizzo',
            key: 'address',
            dataIndex: 'address',
            sorter: (a, b) => {
               a = a.address || ''
               b = b.address || ''
               return a.localeCompare(b)
            },
         },
         {
            title: 'Telefono',
            width: 150,
            key: 'phone',
            dataIndex: 'phone',
            sorter: (a, b) => {
               a = a.phone || ''
               b = b.phone || ''
               return a.localeCompare(b)
            },
         },
         {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            align: 'right',
            width: 100,
            render: (text, record) => (
               <Space size='small'>
                  <Button type="primary" size="small"
                     icon={<EditOutlined />}
                     title={languageManager.getMessage(this.props.commonState, 'common.edit')}
                     onClick={() => this.showModalEditWarehouse(record.id)}
                     disabled={this.state.loadersLines[record.id]}
                  />
                  <Button type="danger" size='small'
                     loading={this.state.loadersLines[record.id]}
                     icon={<DeleteOutlined />}
                     onClick={() =>
                        Modal.confirm({
                           title: languageManager.getMessage(this.props.commonState, 'component.orders.delete_order_line'),
                           icon: <ExclamationCircleOutlined />,
                           content: languageManager.getMessage(this.props.commonState, 'common.askOperationConfirm'),
                           okText: languageManager.getMessage(this.props.commonState, 'common.confirm'),
                           onOk: () => this.deleteItems([record.id])
                        })
                     }
                  />
               </Space>
            ),
         },
      ];

      const { selectedRowKeys } = this.state
      const tableLayout = {
         bordered: true,
         className: "table-striped-rows",
         size: "small",
         loading: this.state.tableLoader,
         onChange: this.handleTableChange,
         pagination: {
            pageSize: this.state.page_size,
            onChange: this.listPaginationChangeHandler,
            total: this.state.total_result
         },
         rowSelection: {
            selectedRowKeys,
            onChange: this.onSelectChange,
            preserveSelectedRowKeys: true,
         }
      }


      const pageFilters = (
         <Collapse defaultActiveKey={['filters']}>
            <Panel header="Filtri" key="filters">
               <TableFilters submitFilters={this.submitFilters} resetFilters={this.resetFilters} filters={this.state.filters} selectValues={this.state.filtersValues} />
            </Panel>
         </Collapse>
      );

      const hasSelected = selectedRowKeys.length > 0;
      const singleSelected = selectedRowKeys.length > 1;
      const pageContent = (
         <div className="card">
            <div className="card-header">
               <div className="align-items-center row">
                  <div className="col-sm-6">{languageManager.getMessage(this.props.commonState, 'component.warehouses.title')}</div>
                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">
                        <Space>
                           {/* <Link to={'/purchase_orders/edit'}> */}
                           <Button type="primary"
                              onClick={() => this.showModalEditWarehouse()}
                              icon={<PlusOutlined />}>Aggiungi magazzino</Button>
                           {/* </Link> */}
                           {/* <Button
                              type='primary'
                              icon={<UploadOutlined />}
                              onClick={() => this.props.history.push('/orders/import_csv/')}
                           >
                              Importa CSV
                           </Button> */}
                           <ModalEditWarehouse
                              visible={this.state.showModalEditWarehouse}
                              itemId={this.state.modalEditWarehouseItemId}
                              onClose={() => this.showModalEditWarehouse(false)}
                              onSubmit={(itemList) => this.handleEditWarehouse(itemList)} />
                        </Space>
                     </div>
                  </div>
               </div></div>
            <div className="card-body">
               <Row>
                  <Col span={24}>{pageFilters}</Col>
               </Row>
               <Row>
                  <Col span={24}>
                     <div className="align-items-center row" style={{ marginTop: 15, marginBottom: 5 }}>
                        <div className="col-sm-6">
                           <Space direction='horizontal' size='small'>
                              <Button
                                 onClick={() => this.selectAllHandler()}
                              >{this.state.selectedAll ? 'Deseleziona' : 'Seleziona tutto'}
                              </Button>
                              <span>
                                 {hasSelected ? singleSelected ? `${selectedRowKeys.length} elementi selezionati` : `${selectedRowKeys.length} elemento selezionato` : ''}
                              </span>
                              <DropdownMenu
                                 type='primary'
                                 title='Azioni multiple'
                                 action={this.multipleActionHandler}
                                 values={this.state.multipleActionList}
                                 disabled={!hasSelected}
                                 loading={this.state.multipleActionLoader}
                              />
                           </Space>
                        </div>
                        <div className="col-sm-6">
                           <div className="float-right d-none d-md-block">
                              {/* <Button type="primary" loading={this.state.loader}>
                                    Mostra solo righe con differenze
                                 </Button> */}
                           </div>
                        </div>
                     </div>
                  </Col>
               </Row>
               <Row>
                  <Col span={24}>
                     <Table {...tableLayout}
                        columns={table_columns}
                        dataSource={this.state.itemList}
                        // rowSelection={rowSelection}
                        rowKey={(record) => (record.id)}
                     />
                  </Col>
               </Row>
            </div>
         </div >
      );

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>
      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState,
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(WarehousesList);