import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import Moment from 'moment'

import API_BASE from '../../../../api/apiBase'
import API_CONFIG from '../../../../api/apiConfig'

import { Modal, Form, Upload, InputNumber, Button, Space, Divider, Switch, Select, notification, message, List } from 'antd'
import { InfoCircleOutlined, InboxOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';

import languageManager from '../../../../services/languageManager';
import * as storeActions from '../store/actions/warehouse_movements';
import * as productsActions from '../../../catalogue/products/store/actions/products';

class ModalImportMovements extends Component {
    state = {
        ...this.props,
        componentLoader: true,
        formLoader: false,
        formValues: {},
        componentLanguage: this.props.authState.defaultLanguage
    }
    formRef = React.createRef();

    componentDidMount() {
        // console.log('Component did mount')
        Promise.all([
            //  this.getSuppliersList()
        ]).then(() => {
            this.setState({
                componentLoader: false
            });
        });
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        // console.log('Component did update', nextProps, this.state)
        if (_.isEqual(nextProps.visible, this.props.visible) && _.isEqual(nextState, this.state)) {
            return false
        }
        return true
    }

    onFinish = values => {
        this.setState({ formLoader: true })
        const data = {}
        data.file_path = values.fileImport[0].response.data.file_path
        data.file_name = values.fileImport[0].response.data.file_name
        data.warehouse_id = values.warehouse_id
        data.reset_missing_products = values.reset_missing_products
        if (!data.file_path || !data.file_name) {
            this.setState({ formLoader: false, draggerDisable: false })
            notification.error({
                message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                description: languageManager.getMessage(this.props.commonState, 'notification.error.dataNotValid'),
            })
        }
        else {
            storeActions.importCSV(data)
                .then(response => {
                    this.setState({ formLoader: false })
                    try {
                        this.formRef.current.resetFields()
                        this.state.onClose()
                        this.state.onSubmit(response.data)
                        this.setState({ draggerDisable: false })
                        notification.success({
                            message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
                            description: response.human ? (
                                <List
                                    size="small"
                                    split={false}
                                    dataSource={response.human}
                                    renderItem={item => <List.Item>{item}</List.Item>}
                                />
                            ) :  languageManager.getMessage(this.props.commonState, 'notification.confirm.importOk'),
                        })
                    } catch (e) {
                        notification.error({
                            message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                            description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                        })
                        if (this.props.commonState.debug) console.log(e)
                    }

                }).catch((e) => {
                    this.setState({ formLoader: false })
                    notification.error({
                        message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                        description: e.human ? (
                            <List
                                size="small"
                                split={false}
                                dataSource={e.human}
                                renderItem={item => <List.Item>{item}</List.Item>}
                            />
                        ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                        duration: 0
                    })
                    if (this.props.commonState.debug) console.log(e)
                });
        }
    }

    onClose = () => {
        this.formRef.current.resetFields()
        this.setState({ draggerDisable: false })
        this.state.onClose()
    }

    render() {
        console.log('Render modal import movements', this.state)
        const { Option } = Select
        const scope = this
        const formId = 'ImportMovements'

        const mainLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 18 },
            layout: "horizontal",
        };

        const normFile = e => {
            console.log('Upload event:', e);
            if (Array.isArray(e)) {
                return e;
            }
            return e && e.fileList;
        };
        const draggerProps = {
            name: 'file',
            multiple: false,
            disabled: this.state.draggerDisable,
            action: API_BASE.url + API_CONFIG.appUrls.files.fileImport,
            onChange(info) {
                scope.setState({ draggerDisable: info.fileList.length });
                console.log("CHANGE: ", info);
                const { status } = info.file;
                if (status !== 'uploading') {
                    console.log(info.file, info.fileList);
                }
                if (status === 'done') {
                    message.success(`${info.file.name} file uploaded successfully.`);
                } else if (status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
        };

        return (
            <Modal
                visible={this.props.visible}
                title="Importa inventario di magazzino"
                width={800}
                onClose={this.onClose}
                onCancel={this.onClose}
                footer={[
                    <Button key="back" onClick={this.onClose} disabled={this.state.formLoader}>Annulla</Button>,
                    <Button key='submitForm' form={formId} type="primary" htmlType="submit" loading={this.state.formLoader}>Salva</Button>
                ]}
            >
                <Form
                    {...mainLayout}
                    id={formId}
                    ref={this.formRef}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                >
                    <Form.Item
                        name="fileImport"
                        rules={[{ required: true, message: 'Scegli un file' }]}
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                        colon={false}
                        wrapperCol={24}
                    >
                        <Upload.Dragger {...draggerProps}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">
                                Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                                band files
                            </p>
                        </Upload.Dragger>
                    </Form.Item>
                    <Form.Item
                        label="Magazzino"
                        name="warehouse_id"
                        rules={[{ required: true, message: 'Scegli il magazzino utilizzato' }]}
                        initialValue={this.props.warehouseList.length ? this.props.warehouseList[0].id : null}
                    >
                        <Select
                            showSearch
                            placeholder="Seleziona il magazzino utilizzato"
                            optionFilterProp="children"
                            allowClear={true}
                        >
                            {
                                this.props.warehouseList.map((warehouse) => {
                                    return <Option key={warehouse.id} value={warehouse.id}>{warehouse.name}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Azzera prodotti mancanti"
                        extra="Tutti i lotti dei prodotti non presenti nel file di import verranno scaricati dal magazzino"
                        name="reset_missing_products"
                        valuePropName="checked"
                    >
                        <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    return {
        authState: state.authenticationState,
        commonState: state.commonState
    };
}

export default connect(mapStateToProps)(ModalImportMovements)