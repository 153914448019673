export enum ActionEnum {
   INSERT,
   EDIT,
}
export enum MultipleActionEnum {
   EXPORT,
   DELETE,
}
export enum CustomerTypeEnum {
   USER,
   COMPANY,
}
export enum SellPriceTypeEnum {
   LIST_PRICE,
   USE_RULES,
}
export enum PriceRuleTypeEnum {
   MARGINE,
   AUMENTO,
   SCONTO,
}
export enum OrderDocumentTypeEnum {
   RECEIPT,
   INVOICE,
}
export enum OrderStatusEnum {
   NULL,
   WAITING_ACCEPTANCE,
   PENDING,
   PROCESSING,
   WAITING_SHIPMENT,
   SHIPPED,
   DELIVERED,
   CANCELLED,
   UNKNOWN
}
export enum OrderStatusActionEnum {
   ACCEPT,
   MULTIPLE_ACCEPT,
   ACCEPT_PARTIAL,
   PROCESS,
   MULTIPLE_PROCESS,
   REFUSE,
   MULTIPLE_REFUSE,
}
export enum OrderLineActionEnum {
   REFUSE,
}

export enum ProductsImportFileCSVType {
   NULL,
   QUANTITIES,
   PRICES,
   IMAGES,
   TODELETE,
   ADDCATEGORIES,
   TEXTS,
   ADDTAGS,
}

export enum OrdersImportFileCSVType {
   NULL0,
   TRACKING,
}

export enum WarehouseMovementTypeEnum {
   CHARGE,
   DISCHARGE,
   RESERVE,
   ORDER,
}