import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Moment from 'moment';

import { Typography, Table, Select, Button, Tooltip, Space, notification, Popconfirm, Collapse, Col, Row, Avatar } from 'antd';
import { InfoCircleOutlined, PlusOutlined, CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
// import Highlighter from 'react-highlight-words';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

import languageManager from '../../../services/languageManager';
import { startLoading, endLoading } from '../../../shared/utility';

import * as storeActions from './store/actions/groups';

import Loader from '../../common/PageElements/Loader';
import DropdownMenu from '../../common/components/DropdownMenu'

import TableFilters from '../../common/components/TableFilters';

class GroupsList extends Component {
   state = {
      localPath: this.props.history.location.pathname,
      pageInit: true,
      pageLoader: true,
      tableLoader: false,
      multipleActionLoader: false,
      loadersGroups: [],
      parentId: null,
      itemList: [],
      fullListIds: [],
      componentLanguage: this.props.authState.defaultLanguage,
      current_page: this.props.location.state ? this.props.location.state.previousState.current_page : 1,
      page_size: this.props.location.state ? this.props.location.state.previousState.page_size : 50,
      total_result: 0,

      showTableFilters: false,
      filters: this.props.location.state ? this.props.location.state.previousState.filters : [],
      filtersValues: this.props.location.state ? this.props.location.state.previousState.filtersValues : {},
      appliedFilters: this.props.location.state ? this.props.location.state.previousState.appliedFilters : [],

      selectedRowKeys: [],
      selectedAll: false,
      multipleActionList: [
         {
            action: 'export_csv',
            name: 'Esporta selezionati in CSV'
         },
         {
            action: 'delete_groups',
            name: 'Cancella gruppi',
            needconfirmation: 'yes',
         },
      ],
      marketplaceDropdowns: [],
   };

   getGroupsList = (page, pageSize, filters) => {
      // asyncronous calls
      this.setState({ tableLoader: true })
      return storeActions.getListExtended(page, pageSize, filters)
         .then(response => {
            console.log(response);
            try {
               this.setState({
                  itemList: response.data.data,
                  fullListIds: response.full_list_ids,
                  current_page: page,
                  page_size: pageSize,
                  total_result: response.data.total,
                  filters: response.list_filters,
                  appliedFilters: response.applied_filters,
                  tableLoader: false
               });
            } catch (e) {
               this.setState({ tableLoader: false })
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                  duration: 0,
               })
            }

         }).catch((e) => {
            this.setState({ tableLoader: false });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   prepareMarketplaceDropdowns = () => {
      try {
         let newExport = {}
         let newDropdown = {}
         let newMarketplaceDropdowns = []
         _.forEach(this.props.authState.userData.activeMarketplaces, (marketplace) => {
            newDropdown = {}
            newDropdown.title = marketplace.name
            newDropdown.actionList = []
            if (marketplace.marketplace_base.alias === 'woocommerce') {
               newExport = {
                  action: 'sync_woocommerce',
                  name: 'Invia gruppi a ' + marketplace.name,
                  params: {
                     marketplace_id: marketplace.id
                  },
                  needconfirmation: 'yes',
               }
               newDropdown.actionList.splice(newDropdown.actionList.length - 1, 0, newExport)
            }
            newMarketplaceDropdowns.push(newDropdown)
         })
         this.setState({
            marketplaceDropdowns: newMarketplaceDropdowns,
         });

      } catch (e) {
         notification.open({
            message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
            description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
            icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
         })
         if (this.props.commonState.debug) console.log(e);
      }
   }

   componentDidMount() {
      Promise.all([
         this.getGroupsList(this.state.current_page, this.state.page_size, this.state.filtersValues),
         // this.getComplements()
      ]).then(() => {
         this.setState({
            pageLoader: false
         });
      });
      // if(!_.isNil(this.props.authState.userData.activeMarketplaces)) {
      //    this.prepareMarketplaceDropdowns()
      // }
   }
   // componentDidUpdate(prevProps) {
   //    if (this.props.authState.userData.activeMarketplaces !== prevProps.authState.userData.activeMarketplaces) {
   //       this.prepareMarketplaceDropdowns()
   //    }
   // }

   editItemHandler = (itemId) => {
      this.props.history.push({
         pathname: itemId ? '/customer_groups/edit/' + itemId : '/customer_groups/edit/',
         state: { previousState: this.state }
      });
   }

   deleteMultipleItemsHandler = (productIds) => {
      // this.setState({ loader: true });
      // storeActions.deleteMultipleItems(productIds, true)
      //    .then(response => {
      //       console.log(response);
      //       try {
      //          // let list = [...this.state.itemList];
      //          // list = list.filter(item => item.id !== itemId);

      //          this.getProductsList(this.state.current_page, this.state.page_size, this.state.filtersValues);
      //          notification.open({
      //             message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
      //             description: languageManager.getMessage(this.props.commonState, 'notification.confirm.deleteOk'),
      //             icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
      //          })
      //       } catch (e) {
      //          notification.open({
      //             message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
      //             description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
      //             icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
      //          })
      //       }

      //    }).catch((e) => {
      //       notification.open({
      //          message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
      //          description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
      //          icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
      //       })
      //       if (this.props.commonState.debug) console.log(e);
      //    });
   }

   deleteItemHandler = (itemId) => {
      this.setState({ loadersGroups: startLoading(this.state.loadersGroups, itemId) });
      storeActions.deleteItem(itemId, true)
         .then(response => {
            console.log(response);
            try {
               Promise.all([
                  this.getGroupsList(this.state.current_page, this.state.page_size, this.state.filtersValues)
               ]).then(() => {
                  this.setState({ loadersGroups: endLoading(this.state.loadersGroups, itemId) });
               });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.deleteOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               this.setState({ loadersGroups: endLoading(this.state.loadersGroups, itemId) });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            this.setState({ loadersGroups: endLoading(this.state.loadersGroups, itemId) });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   confirmHandler = (onConfirm, itemIds) => {
      switch (onConfirm) {
         case 'deleteItem':
            if (itemIds) {
               this.deleteItemHandler(itemIds);
            }
            break;
         case 'deleteItems':
            if (itemIds) {
               this.deleteMultipleItemsHandler(itemIds);
            }
            break;
         default:
            break;
      }
   }

   setDefaultLanguageHandler = (value) => {
      this.setState({ componentLanguage: value })
   }

   groupsExportHandler = (marketplace, itemIds, params) => {

   }

   groupsSyncHandler = (marketplace, itemIds, params) => {
      let filename
      switch (marketplace) {
         case 'woocommerce':
            this.setState({ multipleActionLoader: true });
            filename = Moment().format('YYYY.MM.DD-HH.mm.ss') + '_GROUPS_WOOCOMMERCE_SYNC.csv';
            storeActions.exportGroupsForWoocommerce(filename, itemIds, 'sync', params)
               .then(response => {
                  this.setState({ multipleActionLoader: false });
                  console.log(response);
                  try {
                     this.setState({ loader: false });
                  } catch (e) {
                     notification.open({
                        message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                        description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                        icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                     })
                  }

               }).catch((e) => {
                  this.setState({ multipleActionLoader: false });
                  notification.open({
                     message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                     description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                     icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                  })
                  if (this.props.commonState.debug) console.log(e);
               });
            break;
         default:
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.no_marketplace_selected'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            break;
      }
   }

   onSelectChange = (selectedRowKeys) => {
      this.setState({
         selectedRowKeys: selectedRowKeys
      });
      console.log(selectedRowKeys);
   };

   submitFilters = (values) => {
      this.setState({ filtersValues: values })
      this.getGroupsList(this.state.current_page, this.state.page_size, values)
   }

   selectAllHandler = () => {
      if (this.state.selectedAll) {
         this.setState({
            selectedAll: false,
            selectedRowKeys: [],
         });
      }
      else {
         this.setState({
            selectedAll: true,
            selectedRowKeys: this.state.fullListIds,
         });
      }
   }
   multipleActionHandler = (value) => {
      console.log(value)
      const props = value.item.props
      switch (props.action) {
         case 'export_csv':
            this.groupsExportHandler('nzt', this.state.selectedRowKeys);
            break;
         case 'delete_groups':
            this.confirmHandler('deleteItems', this.state.selectedRowKeys);
            break;
         case 'sync_woocommerce':
            this.groupsSyncHandler('woocommerce', this.state.selectedRowKeys, props.params);
            break;
         default:
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            break;

      }
   }

   handleTableChange = (pagination, filters, sorter, extra) => {
      console.log(pagination, filters, sorter, extra);
      this.getGroupsList(pagination.current, pagination.pageSize, this.state.filtersValues);
   };

   render() {
      if (!this.props.authState.userData.languages || this.state.pageLoader) return <Loader />;
      const { Title } = Typography
      const { Option } = Select;
      const { Panel } = Collapse;
      var table_columns = [
         {
            title: 'Gruppo',
            key: 'name',
            fixed: 'left',
            width: 250,
            render: (record) => (
               <a className="link-cell" onClick={() => this.editItemHandler(record.id)}>
                  {record.name}
               </a>
            ),
            sorter: (a, b) => a.name.localeCompare(b.name),
         },
         {
            title: 'Percentuale di sconto',
            key: 'discount_percentage',
            render: (record) => (
               record.discount_percentage ? record.discount_percentage + '%' : ''
            ),
            sorter: (a, b) => a.discount_percentage - b.discount_percentage,
         },
      ];

      _.forEach(this.props.authState.userData.activeMarketplaces, (el, i) => {
         table_columns.push(
            {
               title: (
                  <Tooltip title={el.name}>
                     <Avatar shape='square' size={20} src={el.icon} />
                  </Tooltip>
               ),
               key: el.id,
               className: 'marketplace_sync_column',
               render: (text, record) => (
                  record.marketplace_sync && record.marketplace_sync[el.id] && record.marketplace_sync[el.id].value ? (
                     <Tooltip title={(
                        <Space direction="vertical" size='small'>
                           {languageManager.getMessage(this.props.commonState, 'tooltip.value') + ': ' + record.marketplace_sync[el.id].value}
                           {languageManager.getMessage(this.props.commonState, 'tooltip.last_update') + ': ' + Moment(record.marketplace_sync[el.id].last_update).format('L LTS')}
                        </Space>
                     )}>
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                     </Tooltip>
                  ) : (
                     <Tooltip title={languageManager.getMessage(this.props.commonState, 'tooltip.missing_sync')}>
                        <CloseCircleTwoTone twoToneColor="red" />
                     </Tooltip>
                  )
               ),
            },
         )
      })

      table_columns.push(
         {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            align: 'right',
            width: 100,
            render: (text, record) => (
               <Space>
                  <Button type="primary" size='small'
                     disabled={this.state.loadersGroups[record.id]}
                     icon={<EditOutlined />}
                     onClick={() => this.editItemHandler(record.id)}
                  />
                  <Popconfirm
                     placement="topRight"
                     title={languageManager.getMessage(this.props.commonState, 'common.askDeleteConfirm')}
                     onConfirm={() => this.confirmHandler('deleteItem', record.id)}
                     okText={languageManager.getMessage(this.props.commonState, 'common.yes')}
                     cancelText={languageManager.getMessage(this.props.commonState, 'common.no')}
                  >
                     <Button type="danger" size='small'
                        loading={this.state.loadersGroups[record.id]}
                        icon={<DeleteOutlined />}
                     />
                  </Popconfirm>
               </Space>),
         }
      )

      const tableLayout = {
         bordered: true,
         size: "small",
         loading: this.state.tableLoader,
         pagination: {
            pageSize: this.state.page_size,
            total: this.state.total_result,
            showSizeChanger: true,
            defaultPageSize: 50,
            size: "small",
            showTotal: total => total + (total !== 1 ? ' gruppi' : ' gruppo')
         },
         onChange: this.handleTableChange
      }

      const { selectedRowKeys } = this.state;
      const rowSelection = {
         selectedRowKeys,
         onChange: this.onSelectChange,
         preserveSelectedRowKeys: true
      };

      const pageFilters = (
         <Collapse defaultActiveKey={['filters']}>
            <Panel header="Filtri" key="filters">
               <TableFilters submitFilters={this.submitFilters} resetFilters={this.resetFilters} filters={this.state.filters} selectValues={this.state.filtersValues} />
            </Panel>
         </Collapse>
      );

      const hasSelected = selectedRowKeys.length > 0;
      const singleSelected = selectedRowKeys.length > 1;
      const pageContent = (
         <div className="card">
            <div className="card-header">
               <div className="align-items-center row">
                  <div className="col-sm-6">
                     <Title level={5}>{languageManager.getMessage(this.props.commonState, 'component.groups.title')}</Title>
                  </div>
                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">
                        <Space>
                           <Button
                              type='primary'
                              icon={<PlusOutlined />}
                              onClick={() => this.editItemHandler()}
                           >
                              Crea nuovo gruppo
                           </Button>
                           {/* <Button
                              type='primary'
                              icon={<UploadOutlined />}
                              onClick={() => this.props.history.push('/products/import/')}
                           >
                              Importa anagrafiche prodotti
                           </Button> */}
                           <Select
                              showSearch
                              className="width-70px-I"
                              placeholder="Seleziona la lingua"
                              defaultValue={this.state.componentLanguage.toUpperCase()}
                              optionFilterProp="children"
                              onChange={this.setDefaultLanguageHandler}
                           >
                              {
                                 this.props.authState.userData.languages.map((lang) => {
                                    return <Option key={lang.value} value={lang.value}>{lang.label}</Option>
                                 })
                              }
                           </Select>
                        </Space>
                     </div>
                  </div>
               </div>
            </div>
            <div className="card-body">
               <Row>
                  <Col span={24}>
                     {pageFilters}
                  </Col>
               </Row>
               <Row>
                  <Col span={24}>
                     <div className="align-items-center row" style={{ marginTop: 15, marginBottom: 5 }}>
                        <div className="col-sm-12">
                           <div className="d-none d-md-block">
                              <Space>
                                 <Button
                                    onClick={() => this.selectAllHandler()}
                                    disabled={_.isEmpty(this.state.fullListIds)}
                                 >{this.state.selectedAll ? 'Deseleziona' : 'Seleziona tutto'}
                                 </Button>
                                 <span>
                                    {hasSelected ? singleSelected ? `${selectedRowKeys.length} elementi selezionati` : `${selectedRowKeys.length} elemento selezionato` : ''}
                                 </span>
                                 <DropdownMenu
                                    type='primary'
                                    title='Azioni multiple'
                                    action={(event) => this.multipleActionHandler(event)}
                                    values={this.state.multipleActionList}
                                    disabled={!hasSelected}
                                    loading={this.state.multipleActionLoader}
                                 />
                                 {
                                    this.state.marketplaceDropdowns.map((dropdown, i) => {
                                       return (
                                          <DropdownMenu
                                             key={i}
                                             type='primary'
                                             title={'Azioni ' + dropdown.title}
                                             action={this.multipleActionHandler}
                                             values={dropdown.actionList}
                                             disabled={!hasSelected}
                                             loading={this.state.multipleActionLoader}
                                          />
                                       )
                                    })
                                 }
                              </Space>
                           </div>
                        </div>
                     </div>
                     <Table
                        key='dataTable'
                        {...tableLayout}
                        rowSelection={rowSelection}
                        columns={table_columns}
                        dataSource={this.state.itemList}
                        scroll={{ x: 'max-content' }}
                        rowKey={record => (record.id)}
                     />
                  </Col>
               </Row>
            </div>
            <div className="card-footer">
               <Button
                  size='large'
                  onClick={() => { this.props.history.goBack() }}
               >
                  <i className="mdi mdi-arrow-left-drop-circle-outline mr-1"></i>
                  {languageManager.getMessage(this.props.commonState, 'common.back')}
               </Button>
            </div>
         </div>
      );

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>
      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState,
   };
};

export default connect(mapStateToProps)(GroupsList);