import Axios from 'axios';
import API_BASE from './apiBase';
import API_CONFIG from './apiConfig';

class BatchHistoryApi {

   static getList() {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.batchHistory.getList)
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static getListExtended(page, pageSize, filters) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.batchHistory.getListExtended, { page: page, page_size: pageSize, filters_values: JSON.stringify(filters) })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static getItem(itemId) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.batchHistory.getItem, {
            id: itemId
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static getComplements() {
      return new Promise((resolve, reject) => {
         Axios.all([
            Axios.post(API_BASE.url + API_CONFIG.appUrls.warehouses.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.customers.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.suppliers.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.products.getListCascader, { allow_select_parent: true }),
         ])
            .then(Axios.spread((warehouseList, customerList, supplierList, productCascaderList) => {
               const response = {
                  warehouseList: warehouseList,
                  customerList: customerList,
                  supplierList: supplierList,
                  productCascaderList: productCascaderList,
               }
               resolve(response);
            }))
            .catch(error => {
               reject(error);
            });
      });
   }
   static printList(filename, itemIds) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.batchHistory.printList, {filename: filename, item_ids: itemIds}, {responseType: 'blob'})
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

}

export default BatchHistoryApi;