import LogsApi from '../../../../../api/LogsApi';
import _ from 'lodash';

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function getListExtended(page, pageSize, filters) {
   return LogsApi.getListExtended(page, pageSize, filters)
      .then(response => {
         console.log(response);
         if (response.data.success === true) {
            response.data.data.data.forEach(el => {
               el.key = el.id;
            });
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}