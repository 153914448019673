import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import languageManager from '../../../../services/languageManager'

import { Form, Input, InputNumber, Select, Switch, Divider } from 'antd'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'

import SelectCategorySingle from '../../categories/widget/SelectCategorySingle'

const SaleRuleForm = (props) => {
    const [layout, setLayout] = useState(props.layout)
    const [marketplaceId, setMarketplaceId] = useState(props.marketplaceId)
    const [values, setValues] = useState(props.values)

    const [discountRulesList, setDiscountRulesList] = useState(props.discountRulesList)
    const [customerGroupsList, setCustomerGroupsList] = useState(props.customerGroupsList)
    const [categoriesTree, setCategoriesTree] = useState(props.categoriesTree)
    const [brandsList, setBrandsList] = useState(props.brandsList)
    const [tagsList, setTagsList] = useState(props.tagsList)

    const [showExpirationFields, setExpirationFields] = useState(false)
    const [showCustomerGroupSelect, setShowCustomerGroupSelect] = useState(false)
    const [showCategorySelect, setShowCategorySelect] = useState(false)
    const [showBrandSelect, setShowBrandSelect] = useState(false)
    const [showTagSelect, setShowTagSelect] = useState(false)

    const [form] = Form.useForm()
    const { Option } = Select

    useEffect(() => {
        setMarketplaceId(props.marketplaceId)
        setValues(props.values)
        if (_.isEmpty(props.values)) {
            clearSwitch()
            form.resetFields()
        } else {
            setSwitch(props.values)
            form.setFieldsValue(props.values)
        }
    }, [props.values, props.marketplaceId])

    const submitForm = (values) => {
        values.marketplace_id = marketplaceId
        props.submitForm(values)
    }

    const setSwitch = (values) => {
        setExpirationFields(values.on_expiration)
        setShowCustomerGroupSelect(values.on_group)
        setShowCategorySelect(values.on_category)
        setShowBrandSelect(values.on_brand)
        setShowTagSelect(values.on_tag)
    }
    const clearSwitch = () => {
        setExpirationFields(false)
        setShowCustomerGroupSelect(false)
        setShowCategorySelect(false)
        setShowBrandSelect(false)
        setShowTagSelect(false)
    }
    const toggleExpirationFields = () => {
        setExpirationFields(!showExpirationFields)
    }
    const toggleCustomerGroupSelect = () => {
        setShowCustomerGroupSelect(!showCustomerGroupSelect)
    }
    const toggleCategorySelect = () => {
        setShowCategorySelect(!showCategorySelect)
    }
    const toggleBrandSelect = () => {
        setShowBrandSelect(!showBrandSelect)
    }
    const toggleTagSelect = () => {
        setShowTagSelect(!showTagSelect)
    }

    return (
        <Form
            form={form}
            {...layout}
            id="saleRuleDrawer"
            name="basic"
            onFinish={submitForm}
        >
            <Form.Item
                label="Nome"
                name="name"
                rules={[{ required: true, message: 'Inserisci il nome della regola' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Regola"
                name="price_rule_id"
                rules={[{ required: true }]}
            >

                <Select
                    showSearch
                    placeholder="Seleziona la regola da applicare"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.props.searchvalue.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {
                        discountRulesList.map((rule) => {
                            return <Option key={rule.id} value={rule.id} searchvalue={rule.name}>{rule.name}</Option>
                        })
                    }
                </Select>

            </Form.Item>
            <Divider />
            <Form.Item
                label="Applica a prodotti in scadenza"
                name="on_expiration"
                valuePropName="checked"
            >
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    onChange={toggleExpirationFields}
                />
            </Form.Item>

            {showExpirationFields ? (
                <Form.Item
                    label="Giorni prima della scadenza"
                    name="days_before_expiration"
                    rules={[{ required: showExpirationFields }]}
                >
                    <InputNumber
                        precision={0}
                        step={1}
                    />
                </Form.Item>
            ) : ''}
            <Form.Item
                label="Applica a gruppo di clienti"
                name="on_group"
                valuePropName="checked"
            >
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    onChange={toggleCustomerGroupSelect}
                />
            </Form.Item>

            {showCustomerGroupSelect ? (
                <Form.Item
                    label="Seleziona il gruppo di clienti"
                    name="group_id"
                    rules={[{ required: showCustomerGroupSelect }]}
                >
                    <Select
                        showSearch
                        placeholder="Seleziona il gruppo di clienti"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.props.searchvalue.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {
                            customerGroupsList.map((group) => {
                                return <Option key={group.id} value={group.id} searchvalue={group.name}>{group.name}</Option>
                            })
                        }
                    </Select>
                </Form.Item>
            ) : ''}
            <Form.Item
                label="Applica a categoria"
                name="on_category"
                valuePropName="checked"
            >
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    onChange={toggleCategorySelect}
                />
            </Form.Item>

            {showCategorySelect ? (
                <Form.Item
                    label="Seleziona la categoria"
                    name="category_id"
                    rules={[{ required: showCategorySelect }]}
                >
                    <SelectCategorySingle
                        categories_tree={categoriesTree}
                        placeholder="Seleziona la categoria"
                    />
                </Form.Item>
            ) : ''}

            <Form.Item
                label="Applica a brand"
                name="on_brand"
                valuePropName="checked"
            >
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    onChange={toggleBrandSelect}
                />
            </Form.Item>

            {showBrandSelect ? (
                <Form.Item
                    label="Seleziona il brand"
                    name="brand_id"
                    rules={[{ required: showBrandSelect }]}
                >
                    <Select
                        showSearch
                        placeholder="Seleziona il brand"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.props.searchvalue.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {
                            brandsList.map((brand) => {
                                return <Option key={brand.id} value={brand.id} searchvalue={brand.name}>{brand.name}</Option>
                            })
                        }
                    </Select>
                </Form.Item>
            ) : ''}

            <Form.Item
                label="Applica a tag"
                name="on_tag"
                valuePropName="checked"
            >
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    onChange={toggleTagSelect}
                />
            </Form.Item>

            {showTagSelect ? (
                <Form.Item
                    label="Seleziona il tag"
                    name="tag_id"
                    rules={[{ required: showTagSelect }]}
                >
                    <Select
                        showSearch
                        placeholder="Seleziona il tag"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.props.searchvalue.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {
                            tagsList.map((tag) => {
                                return <Option key={tag.id} value={tag.id} searchvalue={tag.t_name}>{tag.t_name}</Option>
                            })
                        }
                    </Select>
                </Form.Item>
            ) : ''}
            <Divider />
            <Form.Item
                label="Attiva regola"
                name="active"
                valuePropName="checked"
            >
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                />
            </Form.Item>
            <Form.Item name="id" initialValue={values.itemId} noStyle><Input type="hidden" /></Form.Item>
            {/* <Form.Item name="marketplace_id" initialValue={marketplaceId} noStyle><Input type="hidden" /></Form.Item> */}
        </Form>
    )
}

const mapStateToProps = state => {
    return {
        commonState: state.commonState,
    }
}

export default connect(mapStateToProps)(SaleRuleForm)