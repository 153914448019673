import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import languageManager from '../../../../services/languageManager';
import { PriceRuleTypeEnum } from '../../../../shared/enums.ts'

import { Select, Radio, Form, Input } from 'antd';

const PriceRulesForm = (props) => {
   console.log(props)
   const [layout, setLayout] = useState(props.layout)
   const [values, setValues] = useState(props.values)

   const [form] = Form.useForm()
   const { Option } = Select;
   const { TextArea } = Input;

   useEffect(() => {
      setValues(props.values)
      form.setFieldsValue(props.values)
   }, [props.values]);

   const submitForm = (values) => {
      props.submitForm(values)
   }

   const handlePriceRuleTypeChange = (e) => {
      var newFormValues = { ...values }
      newFormValues.variation_type = e.target.value
      setValues(newFormValues)
   }

   return (

      <Form
         form={form}
         {...layout}
         id="formPriceRules"
         name="basic"
         onFinish={submitForm}
      >
         <Form.Item
            label="Nome variazione"
            name="name"
            placeholder="Inserisci il nome"
            rules={[{ required: true, message: 'Inserisci il nome' }]}
         >
            <Input />
         </Form.Item>
         <Form.Item
            label="Tipo di variazione"
            name="variation_type"
            rules={[{ required: true, message: 'Seleziona un tipo di variazione' }]}
         >
            <Radio.Group
               onChange={handlePriceRuleTypeChange}>
               <Radio.Button value={PriceRuleTypeEnum.MARGINE}>Margine netto</Radio.Button>
               <Radio.Button value={PriceRuleTypeEnum.AUMENTO}>Aumento</Radio.Button>
               <Radio.Button value={PriceRuleTypeEnum.SCONTO}>Sconto</Radio.Button>
            </Radio.Group>
         </Form.Item>
         {
            values.variation_type === PriceRuleTypeEnum.MARGINE ? (
               <Form.Item
                  label="Margine percentuale"
                  name="margin_percentage"
               >
                  <Input
                     type="number"
                     addonBefore="+"
                     addonAfter="%"
                     style={{ width: 150 }}
                     min={0}
                     step={0.1}
                     precision={2}
                  />
               </Form.Item>
            ) : ''
         }
         {
            values.variation_type === PriceRuleTypeEnum.AUMENTO ? ([
               <Form.Item
                  label="Aumento percentuale"
                  name="variation_percentage"
                  key="variation_percentage"
               >
                  <Input
                     type="number"
                     addonBefore="+"
                     addonAfter="%"
                     style={{ width: 150 }}
                     min={0}
                     step={0.1}
                     precision={2}
                  />
               </Form.Item>,
               <Form.Item
                  label="Aumento fisso (IVA compresa)"
                  name="variation_amount"
                  key="variation_amount"
               >
                  <Input
                     type="number"
                     addonBefore="+"
                     addonAfter="€"
                     style={{ width: 150 }}
                     min={0}
                     step={0.1}
                     precision={2}
                  />
               </Form.Item>
            ]) : ''
         }
         {
            values.variation_type === PriceRuleTypeEnum.SCONTO ? ([
               <Form.Item
                  label="Sconto percentuale"
                  name="discount_percentage"
                  key="discount_percentage"
               >
                  <Input
                     type="number"
                     addonBefore="-"
                     addonAfter="%"
                     style={{ width: 150 }}
                     min={0}
                     step={0.1}
                     precision={2}
                  />
               </Form.Item>,
               <Form.Item
                  label="Sconto fisso (IVA compresa)"
                  name="discount_amount"
                  key="discount_amount"
               >
                  <Input
                     type="number"
                     addonBefore="-"
                     addonAfter="€"
                     style={{ width: 150 }}
                     min={0}
                     step={0.1}
                     precision={2}
                  />
               </Form.Item>
            ]) : ''
         }
         <Form.Item
            label="Descrizione"
            name="description"
         >
            <TextArea
               rows={4}
            />
         </Form.Item>

         <Form.Item label="id" name="id" noStyle><Input type="hidden" /></Form.Item>
         <Form.Item label="language" name="language" noStyle><Input type="text" type="hidden" /></Form.Item>

      </Form>
   )
}


const mapStateToProps = state => {
   return {
      commonState: state.commonState,
   };
};

export default connect(mapStateToProps)(PriceRulesForm);