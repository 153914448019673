import CustomerGroupsApi from '../../../../../api/CustomerGroupsApi';
import _ from 'lodash';

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function getList(parentId = null) {
   return CustomerGroupsApi.getList(parentId)
      .then(response => {
         if (response.data.success === true) {
            response.data.data.forEach(el => {
               el.key = el.id;
            });
            console.log(response);
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function getListExtended(page, pageSize, filters) {
   return CustomerGroupsApi.getListExtended(page, pageSize, filters)
      .then(response => {
         if (response.data.success === true) {
            response.data.data.data.forEach(el => {
               el.key = el.id;
            });
            console.log(response);
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function getItem(itemId) {
   return CustomerGroupsApi.getItem(itemId)
      .then(response => {
         if (response.data.success === true) {
            console.log(response);
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function createOrUpdate(itemData) {
   console.log(itemData);
   return CustomerGroupsApi.createOrUpdate(itemData)
      .then(response => {
         if (response.data.success === true) {
            console.log(response);
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function deleteItem(itemId, return_list = false) {
   return CustomerGroupsApi.deleteItem(itemId, return_list)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function getComplements() {
   return CustomerGroupsApi.getComplements()
      .then(response => {
         console.log(response);
         const responseData = {}
         _.forEach(response, (el, i) => {
            if (el.data.success !== true) {
               const error = el.data.error ? el.data.error : 'Server error'
               throw new Error(error)
            }
            responseData[i] = el.data.data;
         });
         return responseData;
      })
      .catch(error => {
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function exportGroupsForWoocommerce(filename, itemIds, action, params) {
   return CustomerGroupsApi.exportGroupsForWoocommerce(filename, itemIds, action, params)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}