import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import Moment from 'moment'
import ModalLoader from '../../../common/PageElements/Loader'

import { Modal, Form, Input, List, Button, Space, Divider, Cascader, Select, notification } from 'antd'

import languageManager from '../../../../services/languageManager'
import * as storeActions from '../store/actions/warehouses'

class ModalEditWarehouse extends Component {
    state = {
        ...this.props,
        componentLoader: true,
        contentLoader: true,
        saveLoader: false,
        componentLanguage: this.props.authState.defaultLanguage
    }
    formRef = React.createRef();

    componentDidMount() {
        // console.log('Component did mount')
        Promise.all([
            //  this.getSuppliersList()
        ]).then(() => {
            this.setState({
                componentLoader: false
            });
        });
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        // console.log('Component did update', nextProps, this.state)
        if (_.isEqual(nextProps.visible, this.props.visible) && _.isEqual(nextState, this.state)) {
            return false
        }
        return true
    }

    componentDidUpdate = (prevProps, prevState) => {
        console.log('Component did update')
        if (!_.isEqual(prevProps.visible, this.props.visible)) {
            if (!_.isNil(this.props.itemId)) {
                this.getItem(this.props.itemId)
                // const newFormValues = { ...this.props.item }
                // this.setFormValues(newFormValues)
                // this.setState({ lineId: this.props.item.id, actualProduct: this.props.item.product_id })
            } else {
                if (this.formRef.current) {
                    this.formRef.current.resetFields()
                }
            }
            // this.setState({ ...this.props })
            // console.log('Component did update - update state', prevState, this.props)
        }
    }

    getItem = (itemId) => {
        // asyncronous calls
        this.setState({ componentLoader: true })
        return storeActions.getItem(itemId)
            .then(response => {
                if (this.props.commonState.debug) console.log(response)
                this.setState({ componentLoader: false })
                try {
                    this.setFormValues(response.data)
                } catch (e) {
                    notification.error({
                        message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                        description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                    })
                    if (this.props.commonState.debug) console.log(e)
                }
            }).catch((e) => {
                this.setState({ componentLoader: false })
                notification.error({
                    message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                    description: e.human ? (
                        <List
                            size="small"
                            split={false}
                            dataSource={e.human}
                            renderItem={item => <List.Item>{item}</List.Item>}
                        />
                    ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                    duration: 0
                })
                if (this.props.commonState.debug) console.log(e)
            });
    }

    setFormValues = (item) => {
        if (this.formRef.current) {
            console.log('Set form values', item)
            this.formRef.current.setFieldsValue({ ...item })
            //     id: item.id,
            //     internal_code: item.internal_code,
            //     name: item.name,
            //     address: item.address,
            //     phone: item.phone,
            //     productName: item.product_name,
            //     supplierCode: item.supplier_id,
            //     batch: item.batch,
            //     expirationDate: item.expiration_date ? Moment(item.expiration_date) : null,
            //     quantity: item.quantity,
            //     buyPrice: item.unit_price,
            //     taxRatePercentage: item.tax_rate,
            //     totalPrice: item.total_price,
            // })
        }
    }

    onFinish = values => {
        // if (this.props.commonState.debug) console.log('on finish', values)
        this.setState({ saveLoader: true });
        storeActions.createOrUpdate(values, true)
            .then(response => {
                this.setState({ saveLoader: false })
                try {
                    if (this.props.commonState.debug) console.log('Response', response.data)
                    this.formRef.current.resetFields()
                    this.props.onSubmit(response.data)
                    this.props.onClose()
                    notification.success({
                        message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
                        description: languageManager.getMessage(this.props.commonState, !_.isNil(this.props.itemId) ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
                    })
                } catch (e) {
                    notification.error({
                        message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                        description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                        duration: 0
                    })
                    if (this.props.commonState.debug) console.log(e)
                }
            }).catch((e) => {
                this.setState({ saveLoader: false })
                notification.error({
                    message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                    description: e.human ? (
                        <List
                            size="small"
                            split={false}
                            dataSource={e.human}
                            renderItem={item => <List.Item>{item}</List.Item>}
                        />
                    ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                    duration: 0
                })
                if (this.props.commonState.debug) console.log(e)
            })
    };

    onClose = () => {
        this.formRef.current.resetFields()
        this.state.onClose()
    }

    render() {
        console.log('Render modal add warehouse', this.props)
        const formId = 'formEditWarehouse'
        const { Option } = Select
        const { TextArea } = Input

        const mainLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 18 },
            layout: "horizontal",
        };

        return (
            <Modal
                visible={this.props.visible}
                title={(!_.isNil(this.props.itemId) ? 'Modifica ' : 'Crea ') + 'magazzino'}
                width={800}
                onClose={this.onClose}
                onCancel={this.onClose}
                footer={[
                    <Button key="back" onClick={this.onClose} disabled={this.state.saveLoader}>Annulla</Button>,
                    <Button key='submitForm' form={formId} type="primary" htmlType="submit" loading={this.state.saveLoader}>Salva</Button>
                ]}
            >
                {
                    this.state.componentLoader ? <ModalLoader /> : (
                        <Form
                            id={formId}
                            ref={this.formRef}
                            {...mainLayout}
                            onFinish={this.onFinish}
                            onFinishFailed={this.onFinishFailed}
                        >
                            <Form.Item
                                label="Codice interno"
                                name="internal_code"
                            >
                                <Input />
                            </Form.Item>
                            <Divider />
                            <Form.Item
                                label="Nome magazzino"
                                name="name"
                                rules={[{ required: true, message: 'Inserisci il nome identificativo del magazzino' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Indirizzo"
                                name="address"
                            >
                                <TextArea />
                            </Form.Item>
                            <Form.Item
                                label="Telefono"
                                name="phone"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item name="id" noStyle><Input type="hidden" /></Form.Item>
                            <Form.Item label="language" name="language" initialValue={this.state.componentLanguage} noStyle><Input type="hidden" /></Form.Item>
                        </Form>
                    )
                }
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    return {
        authState: state.authenticationState,
        commonState: state.commonState
    };
}

export default connect(mapStateToProps)(ModalEditWarehouse)