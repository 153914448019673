import Axios from 'axios';
import API_BASE from './apiBase';
import API_CONFIG from './apiConfig';

class OrdersApi {

   static createOrUpdate(itemData) {
      console.log(itemData)
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.orders.createOrUpdate, {
            ...itemData
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               console.log(error);
               reject(error);
            });

      });
   }

   static getList() {

      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.orders.getList)
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getListExtended(page, pageSize, filters) {

      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.orders.getListExtended, { page: page, page_size: pageSize, filters_values: JSON.stringify(filters) })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getListFilters() {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.orders.getListFilters, {})
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getOrdersFilterComplements() {
      return new Promise((resolve, reject) => {
         Axios.all([
            Axios.post(API_BASE.url + API_CONFIG.appUrls.orderStatuses.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.marketplaces.getList),
         ])
            .then(Axios.spread((orderStatusesList, marketplacesList) => {
               const response = {
                  orderStatusesList: orderStatusesList,
                  marketplacesList: marketplacesList
               }
               resolve(response);
            }))
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getItem(itemId) {

      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.orders.getItem, {
            id: itemId
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   static getCreateOrderComplements() {
      return new Promise((resolve, reject) => {
         Axios.all([
            Axios.post(API_BASE.url + API_CONFIG.appUrls.customers.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.shippingMethods.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.paymentMethods.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.banks.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.taxRates.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.products.getListCascader),
         ])
            .then(Axios.spread((customerList, shippingMethodList, paymentMethodList, bankList, taxRateList, productCascaderList) => {
               const response = {
                  customerList: customerList,
                  shippingMethodList: shippingMethodList,
                  paymentMethodList: paymentMethodList,
                  bankList: bankList,
                  taxRateList: taxRateList,
                  productCascaderList: productCascaderList,
               }
               resolve(response);
            }))
            .catch(error => {
               reject(error);
            });
      });
   }
   static getOrderComplements() {
      return new Promise((resolve, reject) => {
         Axios.all([
            Axios.post(API_BASE.url + API_CONFIG.appUrls.carriers.getList),
         ])
            .then(Axios.spread((carrierList) => {
               const response = {
                  carrierList: carrierList,
               }
               resolve(response);
            }))
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static updateOrderStatus(orderId, newStatus) {

      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.orders.updateStatus, {
            order_id: orderId,
            new_status: newStatus,
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static acceptOrder(orderId) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.orders.acceptOrder, { order_id: orderId })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static acceptMultipleOrders(orderIds) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.orders.acceptMultipleOrders, { order_ids: orderIds })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static acceptPartialOrder(orderId, refusedLines) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.orders.acceptPartialOrder, { order_id: orderId, refused_lines: refusedLines })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static processOrder(orderId) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.orders.processOrder, { order_id: orderId })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static processMultipleOrders(orderIds) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.orders.processMultipleOrders, { order_ids: orderIds })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static refuseOrder(orderId) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.orders.refuseOrder, { order_id: orderId })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static refuseMultipleOrders(orderIds) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.orders.refuseMultipleOrders, { order_ids: orderIds })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static cancelOrder(orderId) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.orders.cancelOrder, { order_id: orderId })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static refuseOrderLine(lineId) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.orders.refuseOrderLine, { line_id: lineId })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static cancelOrderLine(orderId, lineId, params) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.orders.cancelOrderLine, {
            order_id: orderId,
            line_id: lineId,
            ...params
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static refundOrderLine(orderId, lineId, params) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.orders.refundOrderLine, {
            order_id: orderId,
            line_id: lineId,
            ...params
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static returnOrderLine(orderId, lineId, params) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.orders.returnOrderLine, {
            order_id: orderId,
            line_id: lineId,
            ...params
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static shipOrder(orderId, params) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.orders.shipOrder, {
            order_id: orderId,
            ...params
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getPrivaliaOrders(return_list) {

      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.orders.getPrivaliaOrders, { return_list: return_list })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static downloadOrders(marketplace_id, return_list) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.orders.downloadOrders, { marketplace_id: marketplace_id, return_list: return_list })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static sendToFattura24(orderId) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.orders.sendToFattura24, {
            order_id: orderId,
            marketplace_id: 4,
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   static sendToFattureincloud(orderId, documentType) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.orders.sendToFattureincloud, {
            order_id: orderId,
            marketplace_id: 4,
            document_type: documentType,
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }


   static exportOrders(filename, orderIds) {

      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.orders.exportOrders, { filename: filename, order_ids: orderIds })
            .then(response => {
               // window.open(API_BASE.url + API_CONFIG.appUrls.orders.exportOrders);
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }
   static exportOrdersSummary(filename, orderIds) {

      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.orders.exportOrdersSummary, { filename: filename, order_ids: orderIds })
            .then(response => {
               // window.open(API_BASE.url + API_CONFIG.appUrls.orders.exportOrders);
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }
   static printDetails(filename, itemId) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.orders.printDetails, { filename: filename, item_id: itemId }, { responseType: 'blob' })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   static printMultipleDetails(filename, itemIds) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.orders.printMultipleDetails, { filename: filename, item_ids: itemIds },
            {
               responseType: 'arraybuffer'
            })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               const decodedString = String.fromCharCode.apply(null, new Uint8Array(error));
               const obj = JSON.parse(decodedString);
               // console.log(obj)
               reject(obj)
            });
      });
   }

   static sendMail(filename, itemId) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.orders.sendMail, { filename: filename, item_id: itemId })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
   *
   * @param
   * @returns {Promise<unknown>}
   */
   static importFileCSV(formData) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.orders.importFileCSV, { ...formData })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

}

export default OrdersApi;