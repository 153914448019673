import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import Moment from 'moment'

import { Drawer, Button, Space, notification } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons';

import languageManager from '../../../../services/languageManager';
import * as storeActions from '../store/actions/paymentMethod';

import PaymentMethodForm from '../components/PaymentMethodForm'
import Loader from '../../../common/PageElements/Loader'

class CreatePaymentMethodDrawer extends Component {
   state = {
      ...this.props,
      componentLoader: true,
      formLoader: false,
      formValues: {},
      componentLanguage: this.props.authState.defaultLanguage
   }
   formRef = React.createRef();

   componentDidMount() {
      // console.log('Component did mount')
      Promise.all([
         // this.getSuppliersList()
      ]).then(() => {
         this.setState({
            componentLoader: false
         });
      });
   }

   shouldComponentUpdate = (nextProps, nextState) => {
      // console.log('Component did update', nextProps, this.state)
      if (_.isEqual(nextProps.visible, this.state.visible)) {
         return false
      }
      return true
   }

   componentDidUpdate = (prevProps, prevState) => {
      if (!_.isEqual(prevState.visible, this.props.visible)) {
         console.log(this.props.item)
         if (!_.isNil(this.props.item)) {
            var newFormValues = { ...this.props.item }
            this.setFormValues(newFormValues)
         }
      }
   }
   setFormValues = (item) => {
      if (this.formRef.current) {
         this.formRef.current.setFieldsValue(item)
      }
   }

   onFinish = values => {
      this.setState({ formLoader: true });
      values.return_list = true;
      storeActions.createOrUpdate(values)
         .then(response => {
            try {
               this.formRef.current.resetFields()
               this.state.onSubmit(response.data)
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
                  description: languageManager.getMessage(this.props.commonState, this.state.action === 'edit' ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               this.setState({ formLoader: false });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            this.setState({ formLoader: false });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
         });
   };

   onClose = () => {
      this.formRef.current.resetFields()
      this.state.onClose()
   }

   render() {
      console.log('Render customer drawer', this.state)

      const formLayout = {
         layout: 'horizontal'
      };

      return (
         <Drawer
            title={(!_.isNil(this.props.item) && !_.isNil(this.props.item.id) ? 'Modifica' : 'Aggiungi') + ' metodo di pagamento'}
            width={500}
            onClose={this.props.onClose}
            visible={this.props.visible}
            drawerStyle={{ paddingTop: 70 }}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
               <div className='float-right'>
                  <Space>
                     <Button onClick={this.props.onClose} disabled={this.state.saveLoader}>Chiudi</Button>
                     <Button form="PaymentMethodForm" type="primary" htmlType="submit" loading={this.state.saveLoader}>Salva</Button>
                  </Space>
               </div>
            }
         >
            {
               this.state.componentLoader ? <Loader /> : (
                  <PaymentMethodForm
                     layout={formLayout}
                     submitForm={this.onFinish}
                     values={this.props.item}
                  />
               )
            }
         </Drawer>
      )
   }
}

function mapStateToProps(state) {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
}

export default connect(mapStateToProps)(CreatePaymentMethodDrawer)