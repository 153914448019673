import SpecificsApi from '../../../../../api/SpecificsApi';
import _ from 'lodash';

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function getList() {
   return SpecificsApi.getList()
      .then(response => {
         // if (response.success === true) {
         if (response.data.success === true) {
            response.data.data.forEach(el => {
               el.key = el.id;
            });
            console.log(response);
            // return response;
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function getItem(itemId) {
   return SpecificsApi.getItem(itemId)
      .then(response => {
         // if (response.success === true) {
         if (response.data.success === true) {
            console.log(response);
            // return response;
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function getSpecificComplements() {
   return SpecificsApi.getSpecificComplements()
      .then(response => {
         const responseData = {}
         _.forEach(response, (el, i) => {
            if (el.data.success !== true) {
               const error = el.data.data.error ? el.data.data.error : 'Server error'
               throw new Error(error)
            }
            responseData[i] = el.data.data;
         });
         return responseData;
      })
      .catch(error => {
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function createOrUpdate(itemData) {
   console.log(itemData);
   return SpecificsApi.createOrUpdate(itemData)
      .then(response => {
         // if (response.success === true) {
         if (response.data.success === true) {
            console.log(response);
            // return response;
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function toggleValue(itemId, value) {
   return SpecificsApi.toggleValue({id: itemId, value: value})
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function deleteItem(itemId, return_list = false) {
   return SpecificsApi.deleteItem(itemId, return_list)
      .then(response => {
         // if (response.success === true) {
         if (response.data.success === true) {
            // return response;
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}



// export const addIngredient = ( name ) => {
//     return {
//         type: actionTypes.ADD_INGREDIENT,
//         ingredientName: name
//     };
// };

// export const removeIngredient = ( name ) => {
//     return {
//         type: actionTypes.REMOVE_INGREDIENT,
//         ingredientName: name
//     };
// };

// export const getDataFailed = () => {
//     return {
//         type: actionTypes.FETCH_DATA_FAILED
//     };
// };

// export const getList = () => {
//     return dispatch => {
//         axios.get( 'https://react-my-burger.firebaseio.com/ingredients.json' )
//             .then( response => {
//                dispatch(setIngredients(response.data));
//             } )
//             .catch( error => {
//                 dispatch(fetchIngredientsFailed());
//             } );
//     };
// };