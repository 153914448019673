import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { Table, Form, Input, Button, Space, notification, Popconfirm, Tooltip, Collapse } from 'antd';
import { InfoCircleOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { SearchOutlined, DeleteOutlined, EditOutlined, MenuOutlined } from '@ant-design/icons';

import languageManager from '../../../services/languageManager';

import * as storeActions from './store/actions/priceRules';

import Loader from '../../common/PageElements/Loader';
import PageHeader from '../../common/PageElements/PageHeader'

class PriceRulesList extends Component {
   state = {
      loader: true,
      setsLoader: true,
      itemList: [],
      searchText: '',
      searchedColumn: '',
      componentLanguage: this.props.authState.defaultLanguage,
      priceRulesList: [],
      marketplacesList: [],
   };

   componentDidMount() {
      console.log(this.state);

      // asyncronous calls
      storeActions.getList()
         .then(response => {
            console.log(response);
            try {
               // _.forEach(response.marketplacesList, (el, i) => {
               //    if (_.isNil(response.priceRulesList[el.id])) {
               //       response.priceRulesList[el.id] = [];
               //    }
               // });
               this.setState({
                  response: response,
                  priceRulesList: response.data,
                  loader: false
               });
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   deleteItemHandler = (itemId) => {
      this.setState({ loader: true });
      storeActions.deleteItem(itemId, true, 'list_by_marketplace')
         .then(response => {
            console.log(response);
            try {
               // let list = [...this.state.itemList];
               // list = list.filter(item => item.id !== itemId);

               this.setState({ priceRulesList: response.data, loader: false });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.deleteOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   confirmHandler = (onConfirm, itemId) => {
      switch (onConfirm) {
         case 'deleteItem':
            if (itemId) {
               this.deleteItemHandler(itemId);
            }
            break;
         default:
            return;
      }
   }

   // createItemHandler = () => {
   //    this.props.history.push('/taxes/taxrates/edit/');
   // }

   toggleActiveHandler = (itemId) => {
      this.setState({ loader: true });
      storeActions.toggleActive(itemId)
         .then(response => {
            this.setState({ loader: false });
            console.log(response);
            try {
               // this.setState({ itemData: response.data });
               console.log(this.state);
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
         });
   }

   render() {

      const table_columns = [
         { title: 'Regola', width: 100, dataIndex: 'name', key: 'name', fixed: 'left' },
         {
            title: 'Variazioni', width: 100, dataIndex: 'variations', key: 'variations', fixed: 'left',
            render: (text, record) => (
               <div>
                  {record.variation_percentage ? <span>{record.variation_percentage}%</span> : ''}
                  {record.variation_percentage && record.variation_amount ? <br /> : ''}
                  {record.variation_amount ? <span>{record.variation_amount}€</span> : ''}
               </div>
            )
         },
         // {
         //    title: 'Categoria', width: 100, key: 'category', fixed: 'left',
         //    render: (text, record) => (
         //       !_.isNil(record.category) ? record.category.name[this.state.componentLanguage] : ''
         //    )
         // },
         // {
         //    title: 'Brand', width: 100, key: 'brand', fixed: 'left',
         //    render: (text, record) => (
         //       !_.isNil(record.brand) ? record.brand.name : ''
         //    )
         // },
         // {
         //    title: 'Attivo', width: 100, dataIndex: 'active', key: 'percentage', fixed: 'center',
         //    render: (text, record) => (
         //       <span>
         //          <a onClick={() => this.toggleActiveHandler(record.id)}>{record.active ? <CheckOutlined twoToneColor="green" /> : <CloseOutlined twoToneColor="#red" />}</a>
         //       </span>),
         // },
         {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            align: 'right',
            width: 100,
            render: (text, record) => (
               <Space>
                  <Tooltip title={languageManager.getMessage(this.props.commonState, 'common.edit')}>
                     <Button type="primary"
                        size='small'
                        icon={<EditOutlined />}
                        onClick={() => this.props.history.push('/price_rules/edit/' + (record.id))}
                     />
                  </Tooltip>
                  <Popconfirm
                     placement="topRight"
                     title={languageManager.getMessage(this.props.commonState, 'common.askDeleteConfirm')}
                     onConfirm={() => this.confirmHandler('deleteItem', record.id)}
                     okText={languageManager.getMessage(this.props.commonState, 'common.yes')}
                     cancelText={languageManager.getMessage(this.props.commonState, 'common.no')}
                  >
                     <Tooltip title={languageManager.getMessage(this.props.commonState, 'common.delete')}>
                        <Button type="danger"
                        size='small'
                           icon={<DeleteOutlined />}
                        />
                     </Tooltip>
                  </Popconfirm>
               </Space>
            ),
         },
      ];

      const tableLayout = {
         bordered: true,
         size: 'small'
      }

      const RulesTable = () => {
         console.log(this.state);
         return (
            <Table
               {...tableLayout}
               columns={table_columns}
               dataSource={this.state.priceRulesList}
            />
         );
      }

      const boxRules = (
         <div className="card">
            <div className="card-header">
               <div className="align-items-center row">
                  <div className="col-sm-6">
                     {languageManager.getMessage(this.props.commonState, 'component.price_rules.title')}
                  </div>
                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">
                        <Space>
                           <Button
                              type='primary'
                              icon={<PlusSquareOutlined />}
                              onClick={() => this.props.history.push('/price_rules/edit/')}
                           >
                              {languageManager.getMessage(this.props.commonState, 'component.price_rules.add')}
                           </Button>
                        </Space>
                     </div>
                  </div>
               </div></div>
            <div className="card-body">
               {this.state.loader ? <Loader /> : <RulesTable />}
            </div>
         </div>
      );

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {boxRules}
               </div>
            </div>
         </div>
      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState,
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(PriceRulesList);