import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import languageManager from '../../../../services/languageManager'
import * as storeActions from '../store/actions/customers'

import { Descriptions, Space, notification, List, Row, Col, Divider, Typography, Card, Table } from 'antd';
import { Form, Button, Input, Radio } from 'antd';
import { InfoCircleOutlined, CloseCircleTwoTone, WarningTwoTone, ContactsOutlined } from '@ant-design/icons';

class CustomerMarketplaceCard extends Component {
   state = {
      ...this.props,
      //category_name
      //marketplace
      //sync_data
      loaderSave: false,
      loaderSync: false,
      componentLoader: true,
      showSyncData: false,
      ebaySuggestCategoriesParams: {},
   }
   formRef = React.createRef();

   componentDidMount() {
      Promise.all([
      ]).then(() => {
         console.log('MOUNT CustomerMarketplaceCard', this.state)
         this.setState({
            showSyncData: !_.isUndefined(this.state.sync_data),
            componentLoader: false,
         });
      });
   }

   onFinish = values => {
      // console.log(values)
      this.setState({ loaderSave: true })
      const itemIds = [this.state.product_id]
      const params = {
         marketplace_id: values.marketplace_id,
         marketplace_product_id: values.marketplace_product_id,
         marketplace_product_name: values.marketplace_product_name,
      }
      if (values.active) {
         storeActions.activateForMarketplace(itemIds, params)
            .then(response => {
               console.log(response);
               this.setState({ loaderSave: false, showSyncData: true })
               notification.success({
                  message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
                  description: response.human ? (
                     <List
                        size="small"
                        split={false}
                        dataSource={response.human}
                        renderItem={item => <List.Item>{item}</List.Item>}
                     />
                  ) : languageManager.getMessage(this.props.commonState, 'notification.confirm.updateOk'),
                  duration: 0
               })
            }).catch((e) => {
               this.setState({ loaderSave: false })
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: e.human ? (
                     <List
                        size="small"
                        split={false}
                        dataSource={e.human}
                        renderItem={item => <List.Item>{item}</List.Item>}
                     />
                  ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                  duration: 0
               })
            });
      } else {
         storeActions.deactivateForMarketplace(itemIds, params)
            .then(response => {
               console.log(response);
               this.setState({ loaderSave: false, showSyncData: false })
               notification.success({
                  message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
                  description: response.human ? (
                     <List
                        size="small"
                        split={false}
                        dataSource={response.human}
                        renderItem={item => <List.Item>{item}</List.Item>}
                     />
                  ) : languageManager.getMessage(this.props.commonState, 'notification.confirm.updateOk'),
                  duration: 0
               })
            }).catch((e) => {
               this.setState({ loaderSave: false })
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: e.human ? (
                     <List
                        size="small"
                        split={false}
                        dataSource={e.human}
                        renderItem={item => <List.Item>{item}</List.Item>}
                     />
                  ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                  duration: 0
               })
            });
      }
   };

   onFinishFailed = errorInfo => {
      notification.open({
         message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
         description: languageManager.getMessage(this.props.commonState, 'notification.error.formInvalid'),
         icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
      })
   };

   sendToMarketplace = () => {
      // console.log(values)
      this.setState({ loaderSync: true })
      const itemIds = [this.state.product_id]
      const params = {
         marketplace_id: this.state.marketplace.id,
      }
      storeActions.sendToMarketplace(itemIds, params)
         .then(response => {
            console.log(response);
            this.setState({ loaderSync: false })
            notification.success({
               message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
               description: response.human ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={response.human}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.confirm.sendOk'),
               duration: 0
            })

         }).catch((e) => {
            this.setState({ loaderSync: false })
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.human ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.human}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.sendKo'),
               duration: 0
            })
         })
   }

   // toggleSyncData = (e) => {
   //    console.log(e.target.value)
   //    this.setState({ showSyncData: e.target.value })
   // }

   render() {
      const { Title } = Typography;
      const marketplace = this.state.marketplace
      const sync_data = this.state.sync_data
      const formLayout = {
         labelCol: { span: 6 },
         wrapperCol: { span: 18 },
      };
      return (
         <Card
            title={marketplace.name}
            extra={
               <Space size='small'>
                  <Button
                     // icon={}
                     // disabled={_.isUndefined(sync_data) || !this.state.showSyncData || this.state.loaderSave}
                     disabled={!this.state.showSyncData || this.state.loaderSave}
                     loading={this.state.loaderSync}
                     onClick={this.sendToMarketplace}
                  >Invia al Marketplace</Button>
                  <Button
                     // icon={}
                     disabled={this.state.loaderSync}
                     loading={this.state.loaderSave}
                     form={"CustomerMarketplace_" + marketplace.id} type="primary" htmlType="submit">Salva</Button>
               </Space>
            }
         >
            <Form
               ref={this.formRef}
               {...formLayout}
               id={"CustomerMarketplace_" + marketplace.id}
               onFinish={this.onFinish}
               onFinishFailed={this.onFinishFailed}
            >
               <Form.Item
                  name="active"
                  label="Stato"
                  initialValue={!_.isNil(sync_data) ? true : false}
               >
                  {/* <Radio.Group onChange={this.toggleSyncData}> */}
                  <Radio.Group>
                     <Radio.Button value={true}>Abilitato</Radio.Button>
                     <Radio.Button value={false}>Disabilitato</Radio.Button>
                  </Radio.Group>
               </Form.Item>
               {
                  this.state.showSyncData ? (
                     marketplace.marketplace_base.alias === 'ebay' ? ([
                        <Form.Item
                           key="marketplace_code"
                           label="ID cliente"
                           name="marketplace_code"
                           initialValue={!_.isUndefined(sync_data) ? sync_data.marketplace_code : null}
                        >
                           <Input disabled />
                        </Form.Item>,
                        <Form.Item
                           key="marketplace_offer_id"
                           label="ID offerta"
                           name="marketplace_offer_id"
                           initialValue={!_.isUndefined(sync_data) ? sync_data.marketplace_offer_id : null}
                        >
                           <Input disabled />
                        </Form.Item>,
                        <Form.Item
                           key="marketplace_listing_id"
                           label="ID vendita"
                           name="marketplace_listing_id"
                           initialValue={!_.isUndefined(sync_data) ? sync_data.marketplace_listing_id : null}
                        >
                           <Input disabled />
                        </Form.Item>,
                        <Form.Item
                           name="marketplace_product_name"
                           key="marketplace_product_name"
                           label="Nome prodotto"
                           initialValue={!_.isUndefined(sync_data) ? sync_data.marketplace_product_name : null}
                        >
                           <Input />
                        </Form.Item>,
                        <Form.Item
                           key="quantity"
                           label="Quantità"
                           name="quantity"
                           initialValue={!_.isUndefined(sync_data) ? sync_data.quantity : null}
                        >
                           <Input disabled />
                        </Form.Item>,
                        <Form.Item
                           key="standard_price"
                           label="Prezzo di listino"
                           name="standard_price"
                           initialValue={!_.isUndefined(sync_data) ? sync_data.standard_price : null}
                        >
                           <Input disabled />
                        </Form.Item>,
                        <Form.Item
                           key="sale_price"
                           label="Prezzo scontato"
                           name="sale_price"
                           initialValue={!_.isUndefined(sync_data) ? sync_data.sale_price : null}
                        >
                           <Input disabled />
                        </Form.Item>,
                     ]) : marketplace.marketplace_base.alias === 'woocommerce' ? ([
                        <Form.Item
                           key="marketplace_code"
                           name="marketplace_code"
                           label="ID cliente"
                           initialValue={!_.isUndefined(sync_data) ? sync_data.marketplace_code : null}
                        >
                           <Input disabled />
                        </Form.Item>,
                        <Form.Item
                           key="marketplace_username"
                           name="marketplace_username"
                           label="Username"
                           initialValue={!_.isUndefined(sync_data) ? sync_data.marketplace_username : null}
                        >
                           <Input disabled />
                        </Form.Item>,
                        <Form.Item
                           key="marketplace_name"
                           name="marketplace_full_name"
                           label="Nome su MP"
                           initialValue={!_.isUndefined(sync_data) ? sync_data.marketplace_full_name : null}
                        >
                           <Input disabled />
                        </Form.Item>,
                        <Form.Item
                           key="marketplace_role"
                           name="marketplace_role"
                           label="Ruolo su MP"
                           initialValue={!_.isUndefined(sync_data) ? sync_data.marketplace_role : null}
                        >
                           <Input disabled />
                        </Form.Item>,
                        <Form.Item
                           key="marketplace_group"
                           name="marketplace_group"
                           label="Gruppo su MP"
                           initialValue={!_.isUndefined(sync_data) ? sync_data.marketplace_group : null}
                        >
                           <Input disabled />
                        </Form.Item>,
                     ]) : null
                  ) : null
               }
               <Form.Item label="marketplace_id" name="marketplace_id" initialValue={marketplace.id} noStyle><Input type="hidden" /></Form.Item>
            </Form>
         </Card>
      )
   }
}

function mapStateToProps(state) {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
}

export default connect(mapStateToProps)(CustomerMarketplaceCard)