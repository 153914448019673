import PurchaseOrdersApi from '../../../../../api/PurchaseOrdersApi';
import _ from 'lodash';

export function createOrUpdate(itemData) {
   console.log(itemData);
   return PurchaseOrdersApi.createOrUpdate(itemData)
      .then(response => {
         if (response.data.success === true) {
            console.log(response);
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function getList() {
   return PurchaseOrdersApi.getList()
      .then(response => {
         console.log(response);
         // if (response.success === true) {
         if (response.data.success === true) {
            response.data.data.forEach(el => {
               el.key = el.id;
            });
            console.log(response);
            // return response;
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

export function getListExtended(page, pageSize, filters) {
   return PurchaseOrdersApi.getListExtended(page, pageSize, filters)
      .then(response => {
         console.log(response);
         // if (response.success === true) {
         if (response.data.success === true) {
            response.data.data.data.forEach(el => {
               el.key = el.id;
            });
            console.log(response);
            // return response;
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

export function getItem(itemId) {
   return PurchaseOrdersApi.getItem(itemId)
      .then(response => {
         console.log(response);
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         throw error;
      });
}
export function getItemByCode(itemCode, codeType) {
   return PurchaseOrdersApi.getItemByCode(itemCode, codeType)
      .then(response => {
         console.log(response);
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function getComplements() {
   return PurchaseOrdersApi.getComplements()
      .then(response => {
         console.log(response);
         const responseData = {}
         _.forEach(response, (el, i) => {
            if (el.data.success !== true) {
               const error = el.data.error ? el.data.error : 'Server error'
               throw new Error(error)
            }
            responseData[i] = el.data.data;
         });
         return responseData;
      })
      .catch(error => {
         throw error;
      });
}

export function updateOrderStatus(orderId, newStatus) {
   return PurchaseOrdersApi.updateOrderStatus(orderId, newStatus)
      .then(response => {
         console.log(response);
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function cancelOrder(orderId) {
   return PurchaseOrdersApi.cancelOrder(orderId)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function cancelOrderLine(orderId, lineId, params) {
   return PurchaseOrdersApi.cancelOrderLine(orderId, lineId, params)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function exportOrders(filename, orderIds) {
   return PurchaseOrdersApi.exportOrders(filename, orderIds)
      .then(response => {
         console.log(response);
         let blob = new Blob(['\ufeff' + response.data], { type: 'application/*' })
         let link = document.createElement('a')
         link.href = window.URL.createObjectURL(blob)
         link.download = filename;
         link.click();
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}
export function printDetails(filename, itemId) {
   return PurchaseOrdersApi.printDetails(filename, itemId)
      .then(response => {
         console.log(response);
         let blob = new Blob([response.data], { type: 'application/*' })
         let link = document.createElement('a')
         link.href = window.URL.createObjectURL(blob)
         link.download = filename;
         link.click();
      })
      .catch(error => {
         throw error;
      });
}