import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import Moment from 'moment'

import { Row, Col, Table, Select, Button, Avatar, Modal, Tooltip, Space, notification, Popconfirm, Typography } from 'antd'
import { InfoCircleOutlined, PlusSquareOutlined, QuestionCircleOutlined } from '@ant-design/icons'
// import Highlighter from 'react-highlight-words';
import { OrderedListOutlined, EditOutlined, DeleteOutlined, CheckCircleTwoTone, CloseCircleTwoTone, ClockCircleTwoTone, PrinterOutlined } from '@ant-design/icons'

import languageManager from '../../../services/languageManager'
import { startLoading, endLoading } from '../../../shared/utility'

import * as storeActions from './store/actions/categories'

import Loader from '../../common/PageElements/Loader'
import DropdownMenu from '../../common/components/DropdownMenu'

class CategoriesList extends Component {
   state = {
      componentLanguage: this.props.authState.defaultLanguage,
      pageLoader: true,
      tableLoader: false,
      multipleActionLoader: false,
      loadersDelete: [],
      itemList: [],
      fullParentsIds: [],
      selectedRowKeys: [],
      selectedAll: false,
      expandedRowKeys: [],
      expandedAll: false,
      marketplaceDropdowns: [],
      filters: {
         list: [],
         values: {},
         applied: []
      },
      pagination: {
         current_page: 1,
         page_size: 50,
         total_result: 0
      },
      multipleActionList: [
         {
            action: 'print_listings',
            name: languageManager.getMessage(this.props.commonState, 'component.categories.print_listings'),
            // needconfirmation: 'yes',
         },
         {
            action: 'print_catalog',
            name: languageManager.getMessage(this.props.commonState, 'component.categories.print_catalog'),
            // needconfirmation: 'yes',
         },
         // {
         //    action: 'delete_items',
         //    name: languageManager.getMessage(this.props.commonState, 'component.categories.delete_items'),
         //    needconfirmation: 'yes',
         // },
      ],
   };

   getData = (pagination, filters) => {
      // asyncronous calls
      return storeActions.getListExtended(pagination.current_page, pagination.page_size, filters.values)
         .then(response => {
            console.log(response.data);
            try {
               this.setState({
                  itemList: response.data,
                  fullParentsIds: response.full_parents_ids,
               })
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   prepareMarketplaceDropdowns = () => {
      let newExport = {}
      let newDropdown = {}
      let newMarketplaceDropdowns = []
      console.log(this.props.authState.userData.activeMarketplaces)
      _.forEach(this.props.authState.userData.activeMarketplaces, (marketplace) => {
         if (marketplace.marketplace_base.alias === 'woocommerce') {
            newDropdown = {}
            newDropdown.title = marketplace.name
            newDropdown.actionList = []
            newExport = {
               action: 'activate_for_marketplace',
               name: 'Abilita sul marketplace ' + marketplace.name,
               params: {
                  marketplace_id: marketplace.id
               },
               needconfirmation: 'yes',
            }
            newDropdown.actionList.push(newExport)
            newExport = {
               action: 'deactivate_for_marketplace',
               name: 'Disabilita sul marketplace ' + marketplace.name,
               params: {
                  marketplace_id: marketplace.id
               },
               needconfirmation: 'yes',
            }
            newDropdown.actionList.push(newExport)
            newExport = {
               action: 'send_to_woocommerce',
               name: 'Invia anagrafica dei selezionati a ' + marketplace.name,
               params: {
                  marketplace_id: marketplace.id
               },
               needconfirmation: 'yes',
            }
            newDropdown.actionList.push(newExport)
            newMarketplaceDropdowns.push(newDropdown)
         }
      })
      this.setState({
         marketplaceDropdowns: newMarketplaceDropdowns,
      });
   }

   componentDidMount() {
      Promise.all([
         this.getData(this.state.pagination, this.state.filters),
         // this.getComplements()
      ]).then(() => {
         this.prepareMarketplaceDropdowns()
         this.setState({
            pageLoader: false
         });
      });
   }

   orderLinkHandler = () => {
      this.props.history.push('/categories/order');
   }


   printListingsHandler = (itemIds) => {
      console.log('printListings')
      this.setState({ multipleActionLoader: true })
      let now = Moment().format('YYYY.MM.DD-HH.mm.ss');
      let filename = now + '_stampa_listini.zip';
      storeActions.printListings(itemIds, filename)
         .then(response => {
            console.log(response);
            try {
               notification.success({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.downloadOk'),
               })
            } catch (e) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
               if (this.props.commonState.debug) console.log(e);
            }
            this.setState({ multipleActionLoader: false })
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            if (this.props.commonState.debug) console.log(e);
            this.setState({ multipleActionLoader: false })
         });
   }
   printCatalogHandler = (itemIds) => {
      console.log('printCatalog')
      this.setState({ multipleActionLoader: true })
      let now = Moment().format('YYYY.MM.DD-HH.mm.ss');
      let filename = now + '_stampa_catalogo.pdf';
      storeActions.printCatalog(itemIds, filename)
         .then(response => {
            console.log(response);
            try {
               notification.success({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.downloadOk'),
               })
            } catch (e) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
               if (this.props.commonState.debug) console.log(e);
            }
            this.setState({ multipleActionLoader: false })
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            if (this.props.commonState.debug) console.log(e);
            this.setState({ multipleActionLoader: false })
         });
   }

   deleteItemsHandler = (itemIds) => {
      // this.setState({ loadersDelete: startLoading(this.state.loadersDelete, itemIds) })
      // storeActions.deleteItems(itemIds, true)
      //    .then(response => {
      //       console.log(response);
      //       try {
      //          // let list = [...this.state.itemList];
      //          // list = list.filter(item => item.id !== itemId);

      //          this.setState({ itemList: response.data, loadersDelete: endLoading(this.state.loadersDelete, itemIds) });
      //          notification.open({
      //             message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
      //             description: languageManager.getMessage(this.props.commonState, 'notification.confirm.deleteOk'),
      //             icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
      //          })
      //       } catch (e) {
      //          notification.open({
      //             message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
      //             description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
      //             icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
      //          })
      //          this.setState({ loadersDelete: endLoading(this.state.loadersDelete, itemIds) });
      //       }

      //    }).catch((e) => {
      //       notification.open({
      //          message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
      //          description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
      //          icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
      //       })
      //       this.setState({ loadersDelete: endLoading(this.state.loadersDelete, itemIds) });
      //       if (this.props.commonState.debug) console.log(e);
      //    });
   }

   multipleActionHandler = (value) => {
      console.log(value)
      const props = value.item.props
      switch (props.action) {
         case 'print_listings':
            this.printListingsHandler(this.state.selectedRowKeys)
            break
         case 'print_catalog':
            this.printCatalogHandler(this.state.selectedRowKeys)
            break
         case 'delete_items':
            this.deleteMultipleItemsHandler(this.state.selectedRowKeys)
            break
         case 'activate_for_marketplace':
            this.categoriesActivationHandler(this.state.selectedRowKeys, props.params)
            break
         case 'deactivate_for_marketplace':
            this.categoriesDeactivationHandler(this.state.selectedRowKeys, props.params)
            break
         case 'send_to_woocommerce':
            this.categoriesSendHandler('woocommerce', this.state.selectedRowKeys, props.params)
            break
         default:
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
            })
            break;
      }
   }

   categoriesActivationHandler = (itemIds, params) => {
      // console.log('categoriesActivationHandler', itemIds, params)
      // this.setState({ multipleActionLoader: true });
      // storeActions.activateForMarketplace(itemIds, params)
      //    .then(response => {
      //       try {
      //          console.log(response);
      //          Promise.all([
      //             this.getData(this.state.pagination, this.state.filters),
      //          ]).then(() => {
      //             this.setState({
      //                multipleActionLoader: false
      //             });
      //             notification.open({
      //                message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
      //                description: languageManager.getMessage(this.props.commonState, 'notification.success.sendOk'),
      //                icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
      //             })
      //          });
      //       } catch (e) {
      //          notification.open({
      //             message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
      //             description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
      //             icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
      //          })
      //       }
      //    }).catch((e) => {
      //       this.setState({ multipleActionLoader: false });
      //       notification.open({
      //          message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
      //          description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
      //          icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
      //       })
      //       if (this.props.commonState.debug) console.log(e);
      //    });
   }

   categoriesDeactivationHandler = (itemIds, params) => {
      // console.log('categoriesDeactivationHandler', itemIds, params)
      // this.setState({ multipleActionLoader: true });
      // storeActions.deactivateForMarketplace(itemIds, params)
      //    .then(response => {
      //       try {
      //          console.log(response);
      //          Promise.all([
      //             this.getData(this.state.pagination, this.state.filters),
      //          ]).then(() => {
      //             this.setState({
      //                multipleActionLoader: false
      //             });
      //             notification.open({
      //                message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
      //                description: languageManager.getMessage(this.props.commonState, 'notification.success.sendOk'),
      //                icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
      //             })
      //          });
      //       } catch (e) {
      //          notification.open({
      //             message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
      //             description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
      //             icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
      //          })
      //       }
      //    }).catch((e) => {
      //       this.setState({ multipleActionLoader: false });
      //       notification.open({
      //          message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
      //          description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
      //          icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
      //       })
      //       if (this.props.commonState.debug) console.log(e);
      //    });
   }

   onSelectChange = (selectedRowKeys) => {
      this.setState({ selectedRowKeys: selectedRowKeys })
   }

   expandAllHandler = () => {
      var allIds = _.clone(this.state.fullParentsIds)
      this.setState({
         expandedAll: true,
         expandedRowKeys: allIds,
      });
   }
   expandNoneHandler = () => {
      this.setState({
         expandedAll: false,
         expandedRowKeys: [],
      });
   }
   onExpandChange = (expanded, record) => {
      var expandedRows = _.clone(this.state.expandedRowKeys)
      if (expanded) {
         expandedRows.push(record.id)
      } else {
         _.pull(expandedRows, record.id)
      }
      this.setState({
         expandedAll: _.isEqual(_.sortBy(expandedRows), _.sortBy(this.state.fullParentsIds)),
         expandedRowKeys: expandedRows,
      });
   }

   setDefaultLanguageHandler = (value) => {
      this.setState({ componentLanguage: value })
   }

   render() {
      if (this.state.pageLoader) return <Loader />
      const { Option } = Select
      const { selectedRowKeys } = this.state

      const rowSelection = {
         selectedRowKeys: selectedRowKeys,
         onChange: this.onSelectChange,
         checkStrictly: false
      }
      const hasSelected = selectedRowKeys.length > 0
      const singleSelected = selectedRowKeys.length > 1

      var table_columns = [
         // { title: 'Codice', width: 100, dataIndex: 'internal_code', key: 'internal_code', fixed: 'left' },
         {
            title: 'Nome',
            key: 'name',
            fixed: 'left',
            render: (record) => (
               <Link
                  to={{
                     pathname: "/categories/edit/" + record.id,
                  }}
               >{record.name[this.state.componentLanguage]}</Link>
            ),
            sorter: (a, b) => a.name[this.state.componentLanguage].localeCompare(b.name[this.state.componentLanguage]),
         },
         {
            title: 'Sottocategorie',
            key: 'subcategories_count',
            width: 120,
            render: (record) => (
               record.subcategories_count
            ),
            sorter: (a, b) => a.subcategories_count - b.subcategories_count,
         },
         {
            title: 'Prodotti',
            key: 'products_count',
            width: 100,
            render: (record) => (
               record.products_count
            ),
            sorter: (a, b) => a.products_count - b.products_count,
         },
         // {
         //    title: 'Stampa',
         //    key: 'print',
         //    width: 70,
         //    align: 'center',
         //    render: (record) => (
         //       record.exclude_from_print ? (
         //          <CloseCircleTwoTone twoToneColor="red" />
         //       ) : (
         //          <PrinterOutlined />
         //       )
         //    ),
         // }
      ]

      // _.forEach(this.props.authState.userData.activeMarketplaces, (el, i) => {
      //    switch(el.marketplace_base.alias) {
      //       case 'ebay':
      //          table_columns.push(
      //             {
      //                title: (
      //                   <Tooltip title={el.name}>
      //                      <Avatar shape='square' size={20} src={el.icon} />
      //                   </Tooltip>
      //                ),
      //                key: el.id,
      //                className: 'marketplace_sync_column',
      //                render: (text, record) => (
      //                   record.marketplace_sync && record.marketplace_sync[el.id] ? (
      //                      <Tooltip placement="topRight" title={(
      //                         <Space direction="vertical" size='small'>
      //                            {languageManager.getMessage(this.props.commonState, 'tooltip.value') + ': ' + record.marketplace_sync[el.id].marketplace_code}
      //                            {record.marketplace_sync[el.id].marketplace_name}
      //                         </Space>
      //                      )}>
      //                         <CheckCircleTwoTone twoToneColor="#52c41a" />
      //                      </Tooltip>
      //                   ) : (
      //                      <Tooltip title={languageManager.getMessage(this.props.commonState, 'tooltip.item_not_active')}>
      //                         <CloseCircleTwoTone twoToneColor="red" />
      //                      </Tooltip>
      //                   )
      //                ),
      //             },
      //          )
      //          break
      //       case 'woocommerce':
      //          table_columns.push(
      //             {
      //                title: (
      //                   <Tooltip title={el.name}>
      //                      <Avatar shape='square' size={20} src={el.icon} />
      //                   </Tooltip>
      //                ),
      //                key: el.id,
      //                className: 'marketplace_sync_column',
      //                render: (text, record) => (
      //                   record.marketplace_sync && record.marketplace_sync[el.id] && record.marketplace_sync[el.id].marketplace_code ? (
      //                      <Tooltip placement="topRight" title={(
      //                         <Space direction="vertical" size='small'>
      //                            {languageManager.getMessage(this.props.commonState, 'tooltip.value') + ': ' + record.marketplace_sync[el.id].marketplace_code}
      //                            {languageManager.getMessage(this.props.commonState, 'tooltip.last_update') + ': ' + Moment(record.marketplace_sync[el.id].last_update).format('L LTS')}
      //                         </Space>
      //                      )}>
      //                         <CheckCircleTwoTone twoToneColor="#52c41a" />
      //                      </Tooltip>
      //                   ) : record.marketplace_sync && record.marketplace_sync[el.id] ? (
      //                      <Tooltip title={languageManager.getMessage(this.props.commonState, 'tooltip.waiting_sync')}>
      //                         <ClockCircleTwoTone twoToneColor="orange" />
      //                      </Tooltip>
      //                   ) : (
      //                      <Tooltip title={languageManager.getMessage(this.props.commonState, 'tooltip.missing_sync')}>
      //                         <CloseCircleTwoTone twoToneColor="red" />
      //                      </Tooltip>
      //                   )
      //                ),
      //             },
      //          )
      //          break
      //    }
      // })

      table_columns.push(
         {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            align: 'right',
            width: 100,
            render: (text, record) => (
               <Space>
                  <Link
                     to={{
                        pathname: "/categories/edit/" + record.id,
                     }}
                  >
                     <Button type="primary" size='small'
                        icon={<EditOutlined />}
                     />
                  </Link>
                  <Button type="danger" size='small'
                     icon={<DeleteOutlined />}
                     onClick={() =>
                        Modal.confirm({
                           title: languageManager.getMessage(this.props.commonState, 'common.delete_item'),
                           icon: <QuestionCircleOutlined />,
                           content: languageManager.getMessage(this.props.commonState, 'common.askDeleteConfirm'),
                           okText: languageManager.getMessage(this.props.commonState, 'common.confirm'),
                           onOk: () => this.deleteItemHandler(record.id)
                        })
                     }
                  />
               </Space>),
         },
      )

      const tableLayout = {
         bordered: true,
         size: "small",
         loading: this.state.tableLoader,
         expandable: {
            indentSize: 30,
            expandedRowKeys: this.state.expandedRowKeys,
            onExpand: this.onExpandChange
         },
      }

      const pageContent = (
         <div className="card">
            <div className="card-header">
               <div className="align-items-center row">
                  <div className="col-sm-6">
                     {languageManager.getMessage(this.props.commonState, 'component.categories.title')}
                  </div>

                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">
                        <Space>
                           {/* <Button
                              size='large'
                              type='primary'
                              icon={<PlusSquareOutlined />}
                              onClick={() => { this.export() }}
                           >
                              Esporta categorie
                        </Button> */}
                           <Button
                              type='primary'
                              icon={<OrderedListOutlined />}
                              onClick={() => { this.props.history.push('/categories/order') }}
                           >
                              Ordina categorie
                           </Button>
                           <Button
                              type='primary'
                              icon={<PlusSquareOutlined />}
                              onClick={() => this.props.history.push('/categories/edit/')}
                           >
                              Crea categoria
                           </Button>
                           {
                              this.props.authState.userData.languages ? (
                                 <Select
                                    showSearch
                                    className="width-70px-I"
                                    placeholder="Seleziona la lingua"
                                    defaultValue={this.state.componentLanguage.toUpperCase()}
                                    optionFilterProp="children"
                                    onChange={this.setDefaultLanguageHandler}
                                 >
                                    {
                                       this.props.authState.userData.languages.map((lang) => {
                                          return <Option key={lang.value} value={lang.value}>{lang.label}</Option>
                                       })
                                    }
                                 </Select>
                              ) : null
                           }
                        </Space>
                     </div>
                  </div>
               </div>
            </div>
            <div className="card-body">
               <Row>
                  <Col span={24}>
                     <div className="align-items-center row" style={{ marginTop: 15, marginBottom: 5 }}>
                        <div className="col-sm-12">
                           <div className="d-none d-md-block">
                              <Space>
                                 {
                                    !this.state.expandedAll ? (
                                       <Button
                                          onClick={() => this.expandAllHandler()}
                                       >Espandi tutto
                                       </Button>
                                    ) : null
                                 }
                                 {
                                    !_.isEmpty(this.state.expandedRowKeys) ? (
                                       <Button
                                          onClick={() => this.expandNoneHandler()}
                                       >Collassa tutto
                                       </Button>
                                    ) : null
                                 }
                                 <span>
                                    {hasSelected ? singleSelected ? `${selectedRowKeys.length} elementi selezionati` : `${selectedRowKeys.length} elemento selezionato` : ''}
                                 </span>
                                 <DropdownMenu
                                    type='primary'
                                    title='Azioni multiple'
                                    action={(event) => this.multipleActionHandler(event)}
                                    values={this.state.multipleActionList}
                                    disabled={!hasSelected}
                                    loading={this.state.multipleActionLoader}
                                 />
                                 {/* {
                                    this.state.marketplaceDropdowns.map((dropdown, i) => {
                                       return (
                                          <DropdownMenu
                                             key={i}
                                             type='primary'
                                             title={'Azioni ' + dropdown.title}
                                             action={this.multipleActionHandler}
                                             values={dropdown.actionList}
                                             disabled={!hasSelected}
                                             loading={this.state.multipleActionLoader}
                                          />
                                       )
                                    })
                                 } */}
                              </Space>
                           </div>
                        </div>
                     </div>
                     <Table
                        {...tableLayout}
                        rowSelection={rowSelection}
                        pagination={false}
                        columns={table_columns}
                        dataSource={this.state.itemList}
                        scroll={{ x: 1000 }}
                     />
                  </Col>
               </Row>
            </div>
            <div className="card-footer">
               {
                  this.state.parentId ? (
                     <Button
                        size='large'
                        onClick={() => this.changeLevelHandler(this.state.parentId)}
                     >
                        <i className="mdi mdi-arrow-left-drop-circle-outline mr-1"></i>
                        {languageManager.getMessage(this.props.commonState, 'common.back')}
                     </Button>
                  ) : ''
               }
            </div>
         </div>
      );

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>
      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState,
   };
};

export default connect(mapStateToProps)(CategoriesList);