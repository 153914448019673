import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import Moment from 'moment'

import { Typography, Table, Select, Button, Tooltip, Space, notification, List, Popconfirm, Collapse, Col, Row, Image, Avatar } from 'antd'
import { InfoCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { ClockCircleTwoTone, CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons'

import languageManager from '../../../services/languageManager'

import * as storeActions from './store/actions/products'

import Loader from '../../common/PageElements/Loader'
import DropdownMenu from '../../common/components/DropdownMenu'

import TableFilters from '../../common/components/TableFilters'

class ProductsList extends Component {
   state = {
      localPath: this.props.history.location.pathname,
      pageInit: true,
      pageLoader: true,
      tableLoader: false,
      multipleActionLoader: false,
      parentId: null,
      itemList: [],
      fullListIds: [],
      componentLanguage: this.props.authState.defaultLanguage,
      current_page: this.props.location.state ? this.props.location.state.previousState.current_page : 1,
      page_size: this.props.location.state ? this.props.location.state.previousState.page_size : 50,
      total_result: 0,

      showTableFilters: false,
      filters: this.props.location.state ? this.props.location.state.previousState.filters : [],
      filtersValues: this.props.location.state ? this.props.location.state.previousState.filtersValues : {},
      appliedFilters: this.props.location.state ? this.props.location.state.previousState.appliedFilters : [],

      selectedRowKeys: [],
      selectedAll: false,
      multipleActionList: [
         {
            action: 'export_csv',
            name: 'Esporta selezionati in CSV'
         },
         {
            action: 'delete_products',
            name: 'Cancella prodotti',
            needconfirmation: 'yes',
         },
      ],
      marketplaceDropdowns: [],
   };

   getProductsList = (page, pageSize, filters) => {
      // asyncronous calls
      this.setState({ tableLoader: true })
      return storeActions.getListExtended(page, pageSize, filters)
         .then(response => {
            console.log(response);
            try {
               this.setState({
                  itemList: response.data.data,
                  fullListIds: response.full_list_ids,
                  current_page: page,
                  page_size: pageSize,
                  total_result: response.data.total,
                  filters: response.list_filters,
                  appliedFilters: response.applied_filters,
                  tableLoader: false
               });
            } catch (e) {
               this.setState({ tableLoader: false })
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                  duration: 0,
               })
            }

         }).catch((e) => {
            this.setState({ tableLoader: false });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   prepareMarketplaceDropdowns = () => {
      let newExport = {}
      let newDropdown = {}
      let newMarketplaceDropdowns = []
      _.forEach(this.props.authState.userData.activeMarketplaces, (marketplace) => {
         newDropdown = {}
         newDropdown.title = marketplace.name
         newDropdown.actionList = []
         if (marketplace.marketplace_base.alias === 'privalia') {
            newExport = {
               action: 'export_privalia',
               name: 'Esporta selezionati in CSV per ' + marketplace.name,
               params: {
                  marketplace_id: marketplace.id
               }
            }
            newDropdown.actionList.push(newExport)
            newExport = {
               action: 'sync_privalia',
               name: 'Invia anagrafica dei selezionati a ' + marketplace.name,
               params: {
                  marketplace_id: marketplace.id
               },
               needconfirmation: 'yes',
            }
            newDropdown.actionList.push(newExport)
            newExport = {
               action: 'sync_quantities_privalia',
               name: 'Invia quantità dei selezionati a ' + marketplace.name,
               params: {
                  marketplace_id: marketplace.id
               },
               needconfirmation: 'yes',
            }
            newDropdown.actionList.push(newExport)
            newExport = {
               action: 'sync_prices_privalia',
               name: 'Invia prezzi dei selezionati a ' + marketplace.name,
               params: {
                  marketplace_id: marketplace.id
               },
               needconfirmation: 'yes',
            }
            newDropdown.actionList.push(newExport)
         }
         else if (marketplace.marketplace_base.alias === 'woocommerce') {
            newExport = {
               action: 'activate_for_marketplace',
               name: 'Abilita sul marketplace ' + marketplace.name,
               params: {
                  marketplace_id: marketplace.id
               },
               needconfirmation: 'yes',
            }
            newDropdown.actionList.push(newExport)
            newExport = {
               action: 'deactivate_for_marketplace',
               name: 'Disabilita sul marketplace ' + marketplace.name,
               params: {
                  marketplace_id: marketplace.id
               },
               needconfirmation: 'yes',
            }
            newDropdown.actionList.push(newExport)
            newExport = {
               action: 'send_to_woocommerce',
               name: 'Invia anagrafica dei selezionati a ' + marketplace.name,
               params: {
                  marketplace_id: marketplace.id
               },
               needconfirmation: 'yes',
            }
            newDropdown.actionList.push(newExport)
         }
         newMarketplaceDropdowns.push(newDropdown)
      })
      this.setState({
         marketplaceDropdowns: newMarketplaceDropdowns,
      });
   }

   getComplements = () => {
      return storeActions.getProductListComplements()
         .then(response => {
            console.log(response);
            try {

            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e);
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   componentDidMount() {
      Promise.all([
         this.getProductsList(this.state.current_page, this.state.page_size, this.state.filtersValues),
         // this.getComplements()
      ]).then(() => {
         this.prepareMarketplaceDropdowns()
         this.setState({
            pageLoader: false
         });
      });
   }

   editItemHandler = (itemId) => {
      this.props.history.push({
         pathname: '/products/edit/' + itemId,
         state: { previousState: this.state }
      });
   }

   deleteMultipleItemsHandler = (productIds) => {
      this.setState({ loader: true });
      storeActions.deleteMultipleItems(productIds, true)
         .then(response => {
            console.log(response);
            try {
               // let list = [...this.state.itemList];
               // list = list.filter(item => item.id !== itemId);

               this.getProductsList(this.state.current_page, this.state.page_size, this.state.filtersValues);
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.deleteOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   confirmMultipleHandler = (onConfirm, productIds) => {
      switch (onConfirm) {
         case 'deleteItems':
            if (productIds) {
               this.deleteMultipleItemsHandler(productIds);
            }
            break;
         default:
            break;
      }
   }

   deleteItemHandler = (itemId) => {
      this.setState({ loader: true });
      storeActions.deleteItem(itemId, true)
         .then(response => {
            console.log(response);
            try {
               // let list = [...this.state.itemList];
               // list = list.filter(item => item.id !== itemId);

               this.getProductsList(this.state.current_page, this.state.page_size, this.state.filtersValues);
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.deleteOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   confirmHandler = (onConfirm, itemId) => {
      switch (onConfirm) {
         case 'deleteItem':
            if (itemId) {
               this.deleteItemHandler(itemId);
            }
            break;
         default:
            break;
      }
   }

   toggleValueHandler = (itemId, value) => {
      this.setState({ loader: true });
      storeActions.toggleValue(itemId, value, this.state.componentLanguage)
         .then(response => {
            console.log(response);
            try {
               // this.setState({ itemData: response.data });

               const newState = { ...this.state };
               const itemIndex = newState.itemList.findIndex((obj => obj.id === response.data.id));
               // console.log("Found index", itemIndex);
               newState.itemList[itemIndex] = response.data;
               // console.log("Item", newState.itemList[itemIndex]);
               this.setState({ itemList: newState.itemList, loader: false });
               // console.log(response.data);
               // console.log(this.state);



               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.success.updateOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               this.setState({ loader: false });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            this.setState({ loader: false });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
         });
   }

   setDefaultLanguageHandler = (value) => {
      this.setState({ componentLanguage: value })
   }

   productsExportHandler = (marketplace, productIds, params) => {
      let filename
      switch (marketplace) {
         case 'nzt':
            this.setState({ multipleActionLoader: true });
            filename = Moment().format('YYYY.MM.DD-HH.mm.ss') + '_PRODUCTS.csv';
            storeActions.exportProductsCSV(filename, productIds)
               .then(response => {
                  this.setState({ multipleActionLoader: false });
                  console.log(response);
                  try {
                     this.setState({ loader: false });
                  } catch (e) {
                     notification.open({
                        message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                        description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                        icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                     })
                  }

               }).catch((e) => {
                  this.setState({ multipleActionLoader: false });
                  notification.open({
                     message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                     description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                     icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                  })
                  if (this.props.commonState.debug) console.log(e);
               });
            break;
         case 'privalia':
            this.setState({ multipleActionLoader: true });
            filename = Moment().format('YYYY.MM.DD-HH.mm.ss') + '_PRODUCTS_PRIVALIA.csv';
            storeActions.exportProductsForPrivalia(filename, productIds, 'export', params)
               .then(response => {
                  this.setState({ multipleActionLoader: false });
                  console.log(response);
                  try {
                     this.setState({ loader: false });
                  } catch (e) {
                     notification.open({
                        message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                        description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                        icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                     })
                  }

               }).catch((e) => {
                  this.setState({ multipleActionLoader: false });
                  notification.open({
                     message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                     description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                     icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                  })
                  if (this.props.commonState.debug) console.log(e);
               });
            break;
         default:
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.no_marketplace_selected'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            break;
      }
   }

   productsActivationHandler = (productIds, params) => {
      console.log('productsActivationHandler', productIds, params)
      this.setState({ multipleActionLoader: true });
      storeActions.activateForMarketplace(productIds, params)
         .then(response => {
            console.log(response);
            Promise.all([
               this.getProductsList(this.state.current_page, this.state.page_size, this.state.filtersValues),
            ]).then(() => {
               this.setState({
                  multipleActionLoader: false
               });
               notification.success({
                  message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
                  description: response.human ? (
                     <List
                        size="small"
                        split={false}
                        dataSource={response.human}
                        renderItem={item => <List.Item>{item}</List.Item>}
                     />
                  ) : languageManager.getMessage(this.props.commonState, 'notification.confirm.updateOk'),
                  duration: 0
               })
            });

         }).catch((e) => {
            this.setState({ multipleActionLoader: false });
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.human ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.human}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   productsDeactivationHandler = (productIds, params) => {
      console.log('productsDeactivationHandler', productIds, params)
      this.setState({ multipleActionLoader: true });
      storeActions.deactivateForMarketplace(productIds, params)
         .then(response => {
            console.log(response);
            Promise.all([
               this.getProductsList(this.state.current_page, this.state.page_size, this.state.filtersValues),
            ]).then(() => {
               this.setState({
                  multipleActionLoader: false
               });
               notification.success({
                  message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
                  description: response.human ? (
                     <List
                        size="small"
                        split={false}
                        dataSource={response.human}
                        renderItem={item => <List.Item>{item}</List.Item>}
                     />
                  ) : languageManager.getMessage(this.props.commonState, 'notification.confirm.updateOk'),
                  duration: 0
               })
            });

         }).catch((e) => {
            this.setState({ multipleActionLoader: false });
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.human ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.human}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   productsSendHandler = (marketplace, productIds, params) => {
      let filename
      switch (marketplace) {
         case 'woocommerce':
            this.setState({ multipleActionLoader: true });
            storeActions.sendToMarketplace(productIds, params)
               .then(response => {
                  this.setState({ multipleActionLoader: false });
                  console.log(response);
                  try {
                     notification.open({
                        message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                        description: languageManager.getMessage(this.props.commonState, 'notification.success.sendOk'),
                        icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                     })
                  } catch (e) {
                     notification.open({
                        message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                        description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                        icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                     })
                  }

               }).catch((e) => {
                  this.setState({ multipleActionLoader: false });
                  notification.open({
                     message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                     description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                     icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                  })
                  if (this.props.commonState.debug) console.log(e);
               });
            break;
         default:
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.no_marketplace_selected'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            break;
      }
   }

   productsSyncHandler = (marketplace, productIds, params) => {
      let filename
      switch (marketplace) {
         case 'privalia':
            this.setState({ multipleActionLoader: true });
            filename = Moment().format('YYYY.MM.DD-HH.mm.ss') + '_PRODUCTS_PRIVALIA_SYNC.csv';
            storeActions.exportProductsForPrivalia(filename, productIds, 'sync', params)
               .then(response => {
                  this.setState({ multipleActionLoader: false });
                  console.log(response);
                  try {
                     this.setState({ loader: false });
                  } catch (e) {
                     notification.open({
                        message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                        description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                        icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                     })
                  }

               }).catch((e) => {
                  this.setState({ multipleActionLoader: false });
                  notification.open({
                     message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                     description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                     icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                  })
                  if (this.props.commonState.debug) console.log(e);
               });
            break;
         case 'woocommerce':
            this.setState({ multipleActionLoader: true });
            filename = Moment().format('YYYY.MM.DD-HH.mm.ss') + '_PRODUCTS_WOOCOMMERCE_SYNC.csv';
            storeActions.exportProductsForWoocommerce(filename, productIds, 'sync', params)
               .then(response => {
                  this.setState({ multipleActionLoader: false });
                  console.log(response);
                  try {
                     this.setState({ loader: false });
                  } catch (e) {
                     notification.open({
                        message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                        description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                        icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                     })
                  }

               }).catch((e) => {
                  this.setState({ multipleActionLoader: false });
                  notification.open({
                     message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                     description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                     icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                  })
                  if (this.props.commonState.debug) console.log(e);
               });
            break;
         default:
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.no_marketplace_selected'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            break;
      }
   }

   productsSyncQuantitiesHandler = (marketplace, productIds, params) => {
      let filename
      switch (marketplace) {
         case 'privalia':
            this.setState({ multipleActionLoader: true });
            filename = Moment().format('YYYY.MM.DD-HH.mm.ss') + '_QUANTITIES_PRIVALIA_SYNC.csv';
            storeActions.exportProductsForPrivalia(filename, productIds, 'sync_quantities', params)
               .then(response => {
                  this.setState({ multipleActionLoader: false });
                  console.log(response);
                  try {
                     this.setState({ loader: false });
                  } catch (e) {
                     notification.open({
                        message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                        description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                        icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                     })
                  }

               }).catch((e) => {
                  this.setState({ multipleActionLoader: false });
                  notification.open({
                     message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                     description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                     icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                  })
                  if (this.props.commonState.debug) console.log(e);
               });
            break;
         default:
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.no_marketplace_selected'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            break;
      }
   }

   productsSyncPricesHandler = (marketplace, productIds, params) => {
      let filename
      switch (marketplace) {
         case 'privalia':
            this.setState({ multipleActionLoader: true });
            filename = Moment().format('YYYY.MM.DD-HH.mm.ss') + '_PRICES_PRIVALIA_SYNC.csv';
            storeActions.exportProductsForPrivalia(filename, productIds, 'sync_prices', params)
               .then(response => {
                  this.setState({ multipleActionLoader: false });
                  console.log(response);
                  try {
                     this.setState({ loader: false });
                  } catch (e) {
                     notification.open({
                        message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                        description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                        icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                     })
                  }

               }).catch((e) => {
                  this.setState({ multipleActionLoader: false });
                  notification.open({
                     message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                     description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                     icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                  })
                  if (this.props.commonState.debug) console.log(e);
               });
            break;
         default:
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.no_marketplace_selected'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            break;
      }
   }

   onSelectChange = (selectedRowKeys) => {
      this.setState({
         selectedRowKeys: selectedRowKeys
      });
      console.log(selectedRowKeys);
   };

   submitFilters = (values) => {
      this.setState({ filtersValues: values })
      this.getProductsList(this.state.current_page, this.state.page_size, values)
   }
   resetFilters = () => {
      this.getProductsList(this.state.current_page, this.state.page_size, []);
   }

   selectAllHandler = () => {
      if (this.state.selectedAll) {
         this.setState({
            selectedAll: false,
            selectedRowKeys: [],
         });
      }
      else {
         this.setState({
            selectedAll: true,
            selectedRowKeys: this.state.fullListIds,
         });
      }
   }
   multipleActionHandler = (value) => {
      const props = value.item.props
      console.log(props.action, value)
      switch (props.action) {
         case 'export_csv':
            this.productsExportHandler('nzt', this.state.selectedRowKeys)
            break
         case 'delete_products':
            this.confirmMultipleHandler('deleteItems', this.state.selectedRowKeys)
            break
         case 'export_privalia':
            this.productsExportHandler('privalia', this.state.selectedRowKeys, props.params)
            break
         case 'sync_privalia':
            this.productsSyncHandler('privalia', this.state.selectedRowKeys, props.params)
            break
         case 'sync_quantities_privalia':
            this.productsSyncQuantitiesHandler('privalia', this.state.selectedRowKeys, props.params)
            break
         case 'sync_prices_privalia':
            this.productsSyncPricesHandler('privalia', this.state.selectedRowKeys, props.params)
            break
         case 'activate_for_marketplace':
            this.productsActivationHandler(this.state.selectedRowKeys, props.params)
            break
         case 'deactivate_for_marketplace':
            this.productsDeactivationHandler(this.state.selectedRowKeys, props.params)
            break
         case 'send_to_woocommerce':
            this.productsSendHandler('woocommerce', this.state.selectedRowKeys, props.params)
            break
         default:
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            break

      }
   }

   handleTableChange = (pagination, filters, sorter, extra) => {
      console.log(pagination, filters, sorter, extra);
      this.getProductsList(pagination.current, pagination.pageSize, this.state.filtersValues);
   };

   render() {
      if (!this.props.authState.userData.languages) return '';
      const { Title } = Typography
      const { Option } = Select;
      const { Panel } = Collapse;
      var table_columns = [
         { title: 'SKU', width: 150, dataIndex: 'sku', key: 'sku', fixed: 'left' },
         {
            title: "Immagine",
            width: 91, height: 91,
            // dataIndex: 'img',
            key: 'img',
            render: (record) => (
               !_.isNil(record.gallery[0]) ? <Image width={75} src={record.gallery[0].thumb_75} /> : ''
               // record.name[this.state.componentLanguage]
            )
         },
         {
            title: 'Nome',
            key: 'name',
            render: (record) => (
               <a className="link-cell" onClick={() => this.editItemHandler(record.id)}>
                  {record.title}
               </a>
            ),
            sorter: (a, b) => a.title.localeCompare(b.title),
         },
         // {
         //    title: 'Brand', key: 'brand', width: 150,
         //    render: (record) => (
         //       !_.isNil(record.brand) ? record.brand.name : ''
         //    ),
         //    sorter: (a, b) => a.brand.name.localeCompare(b.brand.name),
         // },
         // {
         //    title: 'Categoria principale', key: 'main_category', width: 200,
         //    render: (record) => (
         //       !_.isNil(record.main_category[0]) ? record.main_category[0].name[this.state.componentLanguage] : ''
         //    ),
         //    sorter: (a, b) => a.main_category[0].name[this.state.componentLanguage].localeCompare(b.main_category[0].name[this.state.componentLanguage]),
         // },
         {
            title: 'Tipo prodotto', key: 'product_type', width: 100,
            render: (record) => (
               record.is_parent ? 'Variabile' : 'Semplice'
            )
         },
         {
            title: 'Quantità', key: 'quantity', width: 100,
            render: (record) => (
               record.quantity
            ),
            sorter: (a, b) => a.quantity - b.quantity
         },
         {
            title: 'Acquisto (i.e.)', key: 'price_buy', width: 120,
            render: (record) => (
               record.price_buy ? record.price_buy.toFixed(2) + '€' : ''
            ),
            sorter: (a, b) => a.price_buy - b.price_buy
         },
         {
            title: 'Listino (i.e.)', key: 'price_sell', width: 120,
            render: (record) => (
               record.price_on_request ? 'Su richiesta' :
                  record.price_on_variation ? record.price_retail_for_list_min.toFixed(2) + '€ - ' + record.price_retail_for_list_max.toFixed(2) + '€' :
                     record.price_sell ? record.price_sell.toFixed(2) + '€' : null
            ),
            sorter: (a, b) => a.price_retail_for_list_min - b.price_retail_for_list_min
         },
         {
            title: 'Prezzo attuale', key: 'actual_price', width: 120,
            render: (record) => (
               record.price_on_request ? 'Su richiesta' : record.actual_price ? record.actual_price.toFixed(2) + '€' : ''
            ),
            sorter: (a, b) => a.actual_price - b.actual_price
         },
         // { title: 'Prezzo di vendita', dataIndex: 'current_price', key: 'current_price' },
         // {
         //    title: languageManager.getMessage(this.props.commonState, 'common.visible'), width: 100, dataIndex: 'visible', key: 'percentage', fixed: 'center',
         //    render: (text, record) => (
         //       <span>
         //          <a onClick={() => this.toggleValueHandler(record.id, 'visible')}>{record.visible[this.state.componentLanguage] ? <CheckOutlined className="color-green-I" /> : <CloseOutlined className="color-red-I" />}</a>
         //       </span>),
         //    filters: [
         //       {
         //          text: languageManager.getMessage(this.props.commonState, 'common.visible'),
         //          value: true,
         //       },
         //       {
         //          text: languageManager.getMessage(this.props.commonState, 'common.visible'),
         //          value: false,
         //       },
         //    ],
         //    filterMultiple: false,
         //    onFilter: (value, record) => record.visible[this.state.componentLanguage] === value,
         // },
      ]


      _.forEach(this.props.authState.userData.activeMarketplaces, (el, i) => {
         table_columns.push(
            {
               title: (
                  <Tooltip title={el.name}>
                     <Avatar shape='square' size={20} src={el.icon} />
                  </Tooltip>
               ),
               key: el.id,
               className: 'marketplace_sync_column',
               render: (text, record) => (
                  record.marketplace_sync && record.marketplace_sync[el.id] && record.marketplace_sync[el.id].value ? (
                     <Tooltip title={(
                        <Space direction="vertical" size='small'>
                           {languageManager.getMessage(this.props.commonState, 'tooltip.value') + ': ' + record.marketplace_sync[el.id].value}
                           {languageManager.getMessage(this.props.commonState, 'tooltip.last_update') + ': ' + Moment(record.marketplace_sync[el.id].last_update).format('L LTS')}
                        </Space>
                     )}>
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                     </Tooltip>
                  ) : record.marketplace_sync && record.marketplace_sync[el.id] ? (
                     <Tooltip title={languageManager.getMessage(this.props.commonState, 'tooltip.waiting_sync')}>
                        <ClockCircleTwoTone twoToneColor="orange" />
                     </Tooltip>
                  ) : (
                     <Tooltip title={languageManager.getMessage(this.props.commonState, 'tooltip.missing_sync')}>
                        <CloseCircleTwoTone twoToneColor="red" />
                     </Tooltip>
                  )
               ),
            },
         )
      })

      table_columns.push(
         {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            align: 'right',
            width: 100,
            render: (text, record) => (
               <Space>
                  <Tooltip title={languageManager.getMessage(this.props.commonState, 'common.edit')}>
                     <Button type="primary" size='small'
                        icon={<EditOutlined />}
                        onClick={() => this.editItemHandler(record.id)}
                     />
                     {/* <Dropdown.Button type="primary" onClick={() => this.editItemHandler(record.id)} overlay={actionsMenu(record.id)}><EditOutlined /></Dropdown.Button> */}
                  </Tooltip>
                  <Popconfirm
                     placement="topRight"
                     title={languageManager.getMessage(this.props.commonState, 'common.askDeleteConfirm')}
                     onConfirm={() => this.confirmHandler('deleteItem', record.id)}
                     okText={languageManager.getMessage(this.props.commonState, 'common.yes')}
                     cancelText={languageManager.getMessage(this.props.commonState, 'common.no')}
                  >
                     <Tooltip title={languageManager.getMessage(this.props.commonState, 'common.delete')}>
                        <Button type="danger" size='small'
                           icon={<DeleteOutlined />}
                        />
                     </Tooltip>
                  </Popconfirm>
               </Space>),
         },
      )

      const tableLayout = {
         bordered: true,
         size: "small",
         loading: this.state.tableLoader,
         pagination: {
            pageSize: this.state.page_size,
            total: this.state.total_result,
            showSizeChanger: true,
            defaultPageSize: 50,
            size: "small",
            showTotal: total => total + (total !== 1 ? ' prodotti' : ' prodotto')
         },
         onChange: this.handleTableChange
      }

      const { selectedRowKeys } = this.state;
      const rowSelection = {
         selectedRowKeys,
         onChange: this.onSelectChange,
         preserveSelectedRowKeys: true
      };

      const pageFilters = (
         <Collapse defaultActiveKey={['filters']}>
            <Panel header="Filtri" key="filters">
               <TableFilters submitFilters={this.submitFilters} resetFilters={this.resetFilters} filters={this.state.filters} selectValues={this.state.filtersValues} />
            </Panel>
         </Collapse>
      );

      const hasSelected = selectedRowKeys.length > 0;
      const singleSelected = selectedRowKeys.length > 1;
      const pageContent = (
         <div className="card">
            <div className="card-header">
               <div className="align-items-center row">
                  <div className="col-sm-6">
                     <Title level={5}>{languageManager.getMessage(this.props.commonState, 'component.products.title')}</Title>
                  </div>
                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">
                        <Space>
                           <Button
                              type='primary'
                              icon={<PlusOutlined />}
                              onClick={() => this.props.history.push('/products/edit/')}
                           >
                              Crea prodotto
                           </Button>
                           <Button
                              type='primary'
                              icon={<UploadOutlined />}
                              onClick={() => this.props.history.push('/products/import/')}
                           >
                              Importa anagrafiche prodotti
                           </Button>
                           <Button
                              type='primary'
                              icon={<UploadOutlined />}
                              onClick={() => this.props.history.push('/products/import_csv/')}
                           >
                              Importa CSV aggiornamenti
                           </Button>
                           <Select
                              showSearch
                              className="width-70px-I"
                              placeholder="Seleziona la lingua"
                              defaultValue={this.state.componentLanguage.toUpperCase()}
                              optionFilterProp="children"
                              onChange={this.setDefaultLanguageHandler}
                           //  onFocus={onFocus}
                           //  onBlur={onBlur}
                           //  onSearch={onSearch}
                           //  filterOption={(input, option) =>
                           //    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                           //  }
                           >
                              {
                                 this.props.authState.userData.languages.map((lang) => {
                                    return <Option key={lang.value} value={lang.value}>{lang.label}</Option>
                                 })
                              }
                           </Select>
                        </Space>
                     </div>
                  </div>
               </div>
            </div>
            <div className="card-body">
               <Row>
                  <Col span={24}>
                     {pageFilters}
                  </Col>
               </Row>
               <Row>
                  <Col span={24}>
                     <div className="align-items-center row" style={{ marginTop: 15, marginBottom: 5 }}>
                        <div className="col-sm-12">
                           <div className="d-none d-md-block">
                              <Space>
                                 <Button
                                    onClick={() => this.selectAllHandler()}
                                 >{this.state.selectedAll ? 'Deseleziona' : 'Seleziona tutto'}
                                 </Button>
                                 <span>
                                    {hasSelected ? singleSelected ? `${selectedRowKeys.length} elementi selezionati` : `${selectedRowKeys.length} elemento selezionato` : ''}
                                 </span>
                                 <DropdownMenu
                                    type='primary'
                                    title='Azioni multiple'
                                    action={(event) => this.multipleActionHandler(event)}
                                    values={this.state.multipleActionList}
                                    disabled={!hasSelected}
                                    loading={this.state.multipleActionLoader}
                                 />
                                 {
                                    this.state.marketplaceDropdowns.map((dropdown, i) => {
                                       return (
                                          <DropdownMenu
                                             key={i}
                                             type='primary'
                                             title={dropdown.title}
                                             action={(event) => this.multipleActionHandler(event)}
                                             values={dropdown.actionList}
                                             disabled={!hasSelected}
                                             loading={this.state.multipleActionLoader}
                                          />
                                       )
                                    })
                                 }
                              </Space>
                           </div>
                        </div>
                     </div>
                     {this.state.pageLoader ? <Loader /> : (
                        <Table
                           {...tableLayout}
                           rowSelection={rowSelection}
                           columns={table_columns}
                           dataSource={this.state.itemList}
                           scroll={{ x: 'max-content' }}
                           rowKey={record => (record.id)}
                        />
                     )}
                  </Col>
               </Row>
            </div>
            <div className="card-footer">
               <Button
                  size='large'
                  onClick={() => { this.props.history.goBack() }}
               >
                  <i className="mdi mdi-arrow-left-drop-circle-outline mr-1"></i>
                  {languageManager.getMessage(this.props.commonState, 'common.back')}
               </Button>
            </div>
         </div>
      );

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>
      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState,
   };
};

export default connect(mapStateToProps)(ProductsList);