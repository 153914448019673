import WarehouseMovementsApi from '../../../../../api/WarehouseMovementsApi';
import _ from 'lodash';

export function createOrUpdate(itemData) {
   console.log(itemData);
   return WarehouseMovementsApi.createOrUpdate(itemData)
      .then(response => {
         if (response.data.success === true) {
            console.log(response);
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function getList() {
   return WarehouseMovementsApi.getList()
      .then(response => {
         console.log(response);
         // if (response.success === true) {
         if (response.data.success === true) {
            response.data.data.forEach(el => {
               el.key = el.id;
            });
            console.log(response);
            // return response;
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

export function getListExtended(page, pageSize, filters) {
   return WarehouseMovementsApi.getListExtended(page, pageSize, filters)
      .then(response => {
         console.log(response);
         // if (response.success === true) {
         if (response.data.success === true) {
            response.data.data.data.forEach(el => {
               el.key = el.id;
            });
            console.log(response);
            // return response;
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

export function getItem(itemId) {
   return WarehouseMovementsApi.getItem(itemId)
      .then(response => {
         console.log(response);
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         throw error;
      });
}
export function getItemByCode(itemCode, codeType) {
   return WarehouseMovementsApi.getItemByCode(itemCode, codeType)
      .then(response => {
         console.log(response);
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function getComplements() {
   return WarehouseMovementsApi.getComplements()
      .then(response => {
         console.log(response);
         const responseData = {}
         _.forEach(response, (el, i) => {
            if (el.data.success !== true) {
               const error = el.data.error ? el.data.error : 'Server error'
               throw new Error(error)
            }
            responseData[i] = el.data.data;
         });
         return responseData;
      })
      .catch(error => {
         throw error;
      });
}

export function deleteItems(itemIds) {
   return WarehouseMovementsApi.deleteItems(itemIds)
      .then(response => {
         console.log(response);
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function getProductStocksListExtended(page, pageSize, filters) {
   return WarehouseMovementsApi.getProductStocksListExtended(page, pageSize, filters)
      .then(response => {
         console.log(response);
         // if (response.success === true) {
         if (response.data.success === true) {
            response.data.data.data.forEach(el => {
               el.key = el.id;
            });
            console.log(response);
            // return response;
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

export function importCSV(formData) {
   return WarehouseMovementsApi.importCSV(formData)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function exportCsv(filename, itemIds) {
   return WarehouseMovementsApi.exportCsv(filename, itemIds)
      .then(response => {
         console.log(response);
         let blob = new Blob([response.data], { type: 'application/*' })
         let link = document.createElement('a')
         link.href = window.URL.createObjectURL(blob)
         link.download = filename;
         link.click();
      })
      .catch(error => {
         throw error;
      });
}
export function printList(filename, itemIds) {
   return WarehouseMovementsApi.printList(filename, itemIds)
      .then(response => {
         console.log(response);
         let blob = new Blob([response.data], { type: 'application/*' })
         let link = document.createElement('a')
         link.href = window.URL.createObjectURL(blob)
         link.download = filename;
         link.click();
      })
      .catch(error => {
         throw error;
      });
}
export function exportProductStocksList(filename, itemIds) {
   return WarehouseMovementsApi.exportProductStocksList(filename, itemIds)
      .then(response => {
         console.log(response);
         let blob = new Blob([response.data], { type: 'application/*' })
         let link = document.createElement('a')
         link.href = window.URL.createObjectURL(blob)
         link.download = filename;
         link.click();
      })
      .catch(error => {
         throw error;
      });
}
export function printProductStocksList(filename, itemIds) {
   return WarehouseMovementsApi.printProductStocksList(filename, itemIds)
      .then(response => {
         console.log(response);
         let blob = new Blob([response.data], { type: 'application/*' })
         let link = document.createElement('a')
         link.href = window.URL.createObjectURL(blob)
         link.download = filename;
         link.click();
      })
      .catch(error => {
         throw error;
      });
}
export function printListBeforeDate(filename, end_date = null) {
   return WarehouseMovementsApi.printListBeforeDate(filename, end_date)
      .then(response => {
         console.log(response);
         let blob = new Blob([response.data], { type: 'application/*' })
         let link = document.createElement('a')
         link.href = window.URL.createObjectURL(blob)
         link.download = filename;
         link.click();
      })
      .catch(error => {
         throw error;
      });
}