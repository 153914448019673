import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import languageManager from '../../../../services/languageManager'

import { Form, Input, InputNumber, Select, Switch, Divider } from 'antd'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'

const ShippingMethodForm = (props) => {
    const [layout, setLayout] = useState(props.layout)
    // const [customerId, setCustomerId] = useState(props.customerId)
    const [values, setValues] = useState(props.values)

    const [form] = Form.useForm()
    const { Option } = Select

    useEffect(() => {
        console.log(props)
        // setCustomerId(props.customerId)
        setValues(props.values)
        if (_.isEmpty(props.values)) {
            form.resetFields()
        } else {
            form.setFieldsValue(props.values)
        }
    }, [props.values])

    const submitForm = (values) => {
        // values.customer_id = customerId
        props.submitForm(values)
    }

    return (
        <Form
            form={form}
            {...layout}
            id="ShippingMethodForm"
            onFinish={submitForm}
        >
            <Form.Item
                label="Nome"
                name="name"
                rules={[{ required: true, message: 'Inserisci un nome identificativo per il metodo di spedizione' }]}
            >
                <Input />
            </Form.Item>
            <Divider />
            <Form.Item
                label="URL"
                name="url"
            >
                <Input type='url' />
            </Form.Item>
            <Form.Item
                label="URL di tracking"
                name="tracking_url"
            >
                <Input type='url' />
            </Form.Item>
            <Form.Item
                label="Invia email automaticamente"
                name="send_email"
                valuePropName="checked"
            >
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                />
            </Form.Item>
            <Form.Item
                label="Email"
                name="email"
            >
                <Input type='email' />
            </Form.Item>
            <Form.Item name="id" noStyle><Input type="hidden" /></Form.Item>
        </Form>
    )
}

const mapStateToProps = state => {
    return {
        commonState: state.commonState,
    }
}

export default connect(mapStateToProps)(ShippingMethodForm)