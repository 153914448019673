import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import Moment from 'moment'

import { WarehouseMovementTypeEnum } from '../../../shared/enums.ts'

import { Table, Button, Modal, Space, notification, List, Row, Col, Collapse } from 'antd'
import { InfoCircleOutlined, DeleteOutlined, VerticalAlignTopOutlined, VerticalAlignBottomOutlined, EditOutlined, ExclamationCircleOutlined, CheckOutlined, PlusOutlined } from '@ant-design/icons'

import languageManager from '../../../services/languageManager'
import { startLoading, endLoading } from '../../../shared/utility'

import * as storeActions from './store/actions/warehouse_movements'

import ModalChargeMovement from './widget/ModalChargeMovement'
import ModalDischargeMovement from './widget/ModalDischargeMovement'
import ModalImportMovements from './widget/ModalImportMovements'

import Loader from '../../common/PageElements/Loader'
import TableFilters from '../../common/components/TableFilters'
import DropdownButton from '../../common/components/DropdownButton'
import DropdownMenu from '../../common/components/DropdownMenu'

class WarehouseMovementList extends Component {
   state = {
      localPath: this.props.history.location.pathname,
      pageLoader: true,
      tableLoader: false,
      loadersLines: [],
      multipleActionLoader: false,
      orderStatusActionLoader: false,

      itemList: [],
      fullListIds: [],
      componentLanguage: this.props.authState.defaultLanguage,

      current_page: this.props.location.state ? this.props.location.state.previousState.current_page : 1,
      page_size: this.props.location.state ? this.props.location.state.previousState.page_size : 50,
      total_result: 0,

      selectedRowKeys: [],
      selectedAll: false,
      multipleActionList: [
         {
            action: 'export',
            name: 'Esporta selezionati in CSV'
         },
         {
            action: 'printList',
            name: 'Stampa selezionati in PDF'
         },
         // {
         //    action: OrderStatusActionEnum.MULTIPLE_ACCEPT.toString(),
         //    name: 'Accetta ordini',
         //    needconfirmation: 'yes'
         // },
         // {
         //    action: OrderStatusActionEnum.MULTIPLE_PROCESS.toString(),
         //    name: 'Metti in preparazione',
         //    needconfirmation: 'yes'
         // },
         // {
         //    action: OrderStatusActionEnum.MULTIPLE_REFUSE.toString(),
         //    name: 'Rifiuta ordini',
         //    needconfirmation: 'yes'
         // }
      ],
      selectedWaitingAcceptance: true,
      action: "",

      showTableFilters: false,
      filters: this.props.location.state ? this.props.location.state.previousState.filters : [],
      filtersValues: this.props.location.state ? this.props.location.state.previousState.filtersValues : {},
      appliedFilters: this.props.location.state ? this.props.location.state.previousState.appliedFilters : [],

      activeMarketplaces: [],
      batchList: [],
      customerList: [],
      productCascaderList: [],
      supplierList: [],
      warehouseList: [],
   };

   componentDidMount() {
      // console.log(this.props.location.state, this.state);
      Promise.all([
         this.getMovementsList(this.state.current_page, this.state.page_size, this.state.filtersValues),
         this.getComplements()
      ]).then(() => {
         this.setState({
            pageLoader: false
         });
      });
   }

   getMovementsList = (page, pageSize, filters) => {
      // asyncronous calls
      this.setState({ tableLoader: true })
      return storeActions.getListExtended(page, pageSize, filters)
         .then(response => {
            console.log(response);
            try {
               this.setState({
                  itemList: response.data.data,
                  fullListIds: response.full_list_ids,
                  current_page: page,
                  page_size: pageSize,
                  total_result: response.data.total,
                  filters: response.list_filters,
                  appliedFilters: response.applied_filters,
                  tableLoader: false
               });
            } catch (e) {
               this.setState({ tableLoader: false })
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            this.setState({ tableLoader: false })
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   getComplements = () => {
      return storeActions.getComplements()
         .then(response => {
            console.log(response);
            try {
               this.setState({
                  response: response,
                  warehouseList: response.warehouseList,
                  customerList: response.customerList,
                  supplierList: response.supplierList,
                  productCascaderList: response.productCascaderList,
                  batchList: response.batchList,
               });

            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e);
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   listPaginationChangeHandler = (page, pageSize) => {
      console.log(page, pageSize, this.state.filtersValues);
      this.getMovementsList(page, pageSize, this.state.filtersValues);
   }

   handleTableChange = (pagination, filters, sorter) => {
      console.log(pagination, filters, sorter)
      // this.fetch({
      //   sortField: sorter.field,
      //   sortOrder: sorter.order,
      //   pagination,
      //   ...filters,
      // });
   };

   onSelectChange = (selectedRowKeys, selection) => {
      this.setState({ selectedRowKeys: selectedRowKeys })
   };

   submitFilters = (values) => {
      // console.log("Selected filters", values)
      Object.keys(values).forEach(function (key) {
         if (Moment.isMoment(values[key])) {
            values[key] = Moment(values[key]).format('YYYY-MM-DD');
         }
      });
      this.setState({ filtersValues: values })
      this.getMovementsList(1, this.state.page_size, values);
   }
   resetFilters = () => {
      console.log("Reset filters")
      this.getMovementsList(1, this.state.page_size, []);
   }

   setActionHandle = (value) => {
      this.setState({
         action: value
      })
   }

   exportToCSVHandler = () => {
      // console.log(this.state.selectedRowKeys);
      this.setState({ multipleActionLoader: true });
      let now = Moment().format('YYYY.MM.DD-HH.mm.ss');
      let filename = now + '_MOVEMENTS.csv';
      storeActions.exportCsv(filename, this.state.selectedRowKeys)
         .then(response => {
            this.setState({ multipleActionLoader: false });
            notification.success({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.confirm.exportOk'),
            })
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            this.setState({ multipleActionLoader: false });
            if (this.props.commonState.debug) console.log(e);
         });
   }
   printListHandler = () => {
      // console.log(this.state.selectedRowKeys);
      this.setState({ multipleActionLoader: true });
      let now = Moment().format('YYYY.MM.DD-HH.mm.ss');
      let filename = now + '_MOVEMENTS.pdf';
      storeActions.printList(filename, this.state.selectedRowKeys)
         .then(response => {
            this.setState({ multipleActionLoader: false });
            notification.success({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.confirm.exportOk'),
            })
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            this.setState({ multipleActionLoader: false });
            if (this.props.commonState.debug) console.log(e);
         });
   }




   selectAllHandler = () => {
      if (this.state.selectedAll) {
         this.setState({
            selectedAll: false,
            selectedRowKeys: [],
         });
      }
      else {
         this.setState({
            selectedAll: true,
            selectedRowKeys: this.state.fullListIds,
         });
      }
   }
   multipleActionHandler = (value) => {
      const props = value.item.props
      switch (props.action) {
         // case 'delete':
         //    this.deleteList();
         //    break;
         case 'export':
            this.exportToCSVHandler();
            break;
         case 'printList':
            this.printListHandler();
            break;
         default:
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
            })
            break;

      }
   }

   deleteItems = (values) => {
      let itemIds = []
      if (Array.isArray(values)) {
         itemIds = values
      } else {
         itemIds.push(values)
      }
      this.setState({ loadersLines: startLoading(this.state.loadersLines, itemIds) });
      storeActions.deleteItems(itemIds)
         .then(response => {
            if (this.props.commonState.debug) console.log(response)
            this.setState({ loadersLines: endLoading(this.state.loadersLines, itemIds) })
            try {
               this.getMovementsList(this.state.current_page, this.state.page_size, this.state.filtersValues)
               notification.success({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.deleteOk'),
               })
            } catch (e) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  duration: 0
               })
            }
         }).catch((e) => {
            this.setState({ loadersLines: endLoading(this.state.loadersLines, itemIds) })
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.human ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.human}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
            if (this.props.commonState.debug) console.log(e)
         })
   }

   showModalChargeMovement = (item) => {
      if (item) {
         this.setState({ modalChargeMovementItem: item, showModalChargeMovement: true })
      } else {
         this.setState({ modalChargeMovementItem: null, showModalChargeMovement: true })
      }
   }
   hideModalChargeMovement = () => {
      this.setState({ modalChargeMovementItem: null, showModalChargeMovement: false })
   }
   handleCreateChargeMovement = () => {
      console.log('Movimentazione creata correttamente')
      Promise.all([
         this.getMovementsList(this.state.current_page, this.state.page_size, this.state.filtersValues),
      ]).then(() => {
         this.setState({ tableLoader: false })
      });
   }

   showModalDischargeMovement = (item) => {
      if (item) {
         this.setState({ modalDischargeMovementItem: item, showModalDischargeMovement: true })
      } else {
         this.setState({ modalDischargeMovementItem: null, showModalDischargeMovement: true })
      }
   }
   hideModalDischargeMovement = () => {
      this.setState({ modalDischargeMovementItem: null, showModalDischargeMovement: false })
   }
   handleCreateDischargeMovement = () => {
      console.log('Movimentazione creata correttamente')
      Promise.all([
         this.getMovementsList(this.state.current_page, this.state.page_size, this.state.filtersValues),
      ]).then(() => {
         this.setState({ tableLoader: false })
      });
   }

   showModalImportMovements = () => {
      this.setState({ showModalImportMovements: true })
   }
   hideModalImportMovements = () => {
      this.setState({ showModalImportMovements: false })
   }
   handleImportMovements = () => {
      console.log('Inventario importato correttamente')
      Promise.all([
         this.getMovementsList(this.state.current_page, this.state.page_size, this.state.filtersValues),
      ]).then(() => {
         this.setState({ tableLoader: false })
      });
   }

   render() {
      if (this.state.pageLoader) return <Loader />;
      const { Panel } = Collapse;

      const table_columns = [
         // { title: 'ID', width: 100, dataIndex: 'id', key: 'id', fixed: 'left' },
         // {
         //    title: 'ID Marketplace',
         //    width: 150,
         //    dataIndex: 'marketplace_id',
         //    key: 'marketplace_id',
         //    fixed: 'left',
         //    render: (text, record) => (
         //       <a onClick={() => this.viewItemHandler(record.id)} >{text}</a>
         //    ),
         // },
         {
            title: 'Data',
            width: 140,
            key: 'movement_date',
            fixed: 'left',
            render: (record) => (
               Moment(record.movement_date).format('L LTS')
            ),
            sorter: (a, b) => a.movement_date.localeCompare(b.movement_date),
         },
         {
            title: 'SKU',
            width: 120,
            key: 'sku',
            fixed: 'left',
            render: (text, record) => (
               record.product_sku
            ),
            sorter: (a, b) => a.product_sku.localeCompare(b.product_sku),
         },
         {
            title: 'Prodotto',
            dataIndex: 'product_name',
            key: 'product_name',
            fixed: 'left',
            // render: (text, record) => (
            //    <Link to={'/purchase_orders/details/' + record.id}>{text}</Link>
            // ),
            sorter: (a, b) => a.product_name.localeCompare(b.product_name),
         },
         {
            title: 'Lotto',
            width: 150,
            dataIndex: 'batch',
            key: 'batch',
            // render: (text, record) => (
            //    <Link to={'/purchase_orders/details/' + record.id}>{text}</Link>
            // ),
            sorter: (a, b) => a.product_name.localeCompare(b.product_name),
         },
         {
            title: 'Scadenza',
            width: 100,
            key: 'expiration_date',
            render: (text, record) => (
               record.expiration_date ? Moment(record.expiration_date).format('L') : null
            )
         },
         {
            title: 'Cliente/Fornitore',
            width: 150,
            key: 'customer_supplier_name',
            render: (text, record) => (
               record.customer_name || record.supplier_name || null
            ),
            sorter: (a, b) => {
               a = a.customer_name || a.supplier_name || ''
               b = b.customer_name || b.supplier_name || ''
               return a.localeCompare(b)
            },
         },
         {
            title: 'Carico',
            width: 75,
            className: 'movement-charge-column',
            dataIndex: 'charged',
            key: 'charged',
            render: (text, record) => (
               record.charged
            ),
            // sorter: (a, b) => a-b,
         },
         {
            title: 'Scarico',
            width: 75,
            className: 'movement-discharge-column',
            dataIndex: 'discharged',
            key: 'discharged',
            render: (text, record) => (
               record.discharged
            ),
            // sorter: (a, b) => a-b,
         },
         {
            title: 'Impegnato',
            width: 75,
            dataIndex: 'reserved',
            key: 'reserved',
            render: (text, record) => (
               record.reserved
            ),
            // sorter: (a, b) => a-b,
         },
         {
            title: 'In arrivo',
            width: 75,
            dataIndex: 'ordered',
            key: 'ordered',
            render: (text, record) => (
               record.ordered
            ),
            // sorter: (a, b) => a-b,
         },
         {
            title: 'Arrivo previsto',
            width: 120,
            key: 'deliver_request_date',
            render: (record) => (
               record.deliver_request_date ? Moment(record.deliver_request_date).format('L') : null
            ),
            sorter: (a, b) => {
               a = a.deliver_request_date || ''
               b = b.deliver_request_date || ''
               return a.localeCompare(b)
            },
         },
         // {
         //    title: 'Cliente/Fornitore',
         //    key: 'customer',
         //    render: (record) => (
         //       record.supplier_name
         //    ),
         //    sorter: (a, b) => {
         //       a = a.supplier_name || ''
         //       b = b.supplier_name || ''
         //       return a.localeCompare(b)
         //    },
         // },
         {
            title: 'Magazzino',
            width: 150,
            key: 'warehouse_name',
            render: (text, record) => (
               record.warehouse_from_name || record.warehouse_to_name || null
            ),
            sorter: (a, b) => {
               a = a.warehouse_from_name || a.warehouse_to_name || ''
               b = b.warehouse_from_name || b.warehouse_to_name || ''
               return a.localeCompare(b)
            },
         },
         {
            title: 'Causale',
            width: 100,
            ellipsis: true,
            key: 'movement_reason',
            render: (record) => (
               record.purchase_order_id ? (
                  <Link to={'/purchase_orders/details/' + record.purchase_order_id}>{record.movement_reason}</Link>
               ) : record.order_id ? (
                  <Link to={'/orders/details/' + record.order_id}>{record.movement_reason}</Link>
               ) : record.movement_reason
            ),
         },
         {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            align: 'right',
            width: 50,
            render: (text, record) => (
               <Space size='small'>
                  {/* {
                     record.movement_type === WarehouseMovementTypeEnum.CHARGE ? (
                        <Button type="primary" size="small"
                           icon={<EditOutlined />}
                           title={languageManager.getMessage(this.props.commonState, 'common.edit')}
                           onClick={() => this.showModalChargeMovement(record)}
                           disabled={this.state.loadersLines[record.id]}
                        />
                     ) : null
                  } */}
                  <Button type="danger" size='small'
                     loading={this.state.loadersLines[record.id]}
                     icon={<DeleteOutlined />}
                     onClick={() =>
                        Modal.confirm({
                           title: languageManager.getMessage(this.props.commonState, 'component.warehouse_movements.delete'),
                           icon: <ExclamationCircleOutlined />,
                           content: languageManager.getMessage(this.props.commonState, 'common.askOperationConfirm'),
                           okText: languageManager.getMessage(this.props.commonState, 'common.confirm'),
                           onOk: () => this.deleteItems([record.id])
                        })
                     }
                     disabled={!_.isNil(record.purchase_order_id) || !_.isNil(record.order_id)}
                  />
               </Space>
            ),
         },
      ];

      const { selectedRowKeys } = this.state;
      const tableLayout = {
         bordered: true,
         className: "table-striped-rows",
         size: "small",
         scroll: { x: 'max-content' },
         loading: this.state.tableLoader,
         onChange: this.handleTableChange,
         pagination: {
            pageSize: this.state.page_size,
            onChange: this.listPaginationChangeHandler,
            total: this.state.total_result
         },
         rowSelection: {
            selectedRowKeys,
            onChange: this.onSelectChange,
            preserveSelectedRowKeys: true,
         }
      }


      const pageFilters = (
         <Collapse defaultActiveKey={['filters']}>
            <Panel header="Filtri" key="filters">
               <TableFilters submitFilters={this.submitFilters} resetFilters={this.resetFilters} filters={this.state.filters} selectValues={this.state.filtersValues} />
            </Panel>
         </Collapse>
      );

      const hasSelected = selectedRowKeys.length > 0;
      const singleSelected = selectedRowKeys.length > 1;
      const pageContent = (
         <div className="card">
            <div className="card-header">
               <div className="align-items-center row">
                  <div className="col-sm-6">{languageManager.getMessage(this.props.commonState, 'component.warehouse_movements.title')}</div>
                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">
                        <Space size='small'>
                           <Button type="primary"
                              icon={<VerticalAlignBottomOutlined />}
                              onClick={() => this.showModalImportMovements()}>Importa inventario</Button>
                           <Button type="primary"
                              icon={<VerticalAlignBottomOutlined />}
                              onClick={() => this.showModalChargeMovement()}>Carico manuale</Button>
                           <Button type="primary"
                              icon={<VerticalAlignTopOutlined />}
                              onClick={() => this.showModalDischargeMovement()}>Scarico manuale</Button>
                           {/* <Button
                              type='primary'
                              icon={<UploadOutlined />}
                              onClick={() => this.props.history.push('/orders/import_csv/')}
                           >
                              Importa CSV
                           </Button> */}
                        </Space>
                        <ModalChargeMovement
                           warehouseList={this.state.warehouseList}
                           productCascaderList={this.state.productCascaderList}
                           supplierList={this.state.supplierList}
                           batchList={this.state.batchList}
                           visible={this.state.showModalChargeMovement}
                           item={this.state.modalChargeMovementItem}
                           onClose={this.hideModalChargeMovement}
                           onSubmit={() => this.handleCreateChargeMovement()} />
                        <ModalDischargeMovement
                           productCascaderList={this.state.productCascaderList}
                           customerList={this.state.customerList}
                           batchList={this.state.batchList}
                           visible={this.state.showModalDischargeMovement}
                           item={this.state.modalDischargeMovementItem}
                           onClose={this.hideModalDischargeMovement}
                           onSubmit={() => this.handleCreateDischargeMovement()} />
                        <ModalImportMovements
                           warehouseList={this.state.warehouseList}
                           visible={this.state.showModalImportMovements}
                           onClose={this.hideModalImportMovements}
                           onSubmit={() => this.handleImportMovements()} />
                     </div>
                  </div>
               </div></div>
            <div className="card-body">
               <Row>
                  <Col span={24}>{pageFilters}</Col>
               </Row>
               <Row>
                  <Col span={24}>
                     <div className="align-items-center row" style={{ marginTop: 15, marginBottom: 5 }}>
                        <div className="col-sm-6">
                           {/* <Space direction='horizontal' size='small'>
                              <Button
                                 onClick={() => this.selectAllHandler()}
                              >{this.state.selectedAll ? 'Deseleziona' : 'Seleziona tutto'}
                              </Button>
                              <span>
                                 {hasSelected ? singleSelected ? `${selectedRowKeys.length} elementi selezionati` : `${selectedRowKeys.length} elemento selezionato` : ''}
                              </span>
                              <DropdownMenu
                                 type='primary'
                                 title='Azioni multiple'
                                 action={this.multipleActionHandler}
                                 values={this.state.multipleActionList}
                                 disabled={!hasSelected}
                                 loading={this.state.multipleActionLoader}
                              />
                           </Space> */}
                        </div>
                        <div className="col-sm-6">
                           <div className="float-right d-none d-md-block">
                              {/* <Button type="primary" loading={this.state.loader}>
                                    Mostra solo righe con differenze
                                 </Button> */}
                           </div>
                        </div>
                     </div>
                  </Col>
               </Row>

               <Row>
                  <Col span={24}>
                     <div className="align-items-center row" style={{ marginTop: 15, marginBottom: 5 }}>
                        <div className="col-sm-6">
                           <div className="d-none d-md-block">
                              <Space>
                                 <Button
                                    onClick={this.selectAllHandler}
                                 >{this.state.selectedAll ? 'Deseleziona' : 'Seleziona tutto'}
                                 </Button>
                                 <span>
                                    {hasSelected ? singleSelected ? `${selectedRowKeys.length} elementi selezionati` : `${selectedRowKeys.length} elemento selezionato` : ''}
                                 </span>
                                 <DropdownMenu
                                    type='primary'
                                    title='Azioni multiple'
                                    action={this.multipleActionHandler}
                                    values={this.state.multipleActionList}
                                    disabled={!hasSelected}
                                    loading={this.state.multipleActionLoader}
                                 />
                              </Space>
                           </div>
                        </div>
                        <div className="col-sm-6">
                           <div className="float-right d-none d-md-block">
                              {/* <Button type="primary" loading={this.state.loader}>
                        Mostra solo righe con differenze
                     </Button> */}
                           </div>
                        </div>
                     </div>
                  </Col>
               </Row>
               <Row>
                  <Col span={24}>
                     <Table {...tableLayout}
                        columns={table_columns}
                        dataSource={this.state.itemList}
                        // rowSelection={rowSelection}
                        rowKey={(record) => (record.id)}
                     />
                  </Col>
               </Row>
            </div>
         </div >
      );

      // const helpButton = {
      //    title: "Aiuto"
      // }

      // const buttons = [];
      // buttons.push(helpButton);

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>
      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState,
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(WarehouseMovementList);