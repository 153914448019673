import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Form, Input, InputNumber, Select, TreeSelect, Switch, Button, Space, notification, List } from 'antd'
import { InfoCircleOutlined, CheckOutlined, CloseOutlined, PlusSquareOutlined } from '@ant-design/icons'

import languageManager from '../../../services/languageManager'
import * as storeActions from './store/actions/groups'

import Loader from '../../common/PageElements/Loader'

class GroupsEdit extends Component {

   state = {
      action: undefined,
      itemId: undefined,
      saveAndClose: false,
      pageLoader: true,
      formLoader: false,
      formValues: {},
      componentLanguage: this.props.authState.defaultLanguage
   };

   // static getDerivedStateFromProps(props, state) {
   //    if (props.action === 'edit') {
   //       const formValues = {
   //          id: props.itemData.id,
   //          name: props.itemData.name.it,
   //          percentage: props.itemData.percentage,
   //          language: 'it'
   //       }
   //       return updateObject(state, { formValues: formValues });
   //       // state.initial_values.name = props.itemData.t_name;
   //       // state.initial_values.percentage = props.itemData.percentage;
   //    }

   //    return state;
   // }

   setForm = (response, lang = this.state.componentLanguage) => {
      const values = {
         name: response.name,
         discount_percentage: response.discount_percentage,
         id: response.id,
         language: lang
      }
      return values;
   }

   getItem = (id) => {
      return storeActions.getItem(id)
         .then(response => {
            console.log(response);
            try {
               const updatedValues = this.setForm(response.data);
               // console.log(updatedValues)
               this.setState({ response: response.data, formValues: updatedValues });
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }
         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   componentDidMount() {
      if (this.props.match.params.id) {
         const itemId = this.props.match.params.id
         Promise.all([
            this.getItem(itemId),
            // this.getComplements()
         ]).then(() => {
            this.setState({
               action: 'edit',
               itemId: itemId,
               pageLoader: false
            });
         });
      }
      else {
         const formValues = {
            language: this.state.componentLanguage,
         }
         Promise.all([
            // this.getComplements()
         ]).then(() => {
            this.setState({
               formValues: formValues,
               action: 'insert',
               pageLoader: false
            });
         });
      }
   }

   handleSaveAndClose = () => {
      this.setState({ saveAndClose: true });
      console.log(this.state);
   }

   // onParentChange = value => {
   //    console.log(value);
   //    this.setState({ value });
   // };

   onFinish = values => {
      this.setState({ formLoader: true });
      values.visible = true;
      storeActions.createOrUpdate(values)
         .then(response => {
            console.log(response);
            try {
               if (this.state.saveAndClose) {
                  this.props.history.goBack();
               }
               const updatedValues = this.setForm(response.data);
               this.setState({ response: response.data, itemId: response.data.id, action: 'edit', formValues: updatedValues, formLoader: false });

               notification.success({
                  message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
                  description: response.human ? (
                     <List
                        size="small"
                        split={false}
                        dataSource={response.human}
                        renderItem={item => <List.Item>{item}</List.Item>}
                     />
                  ) : languageManager.getMessage(this.props.commonState, this.state.action === 'edit' ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
                  duration: 0
               })
            } catch (e) {
               this.setState({ formLoader: false });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            this.setState({ formLoader: false });
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.error ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.error}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
         });
   };

   onFinishFailed = errorInfo => {
      notification.open({
         message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
         description: languageManager.getMessage(this.props.commonState, 'notification.error.formInvalid'),
         icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
      })
   };

   onVisibleSwitchChange = (checked) => {
      let newFormValues = { ...this.state.formValues }
      newFormValues.is_visible = checked;
      this.setState({ formValues: newFormValues });
   }

   setDefaultLanguageHandler = (value) => {
      console.log(value);
      const updatedValues = this.setForm(this.state.response, value);
      this.setState({ formValues: updatedValues, componentLanguage: value })
   }

   render() {
      if (!this.props.authState.userData.languages) return '';
      const layout = {
         labelCol: { span: 8 },
         wrapperCol: { span: 14 },
      };
      const tailLayout = {
         wrapperCol: { offset: 8, span: 16 },
      };
      const { TextArea } = Input;
      const { Option } = Select;

      let form = (
         <Form
            {...layout}
            id="editGroup"
            name="editGroup"
            initialValues={this.state.formValues}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
         >
            <Form.Item
               label="Nome"
               name="name"
               rules={[{ required: true }]}
            >
               <Input />
            </Form.Item>
            <Form.Item
               label="Percentuale di sconto"
               name="discount_percentage"
            >
               <InputNumber
                  defaultValue='0'
                  min='0'
                  max='99'
                  step='0.1'
                  formatter={value => `${value}%`}
                  parser={value => value.replace('%', '')} />
            </Form.Item>
            <Form.Item name="id" noStyle><Input type="hidden" /></Form.Item>
            <Form.Item label="language" name="language" noStyle><Input type="hidden" /></Form.Item>
         </Form>
      );

      const pageContent = (
         <div className="card">
            <div className="card-header">
               <div className="align-items-center row">
                  <div className="col-sm-6">
                     {languageManager.getMessage(this.props.commonState, 'component.groups.title')}
                  </div>
                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">
                        <Space>
                           <Select
                              showSearch
                              className="width-70px-I"
                              placeholder="Seleziona la lingua"
                              defaultValue={this.state.componentLanguage.toUpperCase()}
                              optionFilterProp="children"
                              onChange={this.setDefaultLanguageHandler}
                           >
                              {
                                 this.props.authState.userData.languages.map((lang) => {
                                    return <Option key={lang.value} value={lang.value}>{lang.label}</Option>
                                 })
                              }
                           </Select>
                        </Space>
                     </div>
                  </div>
               </div>
            </div>
            <div className="card-body">
               {this.state.pageLoader ? <Loader /> : form}
            </div>
            <div className="card-footer">
               <div className="align-items-center row">
                  <div className="col-sm-6">
                     <Space>
                        <Button
                           size='large'
                           onClick={() => { this.props.history.goBack() }}
                        >
                           <i className="mdi mdi-arrow-left-drop-circle-outline mr-1"></i>
                           {languageManager.getMessage(this.props.commonState, 'common.back')}
                        </Button>
                     </Space>
                  </div>

                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">

                        <Space>
                           <Button form="editGroup" htmlType="button" disabled={this.state.formLoader}>Annulla</Button>
                           <Button form="editGroup" type="primary" htmlType="submit" loading={this.state.formLoader}>Salva</Button>
                           <Button form="editGroup" type="primary" htmlType="submit" onClick={this.handleSaveAndClose} loading={this.state.formLoader}>Salva e chiudi</Button>
                        </Space>

                     </div>
                  </div>
               </div>
            </div>
         </div>
      );

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>

      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(GroupsEdit);