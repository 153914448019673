import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import Moment from 'moment'

import { Divider, Modal, Table, Form, Switch, Input, InputNumber, Select, DatePicker, Button, Space, notification, Tabs, Typography, Row, Col, Descriptions } from 'antd'
import { InfoCircleOutlined, CheckOutlined, CloseOutlined, DoubleLeftOutlined, PlusOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'

import languageManager from '../../../services/languageManager'
import * as storeActions from './store/actions/purchase_orders'
import * as suppliersActions from '../../personal_data/suppliers/store/actions/suppliers'

import Loader from '../../common/PageElements/Loader'
import { startLoading, endLoading, download } from '../../../shared/utility'

// import SelectCategory from '../categories/widget/SelectCategory'
// import CreateCategoryDrawer from '../categories/widget/CreateCategoryDrawer'

import ModalAddOrderProduct from './widget/ModalAddProduct'
// import CreateStockDrawer from './widget/CreateStockDrawer'
// import ProductMarketplaceCard from './widget/ProductMarketplaceCard'

// import DragSortingUpload from './store/utilities/dragSorting.js'
// import SpecificsSelect from './store/utilities/specificsSelect'
// import TabPrices from './store/utilities/tabPrices'


class PurchaseOrderEdit extends Component {

   state = {
      itemId: null,
      saveAndClose: false,
      pageLoader: true,
      formLoader: false,
      loadersLines: [],
      loaderInternalCode: false,
      errorInternalCode: null,
      loaderSupplierCode: false,
      errorSupplierCode: null,
      formValues: {},

      loaderOrderSave: false,
      componentLanguage: this.props.authState.defaultLanguage,

      supplierList: [],
      shippingMethodList: [],
      paymentMethodList: [],
      bankList: [],
      taxRateList: [],
      productCascaderList: [],
      batchList: [],

      orderLines: [],
      orderTotalQuantity: 0.00,
      orderTotalWeight: 0.00,
      orderTotalVolume: 0.00,
      orderTotalProducts: 0.00,
      orderTotalTax: 0.00,
      orderTotal: 0.00,
      shippingTotal: 0.00,

      showModalChooseShippingAddress: false,
      showModalChooseInvoicingAddress: false,
      showModalAddProduct: false,
      showModalAddOrderLine: false,
      modalEditLineItem: null,

   };
   formRef = React.createRef();

   componentDidMount() {
      if (this.props.match.params.id) {
         const itemId = this.props.match.params.id
         Promise.all([
            this.getItem(itemId),
            this.getComplements()
         ]).then(() => {
            this.setState({
               itemId: itemId,
               pageLoader: false
            })
         })
      }
      else {
         const formValues = {
            language: this.state.componentLanguage,
            create_order_date: Moment(),
         }
         Promise.all([
            this.getComplements()
         ]).then(() => {
            this.setState({
               formValues: formValues,
               action: 'insert',
               pageLoader: false
            });
         });
      }
   }

   getItem = (id) => {
      return storeActions.getItem(id)
         .then(response => {
            console.log(response);
            try {
               const updatedValues = this.setForm(response.data);
               // console.log(updatedValues)
               this.setState({ response: response.data, orderLines: response.data.order_lines, formValues: updatedValues })
               this.getTotalsFromLines(response.data.order_lines);
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e);
            }
         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   setForm = (responseValues, lang = this.state.componentLanguage) => {
      let values = { ...responseValues }
      if (!_.isNil(values.create_order_date)) {
         const newDate = Moment(values.create_order_date)
         values.create_order_date = newDate
      }
      if (!_.isNil(values.deliver_request_date)) {
         const newDate = Moment(values.deliver_request_date)
         values.deliver_request_date = newDate
      }
      if (!_.isNil(values.delivered_order_date)) {
         const newDate = Moment(values.delivered_order_date)
         values.delivered_order_date = newDate
      }
      //    internal_order_number: response.internal_order_number,
      //    supplier_order_number: response.supplier_order_number,
      //    upc: response.upc,
      //    gtin: response.gtin,
      //    name: response.translations[0].title,
      //    brand_id: response.brand_id,
      //    category_ids: response.category_ids,
      //    categories: response.categories,
      //    main_category_id: response.main_category_id,
      //    // intro: response.intro[lang],
      //    description: response.translations[0].description,
      //    file_list: response.file_list,
      //    gallery: response.gallery,
      //    manage_quantity: response.manage_quantity ? true : false,
      //    always_available: response.always_available ? true : false,
      //    use_stocks: response.use_stocks ? true : false,
      //    stocks: response.stocks,
      //    quantity: response.actual_quantity,
      //    price_buy: response.translations[0].price_buy,
      //    price_retail: response.translations[0].price_retail,
      //    tax_rate_id: response.translations[0].tax_rate_id,
      //    weight: response.weight,
      //    length: response.length,
      //    height: response.height,
      //    width: response.width,
      //    tags: tag_ids,
      //    specifics: response.specific_values,
      //    is_parent: response.is_parent,
      //    variations: response.variations,
      //    marketplace_prices: response.marketplace_prices,
      //    marketplace_published: response.marketplace_published,
      //    marketplace_sync: response.marketplace_sync,
      //    // is_visible: response.visible[lang],
      //    language: lang,
      //    image: response.image
      // }

      // if(!_.isNil(responseValues.supplier_id)) {
      //    this.handleSupplierChange(responseValues.supplier_id)
      // }
      values.purchase_order_status_name = responseValues.purchase_order_status.name.it

      values.supplier_id = !_.isNil(responseValues.supplier) ? responseValues.supplier.id : null
      values.supplier_email = !_.isNil(responseValues.supplier) ? responseValues.supplier.email : null
      values.supplier_phone = !_.isNil(responseValues.supplier) ? responseValues.supplier.phone : null
      values.supplier_fiscal_code = !_.isNil(responseValues.supplier) ? responseValues.supplier.fiscal_code : null
      values.supplier_vat = !_.isNil(responseValues.supplier) ? responseValues.supplier.vat : null
      values.supplier_sdi = !_.isNil(responseValues.supplier) ? responseValues.supplier.sdi : null
      values.supplier_pec = !_.isNil(responseValues.supplier) ? responseValues.supplier.pec : null
      values.supplier_note = !_.isNil(responseValues.supplier) ? responseValues.supplier.notes : null
      const actualSupplier = !_.isNil(responseValues.supplier) ? responseValues.supplier : null
      this.setState({
         actualSupplier: actualSupplier,
      })

      return values;
   }

   getComplements = () => {
      return storeActions.getComplements()
         .then(response => {
            console.log(response);
            try {
               this.setState({
                  response: response,
                  supplierList: response.supplierList,
                  shippingMethodList: response.shippingMethodList,
                  productCascaderList: response.productCascaderList,
                  productCascaderList: response.productCascaderList,
                  batchList: response.batchList,
               });

            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e);
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }
   handleSaveAndClose = () => {
      this.setState({ saveAndClose: true });
   }

   handleCodeChange = (value, codeType) => {
      console.log('handleCodeChange', value, codeType)
      switch (codeType) {
         case 'internal':
            this.setState({ loaderInternalCode: true })
            break
         case 'internal':
            this.setState({ loaderSupplierCode: true })
            break
         default: return false
      }
      if (!_.isUndefined(value)) {
         return storeActions.getItemByCode(value, codeType)
            .then(response => {
               console.log(response);
               if (response.data !== false) {
                  console.log('Codice già utilizzato')
                  switch (codeType) {
                     case 'internal':
                        this.setState({ errorInternalCode: true, loaderInternalCode: false })
                        break
                     case 'internal':
                        this.setState({ errorInternalCode: true, loaderSupplierCode: false })
                        break
                  }
               } else {
                  switch (codeType) {
                     case 'internal':
                        this.setState({ errorInternalCode: false, loaderInternalCode: false })
                        break
                     case 'internal':
                        this.setState({ errorSupplierCode: false, loaderSupplierCode: false })
                        break
                  }
               }
            }).catch((e) => {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e)
               switch (codeType) {
                  case 'internal':
                     this.setState({ loaderInternalCode: false })
                     break
                  case 'internal':
                     this.setState({ loaderSupplierCode: false })
                     break
               }
            })
      } else {
         switch (codeType) {
            case 'internal':
               this.setState({ errorInternalCode: null, loaderInternalCode: null })
               break
            case 'internal':
               this.setState({ errorSupplierCode: null, loaderSupplierCode: null })
               break
            default: return false
         }
      }
   }

   handleSupplierChange = (value) => {
      console.log('handleSupplierChange', value)
      this.setState({ loaderSupplier: true })
      if (_.isUndefined(value)) {
         this.formRef.current.setFieldsValue({
            supplier_id: null,
            supplier_type: null,
            supplier_email: null,
            supplier_phone: null,
            supplier_fiscal_code: null,
            supplier_vat: null,
            supplier_sdi: null,
            supplier_pec: null,
            supplier_note: null,
         })
         this.setState({
            actualSupplier: null,
            actualShippingAddress: null,
            actualBillingAddress: null,
            loaderSupplier: false
         })
      } else {
         return suppliersActions.getItem(value)
            .then(response => {
               console.log(response);
               const supplierData = response.data
               this.formRef.current.setFieldsValue({
                  supplier_id: supplierData.id,
                  supplier_email: supplierData.email,
                  supplier_phone: supplierData.phone,
                  supplier_fiscal_code: supplierData.fiscal_code,
                  supplier_vat: supplierData.vat,
                  supplier_sdi: supplierData.sdi,
                  supplier_pec: supplierData.pec,
                  supplier_note: supplierData.notes,
               })
               this.setState({
                  actualSupplier: supplierData,
                  loaderSupplier: false
               })
            }).catch((e) => {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e)
               this.setState({ loaderSupplier: false })
            })
      }
   }
   showModalChooseShippingAddress = (value) => {
      if (_.isUndefined(value)) {
         value = false
      }
      this.setState({ showModalChooseShippingAddress: value })
   }
   useShippingAddress = (address) => {
      this.formRef.current.setFieldsValue({
         shipping_address_1: address.address_1,
         shipping_address_2: address.address_2,
         shipping_city: address.city,
         shipping_postal_code: address.postal_code,
         shipping_state: address.state,
         shipping_country: address.country,
         shipping_email: address.email,
         shipping_phone: address.phone,
         shipping_note: address.note,
      })
   }

   getTotalsFromLines = (actualLines) => {
      console.log('Order Lines:', actualLines)
      let total_products = 0
      let total_tax = 0
      let total = 0
      let quantity = 0
      _.forEach(actualLines, (line, i) => {
         if (line.total_price) total_products += parseFloat(line.total_price)
         if (line.total_tax) total_tax += parseFloat(line.total_tax)
         if (line.total_price && line.total_tax) total += (parseFloat(line.total_price) + parseFloat(line.total_tax))
         quantity += parseFloat(line.quantity)
      });
      this.setState({
         orderTotalProducts: total_products,
         orderTotalTax: total_tax,
         orderTotal: total,
         orderTotalQuantity: quantity,
         orderLines: actualLines,
         loaderOrderLines: false
      });
   }
   showModalAddProduct = (line, index) => {
      if (!_.isNil(line)) {
         line.line_index = index
         this.setState({
            modalEditLineItem: line,
            showModalAddProduct: true,
         })
      } else {
         this.setState({ modalEditLineItem: null, showModalAddProduct: true })
      }
   }
   hideModalAddProduct = () => {
      this.setState({ modalEditLineIndex: null, modalEditLineItem: null, showModalAddProduct: false })
   }
   handleAddProduct = (line) => {
      console.log('handleAddProduct', line)
      this.setState({ loaderOrderLines: true })
      let actualLines = []
      if (!_.isEmpty(this.state.orderLines)) {
         actualLines = this.state.orderLines.map(a => { return { ...a } })
      }
      if (_.isNil(line.line_index)) {
         // line.line_index = actualLines.length
         line.key = Moment().unix()
         line.expiration_date = !_.isNil(line.batch_expiration) ? Moment(line.batch_expiration, 'DD/MM/YYYY').format('YYYY-MM-DD') : null
         actualLines.push(line)
      } else {
         const lineIndex = actualLines.findIndex((obj => obj.line_index == line.line_index))
         line.expiration_date = !_.isNil(line.batch_expiration) ? Moment(line.batch_expiration, 'DD/MM/YYYY').format('YYYY-MM-DD') : null
         actualLines[lineIndex] = line
      }
      this.getTotalsFromLines(actualLines)

      // let total_products = 0
      // let total_tax = 0
      // let total = 0
      // let quantity = 0
      // _.forEach(actualLines, (line, i) => {
      //    total_products += line.total_price
      //    total_tax = line.total_tax
      //    total += (line.total_price + line.total_tax)
      //    quantity += line.quantity
      // });
      // this.setState({
      //    orderLines: actualLines,
      //    orderTotalProducts: total_products,
      //    orderTotalTax: total_tax,
      //    orderTotal: total,
      //    orderTotalQuantity: quantity,
      //    loaderOrderLines: false
      // });
   }
   handleRemoveProduct = lineIndex => {
      console.log('handleRemoveProduct', lineIndex)
      const actualLines = this.state.orderLines.filter((obj, index) => index !== lineIndex)
      this.getTotalsFromLines(actualLines)
   }
   // handleRemoveProduct = lineIndex => {
   //    console.log('handleRemoveProduct', lineIndex)
   //    let actualLines = this.state.orderLines.map(a => { return { ...a } })
   //    const actualLineIndex = actualLines.findIndex(x => x.line_index == lineIndex)
   //    actualLines.splice(actualLineIndex, 1)
   //    this.setState({
   //       orderLines: actualLines,
   //       loaderOrderLines: false
   //    })
   //    this.getTotalsFromLines()
   // }

   onFinish = values => {
      if (!_.isNil(this.state.itemId)) {
         values.id = this.state.itemId
      }
      values.order_lines = this.state.orderLines
      values.total_quantity = this.state.orderTotalQuantity
      values.total_products = this.state.orderTotalProducts
      values.total_tax = this.state.orderTotalTax
      values.total = this.state.orderTotal
      // console.log(this.state.formValues.specifics);
      // console.log(this.state);
      // values.id = this.state.itemId
      // values.specifics = this.state.formValues.specifics
      // values.categories = this.state.formValues.category_ids
      // console.log(values);
      this.setState({ formLoader: true });
      storeActions.createOrUpdate(values)
         .then(response => {
            console.log(response);
            try {
               if (this.state.saveAndClose) {
                  this.props.history.goBack();
               }
               // const updatedValues = this.setForm(response.data);
               // this.setState({ response: response.data, itemId: response.data.id, action: 'edit', formValues: updatedValues, formLoader: false });
               this.setState({ itemId: response.data.id, orderLines: response.data.order_lines, formLoader: false })

               notification.success({
                  message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
                  description: languageManager.getMessage(this.props.commonState, this.state.itemId ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
               })
            } catch (e) {

            }

         }).catch((e) => {
            this.setState({ formLoader: false });
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
         })
   }
   onFinishFailed = errorInfo => {
      notification.open({
         message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
         description: languageManager.getMessage(this.props.commonState, 'notification.error.formInvalid'),
         icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
      })
   };


   render() {
      if (this.state.pageLoader) return <Loader />
      console.log(this.state.orderLines)
      const { Title, Text } = Typography

      const mainLayout = {
         labelCol: { span: 3 },
         wrapperCol: { span: 21 },
         layout: "horizontal",
      };
      const sideLayout = {
         labelCol: { span: 10 },
         wrapperCol: { span: 14 },
         layout: "horizontal",
      };
      const cardLayout = {
         labelCol: { span: 6 },
         wrapperCol: { span: 18 },
         layout: "horizontal",
      };
      const { TextArea } = Input
      const { Option } = Select
      const { TabPane } = Tabs

      const scope = this;

      const radioStyle = {
         display: 'block',
         height: '30px',
         lineHeight: '30px',
      }

      const orderProductsTableLayout = {
         bordered: true,
         size: "small",
         pagination: false,
         loading: this.state.loaderOrderLines,
      }
      const orderProductsColumns = [
         {
            title: 'SKU',
            width: 125,
            dataIndex: 'product_sku',
            key: 'product_sku',
         },
         {
            title: 'Codice fornitore',
            width: 150,
            dataIndex: 'supplier_id',
            key: 'supplier_code',
         },
         {
            title: 'Prodotto',
            key: 'product_name',
            render: (record) => (
               record.product_name
            ),
         },
         {
            title: 'Lotto',
            width: 150,
            dataIndex: 'batch',
            key: 'batch',
         },
         {
            title: 'Scadenza',
            width: 100,
            key: 'expiration_date',
            render: (record) => (
               record.expiration_date ? Moment(record.expiration_date).format('L') : null
            ),
         },
         {
            title: languageManager.getMessage(this.props.commonState, 'component.order_summary.products.table.unit_price'),
            width: 75,
            key: 'unit_price',
            render: (record) => (
               record.unit_price ? parseFloat(record.unit_price).toFixed(2) + '€' : null
            ),
         },
         {
            title: languageManager.getMessage(this.props.commonState, 'component.order_summary.products.table.quantity'),
            width: 75,
            key: 'quantity',
            render: (record) => (
               record.quantity ? parseFloat(record.quantity).toFixed(2) : null
            ),
         },
         {
            title: languageManager.getMessage(this.props.commonState, 'component.order_summary.products.table.total_price'),
            width: 75,
            key: 'total_price',
            render: (record) => (
               record.total_price ? parseFloat(record.total_price).toFixed(2) + '€' : null
            ),
         },
         {
            title: languageManager.getMessage(this.props.commonState, 'component.order_summary.products.table.total_tax'),
            width: 75,
            key: 'total_tax',
            render: (record) => (
               record.total_tax ? parseFloat(record.total_tax).toFixed(2) + '€' : null
            ),
         },
         {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            align: 'right',
            width: 150,
            render: (text, record, index) => (
               <Space size='small'>
                  <Button type="primary" size='small'
                     icon={<EditOutlined />}
                     onClick={() => this.showModalAddProduct(record, index)}
                  />
                  <Button type="danger" size='small'
                     loading={this.state.loadersLines[record.line_index]}
                     icon={<DeleteOutlined />}
                     onClick={() =>
                        Modal.confirm({
                           title: languageManager.getMessage(this.props.commonState, 'component.orders.delete_order_line'),
                           icon: <ExclamationCircleOutlined />,
                           content: languageManager.getMessage(this.props.commonState, 'common.askOperationConfirm'),
                           okText: languageManager.getMessage(this.props.commonState, 'common.confirm'),
                           onOk: () => this.handleRemoveProduct(record.line_index)
                        })
                     }
                  />
               </Space>
            ),
         },
      ]

      const PurchaseOrderForm = (
         <Form
            ref={this.formRef}
            {...mainLayout}
            id="OrderCreateForm"
            onFinish={this.onFinish}
            initialValues={this.state.formValues}
         >
            <Row gutter={32}>
               <Col span={8}>
                  <Form.Item
                     {...sideLayout}
                     label="Numero ordine"
                     name="internal_order_number"
                     rules={[{ required: true, message: 'Inserisci il numero dell\'ordine' }]}
                  >
                     <Input />
                  </Form.Item>
                  <Form.Item
                     {...sideLayout}
                     label="Numero ordine fornitore"
                     name="supplier_order_number"
                  >
                     <Input />
                  </Form.Item>
                  <Form.Item
                     {...sideLayout}
                     label="Numero ordine di vendita"
                     name="selling_order_number"
                  >
                     <Input />
                  </Form.Item>
                  <Form.Item
                     {...sideLayout}
                     label="Ordine virtuale"
                     name="is_virtual"
                     valuePropName='checked'
                  >
                     <Switch
                        disabled={!_.isNil(this.state.itemId)}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                     />
                  </Form.Item>
                  <Divider />
                  <Form.Item
                     {...sideLayout}
                     label="Data ordine"
                     name="create_order_date"
                     rules={[{ required: true, message: 'Inserisci la data dell\'ordine' }]}
                  >
                     <DatePicker showTime
                        placeholder="Data ordine"
                        format="DD/MM/YYYY HH:mm:ss"
                        allowClear={true}
                     />
                  </Form.Item>
                  <Form.Item
                     {...sideLayout}
                     label="Data di consegna prevista"
                     name="deliver_request_date"
                  >
                     <DatePicker
                        placeholder="Data di consegna prevista"
                        format="DD/MM/YYYY"
                        allowClear={true}
                     />
                  </Form.Item>
                  <Form.Item
                     {...sideLayout}
                     label="Data di consegna"
                     name="delivered_order_date"
                  >
                     <DatePicker showTime
                        placeholder="Ordine non consegnato"
                        format="DD/MM/YYYY HH:mm:ss"
                        disabled
                     />
                  </Form.Item>
                  <Divider />
                  <Form.Item
                     {...sideLayout}
                     label="Note"
                     name="order_note"
                  >
                     <TextArea />
                  </Form.Item>
               </Col>
               <Col span={16}>
                  {
                     this.state.itemId ? (
                        <Row gutter={32}>
                           <Col span={12}>
                              <Descriptions size='small' column={1} bordered labelStyle={{ width: '150px' }} contentStyle={{ fontWeight: 'bold' }}>
                                 <Descriptions.Item label="Stato ordine">{this.state.formValues.purchase_order_status_name}</Descriptions.Item>
                              </Descriptions>
                           </Col>
                        </Row>
                     ) : (

                        <Form.Item
                           label="Stato ordine"
                        >
                           <Input disabled value='In attesa di accettazione' />
                        </Form.Item>
                     )
                  }
                  <Divider />
                  <Form.Item
                     label="Fornitore"
                     name="supplier_id"
                  >
                     <Row gutter={8}>
                        <Col span={20}>
                           <Select
                              showSearch
                              placeholder="Scegli il fornitore"
                              optionFilterProp="children"
                              allowClear={true}
                              onChange={this.handleSupplierChange}
                              loading={this.state.loaderSupplier}
                              defaultValue={this.state.formValues.supplier_id}
                           // onSearch={onSearch}
                           // filterOption={(input, option) =>
                           //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                           // }
                           >
                              {
                                 this.state.supplierList.map((supplier) => {
                                    return <Option key={supplier.id} value={supplier.id}>{supplier.company}</Option>
                                 })
                              }
                           </Select>
                        </Col>
                        {/* //TODO: Aggiungi fornitore
                        <Col>
                           <Button
                              type='success'
                              icon={<PlusOutlined />}
                           // onClick={this.showCreateCategoryDrawer}
                           />
                        </Col> */}
                     </Row>
                  </Form.Item>
                  {
                     this.state.actualSupplier ? (
                        <Row gutter={8}>
                           <Col span={12}>
                              <Form.Item
                                 {...cardLayout}
                                 label="Email"
                                 name="supplier_email"
                              >
                                 <Input disabled />
                              </Form.Item>
                              <Form.Item
                                 {...cardLayout}
                                 label="Telefono"
                                 name="supplier_phone"
                              >
                                 <Input disabled />
                              </Form.Item>
                           </Col>
                           <Col span={12}>
                              <Form.Item
                                 {...cardLayout}
                                 label="C.F."
                                 name="supplier_fiscal_code"
                              >
                                 <Input disabled />
                              </Form.Item>
                              <Form.Item
                                 {...cardLayout}
                                 label="P.IVA"
                                 name="supplier_vat"
                              >
                                 <Input disabled />
                              </Form.Item>
                              <Form.Item
                                 {...cardLayout}
                                 label="PEC"
                                 name="supplier_pec"
                              >
                                 <Input disabled />
                              </Form.Item>
                           </Col>
                           <Col span={24}>
                              <Form.Item
                                 {...mainLayout}
                                 label="Note"
                                 name="supplier_note"
                              >
                                 <TextArea disabled />
                              </Form.Item>
                           </Col>
                        </Row>
                     ) : null
                  }
               </Col>
            </Row>
            <Divider />
            <Space direction='vertical' size='small'>
               <Space direction='horizontal' size='small'>
                  <Button type='success'
                     icon={<PlusOutlined />}
                     onClick={() => this.showModalAddProduct()}>Aggiungi prodotto</Button>
               </Space>
               <Table {...orderProductsTableLayout}
                  columns={orderProductsColumns}
                  dataSource={this.state.orderLines}
               />
               <Row gutter={32}>
                  <Col span={8}>
                     <Descriptions size='small' column={1} bordered contentStyle={{ fontWeight: 'bold' }}>
                        <Descriptions.Item label="Quantità">{this.state.orderTotalQuantity.toFixed(2)}</Descriptions.Item>
                     </Descriptions>
                  </Col>
                  <Col span={8}>
                     <Form.Item
                        {...sideLayout}
                        label="Metodo di spedizione"
                        name="shipping_method_id"
                     >
                        <Select
                           showSearch
                           placeholder="Scegli il metodo di spedizione"
                           optionFilterProp="children"
                           allowClear={true}
                           onChange={this.handleShippingMethodChange}
                        >
                           {
                              this.state.shippingMethodList.map((shipping) => {
                                 return <Option key={shipping.id} value={shipping.id}>{shipping.name}</Option>
                              })
                           }
                        </Select>
                     </Form.Item>
                  </Col>
                  <Col span={8}>
                     <Descriptions size='small' column={1} bordered labelStyle={{ width: '200px' }} contentStyle={{ fontWeight: 'bold' }}>
                        <Descriptions.Item label="Subtotale prodotti">{this.state.orderTotalProducts.toFixed(2) + '€'}</Descriptions.Item>
                        <Descriptions.Item label="Totale IVA">{this.state.orderTotalTax.toFixed(2) + '€'}</Descriptions.Item>
                        <Descriptions.Item label="Totale ordine">{this.state.orderTotal.toFixed(2) + '€'}</Descriptions.Item>
                     </Descriptions>
                  </Col>
               </Row>
            </Space>
            <Form.Item label="purchase_order_status_id" name="purchase_order_status_id" noStyle><Input type="hidden" /></Form.Item>
         </Form >
      )

      const pageContent = (
         <div className="card">
            <div className="card-header">
               <div className="row">
                  <div className="col-sm-6">
                     <Title level={5}>{languageManager.getMessage(this.props.commonState, this.state.itemId ? 'component.purchase_orders.edit' : 'component.purchase_orders.create_new')}</Title>
                  </div>
                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">
                        <Space>
                           <Select
                              showSearch
                              className="width-70px-I"
                              placeholder="Seleziona la lingua"
                              optionFilterProp="children"
                              onChange={this.setDefaultLanguageHandler}
                           >
                              {
                                 this.props.authState.userData.languages.map((lang) => {
                                    return <Option key={lang.value} value={lang.value}>{lang.label}</Option>
                                 })
                              }
                           </Select>
                        </Space>
                     </div>
                  </div>
               </div>
            </div>
            <div className="card-body">
               {PurchaseOrderForm}
               <ModalAddOrderProduct
                  productCascaderList={this.state.productCascaderList}
                  batchList={this.state.batchList}
                  visible={this.state.showModalAddProduct}
                  item={this.state.modalEditLineItem}
                  onClose={this.hideModalAddProduct}
                  onSubmit={(item) => this.handleAddProduct(item)} />
            </div>
            <div className="card-footer">
               <div className="align-items-center row">
                  <div className="col-sm-6">
                     <Space size='small'>
                        <Link to={{ pathname: "/purchase_orders/list" }} >
                           <Button icon={<DoubleLeftOutlined />}>
                              {languageManager.getMessage(this.props.commonState, 'common.back')}
                           </Button>
                        </Link>
                     </Space>
                  </div>

                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">
                        <Space>
                           <Button onClick={this.handleResetForm} disabled={this.state.formLoader}>Annulla</Button>
                           <Button form="OrderCreateForm" type="primary" htmlType="submit"
                              loading={this.state.formLoader}
                              disabled={this.state.errorInternalCode || this.state.errorSupplierCode}>Salva</Button>
                           <Button form="OrderCreateForm" type="primary" htmlType="submit" onClick={this.handleSaveAndClose}
                              loading={this.state.formLoader}
                              disabled={this.state.errorInternalCode || this.state.errorSupplierCode}>Salva e chiudi</Button>
                        </Space>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      );

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>

      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
};

export default connect(mapStateToProps)(PurchaseOrderEdit);