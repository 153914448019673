import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Moment from 'moment';

import { Typography, Table, Select, Button, List, Space, notification, Popconfirm, Collapse, Col, Row } from 'antd';
import { InfoCircleOutlined, PlusOutlined, LeftCircleOutlined, UploadOutlined, FilterOutlined } from '@ant-design/icons';
// import Highlighter from 'react-highlight-words';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

import languageManager from '../../../services/languageManager';
import { startLoading, endLoading } from '../../../shared/utility';

import * as storeActions from './store/actions/batches';

import Loader from '../../common/PageElements/Loader';
import Reloader from '../../common/PageElements/Reloader';
import DropdownMenu from '../../common/components/DropdownMenu'

import TableFilters from '../../common/components/TableFilters';

class BatchesList extends Component {
   state = {
      localPath: this.props.history.location.pathname,
      getError: false,
      pageLoader: true,
      tableLoader: false,
      multipleActionLoader: false,
      loadersCustomers: [],
      parentId: null,
      itemList: [],
      fullListIds: [],
      componentLanguage: this.props.authState.defaultLanguage,
      current_page: this.props.location.state ? this.props.location.state.previousState.current_page : 1,
      page_size: this.props.location.state ? this.props.location.state.previousState.page_size : 50,
      total_result: 0,

      showTableFilters: false,
      filters: this.props.location.state ? this.props.location.state.previousState.filters : [],
      filtersValues: this.props.location.state ? this.props.location.state.previousState.filtersValues : {},
      appliedFilters: this.props.location.state ? this.props.location.state.previousState.appliedFilters : [],

      selectedRowKeys: [],
      selectedAll: false,
      multipleActionList: [
         {
            action: 'export_csv',
            name: 'Esporta selezionati in CSV'
         },
      ],
      importDropdownActionList: [],
   };

   getList = (page, pageSize, filters) => {
      // asyncronous calls
      this.setState({ tableLoader: true })
      return storeActions.getListExtended(page, pageSize, filters)
         .then(response => {
            console.log(response);
            try {
               this.setState({
                  itemList: response.data,
                  fullListIds: response.full_list_ids,
                  current_page: page,
                  page_size: pageSize,
                  total_result: response.full_list_ids.length,
                  filters: response.list_filters,
                  appliedFilters: response.applied_filters,
                  tableLoader: false
               });
            } catch (e) {
               this.setState({ tableLoader: false })
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                  duration: 0,
               })
            }

         }).catch((e) => {
            this.setState({ getError: true });
            if (this.props.commonState.debug) console.log(e);
         });
   }

   getComplements = () => {
      // return storeActions.getListComplements()
      //    .then(response => {
      //       console.log(response);
      //       try {
      //          let newExport = {}
      //          let newDropdown = {}
      //          let newMarketplaceDropdowns = []
      //          _.forEach(response.marketplacesList, (marketplace) => {
      //             newDropdown = {}
      //             newDropdown.title = marketplace.name
      //             newDropdown.actionList = []
      //             if (marketplace.marketplace_base.alias === 'privalia') {
      //                newExport = {
      //                   action: 'export_privalia',
      //                   name: 'Esporta selezionati in CSV per ' + marketplace.name,
      //                   params: {
      //                      marketplace_id: marketplace.id
      //                   }
      //                }
      //                newDropdown.actionList.splice(newDropdown.actionList.length - 1, 0, newExport)
      //                newExport = {
      //                   action: 'sync_privalia',
      //                   name: 'Invia anagrafica dei selezionati a ' + marketplace.name,
      //                   params: {
      //                      marketplace_id: marketplace.id
      //                   },
      //                   needconfirmation: 'yes',
      //                }
      //                newDropdown.actionList.splice(newDropdown.actionList.length - 1, 0, newExport)
      //                newExport = {
      //                   action: 'sync_quantities_privalia',
      //                   name: 'Invia quantità dei selezionati a ' + marketplace.name,
      //                   params: {
      //                      marketplace_id: marketplace.id
      //                   },
      //                   needconfirmation: 'yes',
      //                }
      //                newDropdown.actionList.splice(newDropdown.actionList.length - 1, 0, newExport)
      //                newExport = {
      //                   action: 'sync_prices_privalia',
      //                   name: 'Invia prezzi dei selezionati a ' + marketplace.name,
      //                   params: {
      //                      marketplace_id: marketplace.id
      //                   },
      //                   needconfirmation: 'yes',
      //                }
      //                newDropdown.actionList.splice(newDropdown.actionList.length - 1, 0, newExport)
      //             }
      //             else if (marketplace.marketplace_base.alias === 'woocommerce') {
      //                newExport = {
      //                   action: 'sync_woocommerce',
      //                   name: 'Invia anagrafica dei selezionati a ' + marketplace.name,
      //                   params: {
      //                      marketplace_id: marketplace.id
      //                   },
      //                   needconfirmation: 'yes',
      //                }
      //                newDropdown.actionList.splice(newDropdown.actionList.length - 1, 0, newExport)
      //             }
      //             newMarketplaceDropdowns.push(newDropdown)
      //          })
      //          this.setState({
      //             marketplaceDropdowns: newMarketplaceDropdowns,
      //          });

      //       } catch (e) {
      //          notification.open({
      //             message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
      //             description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
      //             icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
      //          })
      //          if (this.props.commonState.debug) console.log(e);
      //       }

      //    }).catch((e) => {
      //       notification.open({
      //          message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
      //          description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
      //          icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
      //       })
      //       if (this.props.commonState.debug) console.log(e);
      //    });
   }

   componentDidMount() {
      console.log('Component Did Mount')
      Promise.all([
         this.getList(this.state.current_page, this.state.page_size, this.state.filtersValues),
         // this.getComplements()
      ]).then(() => {
         this.setState({
            pageLoader: false
         });
      });
   }

   editItemHandler = (itemId) => {
      this.props.history.push({
         pathname: itemId ? '/batches/edit/' + itemId : '/batches/edit/',
         state: { previousState: this.state }
      });
   }

   deleteMultipleItemsHandler = (itemIds) => {
      // this.setState({ loader: true });
      // storeActions.deleteMultipleItems(productIds, true)
      //    .then(response => {
      //       console.log(response);
      //       try {
      //          // let list = [...this.state.itemList];
      //          // list = list.filter(item => item.id !== itemId);

      //          this.getProductsList(this.state.current_page, this.state.page_size, this.state.filtersValues);
      //          notification.open({
      //             message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
      //             description: languageManager.getMessage(this.props.commonState, 'notification.confirm.deleteOk'),
      //             icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
      //          })
      //       } catch (e) {
      //          notification.open({
      //             message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
      //             description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
      //             icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
      //          })
      //       }

      //    }).catch((e) => {
      //       notification.open({
      //          message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
      //          description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
      //          icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
      //       })
      //       if (this.props.commonState.debug) console.log(e);
      //    });
   }

   confirmMultipleHandler = (onConfirm, productIds) => {
      switch (onConfirm) {
         case 'deleteItems':
            if (productIds) {
               this.deleteMultipleItemsHandler(productIds);
            }
            break;
         default:
            break;
      }
   }

   deleteItemHandler = (itemId) => {
      this.setState({ loadersCustomers: startLoading(this.state.loadersCustomers, itemId) });
      storeActions.deleteItems([itemId], true)
         .then(response => {
            console.log(response);
            try {
               Promise.all([
                  this.getList(this.state.current_page, this.state.page_size, this.state.filtersValues)
               ]).then(() => {
                  this.setState({ loadersCustomers: endLoading(this.state.loadersCustomers, itemId) });
               });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.deleteOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   confirmHandler = (onConfirm, itemId) => {
      switch (onConfirm) {
         case 'deleteItem':
            if (itemId) {
               this.deleteItemHandler(itemId);
            }
            break;
         default:
            break;
      }
   }

   setDefaultLanguageHandler = (value) => {
      this.setState({ componentLanguage: value })
   }

   onSelectChange = (selectedRowKeys) => {
      this.setState({
         selectedRowKeys: selectedRowKeys
      });
      console.log(selectedRowKeys);
   };

   submitFilters = (values) => {
      this.setState({ filtersValues: values })
      this.getList(this.state.current_page, this.state.page_size, values)
   }
   resetFilters = () => {
      this.getList(this.state.current_page, this.state.page_size, []);
   }

   selectAllHandler = () => {
      if (this.state.selectedAll) {
         this.setState({
            selectedAll: false,
            selectedRowKeys: [],
         });
      }
      else {
         this.setState({
            selectedAll: true,
            selectedRowKeys: this.state.fullListIds,
         });
      }
   }
   multipleActionHandler = (value) => {
      console.log(value)
      const props = value.item.props
      switch (props.action) {
         case 'delete_items':
            this.confirmMultipleHandler('deleteItems', this.state.selectedRowKeys);
            break;
         default:
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            break;

      }
   }

   handleTableChange = (pagination, filters, sorter, extra) => {
      console.log(pagination, filters, sorter, extra);
      this.getList(pagination.current, pagination.pageSize, this.state.filtersValues);
   };

   render() {
      if (this.state.getError) return <Reloader />;
      if (this.state.pageLoader) return <Loader />;
      const { Title } = Typography
      const { Option } = Select;
      const { Panel } = Collapse;

      const table_columns = [
         // {
         //    title: 'Nome completo',
         //    key: 'full_name',
         //    fixed: 'left',
         //    width: 250,
         //    render: (record) => (
         //       <a className="link-cell" onClick={() => this.editItemHandler(record.id)}>
         //          {record.full_name}
         //       </a>
         //    ),
         //    sorter: (a, b) => {
         //       a = a.full_name || ''
         //       b = b.full_name || ''
         //       return a.localeCompare(b)
         //    },
         // },
         {
            title: 'SKU',
            width: 120,
            key: 'sku',
            fixed: 'left',
            render: (text, record) => (
               record.product_sku
            ),
            sorter: (a, b) => a.product_sku.localeCompare(b.product_sku),
         },
         {
            title: 'Prodotto',
            dataIndex: 'product_name',
            key: 'product_name',
            fixed: 'left',
            // render: (text, record) => (
            //    <Link to={'/purchase_orders/details/' + record.id}>{text}</Link>
            // ),
            sorter: (a, b) => a.product_name.localeCompare(b.product_name),
         },
         {
            title: 'Lotto',
            width: 150,
            dataIndex: 'batch',
            key: 'batch',
            // render: (text, record) => (
            //    <Link to={'/purchase_orders/details/' + record.id}>{text}</Link>
            // ),
            sorter: (a, b) => a.batch.localeCompare(b.batch),
         },
         {
            title: 'Scadenza',
            width: 100,
            key: 'expiration_date',
            render: (text, record) => (
               record.expiration_date ? Moment(record.expiration_date).format('L') : null
            ),
            sorter: (a, b) => {
               a = a.expiration_date || ''
               b = b.expiration_date || ''
               return a.localeCompare(b)
            },
         },
         {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            align: 'right',
            width: 100,
            render: (text, record) => (
               <Space>
                  <Button type="primary" size='small'
                     disabled={this.state.loadersCustomers[record.id]}
                     icon={<EditOutlined />}
                     onClick={() => this.editItemHandler(record.id)}
                  />
                  {/* <Dropdown.Button type="primary" onClick={() => this.editItemHandler(record.id)} overlay={actionsMenu(record.id)}><EditOutlined /></Dropdown.Button> */}
                  <Popconfirm
                     placement="topRight"
                     title={languageManager.getMessage(this.props.commonState, 'common.askDeleteConfirm')}
                     onConfirm={() => this.confirmHandler('deleteItem', record.id)}
                     okText={languageManager.getMessage(this.props.commonState, 'common.yes')}
                     cancelText={languageManager.getMessage(this.props.commonState, 'common.no')}
                  >
                     <Button type="danger" size='small'
                        loading={this.state.loadersCustomers[record.id]}
                        icon={<DeleteOutlined />}
                     />
                  </Popconfirm>
               </Space>),
         },
      ];
      const tableLayout = {
         bordered: true,
         size: "small",
         loading: this.state.tableLoader,
         pagination: {
            pageSize: this.state.page_size,
            total: this.state.total_result,
            showSizeChanger: true,
            defaultPageSize: 50,
            size: "small",
            showTotal: total => total + (total !== 1 ? ' lotti' : ' lotto')
         },
         onChange: this.handleTableChange
      }
      const { selectedRowKeys } = this.state;
      const rowSelection = {
         selectedRowKeys,
         onChange: this.onSelectChange,
         preserveSelectedRowKeys: true
      };

      const pageFilters = (
         <Collapse defaultActiveKey={['filters']}>
            <Panel header="Filtri" key="filters">
               <TableFilters submitFilters={this.submitFilters} resetFilters={this.resetFilters} filters={this.state.filters} selectValues={this.state.filtersValues} />
            </Panel>
         </Collapse>
      );

      const hasSelected = selectedRowKeys.length > 0;
      const singleSelected = selectedRowKeys.length > 1;
      const pageContent = (
         <div className="card">
            <div className="card-header">
               <div className="align-items-center row">
                  <div className="col-sm-6">
                     <Title level={5}>{languageManager.getMessage(this.props.commonState, 'component.batches.title')}</Title>
                  </div>
                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">
                        <Space>
                           <Button
                              type='primary'
                              icon={<PlusOutlined />}
                              onClick={() => this.editItemHandler()}
                           >
                              Crea nuovo lotto
                           </Button>
                        </Space>
                     </div>
                  </div>
               </div>
            </div>
            <div className="card-body">
               <Row>
                  <Col span={24}>
                     {pageFilters}
                  </Col>
               </Row>
               <Row>
                  <Col span={24}>
                     <div className="align-items-center row" style={{ marginTop: 15, marginBottom: 5 }}>
                        <div className="col-sm-12">
                           <div className="d-none d-md-block">
                              <Space>
                                 <Button
                                    onClick={() => this.selectAllHandler()}
                                    disabled={_.isEmpty(this.state.fullListIds)}
                                 >{this.state.selectedAll ? 'Deseleziona' : 'Seleziona tutto'}
                                 </Button>
                                 <span>
                                    {hasSelected ? singleSelected ? `${selectedRowKeys.length} elementi selezionati` : `${selectedRowKeys.length} elemento selezionato` : ''}
                                 </span>
                                 <DropdownMenu
                                    type='primary'
                                    title='Azioni multiple'
                                    action={(event) => this.multipleActionHandler(event)}
                                    values={this.state.multipleActionList}
                                    disabled={!hasSelected}
                                    loading={this.state.multipleActionLoader}
                                 />
                              </Space>
                           </div>
                        </div>
                     </div>
                     <Table
                        key='dataTable'
                        {...tableLayout}
                        rowSelection={rowSelection}
                        columns={table_columns}
                        dataSource={this.state.itemList}
                        rowKey={record => (record.id)}
                     />
                  </Col>
               </Row>
            </div>
            <div className="card-footer">
               <Button
                  size='large'
                  onClick={() => { this.props.history.goBack() }}
               >
                  <i className="mdi mdi-arrow-left-drop-circle-outline mr-1"></i>
                  {languageManager.getMessage(this.props.commonState, 'common.back')}
               </Button>
            </div>
         </div>
      );

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>
      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState,
   };
};

export default connect(mapStateToProps)(BatchesList);