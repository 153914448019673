import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import Moment from 'moment'

import { OrderStatusEnum, OrderStatusActionEnum } from '../../../shared/enums.ts'

import { Table, Button, Tooltip, Space, notification, Popconfirm, Row, Col, Collapse } from 'antd'
import { InfoCircleOutlined, CheckCircleOutlined, UploadOutlined, DeleteOutlined, EditOutlined, PrinterOutlined, CheckOutlined, CloseOutlined, PlusOutlined, MailOutlined } from '@ant-design/icons'

import languageManager from '../../../services/languageManager'
import { startLoading, endLoading } from '../../../shared/utility'

import * as storeActions from './store/actions/orders'
import * as marketplaceActions from '../../configuration/marketplace/store/actions/marketplaces'

import Loader from '../../common/PageElements/Loader'

import TableFilters from '../../common/components/TableFilters'
import DropdownButton from '../../common/components/DropdownButton'
import DropdownMenu from '../../common/components/DropdownMenu'

class OrdersList extends Component {
   state = {
      localPath: this.props.history.location.pathname,
      pageLoader: true,
      tableLoader: false,
      loaderDownloadOrdersManually: false,
      multipleActionLoader: false,
      orderStatusActionLoader: false,
      orderPrintLoaders: [],
      sendMailLoaders: [],

      itemList: [],
      fullListIds: [],
      componentLanguage: this.props.authState.defaultLanguage,

      current_page: this.props.location.state ? this.props.location.state.previousState.current_page : 1,
      page_size: this.props.location.state ? this.props.location.state.previousState.page_size : 50,
      total_result: 0,

      selectedRowKeys: [],
      selectedAll: false,
      multipleActionList: [
         {
            action: 'export',
            name: 'Esporta dettagli selezionati in CSV'
         },
         {
            action: 'export_summary',
            name: 'Esporta riepilogo selezionati in CSV'
         },
         {
            action: 'print_multiple_details',
            name: 'Stampa ordini selezionati'
         },
         // {
         //    action: OrderStatusActionEnum.MULTIPLE_ACCEPT.toString(),
         //    name: 'Accetta ordini',
         //    needconfirmation: 'yes'
         // },
         // {
         //    action: OrderStatusActionEnum.MULTIPLE_PROCESS.toString(),
         //    name: 'Metti in preparazione',
         //    needconfirmation: 'yes'
         // },
         // {
         //    action: OrderStatusActionEnum.MULTIPLE_REFUSE.toString(),
         //    name: 'Rifiuta ordini',
         //    needconfirmation: 'yes'
         // }
      ],
      selectedWaitingAcceptance: true,
      action: "",

      showTableFilters: false,
      filters: this.props.location.state ? this.props.location.state.previousState.filters : [],
      filtersValues: this.props.location.state ? this.props.location.state.previousState.filtersValues : {},
      appliedFilters: this.props.location.state ? this.props.location.state.previousState.appliedFilters : [],

      activeMarketplaces: [],

   };

   getOrdersList = (page, pageSize, filters) => {
      // asyncronous calls
      this.setState({ tableLoader: true })
      return storeActions.getListExtended(page, pageSize, filters)
         .then(response => {
            console.log(response);
            try {
               let updatedFilters = []
               for (let filter of response.list_filters) {
                  if (filter.type === 'date') {
                     if (filter.value) {
                        filter.value = Moment(filter.value, 'YYYY-MM-DD');
                     }
                  }
                  updatedFilters.push(filter);
               }

               this.setState({
                  itemList: response.data.data,
                  fullListIds: response.full_list_ids,
                  current_page: page,
                  page_size: pageSize,
                  total_result: response.data.total,
                  filters: updatedFilters,
                  appliedFilters: response.applied_filters,
                  tableLoader: false
               });
            } catch (e) {
               this.setState({ tableLoader: false })
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            this.setState({ tableLoader: false })
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   getActiveMarketplacesList = () => {
      return marketplaceActions.getActiveList()
         .then(response => {
            console.log(response);
            try {
               this.setState({ activeMarketplaces: response.data });
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   componentDidMount() {
      // console.log(this.props.location.state, this.state);
      Promise.all([
         this.getOrdersList(this.state.current_page, this.state.page_size, this.state.filtersValues),
         this.getActiveMarketplacesList()
      ]).then(() => {
         this.setState({
            pageLoader: false
         });
      });
   }

   viewItemHandler = (itemId) => {
      this.props.history.push({
         pathname: '/orders/summary/' + itemId,
         state: { previousState: this.state }
      });
   }

   getOrdersHandler = (marketplace) => {
      this.setState({ loaderDownloadOrdersManually: true });
      storeActions.downloadOrders(marketplace.key)
         .then(response => {
            console.log(response);
            this.setState({ loaderDownloadOrdersManually: false });
            try {
               this.getOrdersList(this.state.current_page, this.state.page_size, this.state.filtersValues);
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            this.setState({ loaderDownloadOrdersManually: false });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   // getOrdersExportHandler = (selectedRows) => {
   //    let filename = 'export_orders_data.csv';
   //    storeActions.exportOrders(filename, selectedRows)
   //       .then(response => {
   //          console.log(response);
   //          try {
   //             this.setState({ loader: false });
   //          } catch (e) {
   //             notification.open({
   //                message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
   //                description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
   //                icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
   //             })
   //          }

   //       }).catch((e) => {
   //          notification.open({
   //             message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
   //             description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
   //             icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
   //          })
   //          if (this.props.commonState.debug) console.log(e);
   //       });
   // }

   exportToCSVHandler = () => {
      // console.log(this.state.selectedRowKeys);
      this.setState({ multipleActionLoader: true });
      let now = Moment().format('YYYY.MM.DD-HH.mm.ss');
      let filename = now + '_ORDERS_DETAILS.csv';
      storeActions.exportOrders(filename, this.state.selectedRowKeys)
         .then(response => {
            console.log(response);
            this.setState({ multipleActionLoader: false });
            try {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.exportOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            this.setState({ multipleActionLoader: false });
            if (this.props.commonState.debug) console.log(e);
         });
   }
   exportSummaryToCSVHandler = () => {
      // console.log(this.state.selectedRowKeys);
      this.setState({ multipleActionLoader: true });
      let now = Moment().format('YYYY.MM.DD-HH.mm.ss');
      let filename = now + '_ORDERS_SUMMARY.csv';
      storeActions.exportOrdersSummary(filename, this.state.selectedRowKeys)
         .then(response => {
            console.log(response);
            this.setState({ multipleActionLoader: false });
            try {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.exportOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            this.setState({ multipleActionLoader: false });
            if (this.props.commonState.debug) console.log(e);
         });
   }

   printDetailsHandler = (itemId) => {
      this.setState({ orderPrintLoaders: startLoading(this.state.orderPrintLoaders, itemId) });
      let filename = 'ORDER_' + itemId + '.pdf';
      storeActions.printDetails(filename, itemId)
         .then(response => {
            this.setState({ orderPrintLoaders: endLoading(this.state.orderPrintLoaders, itemId) });
            notification.success({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.confirm.exportOk'),
            })
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            this.setState({ orderPrintLoaders: endLoading(this.state.orderPrintLoaders, itemId) });
            if (this.props.commonState.debug) console.log(e);
         });
   }

   printMultipleDetailsHandler = () => {
      this.setState({ multipleActionLoader: true });
      let now = Moment().format('YYYY.MM.DD-HH.mm.ss');
      let filename = now + '_PRINT_ORDERS.zip';
      storeActions.printMultipleDetails(filename, this.state.selectedRowKeys)
         .then(response => {
            this.setState({ multipleActionLoader: false });
            notification.success({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.confirm.exportOk'),
            })
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            this.setState({ multipleActionLoader: false });
            if (this.props.commonState.debug) console.log(e);
         });
   }

   sendMailHandler = (itemId, orderNumber) => {
      this.setState({ sendMailLoaders: startLoading(this.state.sendMailLoaders, itemId) });
      let filename = 'ORDER_' + orderNumber + '.pdf';
      storeActions.sendMail(filename, itemId)
         .then(response => {
            this.setState({ sendMailLoaders: endLoading(this.state.sendMailLoaders, itemId) });
            notification.success({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.confirm.sendMailOk'),
            })
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            this.setState({ sendMailLoaders: endLoading(this.state.sendMailLoaders, itemId) });
            if (this.props.commonState.debug) console.log(e);
         });
   }

   orderStatusHandler = (orderId, newStatus) => {
      this.setState({ loader: true });
      storeActions.updateOrderStatus(orderId, newStatus)
         .then(response => {
            console.log(response);
            try {
               this.getOrdersList(this.state.current_page, this.state.page_size, this.state.filtersValues);
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   orderActionHandler = (orderId, action) => {
      this.setState({ orderStatusActionLoader: true });
      switch (action) {
         case OrderStatusActionEnum.ACCEPT:
            storeActions.acceptOrder(orderId)
               .then(response => {
                  this.setState({ orderStatusActionLoader: false });
                  console.log(response);
                  notification.open({
                     message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
                     description: response.data.message,
                     icon: <CheckCircleOutlined style={{ color: '#108ee9' }} />,
                  })
                  try {
                     this.getOrdersList(this.state.current_page, this.state.page_size, this.state.filtersValues);
                  } catch (e) {
                     notification.open({
                        message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                        description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                        icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                     })
                  }

               }).catch((e) => {
                  this.setState({ orderStatusActionLoader: false });
                  notification.open({
                     message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                     description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                     icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                  })
                  if (this.props.commonState.debug) console.log(e);
               });
            break;
         case OrderStatusActionEnum.REFUSE:
            storeActions.refuseOrder(orderId)
               .then(response => {
                  this.setState({ orderStatusActionLoader: false });
                  console.log(response);
                  notification.open({
                     message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
                     description: response.data.message,
                     icon: <CheckCircleOutlined style={{ color: '#108ee9' }} />,
                  })
                  try {
                     this.getOrdersList(this.state.current_page, this.state.page_size, this.state.filtersValues);
                  } catch (e) {
                     notification.open({
                        message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                        description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                        icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                     })
                  }

               }).catch((e) => {
                  this.setState({ orderStatusActionLoader: false });
                  notification.open({
                     message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                     description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                     icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                  })
                  if (this.props.commonState.debug) console.log(e);
               });
            break;
         default:
            this.setState({ orderStatusActionLoader: false });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.action_not_found'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            break;
      }
   }

   handleMultipleOrdersAction = action => {
      console.log('handleMultipleOrdersAction', action);
      this.setState({ multipleActionLoader: true });
      switch (action) {
         case OrderStatusActionEnum.MULTIPLE_ACCEPT.toString():
            storeActions.acceptMultipleOrders(this.state.selectedRowKeys)
               .then(response => {
                  this.setState({ multipleActionLoader: false });
                  console.log(response);
                  notification.open({
                     message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
                     description: response.data.message,
                     icon: <CheckCircleOutlined style={{ color: '#108ee9' }} />,
                  })
                  try {
                     this.getOrdersList(this.state.current_page, this.state.page_size, this.state.filtersValues);
                  } catch (e) {
                     notification.open({
                        message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                        description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                        icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                     })
                  }

               }).catch((e) => {
                  this.setState({ multipleActionLoader: false });
                  notification.open({
                     message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                     description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                     icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                  })
                  if (this.props.commonState.debug) console.log(e);
               });
            break;
         case OrderStatusActionEnum.MULTIPLE_PROCESS.toString():
            storeActions.processMultipleOrders(this.state.selectedRowKeys)
               .then(response => {
                  this.setState({ multipleActionLoader: false });
                  console.log(response);
                  notification.open({
                     message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
                     description: response.data.message,
                     icon: <CheckCircleOutlined style={{ color: '#108ee9' }} />,
                  })
                  try {
                     this.getOrdersList(this.state.current_page, this.state.page_size, this.state.filtersValues);
                  } catch (e) {
                     notification.open({
                        message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                        description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                        icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                     })
                  }

               }).catch((e) => {
                  this.setState({ multipleActionLoader: false });
                  notification.open({
                     message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                     description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                     icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                  })
                  if (this.props.commonState.debug) console.log(e);
               });
            break;
         case OrderStatusActionEnum.MULTIPLE_REFUSE.toString():
            storeActions.refuseMultipleOrders(this.state.selectedRowKeys)
               .then(response => {
                  this.setState({ multipleActionLoader: false });
                  console.log(response);
                  notification.open({
                     message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
                     description: response.data.message,
                     icon: <CheckCircleOutlined style={{ color: '#108ee9' }} />,
                  })
                  try {
                     this.getOrdersList(this.state.current_page, this.state.page_size, this.state.filtersValues);
                  } catch (e) {
                     notification.open({
                        message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                        description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                        icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                     })
                  }

               }).catch((e) => {
                  this.setState({ multipleActionLoader: false });
                  notification.open({
                     message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                     description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                     icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                  })
                  if (this.props.commonState.debug) console.log(e);
               });
            break;
         default:
            this.setState({ multipleActionLoader: false });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.action_not_found'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            break;
      }
   }

   listPaginationChangeHandler = (page, pageSize) => {
      console.log(page, pageSize, this.state.filtersValues);
      this.getOrdersList(page, pageSize, this.state.filtersValues);
   }

   handleTableChange = (pagination, filters, sorter) => {
      console.log(pagination, filters, sorter)
      // this.fetch({
      //   sortField: sorter.field,
      //   sortOrder: sorter.order,
      //   pagination,
      //   ...filters,
      // });
   };

   onSelectChange = (selectedRowKeys, selection) => {
      this.setState({
         selectedRowKeys: selectedRowKeys,
         selectedWaitingAcceptance: this.checkSelectionStatus(selection)
      });
      console.log(selection);
   };

   checkSelectionStatus = (selection) => {
      if (this.state.selectedAll) {
         return false;
      }
      selection.map((record) => {
         if (record.status != "WAITING_ACCEPTANCE") {
            return false;
         }
      })
      return true;
   }

   submitFilters = (values) => {
      // console.log("Selected filters", values)
      Object.keys(values).forEach(function (key) {
         if (Moment.isMoment(values[key])) {
            values[key] = Moment(values[key]).format('YYYY-MM-DD');
         }
      });
      this.setState({ filtersValues: values })
      this.getOrdersList(this.state.current_page, this.state.page_size, values);
   }
   resetFilters = () => {
      console.log("Reset filters")
      this.getOrdersList(this.state.current_page, this.state.page_size, []);
   }

   setActionHandle = (value) => {
      this.setState({
         action: value
      })
   }

   confirmActionHandle = () => {

   }




   selectAllHandler = () => {
      if (this.state.selectedAll) {
         this.setState({
            selectedAll: false,
            selectedRowKeys: [],
         });
      }
      else {
         this.setState({
            selectedAll: true,
            selectedRowKeys: this.state.fullListIds,
         });
      }
   }
   multipleActionHandler = (value) => {
      const props = value.item.props
      switch (props.action) {
         case 'export':
            this.exportToCSVHandler();
            break;
         case 'export_summary':
            this.exportSummaryToCSVHandler();
            break;
         case 'print_multiple_details':
            this.printMultipleDetailsHandler();
            break;
         case OrderStatusActionEnum.MULTIPLE_ACCEPT.toString():
         case OrderStatusActionEnum.MULTIPLE_PROCESS.toString():
         case OrderStatusActionEnum.MULTIPLE_REFUSE.toString():
            this.handleMultipleOrdersAction(props.action);
            break;
         default:
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            break;

      }
   }

   render() {
      if (this.state.pageLoader) return <Loader />;
      const { Panel } = Collapse;

      const table_columns = [
         // { title: 'ID', width: 100, dataIndex: 'id', key: 'id', fixed: 'left' },
         // {
         //    title: 'ID Marketplace',
         //    width: 150,
         //    dataIndex: 'marketplace_id',
         //    key: 'marketplace_id',
         //    fixed: 'left',
         //    render: (text, record) => (
         //       <a onClick={() => this.viewItemHandler(record.id)} >{text}</a>
         //    ),
         // },
         {
            title: 'Marketplace', width: 150, key: 'source',
            fixed: 'left',
            render: (record) => (
               record.source
            ),
            sorter: true,
         },
         {
            title: 'Numero ordine',
            width: 150,
            dataIndex: 'order_number',
            key: 'order_number',
            fixed: 'left',
            render: (text, record) => (
               <Link to={'/orders/details/' + record.id}>{text}</Link>
            ),
         },
         {
            title: 'Data ordine',
            width: 150,
            key: 'order_date',
            render: (record) => (
               Moment(record.order_date).format('L LTS')
            ),
            sorter: (a, b) => a.order_date.localeCompare(b.order_date),
         },
         {
            title: 'Data evasione richiesta',
            width: 100,
            key: 'shipping_request_date',
            render: (record) => (
               record.shipping_request_date ? Moment(record.shipping_request_date).format('L') : null
            ),
            sorter: (a, b) => {
               a = a.shipping_request_date || ''
               b = b.shipping_request_date || ''
               return a.localeCompare(b)
            },
         },
         {
            title: 'Data evasione',
            width: 100,
            key: 'shipped_order_date',
            render: (record) => (
               record.shipped_order_date ? Moment(record.shipped_order_date).format('L') : null
            ),
            sorter: (a, b) => {
               a = a.shipped_order_date || ''
               b = b.shipped_order_date || ''
               return a.localeCompare(b)
            },
         },
         {
            title: 'Destinatario', key: 'customer',
            render: (record) => (
               record.customer_name
            ),
            sorter: (a, b) => a.customer_name.localeCompare(b.customer_name)
         },
         {
            title: 'Stato', width: 200, key: 'status',
            render: (record) => (
               record.order_status_name
            ),
            sorter: (a, b) => a.order_status_name.localeCompare(b.order_status_name),
         },
         {
            title: 'Totale', width: 70, key: 'price_total',
            render: (record) => (
               record.price_total_comma ? record.price_total_comma + '€' : ''
            ),
            sorter: (a, b) => a.price_total - b.price_total,
         },
         { title: 'Prodotti', width: 70, dataIndex: 'quantity_total', key: 'quantity_total' },
         {
            title: 'Virtuale',
            width: 50,
            dataIndex: 'is_virtual',
            key: 'is_virtual',
            align: 'center',
            render: (text, record) => (
               record.is_virtual ? <CheckOutlined className='icon-success' /> : <CloseOutlined className='icon-error' />
            ),
         },
         {
            title: 'Email inviata il',
            width: 150,
            key: 'email_sent_at',
            render: (record) => (
               record.email_sent_at ? Moment(record.email_sent_at).format('L LTS') : null
            ),
            sorter: (a, b) => a.email_sent_at.localeCompare(b.email_sent_at),
         },
         {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            align: 'right',
            width: 150,
            render: (text, record) => (
               <Space>
                  {
                     record.order_status_id === OrderStatusEnum.WAITING_ACCEPTANCE ?
                        [
                           <Tooltip key='action_accept' title={languageManager.getMessage(this.props.commonState, 'common.acceptOrder')}>
                              <Button
                                 type="success" size="small"
                                 icon={<CheckOutlined />}
                                 // onClick={() => this.orderStatusHandler(record.id, OrderStatusEnum.PENDING)}
                                 onClick={() => this.orderActionHandler(record.id, OrderStatusActionEnum.ACCEPT)}
                                 loading={this.state.orderStatusActionLoader}
                              />
                           </Tooltip>,
                           <Popconfirm
                              key='action_refuse_confirm'
                              placement="topRight"
                              title={languageManager.getMessage(this.props.commonState, 'common.askDeleteRefuseOrder')}
                              onConfirm={() => this.orderActionHandler(record.id, OrderStatusActionEnum.REFUSE)}
                              okText={languageManager.getMessage(this.props.commonState, 'common.yes')}
                              cancelText={languageManager.getMessage(this.props.commonState, 'common.no')}
                           >
                              <Tooltip key='action_refuse' title={languageManager.getMessage(this.props.commonState, 'common.refuseOrder')}>
                                 <Button
                                    type="danger" size="small"
                                    icon={<DeleteOutlined />}
                                    onClick={this.showModalRefuseOrder}
                                    loading={this.state.orderStatusActionLoader}
                                 ></Button>
                              </Tooltip>
                           </Popconfirm>
                        ]
                        : null
                  }
                  <Link to={'/orders/edit/' + record.id}>
                     <Button type="primary" size="small"
                        icon={<EditOutlined />}
                        title={languageManager.getMessage(this.props.commonState, 'common.view_details')}
                     />
                  </Link>
                  <Tooltip key='action_print' title={languageManager.getMessage(this.props.commonState, 'common.print')}>
                     <Button size="small"
                        icon={<PrinterOutlined />}
                        loading={this.state.orderPrintLoaders[record.id]}
                        onClick={() => this.printDetailsHandler(record.id)}
                        />
                  </Tooltip>
                  <Popconfirm
                     key='action_sendmail_confirm'
                     placement="topRight"
                     title={languageManager.getMessage(this.props.commonState, 'common.askSendMail')}
                     onConfirm={() => this.sendMailHandler(record.id, record.order_number)}
                     okText={languageManager.getMessage(this.props.commonState, 'common.yes')}
                     cancelText={languageManager.getMessage(this.props.commonState, 'common.no')}
                     >
                     <Button type='success' size="small" title="Invia email"
                        icon={<MailOutlined />}
                        loading={this.state.sendMailLoaders[record.id]}
                        disabled={_.isNil(record.order_email)}
                     />
                  </Popconfirm>
               </Space>
            ),
         },
      ];

      const productsColumns = [
         { title: 'SKU', key: 'p_sku', dataIndex: 'sku' },
         {
            title: 'Prodotto', key: 'p_name',
            render: (record) => (
               record.variation_name ? <span>{record.name + ' (' + record.variation_name + ')'}</span> : record.name
            ),
         },
         {
            title: languageManager.getMessage(this.props.commonState, 'component.order_summary.products.table.unit_price'),
            width: 100,
            key: 'unit_price',
            render: (record) => (
               record.unit_price_comma ? record.unit_price_comma + '€' : ''
            ),
         },
         {
            title: languageManager.getMessage(this.props.commonState, 'component.order_summary.products.table.quantity'),
            width: 100,
            dataIndex: 'quantity',
            key: 'quantity',
         },
         {
            title: languageManager.getMessage(this.props.commonState, 'component.order_summary.products.table.total_price'),
            width: 100,
            key: 'total_price',
            render: (record) => (
               record.total_price_comma ? record.total_price_comma + '€' : ''
            ),
         },
      ];

      const tableLayout = {
         bordered: true,
         size: "small",
         loading: this.state.tableLoader,
         onChange: this.handleTableChange,
         pagination: {
            pageSize: this.state.page_size,
            onChange: this.listPaginationChangeHandler,
            total: this.state.total_result
         }
      }

      const { selectedRowKeys } = this.state;
      const rowSelection = {
         selectedRowKeys,
         onChange: this.onSelectChange,
         preserveSelectedRowKeys: true,
      };

      const pageFilters = (
         <Collapse defaultActiveKey={['filters']}>
            <Panel header="Filtri" key="filters">
               <TableFilters submitFilters={this.submitFilters} resetFilters={this.resetFilters} filters={this.state.filters} selectValues={this.state.filtersValues} />
            </Panel>
         </Collapse>
      );

      const hasSelected = selectedRowKeys.length > 0;
      const singleSelected = selectedRowKeys.length > 1;
      const pageContent = (
         <div className="card">
            <div className="card-header">
               <div className="align-items-center row">
                  <div className="col-sm-6">{languageManager.getMessage(this.props.commonState, 'component.orders.title')}</div>
                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">
                        <Space>
                           <Link to={'/orders/edit'}>
                              <Button type="primary"
                                 icon={<PlusOutlined />}>Crea ordine manuale</Button>
                           </Link>
                           <DropdownButton
                              type='primary'
                              title='Download ordini manualmente'
                              action={this.getOrdersHandler}
                              values={this.state.activeMarketplaces}
                              loading={this.state.loaderDownloadOrdersManually}
                           />
                           <Button
                              type='primary'
                              icon={<UploadOutlined />}
                              onClick={() => this.props.history.push('/orders/import_csv/')}
                           >
                              Importa CSV
                           </Button>
                           {/* <Button
                              size='large'
                              type='primary'
                              icon={<UploadOutlined />}
                              onClick={() => this.getOrdersHandler('privalia')}
                           >
                              Scarica ordini Privalia
                           </Button> */}
                           {/* <Button
                              size='large'
                              type='primary'
                              icon={<UploadOutlined />}
                              onClick={() => this.getOrdersExportHandler(this.state.selectedRows)}
                           >
                              Esporta ordini
                           </Button> */}
                        </Space>
                     </div>
                  </div>
               </div></div>
            <div className="card-body">
               <Row>
                  <Col span={24}>{pageFilters}</Col>
               </Row>
               <Row>
                  <Col span={24}>
                     <div className="align-items-center row" style={{ marginTop: 15, marginBottom: 5 }}>
                        <div className="col-sm-6">
                           <div className="d-none d-md-block">
                              <Space>
                                 <Button
                                    onClick={() => this.selectAllHandler()}
                                 >{this.state.selectedAll ? 'Deseleziona' : 'Seleziona tutto'}
                                 </Button>
                                 <span>
                                    {hasSelected ? singleSelected ? `${selectedRowKeys.length} elementi selezionati` : `${selectedRowKeys.length} elemento selezionato` : ''}
                                 </span>
                                 <DropdownMenu
                                    type='primary'
                                    title='Azioni multiple'
                                    action={this.multipleActionHandler}
                                    values={this.state.multipleActionList}
                                    disabled={!hasSelected}
                                    loading={this.state.multipleActionLoader}
                                 />
                              </Space>
                           </div>
                        </div>
                        <div className="col-sm-6">
                           <div className="float-right d-none d-md-block">
                              {/* <Button type="primary" loading={this.state.loader}>
                        Mostra solo righe con differenze
                     </Button> */}
                           </div>
                        </div>
                     </div>
                  </Col>
               </Row>

               <Row>
                  <Col span={24}>
                     <Table {...tableLayout}
                        columns={table_columns}
                        dataSource={this.state.itemList}
                        rowSelection={rowSelection}
                        rowKey={(record) => (record.id)}
                        expandable={{
                           expandedRowRender: record => (
                              <Table
                                 size='small'
                                 columns={productsColumns}
                                 dataSource={record.order_lines}
                                 rowKey={(item) => (item.id)}
                                 pagination={false}
                              />
                           )
                        }}
                        scroll={{ x: 'max-content' }}
                     />
                  </Col>
               </Row>
            </div>
         </div >
      );

      // const helpButton = {
      //    title: "Aiuto"
      // }

      // const buttons = [];
      // buttons.push(helpButton);

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>
      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState,
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(OrdersList);