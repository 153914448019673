import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Table, Form, Input, Button, Space, notification, Popconfirm } from 'antd';
import { InfoCircleOutlined, SettingTwoTone, PlusSquareOutlined } from '@ant-design/icons';
// import Highlighter from 'react-highlight-words';
import { SearchOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';

import languageManager from '../../../services/languageManager';

import * as storeActions from './store/actions/marketplaceProducts';

import { updateObject } from '../../../shared/utility';

import fetchFunctions from '../../../services/fetchFunctions';
import API_CONFIG from '../../../api/apiConfig';
import Axios from 'axios';

import Loader from '../../common/PageElements/Loader';
import PageHeader from '../../common/PageElements/PageHeader'

class MarketplaceProductsList extends Component {
   state = {
      loader: true,
      itemList: [],
      searchText: '',
      searchedColumn: '',
      componentLanguage: this.props.authState.defaultLanguage
   };

   componentDidMount() {
      console.log(this.state);

      // asyncronous calls
      storeActions.getListForListedProducts()
         .then(response => {
            console.log(response);
            try {
               this.setState({ itemList: response.data, loader: false });
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   viewItemHandler = (itemId) => {
      this.props.history.push('/marketplace_products/list_products/' + itemId);
   }

   render() {
      const table_columns = [
         { title: 'Nome', width: 100, dataIndex: 'name', key: 'name', fixed: 'left' },
         { title: 'Prodotti pubblicati', width: 100, key: 'language', fixed: 'left',
            render: (record) => (
               record.listed_products_count
            ),
            sorter: (a, b) => a.language.name[this.state.componentLanguage].localeCompare(b.language.name[this.state.componentLanguage])
         },
         { title: 'Prodotti in coda', width: 100, key: 'currency', fixed: 'left',
            render: (record) => (
               record.queued_products_count
            ),
            sorter: (a, b) => a.currency.name[this.state.componentLanguage].localeCompare(b.currency.name[this.state.componentLanguage])
         },
         {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            align: 'right',
            width: 100,
            render: (text, record) => (
               <Space>
                   <a onClick={() => this.viewItemHandler(record.id)} title={text}><SettingTwoTone /></a>
               </Space>
            ),
         },
      ];

      const tableLayout = {
         bordered: true
      }

      const pageContent = (
         <div className="card">
            <div className="card-header">
               <div className="align-items-center row">
                  <div className="col-sm-12">
                     <div className="float-right d-none d-md-block">
                        <Space>
                           <Button
                              size='large'
                              type='primary'
                              icon={<SettingTwoTone />}
                              onClick={() => this.props.history.push('/marketplaces/list/')}
                           >
                              Configura marketplace
                           </Button>
                        </Space>
                     </div>
                  </div>
               </div></div>
            <div className="card-body">
               {this.state.loader ? <Loader /> : <Table {...tableLayout} columns={table_columns} dataSource={this.state.itemList} scroll={{ x: 1000 }} />}
            </div>
         </div>
      );

      const helpButton = {
         title: "Aiuto"
      }

      const buttons = [];
      buttons.push(helpButton);

      // const editForm = (
      //    <div className="card">
      //       <div className="card-header">Tasse</div>
      //       <div className="card-body">
      //          <TaxRatesForm action={this.state.action} itemData={this.state.itemData} closeAction={() => this.closeActionHandler()} />
      //       </div>
      //    </div>
      // );

      return (
         <div className="container-fluid">
            <PageHeader
               title={languageManager.getMessage(this.props.commonState, 'component.marketplaces.title')}
               buttons={buttons}
            />
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>
      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState,
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(MarketplaceProductsList);