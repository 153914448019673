const API_CONFIG = {   
   appUrls: {
      login: 'auth/login',
      getUserData: 'auth/user',
      logout: 'auth/logout',

      users: {
         editPassword: 'auth/edit_password'
      },

      files: {
         fileImport: 'files/import',
         fileExport: 'files/export'
      },

      dashboard: {
         getOrderData: 'dashboard/get_order_data',
         get_order_totals_details:'/get_order_totals_details',
         get_orders_chart_data:'dashboard/get_orders_chart_data',
      },

      customers: {
         getList: 'customers/list',
         getListExtended: 'customers/list_extended',
         getItem: 'customers/get',
         createOrUpdate: 'customers/save',
         deleteItem: 'customers/delete',

         importFromMarketplace: 'customers/import_from_marketplace',
         importFromCSV: 'customers/import_from_csv',
         exportCSV: 'customers/export_csv',
      },
      customerShippingAddresses: {
         getList: 'customer_shipping_addresses/list',
         getItem: 'customer_shipping_addresses/get',
         createOrUpdate: 'customer_shipping_addresses/save',
         deleteItem: 'customer_shipping_addresses/delete',
      },
      customerInvoicingAddresses: {
         getList: 'customer_invoicing_addresses/list',
         getItem: 'customer_invoicing_addresses/get',
         createOrUpdate: 'customer_invoicing_addresses/save',
         deleteItem: 'customer_invoicing_addresses/delete',
      },
      customerGroups: {
         getList: 'customer_groups/list',
         getListExtended: 'customer_groups/list_extended',
         getItem: 'customer_groups/get',
         createOrUpdate: 'customer_groups/save',
         deleteItem: 'customer_groups/delete',
         sendToWoocommerce: 'customer_groups/send_to_woocommerce',
      },
      customerContacts: {
         getItem: 'customer_contacts/get',
         createOrUpdate: 'customer_contacts/save',
         deleteItem: 'customer_contacts/delete',
      },
      suppliers: {
         getList: 'suppliers/list',
         getListExtended: 'suppliers/list_extended',
         getItem: 'suppliers/get',
         createOrUpdate: 'suppliers/save',
         deleteItem: 'suppliers/delete',

         importFromMarketplace: 'suppliers/import_from_marketplace',
         importFromCSV: 'suppliers/import_from_csv',
         exportCSV: 'suppliers/export_csv',
      },

      categories: {
         getList: 'categories/list',
         getListExtended: 'categories/list_extended',
         getItem: 'categories/getCategory',
         createOrUpdate: 'categories/save',
         deleteItem: 'categories/delete',
         toggleValue: 'categories/toggle_value',
         getTree: 'categories/getTree',
         rebuildTree: 'categories/rebuildTree',
         printListings: 'categories/print_listings',
         printCatalog: 'categories/print_catalog',
      },

      brands: {
         getList: 'brands/list',
         getItem: 'brands/get',
         createOrUpdate: 'brands/save',
         deleteItem: 'brands/delete',
         toggleValue: 'brands/toggle_value'
      },

      tags: {
         getList: 'tags/list',
         getItem: 'tags/get',
         createOrUpdate: 'tags/save',
         deleteItem: 'tags/delete',
      },
      mediaInternalTypes: {
         getList: 'media_internal_types/list',
         getListForFiles: 'media_internal_types/list_for_files',
         getItem: 'media_internal_types/get',
      },

      carriers: {
         getList: 'carriers/list',
      },

      products: {
         getList: 'products/list',
         getListExtended: 'products/list_extended',
         getListCascader: 'products/list_cascader',
         getListParents: 'products/list_parents',
         getItem: 'products/get',
         getItemForGroup: 'products/get_for_group',
         createOrUpdate: 'products/save', 
         update: 'products/update', 
         deleteItem: 'products/delete',
         deleteMultipleItems: 'products/delete_multiple',
         toggleValue: 'products/toggle_value',

         deleteFile: 'products/delete_file',
         addFiles: 'products/add_files',

         saveImagesOrder: 'products/save_images_order',
         deleteImage: 'products/delete_image',
         addImages: 'products/add_images',

         saveVariationImage: 'products/save_variation_image',
         deleteVariationImage: 'products/delete_variation_image',

         listPrices: 'products/list_prices',

         getListForMarketplace: 'products/list_for_marketplace',
         syncProductsWithMarketplace: 'products/sync_marketplace',
         

         importFromCsvWithMap: 'products/import_with_map',
         importFileCSV: 'products/import_file_csv',
         importProductsFromCSV: 'products/import_products_from_csv',

         exportCSV: 'products/export_csv',
         exportCSVPrivalia: 'products/export_csv_privalia',
         exportForPrivalia: 'products/export_for_privalia',

         activateForMarketplace: 'products/activate_for_marketplace',
         deactivateForMarketplace: 'products/deactivate_for_marketplace',
         sendToMarketplace: 'products/send_to_marketplace',
         
         exportCSVWoocommerce: 'products/export_csv_woocommerce',
      },
      productVariations: {
         createOrUpdate: 'product_variations/save',
         deleteItem: 'product_variations/delete',
      },
      productBatches: {
         createOrUpdate: 'product_batches/save',
         getItem: 'product_batches/get',
         getList: 'product_batches/list',
         getListExtended: 'product_batches/list_extended',
         deleteItems: 'product_batches/delete',
      },
      productStocks: {
         createOrUpdate: 'product_stocks/save',
         getListExtended: 'product_stocks/list_extended',
         deleteItem: 'product_stocks/delete',
         exportCsv: 'product_stocks/export_csv',
         printList: 'product_stocks/print_list',
         printListBeforeDate: 'product_stocks/print_list_before_date',
      },
      productPrices: {
         save: 'product_prices/save',
         update: 'product_prices/update',
      },

      specifics: {
         getList: 'specifics/list',
         getItem: 'specifics/get',
         createOrUpdate: 'specifics/save',
         deleteItem: 'specifics/delete',
         toggleValue: 'specifics/toggle_value'
      },

      specificValues: {
         getList: 'specific_values/list',
         getListByParent: 'specific_values/list_by_parent',
         getItem: 'specific_values/get',
         createOrUpdate: 'specific_values/save',
         deleteItem: 'specific_values/delete',
         toggleValue: 'specific_values/toggle_value'
      },

      variations: {
         getList: 'variations/list',
         getItem: 'variations/get',
         createOrUpdate: 'variations/save',
         deleteItem: 'variations/delete',
         toggleValue: 'variations/toggle_value'
      },

      variationValues: {
         getList: 'variation_values/list',
         getListByParent: 'variation_values/list_by_parent',
         getItem: 'variation_values/get',
         createOrUpdate: 'variation_values/save',
         deleteItem: 'variation_values/delete',
         toggleValue: 'variation_values/toggle_value'
      },

      marketplaceListRules: {
         getList: 'marketplace_list_rules/list',
         getListByMarketplace: 'marketplace_list_rules/list_by_marketplace',
         getListForMarketplace: 'marketplace_list_rules/list_for_marketplace',
         getItem: 'marketplace_list_rules/get',
         createOrUpdate: 'marketplace_list_rules/save',
         deleteItem: 'marketplace_list_rules/delete',
         toggleActive: 'marketplace_list_rules/toggle_active'
      },
      marketplaceSaleRules: {
         getList: 'marketplace_sale_rules/list',
         getListByMarketplace: 'marketplace_sale_rules/list_by_marketplace',
         getListForMarketplace: 'marketplace_sale_rules/list_for_marketplace',
         getItem: 'marketplace_sale_rules/get',
         createOrUpdate: 'marketplace_sale_rules/save',
         deleteItem: 'marketplace_sale_rules/delete',
         toggleActive: 'marketplace_sale_rules/toggle_active'
      },
      prices: {
         getList: 'marketplace_price_rules_set/list',
         getListByMarketplace: 'marketplace_price_rules_set/list_by_marketplace',
         getListForMarketplace: 'marketplace_price_rules_set/list_for_marketplace',
         getItem: 'marketplace_price_rules_set/get',
         createOrUpdate: 'marketplace_price_rules_set/save',
         deleteItem: 'marketplace_price_rules_set/delete',
         toggleActive: 'marketplace_price_rules_set/toggle_active'
      },

      marketplacesCountries: {
         getList: 'marketplaces_countries/list',
      },

      marketplacesLanguages: {
         getList: 'marketplaces_languages/list',
      },

      marketplacesCurrencies: {
         getList: 'marketplaces_currencies/list',
      },

      marketplaces: {
         getList: 'marketplaces/list',
         getActiveList: 'marketplaces/list_active',
         getBasesList: 'marketplaces/list_bases',
         getItem: 'marketplaces/get',
         getConfig: 'marketplaces/get_config',
         setConfig: 'marketplaces/set_config',
         createOrUpdate: 'marketplaces/save',
         deleteItem: 'marketplaces/delete',
         toggleActive: 'marketplaces/toggle_active',

         getListForListedProducts: 'marketplaces/list_for_listed_products',
      },

      priceRules: {
         getList: 'price_rules/list',
         getDiscountRulesList: 'price_rules/list_discount_rules',
         getListByMarketplace: 'price_rules/list_by_marketplace',
         getListForMarketplace: 'price_rules/list_for_marketplace',
         getItem: 'price_rules/get',
         createOrUpdate: 'price_rules/save',
         deleteItem: 'price_rules/delete',
         toggleActive: 'price_rules/toggle_active',
         printListings: 'price_rules/print_listings'
      },

      priceRulesSets: {
         getList: 'price_rules_sets/list',
         getListByMarketplace: 'price_rules_sets/list_by_marketplace',
         getListForMarketplace: 'price_rules_sets/list_for_marketplace',
         getItem: 'price_rules_sets/get',
         createOrUpdate: 'price_rules_sets/save',
         deleteItem: 'price_rules_sets/delete',
         toggleActive: 'price_rules_sets/toggle_active'
      },

      taxRates: {
         getList: 'tax_rates/list',
         getItem: 'tax_rates/get',
         createOrUpdate: 'tax_rates/save',
         deleteItem: 'tax_rates/delete',
         toggleActive: 'tax_rates/toggle_active'
      },

      shippingMethods: {
         getList: 'shipping_methods/list',
         getListExtended: 'shipping_methods/list_extended',
         getItem: 'shipping_methods/get',
         createOrUpdate: 'shipping_methods/save',
         deleteItem: 'shipping_methods/delete',
      },

      paymentMethods: {
         getList: 'payment_methods/list',
         getListExtended: 'payment_methods/list_extended',
         getItem: 'payment_methods/get',
         createOrUpdate: 'payment_methods/save',
         deleteItem: 'payment_methods/delete',
      },
      banks: {
         getList: 'banks/list',
         getListExtended: 'banks/list_extended',
         getItem: 'banks/get',
         createOrUpdate: 'banks/save',
         deleteItem: 'banks/delete',
      },

      orders: {
         getList: 'orders/list',
         getListExtended: 'orders/list_extended',
         getListFilters: 'orders/get_list_filters',
         getItem: 'orders/get',
         createOrUpdate: 'orders/save',

         getPrivaliaOrders: 'privalia/get_orders',

         updateStatus: 'orders/update_status',
         shipOrder: 'orders/ship_order',

         sendToFattura24: 'fattura24/save_document',
         sendToFattureincloud: 'fattureincloud/issue_document',

         downloadOrders: 'orders/download_orders',

         exportOrders: 'orders/export',
         exportOrdersSummary: 'orders/export_summary',
         printDetails: 'orders/print_details',
         printMultipleDetails: 'orders/print_multiple_details',
         sendMail: 'orders/send_mail',
         importFileCSV: 'orders/import_file_csv',

         //UPDATE ORDERS
         acceptOrder: 'orders/accept_order',
         acceptMultipleOrders: 'orders/accept_multiple_orders',
         acceptPartialOrder: 'orders/accept_partial_order',
         processOrder: 'orders/process_order',
         processMultipleOrders: 'orders/process_multiple_orders',
         refuseOrder: 'orders/refuse_order',
         refuseMultipleOrders: 'orders/refuse_multiple_orders',
         cancelOrder: 'orders/cancel_order',

         //UPDATE ORDER LINES
         cancelOrderLine: 'orders/cancel_order_line',
         refundOrderLine: 'orders/refund_order_line',
         returnOrderLine: 'orders/return_order_line',

      },

      purchaseOrders: {
         getList: 'purchase_orders/list',
         getListExtended: 'purchase_orders/list_extended',
         getItem: 'purchase_orders/get',
         createOrUpdate: 'purchase_orders/save',
         updateStatus: 'purchase_orders/update_status',
         cancelOrder: 'purchase_orders/cancel_order',
         printDetails: 'purchase_orders/print_details',
      },

      warehouses: {
         getList: 'warehouses/list',
         getListExtended: 'warehouses/list_extended',
         getItem: 'warehouses/get',
         createOrUpdate: 'warehouses/save',
         deleteItems: 'warehouses/delete_items',
      },

      warehouseMovements: {
         getList: 'warehouse_movements/list',
         getListExtended: 'warehouse_movements/list_extended',
         getItem: 'warehouse_movements/get',
         createOrUpdate: 'warehouse_movements/save',
         deleteItems: 'warehouse_movements/delete_items',

         importFromCSV: 'warehouse_movements/import_from_csv',
         exportCsv: 'warehouse_movements/export_csv',
         printList: 'warehouse_movements/print_list',
      },
      batchHistory: {
         getList: 'batch_history/list',
         getListExtended: 'batch_history/list_extended',
         getItem: 'batch_history/get',
         printList: 'batch_history/print_list',
      },

      orderStatuses: {
         getList: 'order_statuses/list',
         getItem: 'order_statuses/get',
      },

      logs: {
         getListExtended: 'connector_logs/list_extended',
         getItem: 'connector_logs/get',
      },

      getEbayOfficialTimeUrl: 'api/connectors/ebay/getEbayOfficialTime',

      getTrafficDataUrl: 'api/connectors/ebay/getTrafficData',
      getSellingDataUrl: 'api/connectors/ebay/getSellingData',

      getTrafficSitesUrl: 'api/connectors/ebay/getTrafficSites',
      getTrafficTotalsUrl: 'api/connectors/ebay/getTrafficTotals',
      getTrafficTotalHitsUrl: 'api/connectors/ebay/getTrafficTotalHits',
      getTrafficCategoriesUrl: 'api/connectors/ebay/getTrafficCategories',
      getTrafficDatatableUrl: 'api/connectors/ebay/getTrafficDatatable',

      getCategoriesTotalsUrl: 'api/connectors/ebay/getCategoriesTotals',
      getCategoriesDatatableUrl: 'api/connectors/ebay/getCategoriesDatatable',
      getCategoriesDetailsTableUrl: 'api/connectors/ebay/getCategoriesDetailsTable',
      getSuggestedCategoryUrl: 'api/connectors/ebay/getSuggestedCategory',

      getPricingTotalsUrl: 'api/connectors/ebay/getPricingTotals',
      getPricingDatatableUrl: 'api/connectors/ebay/getPricingDatatable',
      getPricingDetailsTableUrl: 'api/connectors/ebay/getPricingDetailsTable',
      getCompetitorPricesUrl: 'api/connectors/ebay/getCompetitorPrices',

      getSellingTotalsUrl: 'api/connectors/ebay/getSellingTotals',
      getSellingDatatableUrl: 'api/connectors/ebay/getSellingDatatable',
      getSellingDetailsTableUrl: 'api/connectors/ebay/getSellingDetailsTable',
      getSellingNotSoldTableUrl: 'api/connectors/ebay/getSellingNotSoldTable',
   }
};

export default API_CONFIG;
