import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateObject } from '../../shared/utility';

import { Form, Input, InputNumber, Select, TreeSelect, Switch, DatePicker, Button, Space, notification, Tabs, Typography } from 'antd';
import { InfoCircleOutlined, CheckOutlined, CloseOutlined, EyeTwoTone, EyeInvisibleOutlined, LeftCircleOutlined } from '@ant-design/icons';

import languageManager from '../../services/languageManager';
import * as storeActions from './store/actions/profile';

import Loader from '../common/PageElements/Loader';

class AddressEdit extends Component {

   state = {
      action: 'edit',
      saveAndClose: false,
      loader: false,
      formValues: {},
      componentLanguage: this.props.authState.defaultLanguage,
      tax_rates: [],
      marketplaces: []
   };

   componentDidMount() { }

   handleSaveAndClose = () => {
      this.setState({ saveAndClose: true });
      console.log(this.state);
   }

   onFinish = values => {
      console.log(values);
      this.setState({ loader: true });
      storeActions.editPassword(values)
         .then(response => {
            console.log(response);
            try {
               if (this.state.saveAndClose) {
                  this.props.history.goBack();
               }
               this.setState({ response: response.data, loader: false });

               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
                  description: languageManager.getMessage(this.props.commonState, this.state.action === 'edit' ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {

            }

         }).catch((e) => {
            this.setState({ loader: false });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.message ? e.message : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
         });
   };

   onFinishFailed = errorInfo => {
      notification.open({
         message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
         description: languageManager.getMessage(this.props.commonState, 'notification.error.formInvalid'),
         icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
      })
   };

   render() {
      if (!this.props.authState.userData.languages) return '';
      const mainLayout = {
         labelCol: { span: 8 },
         wrapperCol: { span: 14 },
      };
      const { Title } = Typography;

      let form = (
         <Form
            {...mainLayout}
            id="addressEdit"
            name="basic"
            initialValues={{user_id: this.props.authState.userData.id}}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
         >
         <Form.Item
            label="Indirizzo"
            name="street"
            rules={[{ required: true, message: 'Inserisci la via e il numero civico' }]}
         >
            <Input
                defaultValue = {this.props.address.street}
            />
         </Form.Item>
            <Form.Item
               label="Città"
               name="city"
               rules={[{ required: true, message: 'Inserisci la città' }]}
            >
               <Input
                    defaultValue = {this.props.address.city}
               />
            </Form.Item>
            <Form.Item
               label="Provincia"
               name="province"
               rules={[{ required: true, message: 'Seleziona la provincia' }]}
            >
               <Select
                    defaultValue = {this.props.address.province}
               />
            </Form.Item>
            <Form.Item
               label="CAP"
               name="code"
               
               rules={[{required: true, message: 'Inserisci il CAP'}]}
            >
               <Input
                    defaultValue = {this.props.address.code}
               />
            </Form.Item>

            <Form.Item label="user_id" name="user_id" noStyle><Input type="hidden" /></Form.Item>


         </Form>
      );

      const pageContent = (
         <div className="card">
            <div className="card-body">
               {this.state.loader ? <Loader /> : form}
            </div>
            <div className="card-footer">
               <div className="align-items-center row">
                  <div className="col-sm-6">
                  </div>

                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">

                        <Space>
                           <Button form="addressEdit" htmlType="reset" onClick={this.props.closeModify}>Chiudi</Button>
                           <Button form="addressEdit" type="primary" htmlType="submit">Salva</Button>
                           <Button form="addressEdit" type="primary" htmlType="submit" onClick={this.handleSaveAndClose}>Salva e chiudi</Button>
                        </Space>

                     </div>
                  </div>
               </div>
            </div>
         </div>
      );
      const helpButton = {
         title: "Aiuto"
      }

      const buttons = [];
      buttons.push(helpButton);




      console.log(this.state);
      // this.props.action === 'edit' ? (
      //    initial_values = {
      //       name: this.props.itemData.t_name,
      //       percentage: this.props.itemData.percentage
      //    }) : null;

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>

      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(AddressEdit);