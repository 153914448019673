import API_BASE from './apiBase';
import API_CONFIG from './apiConfig';

import Axios from 'axios';

class CustomerContactsApi {

   static getItem(itemId) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.customerContacts.getItem, {
            id: itemId
         })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static createOrUpdate(itemData, returnList) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.customerContacts.createOrUpdate, {
            ...itemData,
            return_list: returnList
         })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   static deleteItem(itemId, return_list) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.customerContacts.deleteItem, {
            id: itemId,
            return_list: return_list
         })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

}

export default CustomerContactsApi;