import MarketplacesApi from '../../../../../api/MarketplacesApi';
import ProductsApi from '../../../../../api/ProductsApi';
import _ from 'lodash';

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function getListForListedProducts() {
   return MarketplacesApi.getListForListedProducts()
      .then(response => {
         console.log(response);
         // if (response.success === true) {
         if (response.data.success === true) {
            response.data.data.forEach(el => {
               el.key = el.id;
            });
            console.log(response);
            // return response;
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function getListForMarketplace(marketplace_id) {
   return ProductsApi.getListForMarketplace(marketplace_id)
      .then(response => {
         console.log(response);
         // if (response.success === true) {
         if (response.data.success === true) {
            response.data.data.forEach(el => {
               el.key = el.id;
            });
            console.log(response);
            // return response;
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function syncProductsWithMarketplace(marketplace_id) {
   return ProductsApi.syncProductsWithMarketplace(marketplace_id)
      .then(response => {
         console.log(response.data);
         // if (response.success === true) {
         if (response.data.success === true) {
            // return response;
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}