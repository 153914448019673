import React, { Component } from 'react'
import { connect } from 'react-redux'
import { loadActiveMarketplacesData } from '../../../store/actions/authenticationAction';
import _ from 'lodash'
import Moment from 'moment'

import { Row, Col, Table, Typography, Radio, Divider, Button, Space, notification, Popconfirm, Tooltip, Collapse, Spin } from 'antd'
import { InfoCircleOutlined, PlusSquareOutlined, LoadingOutlined, PrinterOutlined } from '@ant-design/icons'
import { CheckOutlined, CloseOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'

import languageManager from '../../../services/languageManager'

import * as storeActions from './store/actions/prices'
import * as marketplacesActions from '../../configuration/marketplace/store/actions/marketplaces'
import * as listRulesActions from './store/actions/marketplaceListRules'
import * as saleRulesActions from './store/actions/marketplaceSaleRules'
import { startLoading, endLoading } from '../../../shared/utility'
import { SellPriceTypeEnum } from '../../../shared/enums.ts'

import ListRuleDrawer from './widget/ListRuleDrawer'
import SaleRuleDrawer from './widget/SaleRuleDrawer'

import Loader from '../../common/PageElements/Loader'
import PageHeader from '../../common/PageElements/PageHeader'

class Prices extends Component {
   state = {
      pageLoader: true,
      loadersSaleRules: [],
      loadersActiveSaleRules: [],
      loadersListRules: [],
      loadersActiveListRules: [],
      loadersMarketplaces: [],
      loaderPrintListings: false,
      componentLanguage: this.props.authState.defaultLanguage,
      marketplaceListRulesList: [],
      marketplaceSaleRulesList: [],
   }

   getMarketplaceListRulesList = () => {
      return listRulesActions.getListByMarketplace()
         .then(response => {
            console.log(response)
            try {
               this.setState({
                  marketplaceListRulesList: response.data,
               })
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e)
         })
   }
   getMarketplaceSaleRulesList = () => {
      return saleRulesActions.getListByMarketplace()
         .then(response => {
            console.log(response)
            try {
               this.setState({
                  marketplaceSaleRulesList: response.data,
               })
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e)
         })
   }

   componentDidMount() {
      Promise.all([
         this.getMarketplaceListRulesList(),
         this.getMarketplaceSaleRulesList(),
      ]).then(() => {
         this.setState({
            pageLoader: false
         })
      })
   }

   deleteItemHandler = (itemId) => {
      this.setState({ loader: true })
      storeActions.deleteItem(itemId, true, 'list_by_marketplace')
         .then(response => {
            console.log(response)
            try {
               // let list = [...this.state.itemList]
               // list = list.filter(item => item.id !== itemId)

               this.setState({ priceRulesSetsList: response.data, loader: false })
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.deleteOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e)
         })
   }

   confirmHandler = (onConfirm, itemId) => {
      switch (onConfirm) {
         case 'deleteItem':
            if (itemId) {
               this.deleteItemHandler(itemId)
            }
            break
         default:
            return
      }
   }

   handleListPriceTypeChange = (marketplace, e) => {
      console.log('handleListPriceTypeChange', marketplace.id)
      this.setState({ loadersMarketplaces: startLoading(this.state.loadersMarketplaces, marketplace.id) });
      var marketplaceValues = { ...marketplace }
      marketplaceValues.sell_price_type = e.target.value
      marketplacesActions.createOrUpdate(marketplaceValues)
         .then(response => {
            console.log(response);
            try {
               Promise.all([
                  this.props.updateActiveMarketplacesList()
               ]).then(() => {
                  this.setState({ loadersMarketplaces: endLoading(this.state.loadersMarketplaces, marketplace.id) });
               })
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.updateOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               this.setState({ loadersMarketplaces: endLoading(this.state.loadersMarketplaces, marketplace.id) });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e);
            }

         }).catch((e) => {
            this.setState({ loadersMarketplaces: endLoading(this.state.loadersMarketplaces, marketplace.id) });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   showListRuleDrawer = (marketplaceId, item) => {
      this.setState({ listRuleDrawerMarketplaceId: marketplaceId, listRuleDrawerItem: item, showListRuleDrawer: true })
   }
   hideListRuleDrawer = () => {
      this.setState({ listRuleDrawerMarketplaceId: null, listRuleDrawerItem: null, showListRuleDrawer: false })
   }
   handleEditListRule = (marketplaceId, rules_list) => {
      var newList = { ...this.state.marketplaceListRulesList }
      newList[marketplaceId] = rules_list
      this.setState({ marketplaceListRulesList: newList, showListRuleDrawer: false })
   }
   handleDeleteListRule = (marketplaceId, itemId) => {
      console.log('handleDeleteListRule', itemId)
      this.setState({ loadersListRules: startLoading(this.state.loadersListRules, itemId) });
      listRulesActions.deleteItem(itemId, true)
         .then(response => {
            console.log(response);
            try {
               var newList = { ...this.state.marketplaceListRulesList }
               newList[marketplaceId] = response.data
               this.setState({ marketplaceListRulesList: newList, loadersListRules: endLoading(this.state.loadersListRules, itemId) });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.deleteOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               this.setState({ loadersListRules: endLoading(this.state.loadersListRules, itemId) });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e);
            }

         }).catch((e) => {
            this.setState({ loadersListRules: endLoading(this.state.loadersListRules, itemId) });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }
   handleToggleActiveListRule = (marketplaceId, itemId) => {
      this.setState({ loadersActiveListRules: startLoading(this.state.loadersActiveListRules, itemId) })
      listRulesActions.toggleActive(itemId)
         .then(response => {
            console.log(response)
            try {
               var newList = { ...this.state.marketplaceListRulesList }
               const itemIndex = newList[marketplaceId].findIndex((obj => obj.id == response.data.id));
               newList[marketplaceId][itemIndex] = response.data;
               this.setState({ marketplaceListRulesList: newList, loadersActiveListRules: endLoading(this.state.loadersActiveListRules, itemId) });
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }
         }).catch((e) => {
            this.setState({ loadersActiveListRules: endLoading(this.state.loadersActiveListRules, itemId) })
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
         })
   }

   showSaleRuleDrawer = (marketplaceId, item) => {
      this.setState({ saleRuleDrawerMarketplaceId: marketplaceId, saleRuleDrawerItem: item, showSaleRuleDrawer: true })
   }
   hideSaleRuleDrawer = () => {
      this.setState({ saleRuleDrawerMarketplaceId: null, saleRuleDrawerItem: null, showSaleRuleDrawer: false })
   }
   handleEditSaleRule = (marketplaceId, rules_list) => {
      var newList = { ...this.state.marketplaceSaleRulesList }
      newList[marketplaceId] = rules_list
      this.setState({ marketplaceSaleRulesList: newList, showSaleRuleDrawer: false })
   }
   handleDeleteSaleRule = (marketplaceId, itemId) => {
      console.log('handleDeleteSaleRule', itemId)
      this.setState({ loadersSaleRules: startLoading(this.state.loadersSaleRules, itemId) });
      saleRulesActions.deleteItem(itemId, true)
         .then(response => {
            console.log(response);
            try {
               var newList = { ...this.state.marketplaceSaleRulesList }
               newList[marketplaceId] = response.data
               this.setState({ marketplaceSaleRulesList: newList, loadersSaleRules: endLoading(this.state.loadersSaleRules, itemId) });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.deleteOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               this.setState({ loadersSaleRules: endLoading(this.state.loadersSaleRules, itemId) });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e);
            }

         }).catch((e) => {
            this.setState({ loadersSaleRules: endLoading(this.state.loadersSaleRules, itemId) });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }
   handleToggleActiveSaleRule = (marketplaceId, itemId) => {
      this.setState({ loadersActiveSaleRules: startLoading(this.state.loadersActiveSaleRules, itemId) })
      saleRulesActions.toggleActive(itemId)
         .then(response => {
            console.log(response)
            try {
               var newList = { ...this.state.marketplaceSaleRulesList }
               const itemIndex = newList[marketplaceId].findIndex((obj => obj.id == response.data.id));
               newList[marketplaceId][itemIndex] = response.data;
               this.setState({ marketplaceSaleRulesList: newList, loadersActiveSaleRules: endLoading(this.state.loadersActiveSaleRules, itemId) });
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }
         }).catch((e) => {
            this.setState({ loadersActiveSaleRules: endLoading(this.state.loadersActiveSaleRules, itemId) })
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
         })
   }

   printListingsHandler = () => {
      console.log('printListings')
      this.setState({loaderPrintListings: true})
      let now = Moment().format('YYYY.MM.DD-HH.mm.ss');
      let filename = now + '_stampa_listini.zip';
      storeActions.printListings(filename)
         .then(response => {
            console.log(response);
            try {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.updateOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               this.setState({loaderPrintListings: false})
            } catch (e) {
               this.setState({loaderPrintListings: false})
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e);
            }

         }).catch((e) => {
            this.setState({loaderPrintListings: false})
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   render() {
      if (this.state.pageLoader) return <Loader />
      const loadingIcon = <LoadingOutlined spin />
      const { Panel } = Collapse
      const { Title } = Typography

      const tableLayout = {
         bordered: true,
         size: 'small'
      }
      const list_rules_table_columns = [
         {
            title: 'Regola',
            width: 100,
            key: 'name',
            className: 'drag-visible',
            fixed: 'left',
            render: (text, record) => (
               record.name
            )
         },
         {
            title: 'Gruppo', width: 100, key: 'customer_group', fixed: 'left',
            render: (text, record) => (
               !_.isNil(record.customer_group) ? record.customer_group.name : ''
            )
         },
         {
            title: 'Categoria', width: 100, key: 'category', fixed: 'left',
            render: (text, record) => (
               !_.isNil(record.category) ? record.category.name[this.state.componentLanguage] : ''
            )
         },
         {
            title: 'Brand', width: 100, key: 'brand', fixed: 'left',
            render: (text, record) => (
               !_.isNil(record.brand) ? record.brand.name : ''
            )
         },
         {
            title: 'Tag', width: 100, key: 'tag', fixed: 'left',
            render: (text, record) => (
               !_.isNil(record.tag) ? record.tag.name[this.state.componentLanguage] : ''
            )
         },
         {
            title: 'Attivo', width: 50, dataIndex: 'active', key: 'active', align: 'center',
            render: (text, record) => (
               <Tooltip title={languageManager.getMessage(this.props.commonState, record.active ? 'common.deactivate' : 'common.activate')}>
                  <Button type={record.active ? 'success' : 'danger'} size="small"
                     icon={record.active ? <CheckOutlined /> : <CloseOutlined />}
                     disabled={this.state.loadersListRules[record.id]}
                     loading={this.state.loadersActiveListRules[record.id]}
                     onClick={() => this.handleToggleActiveListRule(record.marketplace_id, record.id)}
                  />
               </Tooltip>
            )
         },
         {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            align: 'right',
            width: 100,
            render: (text, record) => (
               <Space>
                  <Button type="primary" size="small"
                     icon={<EditOutlined />}
                     disabled={this.state.loadersListRules[record.id] || this.state.loadersActiveListRules[record.id]}
                     onClick={() => this.showListRuleDrawer(record.marketplace_id, record)}
                  />
                  <Popconfirm
                     placement="topRight"
                     title={languageManager.getMessage(this.props.commonState, 'common.askDeleteConfirm')}
                     onConfirm={() => this.confirmHandler('deleteItem', record.id)}
                     okText={languageManager.getMessage(this.props.commonState, 'common.yes')}
                     cancelText={languageManager.getMessage(this.props.commonState, 'common.no')}
                  >
                     <Button type="danger" size="small"
                        icon={<DeleteOutlined />}
                        disabled={this.state.loadersActiveListRules[record.id]}
                        loading={this.state.loadersListRules[record.id]}
                        onClick={() => this.handleDeleteListRule(record.marketplace_id, record.id)}
                     />
                  </Popconfirm>
               </Space>
            ),
         },
      ]
      const sale_rules_table_columns = [
         {
            title: 'Regola',
            width: 100,
            key: 'name',
            className: 'drag-visible',
            fixed: 'left',
            render: (text, record) => (
               record.name
            )
         },
         // {
         //    title: 'Gruppo', width: 100, key: 'customer_group', fixed: 'left',
         //    render: (text, record) => (
         //       !_.isNil(record.customer_group) ? record.customer_group.name : ''
         //    )
         // },
         {
            title: 'Categoria', width: 100, key: 'category', fixed: 'left',
            render: (text, record) => (
               !_.isNil(record.category) ? record.category.name[this.state.componentLanguage] : ''
            )
         },
         {
            title: 'Brand', width: 100, key: 'brand', fixed: 'left',
            render: (text, record) => (
               !_.isNil(record.brand) ? record.brand.name : ''
            )
         },
         {
            title: 'Tag', width: 100, key: 'tag', fixed: 'left',
            render: (text, record) => (
               !_.isNil(record.tag) ? record.tag.name[this.state.componentLanguage] : ''
            )
         },
         {
            title: 'Attivo', width: 50, dataIndex: 'active', key: 'active', align: 'center',
            render: (text, record) => (
               <Tooltip title={languageManager.getMessage(this.props.commonState, record.active ? 'common.deactivate' : 'common.activate')}>
                  <Button type={record.active ? 'success' : 'danger'} size="small"
                     icon={record.active ? <CheckOutlined /> : <CloseOutlined />}
                     disabled={this.state.loadersSaleRules[record.id]}
                     loading={this.state.loadersActiveSaleRules[record.id]}
                     onClick={() => this.handleToggleActiveSaleRule(record.marketplace_id, record.id)}
                  />
               </Tooltip>
            )
         },
         {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            align: 'right',
            width: 100,
            render: (text, record) => (
               <Space>
                  <Button type="primary" size="small"
                     icon={<EditOutlined />}
                     disabled={this.state.loadersSaleRules[record.id] || this.state.loadersActiveSaleRules[record.id]}
                     onClick={() => this.showSaleRuleDrawer(record.marketplace_id, record)}
                  />
                  <Popconfirm
                     placement="topRight"
                     title={languageManager.getMessage(this.props.commonState, 'common.askDeleteConfirm')}
                     onConfirm={() => this.confirmHandler('deleteItem', record.id)}
                     okText={languageManager.getMessage(this.props.commonState, 'common.yes')}
                     cancelText={languageManager.getMessage(this.props.commonState, 'common.no')}
                  >
                     <Button type="danger" size="small"
                        icon={<DeleteOutlined />}
                        disabled={this.state.loadersActiveSaleRules[record.id]}
                        loading={this.state.loadersSaleRules[record.id]}
                        onClick={() => this.handleDeleteSaleRule(record.marketplace_id, record.id)}
                     />
                  </Popconfirm>
               </Space>
            ),
         },
      ]


      const Panels = () => {
         // console.log(this.state)
         return (
            !_.isNil(this.props.authState.userData.activeMarketplaces) && !_.isEmpty(this.props.authState.userData.activeMarketplaces) ? (
               <Collapse defaultActiveKey={'panel_' + this.props.authState.userData.activeMarketplaces[0] ? 'panel_' + this.props.authState.userData.activeMarketplaces[0].id : false}>
                  {
                     this.props.authState.userData.activeMarketplaces.map((marketplace, index) => {
                        return (
                           <Panel header={marketplace.name} key={'panel_' + marketplace.id}>
                              <Row gutter={[16, 16]}>
                                 <Col span={24}>
                                    <Space size='small'>
                                       Prezzo standard di vendita:
                                       <Radio.Group
                                          marketplaceId={marketplace.id}
                                          defaultValue={marketplace.sell_price_type}
                                          onChange={(e) => this.handleListPriceTypeChange(marketplace, e)}
                                          disabled={this.state.loadersMarketplaces[marketplace.id]}
                                       >
                                          <Radio.Button value={SellPriceTypeEnum.LIST_PRICE}>Usa prezzi di listino</Radio.Button>
                                          <Radio.Button value={SellPriceTypeEnum.USE_RULES}>Applica regole</Radio.Button>
                                       </Radio.Group>
                                       {
                                          this.state.loadersMarketplaces[marketplace.id] ? <Spin indicator={loadingIcon} /> : null
                                       }
                                    </Space>
                                 </Col>
                              </Row>
                              {
                                 marketplace.sell_price_type !== SellPriceTypeEnum.LIST_PRICE ? (
                                    <Row gutter={[8, 8]}>
                                       <Col span={12}>
                                          <Title level={5}>Regole prezzo di vendita</Title>
                                       </Col>
                                       <Col span={12} className='align-right'>
                                          <Button
                                             type='primary'
                                             icon={<PlusSquareOutlined />}
                                             onClick={() => this.showListRuleDrawer(marketplace.id, null)}
                                          >
                                             {languageManager.getMessage(this.props.commonState, 'component.price_rules.add')}
                                          </Button>
                                       </Col>
                                       <Col span={24}>
                                          <Table
                                             {...tableLayout}
                                             columns={list_rules_table_columns}
                                             dataSource={this.state.marketplaceListRulesList[marketplace.id]}
                                          />
                                       </Col>
                                       <Divider />
                                    </Row>
                                 ) : null
                              }
                              <Row gutter={[8, 8]}>
                                 <Col span={12}>
                                    <Title level={5}>Regole prezzi scontati</Title>
                                 </Col>
                                 <Col span={12} className='align-right'>
                                    <Button
                                       type='primary'
                                       icon={<PlusSquareOutlined />}
                                       onClick={() => this.showSaleRuleDrawer(marketplace.id, null)}
                                    >
                                       {languageManager.getMessage(this.props.commonState, 'component.price_rules.add')}
                                    </Button>
                                 </Col>
                                 <Col span={24}>
                                    <Table
                                       {...tableLayout}
                                       columns={sale_rules_table_columns}
                                       dataSource={this.state.marketplaceSaleRulesList[marketplace.id]}
                                    />
                                 </Col>
                              </Row>
                           </Panel>
                        )
                     })
                  }
               </Collapse>
            ) : null
         )
      }

      const pageContent = (
         <div className="card">
            <div className="card-header">
               <div className="row">
                  <div className="col-sm-6">
                     <Title level={5}>{languageManager.getMessage(this.props.commonState, 'component.prices.title')}</Title>
                  </div>
                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">
                        <Space>
                           <Button
                              type='primary'
                              icon={<PrinterOutlined />}
                              onClick={() => this.printListingsHandler()}
                              loading={this.state.loaderPrintListings}
                           >
                              {languageManager.getMessage(this.props.commonState, 'component.price_rules.print_listings')}
                           </Button>
                        </Space>
                     </div>
                  </div>
               </div>
            </div>
            <div className="card-body">
               <Panels />
               <ListRuleDrawer
                  visible={this.state.showListRuleDrawer}
                  marketplaceId={this.state.listRuleDrawerMarketplaceId}
                  item={this.state.listRuleDrawerItem}
                  onClose={this.hideListRuleDrawer}
                  onSubmit={(rules_list) => this.handleEditListRule(this.state.listRuleDrawerMarketplaceId, rules_list)}
               />
               <SaleRuleDrawer
                  visible={this.state.showSaleRuleDrawer}
                  marketplaceId={this.state.saleRuleDrawerMarketplaceId}
                  item={this.state.saleRuleDrawerItem}
                  onClose={this.hideSaleRuleDrawer}
                  onSubmit={(rules_list) => this.handleEditSaleRule(this.state.saleRuleDrawerMarketplaceId, rules_list)}
               />
            </div>
         </div>
      )

      const helpButton = {
         title: "Aiuto"
      }

      const buttons = []
      buttons.push(helpButton)

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>
      )
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState,
   }
}
const mapDispatchToProps = dispatch => {
   return {
      updateActiveMarketplacesList: () => dispatch(loadActiveMarketplacesData())
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(Prices)