import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import Moment from 'moment'

import { Modal, Form, Input, InputNumber, Button, Space, Divider, Cascader, Select, notification } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons';

import languageManager from '../../../../services/languageManager';
import * as storeActions from '../store/actions/orders';
import * as productsActions from '../../../catalogue/products/store/actions/products';

class ModalAddOrderLine extends Component {
    state = {
        ...this.props,
        componentLoader: true,
        formLoader: false,
        formValues: {},
        componentLanguage: this.props.authState.defaultLanguage
    }
    formRef = React.createRef();

    componentDidMount() {
        // console.log('Component did mount')
        Promise.all([
            //  this.getSuppliersList()
        ]).then(() => {
            this.setState({
                componentLoader: false
            });
        });
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        // console.log('Component did update', nextProps, this.state)
        if (_.isEqual(nextProps.visible, this.props.visible)) {
            return false
        }
        return true
    }

    componentDidUpdate = (prevProps, prevState) => {
        // console.log('Component did update', prevState, this.props)
        if (!_.isEqual(prevProps.visible, this.props.visible)) {
            if (!_.isNil(this.props.item)) {
                var newFormValues = { ...this.props.item }
                this.setFormValues(newFormValues)
            }
            // this.setState({ ...this.props })
            // console.log('Component did update - update state', prevState, this.props)
        }
    }

    setFormValues = (item) => {
        // console.log('Set form values')
        // console.log(item)
        // console.log(this.formRef)
        if (this.formRef.current) {
            this.formRef.current.setFieldsValue(item)
        }
    }


    handleTotalChange = () => {
        const quantity = this.formRef.current.getFieldValue('quantity')
        const actual_price = this.formRef.current.getFieldValue('actualPrice')
        if (!_.isNil(quantity) && !_.isNil(actual_price)) {
            const total_price = (parseFloat(quantity) * parseFloat(actual_price)).toFixed(2)
            this.formRef.current.setFieldsValue({ totalPrice: total_price })
        } else {
            this.formRef.current.setFieldsValue({ totalPrice: null })
        }
    }

    onFinish = values => {
        console.log(values)
        const taxPercentageIndex = this.props.taxRateList.findIndex((obj => obj.id == values.taxRateId))
        const orderLine = {
            line_marketplace_id: values.line_marketplace_id,
            product_name: values.name,
            quantity: parseFloat(values.quantity),
            unit_price: parseFloat(values.actualPrice),
            unit_tax: parseFloat(values.actualPrice) / 100 * parseFloat(this.props.taxRateList[taxPercentageIndex].percentage),
            total_price: parseFloat(values.totalPrice),
            total_tax: parseFloat(values.totalPrice) / 100 * parseFloat(this.props.taxRateList[taxPercentageIndex].percentage),
            tax_rate: parseFloat(this.props.taxRateList[taxPercentageIndex].percentage),
            weight: parseFloat(values.weight) * parseFloat(values.quantity),
            volume: parseFloat(values.volume) * parseFloat(values.quantity)
        }
        this.formRef.current.resetFields()
        this.state.onSubmit(orderLine)
        this.state.onClose()
        notification.open({
            message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
            description: languageManager.getMessage(this.props.commonState, this.state.action === 'edit' ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
            icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
        })
    }

    onClose = () => {
        this.formRef.current.resetFields()
        this.state.onClose()
    }

    render() {
        console.log('Render modal add line to order', this.state)
        const { Option } = Select

        const mainLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 18 },
            layout: "horizontal",
        };

        return (
            <Modal
                visible={this.props.visible}
                title="Aggiungi prodotto"
                width={800}
                onClose={this.onClose}
                onCancel={this.onClose}
                footer={[
                    <Button key="back" onClick={this.onClose} disabled={this.state.formLoader}>Annulla</Button>,
                    <Button key='submitForm' form="formAddLine" type="primary" htmlType="submit" loading={this.state.formLoader}>Salva</Button>
                ]}
            >
                <Form
                    id="formAddLine"
                    ref={this.formRef}
                    {...mainLayout}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                >
                    <Form.Item
                        label="Prodotto"
                        name="name"
                        rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Quantità"
                        name="quantity"
                        rules={[{ required: true }]}
                    >
                        <InputNumber decimalSeparator=',' step={1} min={1} onChange={this.handleTotalChange} />
                    </Form.Item>
                    <Form.Item
                        label="Prezzo netto di vendita"
                        name="actualPrice"
                        rules={[{ required: true }]}
                    >
                        <InputNumber decimalSeparator=',' precision={2} addonAfter='€' onChange={this.handleTotalChange} />
                    </Form.Item>
                    <Form.Item
                        label="Regime IVA"
                        name="taxRateId"
                        rules={[{ required: true }]}
                    >
                        <Select
                            showSearch
                            placeholder="Seleziona il regime IVA"
                            optionFilterProp="children"
                            allowClear={true}
                        >
                            {
                                this.props.taxRateList.map((tax) => {
                                    return <Option key={tax.id} value={tax.id}>{tax.name[this.state.componentLanguage]}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Prezzo totale"
                        name="totalPrice"
                    >
                        <InputNumber decimalSeparator=',' precision={2} addonAfter='€' disabled />
                    </Form.Item>
                    <Form.Item
                        label="Peso unitario"
                        name="weight"
                    >
                        <InputNumber decimalSeparator=',' precision={2} addonAfter='Kg' />
                    </Form.Item>
                    <Form.Item
                        label="Volume unitario"
                        name="volume"
                        rules={[{ required: true }]}
                    >
                        <InputNumber decimalSeparator=',' precision={2} addonAfter='m³' />
                    </Form.Item>
                    <Form.Item name="line_marketplace_id" noStyle><Input type="hidden" /></Form.Item>
                    <Form.Item label="language" name="language" initialValue={this.state.componentLanguage} noStyle><Input type="hidden" /></Form.Item>
                </Form>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    return {
        authState: state.authenticationState,
        commonState: state.commonState
    };
}

export default connect(mapStateToProps)(ModalAddOrderLine)