import API_BASE from './apiBase';
import API_CONFIG from './apiConfig';

import Axios from 'axios';

class CustomersApi {

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getList(parentId) {

      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.customers.getList, {
            parent_id: parentId
         })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getListExtended(page, pageSize, filters) {

      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.customers.getListExtended, {
            page: page,
            page_size: pageSize,
            filters_values: JSON.stringify(filters)
         })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getItem(itemId) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.customers.getItem, {
            id: itemId
         })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getComplements() {
      return new Promise((resolve, reject) => {
         Axios.all([
            Axios.post(API_BASE.url + API_CONFIG.appUrls.paymentMethods.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.banks.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.customerGroups.getList),
         ])
            .then(Axios.spread((paymentMethodsList, banksList, groupsList) => {
               const response = {
                  paymentMethodsList: paymentMethodsList,
                  banksList: banksList,
                  groupsList: groupsList,
               }
               resolve(response);
            }))
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static createOrUpdate(itemData,) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.customers.createOrUpdate, {
            ...itemData
         })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static deleteItem(itemId, return_list) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.customers.deleteItem, {
            id: itemId,
            return_list: return_list
         })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static importFromMarketplace(marketplaceId, return_list) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.customers.importFromMarketplace, {
            marketplace_id: marketplaceId,
            return_list: return_list
         })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }   
   static importFromCSV(formData) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.customers.importFromCSV, { ...formData })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static createOrUpdateShippingAddress(itemData, return_list) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.customerShippingAddresses.createOrUpdate, {
            ...itemData,
            return_list: return_list
         })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }
   static getShippingAddress(itemId) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.customerShippingAddresses.getItem, {
            id: itemId
         })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   static deleteShippingAddress(itemId, customerId, return_list) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.customerShippingAddresses.deleteItem, {
            id: itemId,
            customer_id: customerId,
            return_list: return_list
         })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static createOrUpdateInvoicingAddress(itemData, return_list) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.customerInvoicingAddresses.createOrUpdate, {
            ...itemData,
            return_list: return_list
         })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }
   static getInvoicingAddress(itemId) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.customerInvoicingAddresses.getItem, {
            id: itemId
         })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   static deleteInvoicingAddress(itemId, customerId, return_list) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.customerInvoicingAddresses.deleteItem, {
            id: itemId,
            customer_id: customerId,
            return_list: return_list
         })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static exportCustomersCSV(filename, customerIds) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.customers.exportCSV, { filename: filename, customer_ids: customerIds })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   
   static activateForMarketplace(productIds, params) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.customers.activateForMarketplace, {
            product_ids: productIds,
            ...params,
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   static deactivateForMarketplace(productIds, params) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.customers.deactivateForMarketplace, {
            product_ids: productIds,
            ...params,
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   static sendToMarketplace(productIds, params) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.customers.sendToMarketplace, {
            product_ids: productIds,
            ...params,
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }


}

export default CustomersApi;