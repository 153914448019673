import BanksApi from '../../../../../api/BanksApi'
import _ from 'lodash'
/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function getList() {
   return BanksApi.getList()
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else {
            throw new Error(response.data.human ? response.data.human :
               response.data.error ? response.data.error : 'serverError');
         }
      })
      .catch(error => {
         throw new Error(error.data.human ? error.data.human :
            error.data.error ? error.data.error : 'serverError');
      });
}

export function getListExtended(page, pageSize, filters) {
   return BanksApi.getListExtended(page, pageSize, filters)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else {
            throw new Error(response.data.human ? response.data.human :
               response.data.error ? response.data.error : 'serverError');
         }
      })
      .catch(error => {
         throw new Error(error.data.human ? error.data.human :
            error.data.error ? error.data.error : 'serverError');
      });
}

export function getItem(itemId) {
   return BanksApi.getItem(itemId)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else {
            throw new Error(response.data.human ? response.data.human :
               response.data.error ? response.data.error : 'serverError');
         }
      })
      .catch(error => {
         throw new Error(error.data.human ? error.data.human :
            error.data.error ? error.data.error : 'serverError');
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function createOrUpdate(itemData) {
   console.log(itemData);
   return BanksApi.createOrUpdate(itemData)
      .then(response => {
         if (response.data.success === true) {
            console.log(response);
            return response.data;
         }
         else {
            throw new Error(response.data.human ? response.data.human :
               response.data.error ? response.data.error : 'serverError');
         }
      })
      .catch(error => {
         throw new Error(error.data.human ? error.data.human :
            error.data.error ? error.data.error : 'serverError');
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function deleteItem(itemId, return_list = false) {
   return BanksApi.deleteItem(itemId, return_list)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else {
            throw new Error(response.data.human ? response.data.human :
               response.data.error ? response.data.error : 'serverError');
         }
      })
      .catch(error => {
         throw new Error(error.data.human ? error.data.human :
            error.data.error ? error.data.error : 'serverError');
      });
}