import * as constants from '../../constants';
import initialState from './initialState';

/**
 *
 * @param state
 * @param action
 */
export default function commonReducer(state = initialState.commonState, action) {
    switch (action.type) {
        case constants.ACTIONS.SYSTEM_FATAL_ERROR:
            return Object.assign({}, state, { errorCode: action.errorCode });
        case constants.ACTIONS.LOAD_LANGUAGES_SUCCESS:
            return Object.assign({}, state, { messages: action.messages });
        case constants.ACTIONS.UPDATE_LANGUAGES_SUCCESS:
            return Object.assign({}, state, { messages: Object.assign(state.messages, action.messages) });
        case constants.ACTIONS.SCREEN_LOCK_REQUEST:
            return Object.assign({}, state, { blocking: true });
        case constants.ACTIONS.SCREEN_UNLOCK_REQUEST:
            return Object.assign({}, state, { blocking: false });
        case constants.ACTIONS.CFG_LOADING:
            return Object.assign({}, state, { cfgLoading: ++state.cfgLoading });
        case constants.ACTIONS.CFG_LOADED:
            return Object.assign({}, state, { cfgLoading: --state.cfgLoading });
        case constants.ACTIONS.GET_COUNTRIES_SUCCESS:
            const typeCountries = Object.assign({}, state.store, { countries: action.countries });
            return Object.assign({}, state, { store: typeCountries });            
        default:
            return state;
    }
};