import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Moment from 'moment'
import _ from 'lodash'

import { Table, Select, Button, Space, notification, Descriptions, Row, Col, List, Modal, Typography } from 'antd';
import { InfoCircleOutlined, DoubleLeftOutlined, ExclamationCircleOutlined, DownloadOutlined, EditOutlined } from '@ant-design/icons';
// import Highlighter from 'react-highlight-words';
import { SearchOutlined, CheckOutlined, CloseOutlined, EditTwoTone, DeleteTwoTone } from '@ant-design/icons';

import languageManager from '../../../services/languageManager';

import * as storeActions from './store/actions/purchase_orders';

import Loader from '../../common/PageElements/Loader';
import PageHeader from '../../common/PageElements/PageHeader'

class PurchaseOrderDetails extends Component {

   state = {
      action: undefined,
      saveAndClose: false,
      pageLoader: true,
      loaderCancelOrder: false,
      orderData: {
         customer: {}
      },
      orderDetails: {},
      orderLines: [],
      componentLanguage: this.props.authState.defaultLanguage,
   };

   componentDidMount() {
      const itemId = this.props.match.params.id
      Promise.all([
         this.getItem(itemId),
         // this.getComplements()
      ]).then(() => {
         this.setState({
            action: 'edit',
            itemId: itemId,
            pageLoader: false
         })
      })
   }

   getItem = (id) => {
      return storeActions.getItem(id)
         .then(response => {
            console.log(response);
            try {
               // console.log(updatedValues)
               this.setState({ response: response.data, orderData: response.data });
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }
         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   handleSaveAndClose = () => {
      this.setState({ saveAndClose: true })
   }

   setDefaultLanguageHandler = (value) => {
      console.log(value);
      const updatedValues = this.setForm(this.state.response, value);
      this.setState({ formValues: updatedValues, componentLanguage: value })
   }

   updateOrderStatus = (status) => {
      console.log('Update order status', this.state.itemId, status)
      // console.log(values)
      this.setState({ loaderUpdateOrder: true })
      storeActions.updateOrderStatus(this.state.itemId, status)
         .then(response => {
            console.log(response);
            Promise.all([
               this.getItem(this.state.itemId)
            ]).then(() => {
               this.setState({ loaderUpdateOrder: false })
               notification.success({
                  message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
                  description: response.human ? (
                     <List
                        size="small"
                        split={false}
                        dataSource={response.human}
                        renderItem={item => <List.Item>{item}</List.Item>}
                     />
                  ) : languageManager.getMessage(this.props.commonState, 'notification.confirm.updateOk'),
                  duration: 0
               })
            })
            // let newOrderData = { ...this.state.orderData }
            // newOrderData.purchase_order_status = response.data.purchase_order_status
         }).catch((e) => {
            this.setState({ loaderUpdateOrder: false })
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.human ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.human}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
         })
   }
   cancelOrder = () => {
      console.log('Cancel order', this.state.itemId)
      // console.log(values)
      this.setState({ loaderCancelOrder: true })
      storeActions.cancelOrder(this.state.itemId)
         .then(response => {
            console.log(response);
            this.setState({ loaderCancelOrder: false })
            this.props.history.push('/purchase_orders/list');
            notification.success({
               message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
               description: response.human ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={response.human}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.confirm.updateOk'),
               duration: 0
            })
         }).catch((e) => {
            this.setState({ loaderCancelOrder: false })
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.human ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.human}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
         })
   }

   render() {
      if (this.state.pageLoader) return <Loader />
      const orderData = this.state.orderData
      const { Title } = Typography
      const { Option } = Select

      const orderProductsTableLayout = {
         bordered: true,
         className: "table-striped-rows",
         size: "small",
         pagination: false,
      }
      const orderProductsColumns = [
         {
            title: 'SKU',
            width: 125,
            dataIndex: 'product_sku',
            key: 'product_sku',
         },
         {
            title: 'Codice fornitore',
            width: 150,
            dataIndex: 'supplier_id',
            key: 'supplier_id',
         },
         {
            title: 'Prodotto',
            key: 'name',
            render: (record) => (
               record.product_name
            ),
         },
         {
            title: 'Lotto',
            width: 150,
            dataIndex: 'batch',
            key: 'batch',
         },
         {
            title: 'Scadenza',
            width: 100,
            key: 'expiration_date',
            render: (record) => (
               record.expiration_date ? Moment(record.expiration_date).format('L') : null
            ),
         },
         {
            title: languageManager.getMessage(this.props.commonState, 'component.order_summary.products.table.unit_price'),
            width: 100,
            key: 'unit_price',
            render: (record) => (
               record.unit_price_comma ? record.unit_price_comma + '€' : ''
            ),
         },
         {
            title: languageManager.getMessage(this.props.commonState, 'component.order_summary.products.table.quantity'),
            width: 100,
            key: 'quantity',
            render: (record) => (
               record.quantity_comma
            ),
         },
         {
            title: languageManager.getMessage(this.props.commonState, 'component.order_summary.products.table.total_price'),
            width: 100,
            key: 'total_price',
            render: (record) => (
               record.total_price_comma ? record.total_price_comma + '€' : ''
            ),
         },
         {
            title: languageManager.getMessage(this.props.commonState, 'component.order_summary.products.table.total_tax'),
            width: 100,
            key: 'total_tax',
            render: (record) => (
               record.total_tax_comma ? record.total_tax_comma + '€' : ''
            ),
         },
      ]

      const order_wrapper = (
         <Space direction='vertical' size='small'>
            <Row gutter={32}>
               <Col span={8}>
                  <Space direction='vertical' size='small'>
                     <Descriptions size='small' column={1} bordered labelStyle={{ width: '200px' }} contentStyle={{ fontWeight: 'bold' }}>
                        <Descriptions.Item label="Numero ordine">{orderData.internal_order_number}</Descriptions.Item>
                        <Descriptions.Item label="Numero ordine fornitore">{orderData.supplier_order_number}</Descriptions.Item>
                        <Descriptions.Item label="Numero ordine di vendita">{orderData.selling_order_number}</Descriptions.Item>
                        <Descriptions.Item label="Tipo di ordine">{orderData.is_virtual ? 'Ordine virtuale' : 'Ordine standard'}</Descriptions.Item>
                     </Descriptions>
                     <Descriptions size='small' column={1} bordered labelStyle={{ width: '200px' }} contentStyle={{ fontWeight: 'bold' }}>
                        <Descriptions.Item label="Data ordine">{orderData.create_order_date_human}</Descriptions.Item>
                        <Descriptions.Item label="Consegna prevista">{orderData.deliver_request_date_human}</Descriptions.Item>
                        <Descriptions.Item label="Data di consegna">{orderData.delivered_order_date_human}</Descriptions.Item>
                     </Descriptions>
                     {
                        !_.isNil(orderData.shipping_method) ? (
                           <Descriptions size='small' column={1} bordered labelStyle={{ width: '200px' }} contentStyle={{ fontWeight: 'bold' }}>
                              <Descriptions.Item label="Metodo di spedizione">{orderData.shipping_method.name}</Descriptions.Item>
                           </Descriptions>
                        ) : null
                     }
                     <Descriptions size='small' column={1} bordered labelStyle={{ width: '200px' }} contentStyle={{ fontWeight: 'bold' }}>
                        <Descriptions.Item label="Note ordine">{orderData.order_note}</Descriptions.Item>
                        <Descriptions.Item label="Note fornitore">{orderData.supplier_note}</Descriptions.Item>
                     </Descriptions>
                  </Space>
               </Col>
               <Col span={16}>
                  <Space direction='vertical'>
                     <Row gutter={32}>
                        <Col span={12}>
                           <Descriptions size='small' column={1} bordered labelStyle={{ width: '150px' }} contentStyle={{ fontWeight: 'bold' }}>
                              <Descriptions.Item label="Stato ordine">{orderData.purchase_order_status.name.it}</Descriptions.Item>
                           </Descriptions>
                        </Col>
                        <Col span={12}>
                           {
                              orderData.purchase_order_status_id === 1 ? (
                                 <Button type='success' size='large' icon={<CheckOutlined />}
                                    loading={this.state.loaderUpdateOrder}
                                    onClick={() =>
                                       Modal.confirm({
                                          title: languageManager.getMessage(this.props.commonState, 'component.orders.update_order'),
                                          icon: <ExclamationCircleOutlined />,
                                          content: languageManager.getMessage(this.props.commonState, 'common.askOperationConfirm'),
                                          okText: languageManager.getMessage(this.props.commonState, 'common.confirm'),
                                          onOk: () => this.updateOrderStatus('WAITING_DELIVERY')
                                       })
                                    }
                                 >Conferma ordine</Button>
                              ) : orderData.purchase_order_status_id === 2 ? (
                                 <Button type='success' size='large' icon={<CheckOutlined />}
                                    loading={this.state.loaderUpdateOrder}
                                    onClick={() =>
                                       Modal.confirm({
                                          title: languageManager.getMessage(this.props.commonState, 'component.orders.update_order'),
                                          icon: <ExclamationCircleOutlined />,
                                          content: languageManager.getMessage(this.props.commonState, 'common.askOperationConfirm'),
                                          okText: languageManager.getMessage(this.props.commonState, 'common.confirm'),
                                          onOk: () => this.updateOrderStatus('DELIVERED')
                                       })
                                    }
                                 >Conferma ricezione ordine</Button>
                              ) : null
                           }
                        </Col>
                     </Row>
                     <Descriptions size='small' column={2} bordered labelStyle={{ width: '150px' }} contentStyle={{ fontWeight: 'bold' }} title='Dati fornitore'>
                        <Descriptions.Item label="C.F.">{orderData.supplier.fiscal_code}</Descriptions.Item>
                        <Descriptions.Item label="P.IVA">{orderData.supplier.vat}</Descriptions.Item>
                     </Descriptions>
                     {/* <Row gutter={32}>
                        <Col span={12}>
                           <Descriptions size='small' column={1} bordered labelStyle={{ width: '150px' }} contentStyle={{ fontWeight: 'bold' }} title='Indirizzo di spedizione'>
                              <Descriptions.Item label="Nome">{orderData.shipping_address.name}</Descriptions.Item>
                              <Descriptions.Item label="Azienda">{orderData.shipping_address.company}</Descriptions.Item>
                              <Descriptions.Item label="Indirizzo">{orderData.shipping_address.address_1}</Descriptions.Item>
                              <Descriptions.Item label="Indirizzo (opz.)">{orderData.shipping_address.address_2}</Descriptions.Item>
                              <Descriptions.Item label="CAP">{orderData.shipping_address.postal_code}</Descriptions.Item>
                              <Descriptions.Item label="Città">{orderData.shipping_address.city}</Descriptions.Item>
                              <Descriptions.Item label="Provincia">{orderData.shipping_address.state}</Descriptions.Item>
                              <Descriptions.Item label="Nazione">{orderData.shipping_address.country}</Descriptions.Item>
                              <Descriptions.Item label="Email">{orderData.shipping_address.email}</Descriptions.Item>
                              <Descriptions.Item label="Telefono">{orderData.shipping_address.phone}</Descriptions.Item>
                              <Descriptions.Item label="Note">{orderData.shipping_address.note}</Descriptions.Item>
                           </Descriptions>
                        </Col>
                        <Col span={12}>
                           {
                              orderData.billing_address ? (
                                 <Descriptions size='small' column={1} bordered labelStyle={{ width: '150px' }} contentStyle={{ fontWeight: 'bold' }} title='Indirizzo di fatturazione'>
                                    <Descriptions.Item label="Nome">{orderData.billing_address.name}</Descriptions.Item>
                                    <Descriptions.Item label="Azienda">{orderData.billing_address.company}</Descriptions.Item>
                                    <Descriptions.Item label="Indirizzo">{orderData.billing_address.address_1}</Descriptions.Item>
                                    <Descriptions.Item label="Indirizzo (opz.)">{orderData.billing_address.address_2}</Descriptions.Item>
                                    <Descriptions.Item label="CAP">{orderData.billing_address.postal_code}</Descriptions.Item>
                                    <Descriptions.Item label="Città">{orderData.billing_address.city}</Descriptions.Item>
                                    <Descriptions.Item label="Provincia">{orderData.billing_address.state}</Descriptions.Item>
                                    <Descriptions.Item label="Nazione">{orderData.billing_address.country}</Descriptions.Item>
                                    <Descriptions.Item label="Email">{orderData.billing_address.email}</Descriptions.Item>
                                    <Descriptions.Item label="Telefono">{orderData.billing_address.phone}</Descriptions.Item>
                                 </Descriptions>
                              ) : null
                           }
                        </Col>
                     </Row> */}
                  </Space>
               </Col>
            </Row>
            <Row gutter={32}>
               <Col span={24}>
                  <Table {...orderProductsTableLayout}
                     columns={orderProductsColumns}
                     dataSource={orderData.order_lines}
                  />
               </Col>
            </Row>
            {/* <Row gutter={32}>
               <Col span={8} offset={16}>
                  <Descriptions size='small' column={1} bordered labelStyle={{ width: '200px' }} contentStyle={{ fontWeight: 'bold' }}>
                     <Descriptions.Item label="Subtotale prodotti">{orderData.product_total_comma + '€'}</Descriptions.Item>
                     <Descriptions.Item label="Totale IVA">{orderData.total_tax_comma + '€'}</Descriptions.Item>
                     <Descriptions.Item label="Spedizione">{orderData.shipping_total_comma + '€'}</Descriptions.Item>
                     <Descriptions.Item label="Totale ordine">{orderData.price_total_comma + '€'}</Descriptions.Item>
                  </Descriptions>
               </Col>
            </Row> */}
         </Space>
      )

      const pageContent = (
         <div className="card">
            <div className="card-header">
               <div className="row">
                  <div className="col-sm-6">
                     <Title level={5}>{languageManager.getMessage(this.props.commonState, 'component.purchase_orders.details')}</Title>
                  </div>
                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">
                        <Space>
                           <Select
                              showSearch
                              className="width-70px-I"
                              placeholder="Seleziona la lingua"
                              optionFilterProp="children"
                              onChange={this.setDefaultLanguageHandler}
                           >
                              {
                                 this.props.authState.userData.languages.map((lang) => {
                                    return <Option key={lang.value} value={lang.value}>{lang.label}</Option>
                                 })
                              }
                           </Select>
                        </Space>
                     </div>
                  </div>
               </div>
            </div>
            <div className="card-body">
               {order_wrapper}
            </div>
            <div className="card-footer">
               <div className="align-items-center row">
                  <div className="col-sm-6">
                     <Space size='small'>
                        <Link to={{ pathname: "/purchase_orders/list" }} >
                           <Button icon={<DoubleLeftOutlined />}>
                              {languageManager.getMessage(this.props.commonState, 'common.back')}
                           </Button>
                        </Link>
                     </Space>
                  </div>
                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">
                        <Space size='small'>
                           <Link to={{ pathname: "/purchase_orders/edit/" + this.state.itemId }} >
                              <Button type='primary' icon={<EditOutlined />}
                                 disabled={this.state.loaderCancelOrder} >
                                 {languageManager.getMessage(this.props.commonState, 'common.edit')}
                              </Button>
                           </Link>
                           <Button type='danger' icon={<CloseOutlined />}
                              loading={this.state.loaderCancelOrder}
                              title="Annulla ordine"
                              onClick={() =>
                                 Modal.confirm({
                                    title: languageManager.getMessage(this.props.commonState, 'component.orders.cancel_order'),
                                    icon: <ExclamationCircleOutlined />,
                                    content: languageManager.getMessage(this.props.commonState, 'common.askOperationConfirm'),
                                    okText: languageManager.getMessage(this.props.commonState, 'common.confirm'),
                                    onOk: this.cancelOrder
                                 })
                              }
                           />
                        </Space>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      );

      const helpButton = {
         title: "Aiuto"
      }
      const buttons = [];
      buttons.push(helpButton);

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>

      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState,
   };
};

export default connect(mapStateToProps)(PurchaseOrderDetails);