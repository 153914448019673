import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Moment from 'moment';
import { OrderStatusEnum, OrderStatusActionEnum, OrderLineActionEnum } from '../../../shared/enums.ts';

import { Form, Layout, Select, Row, Col, Card, Table, Typography, notification, Descriptions, List, Space, Button, Popconfirm, Menu, Input, InputNumber, Switch, Modal } from 'antd';
import { InfoCircleOutlined, DeleteOutlined, RedoOutlined, CheckCircleOutlined, CloseCircleOutlined, EuroOutlined, ReloadOutlined } from '@ant-design/icons';
import languageManager from '../../../services/languageManager';

import * as storeActions from './store/actions/orders';

import Loader from '../../common/PageElements/Loader';
import PageHeader from '../../common/PageElements/PageHeader';
import NotificationsTable from '../../common/components/NotificationsTable';
import { concat, isNull } from 'lodash';

import moment from 'moment';
import 'moment/locale/it';

class OrderSummary extends Component {

   state = {
      action: undefined,
      saveAndClose: false,
      loader: true,
      formShipOrderLoader: false,
      formCancelProductLoader: false,
      formRefundProductLoader: false,
      formReturnProductLoader: false,
      orderStatusActionLoader: false,
      orderDetails: {},
      orderStatus: {},
      orderLines: [],
      orderNotes: [],
      billing_address: {},
      shipping_address: {},
      carrierList: [],
      componentLanguage: this.props.authState.defaultLanguage,
      addShipping: false,
      tracking_code: '',
      carrier_id: '',
      shipped_order_date: '',
      showModal: false,
      showModalShipOrder: false,
      showModalCancelProduct: false,
      lineToCancel: {},
      showModalRefundProduct: false,
      lineToRefund: {},
      showModalReturnProduct: false,
      lineToReturn: {},
      selectedRowKeys: [],

      returnReasonsList: [
         {
            code: "UNKNOWN",
            value: 'Sconosciuto'
         },
         {
            code: "COUNTERFEIT",
            value: 'Contraffatto'
         },
         {
            code: "INCORRECT_PRODUCT",
            value: 'Prodotto non corretto'
         },
         {
            code: "INVOLUNTARY_RETURN",
            value: 'INVOLUNTARY_RETURN'
         },
         {
            code: "PRODUCT_NOT_PURCHASED",
            value: 'PRODUCT_NOT_PURCHASED'
         },
         {
            code: "NOT_RECEIVED",
            value: 'NOT_RECEIVED'
         },
         {
            code: "DELIVERED_TOO_LATE",
            value: 'DELIVERED_TOO_LATE'
         },
         {
            code: "POOR_QUALITY_NOT_FUNCTIONING",
            value: 'POOR_QUALITY_NOT_FUNCTIONING'
         },
         {
            code: "PACKAGE_WAS_DAMAGED",
            value: 'PACKAGE_WAS_DAMAGED'
         },
         {
            code: "PRODUCT_DAMAGED",
            value: 'PRODUCT_DAMAGED'
         },
         {
            code: "MISSING_PARTS_ACCESSORIES",
            value: 'MISSING_PARTS_ACCESSORIES'
         },
         {
            code: "PRODUCT_DIFFERENT_ORDERED",
            value: 'PRODUCT_DIFFERENT_ORDERED'
         },
         {
            code: "PRODUCT_AND_PACKAGE_DAMAGED",
            value: 'PRODUCT_AND_PACKAGE_DAMAGED'
         },
         {
            code: "PRODUCT_NOT_MATCH_WITH_DESCRIPTION",
            value: 'PRODUCT_NOT_MATCH_WITH_DESCRIPTION'
         },
         {
            code: "INCORRECT_PRODUCT_IMAGE",
            value: 'INCORRECT_PRODUCT_IMAGE'
         },
         {
            code: "MORE_THAN_ONE_SIZE",
            value: 'MORE_THAN_ONE_SIZE'
         },
         {
            code: "SIZE_DONT_FIT",
            value: 'SIZE_DONT_FIT'
         },
         {
            code: "VOLUNTARY_RETURN",
            value: 'VOLUNTARY_RETURN'
         },
         {
            code: "INCORRECT_STYLE",
            value: 'INCORRECT_STYLE'
         },
         {
            code: "PRODUCT_NOT_NEEDED",
            value: 'PRODUCT_NOT_NEEDED'
         },
      ],
   };

   getItem = (orderId) => {
      storeActions.getItem(orderId)
         .then(response => {
            console.log(response);
            try {
               this.setState({
                  orderLines: response.data.order_lines,
                  orderDetails: response.data,
                  orderStatus: response.data.order_status,
                  shipping_address: response.data.shipping_address,
                  billing_address: response.data.billing_address,
                  orderNotes: response.data.order_notes,
                  loader: false
               });
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e);
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   componentDidMount() {
      const itemId = this.props.match.params.id;
      // asyncronous calls
      this.getItem(itemId);
      storeActions.getOrderComplements()
         .then(response => {
            console.log(response);
            try {
               this.setState({
                  carrierList: response.carrierList,
               });
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e);
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
      console.log("history state", this.props.location.state)
   }



   orderActionHandler = (orderId, action, refusedLines) => {
      this.setState({ orderStatusActionLoader: true });
      switch (action) {
         case OrderStatusActionEnum.ACCEPT:
            storeActions.acceptOrder(orderId)
               .then(response => {
                  this.setState({ orderStatusActionLoader: false });
                  console.log(response);
                  notification.open({
                     message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
                     description: response.data.message,
                     icon: <CheckCircleOutlined style={{ color: '#108ee9' }} />,
                  })
                  try {
                     this.getItem(this.props.match.params.id);
                  } catch (e) {
                     notification.open({
                        message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                        description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                        icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                     })
                  }

               }).catch((e) => {
                  this.setState({ orderStatusActionLoader: false });
                  notification.open({
                     message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                     description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                     icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                  })
                  if (this.props.commonState.debug) console.log(e);
               });
            break;
         // case OrderStatusActionEnum.ACCEPT_PARTIAL:
         //    storeActions.acceptPartialOrder(orderId, refusedLines)
         //       .then(response => {
         //          this.setState({ orderStatusActionLoader: false });
         //          console.log(response);
         //          notification.open({
         //             message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
         //             description: response.data.message,
         //             icon: <CheckCircleOutlined style={{ color: '#108ee9' }} />,
         //          })
         //          try {
         //             this.getItem(this.props.match.params.id);
         //          } catch (e) {
         //             notification.open({
         //                message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
         //                description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
         //                icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
         //             })
         //          }

         //       }).catch((e) => {
         //          this.setState({ orderStatusActionLoader: false });
         //          notification.open({
         //             message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
         //             description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
         //             icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
         //          })
         //          if (this.props.commonState.debug) console.log(e);
         //       });
         //    break;
         case OrderStatusActionEnum.PROCESS:
            storeActions.processOrder(orderId)
               .then(response => {
                  this.setState({ orderStatusActionLoader: false });
                  console.log(response);
                  notification.open({
                     message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
                     description: response.data.message,
                     icon: <CheckCircleOutlined style={{ color: '#108ee9' }} />,
                  })
                  try {
                     this.getItem(this.props.match.params.id);
                  } catch (e) {
                     notification.open({
                        message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                        description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                        icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                     })
                  }

               }).catch((e) => {
                  this.setState({ orderStatusActionLoader: false });
                  notification.open({
                     message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                     description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                     icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                  })
                  if (this.props.commonState.debug) console.log(e);
               });
            break;
         case OrderStatusActionEnum.REFUSE:
            storeActions.refuseOrder(orderId)
               .then(response => {
                  this.setState({ orderStatusActionLoader: false });
                  console.log(response);
                  notification.open({
                     message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
                     description: response.data.message,
                     icon: <CheckCircleOutlined style={{ color: '#108ee9' }} />,
                  })
                  try {
                     this.getItem(this.props.match.params.id);
                  } catch (e) {
                     notification.open({
                        message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                        description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                        icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                     })
                  }

               }).catch((e) => {
                  this.setState({ orderStatusActionLoader: false });
                  notification.open({
                     message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                     description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                     icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                  })
                  if (this.props.commonState.debug) console.log(e);
               });
            break;
         default:
            this.setState({ orderStatusActionLoader: false });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.action_not_found'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            break;
      }
   }

   orderShipHandler = () => {
      this.setState({ loader: true });
      let shippingParameters = {
         tracking_code: this.state.tracking_code,
         carrier_id: this.state.carrier_id
      }
      storeActions.shipOrder(this.state.orderDetails.id, shippingParameters)
         .then(response => {
            console.log(response);
            try {
               this.getItem(this.state.orderDetails.id);
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   onShipOrderFinish = values => {
      this.setState({ formShipOrderLoader: true });
      storeActions.shipOrder(this.state.orderDetails.id, values)
         .then(response => {
            console.log(response);
            try {
               this.getItem(this.state.orderDetails.id);
               this.setState({ formShipOrderLoader: false, showModalShipOrder: false });

               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
                  description: languageManager.getMessage(this.props.commonState, this.state.action === 'edit' ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
                  icon: <CheckCircleOutlined className="color-green-I" />,
               })
            } catch (e) {
               this.setState({ formShipOrderLoader: false });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <CloseCircleOutlined className="color-red-I" />,
               })
            }

         }).catch((e) => {
            this.setState({ formShipOrderLoader: false });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <CloseCircleOutlined className="color-red-I" />,
            })
         });
   };

   onShipOrderFinishFailed = errorInfo => {
      notification.open({
         message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
         description: languageManager.getMessage(this.props.commonState, 'notification.error.formInvalid'),
         icon: <CloseCircleOutlined className="color-red-I" />,
      })
   };

   onCancelProductFinish = values => {
      console.log(values)
      this.setState({ formCancelProductLoader: true });
      storeActions.cancelOrderLine(this.state.orderDetails.id, this.state.lineToCancel.id, values)
         .then(response => {
            console.log(response);
            try {
               this.getItem(this.state.orderDetails.id);
               this.setState({ formCancelProductLoader: false, showModalCancelProduct: false });

               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
                  description: languageManager.getMessage(this.props.commonState, this.state.action === 'edit' ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
                  icon: <CheckCircleOutlined className="color-green-I" />,
               })
            } catch (e) {
               this.setState({ formCancelProductLoader: false });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <CloseCircleOutlined className="color-red-I" />,
               })
            }

         }).catch((e) => {
            this.setState({ formCancelProductLoader: false });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <CloseCircleOutlined className="color-red-I" />,
            })
         });
   };

   onCancelProductFinishFailed = errorInfo => {
      notification.open({
         message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
         description: languageManager.getMessage(this.props.commonState, 'notification.error.formInvalid'),
         icon: <CloseCircleOutlined className="color-red-I" />,
      })
      this.setState({ formCancelProductLoader: false });
   };

   onRefundProductFinish = values => {
      console.log(values)
      this.setState({ formRefundProductLoader: true });
      storeActions.refundOrderLine(this.state.orderDetails.id, this.state.lineToRefund.id, values)
         .then(response => {
            console.log(response);
            try {
               this.getItem(this.state.orderDetails.id);
               this.setState({ formRefundProductLoader: false, showModalRefundProduct: false });

               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
                  description: languageManager.getMessage(this.props.commonState, this.state.action === 'edit' ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
                  icon: <CheckCircleOutlined className="color-green-I" />,
               })
            } catch (e) {
               this.setState({ formRefundProductLoader: false });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <CloseCircleOutlined className="color-red-I" />,
               })
            }

         }).catch((e) => {
            this.setState({ formRefundProductLoader: false });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <CloseCircleOutlined className="color-red-I" />,
            })
         });
   };

   onRefundProductFinishFailed = errorInfo => {
      notification.open({
         message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
         description: languageManager.getMessage(this.props.commonState, 'notification.error.formInvalid'),
         icon: <CloseCircleOutlined className="color-red-I" />,
      })
      this.setState({ formRefundProductLoader: false });
   };

   onReturnProductFinish = values => {
      console.log(values)
      this.setState({ formReturnProductLoader: true });
      storeActions.returnOrderLine(this.state.orderDetails.id, this.state.lineToReturn.id, values)
         .then(response => {
            console.log(response);
            try {
               this.getItem(this.state.orderDetails.id);
               this.setState({ formReturnProductLoader: false, showModalReturnProduct: false });

               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
                  description: languageManager.getMessage(this.props.commonState, this.state.action === 'edit' ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
                  icon: <CheckCircleOutlined className="color-green-I" />,
               })
            } catch (e) {
               this.setState({ formReturnProductLoader: false });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <CloseCircleOutlined className="color-red-I" />,
               })
            }

         }).catch((e) => {
            this.setState({ formReturnProductLoader: false });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <CloseCircleOutlined className="color-red-I" />,
            })
         });
   };

   onReturnProductFinishFailed = errorInfo => {
      notification.open({
         message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
         description: languageManager.getMessage(this.props.commonState, 'notification.error.formInvalid'),
         icon: <CloseCircleOutlined className="color-red-I" />,
      })
      this.setState({ formReturnProductLoader: false });
   };

   orderLineActionHandler = (lineId, action) => {
      this.setState({ loader: true });
      switch (action) {
         case OrderLineActionEnum.REFUSE:
            storeActions.refuseOrderLine(lineId)
               .then(response => {
                  console.log(response);
                  notification.open({
                     message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
                     description: response.data.message,
                     icon: <CheckCircleOutlined style={{ color: '#108ee9' }} />,
                  })
                  try {
                     this.getItem(this.props.match.params.id);
                  } catch (e) {
                     notification.open({
                        message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                        description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                        icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                     })
                  }

               }).catch((e) => {
                  notification.open({
                     message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                     description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                     icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                  })
                  if (this.props.commonState.debug) console.log(e);
               });
            break;
         default:
            this.setState({ loader: false });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.action_not_found'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            break;
      }
   }

   //OLD
   // rejectProductsHandler = (orderId, newStatus, lineIds) => {
   //    this.setState({ loader: true });
   //    storeActions.rejectOrderProducts(orderId, lineIds)
   //       .then(response => {
   //          console.log(response);
   //          try {
   //             this.orderStatusHandler(orderId, newStatus);
   //          } catch (e) {
   //             notification.open({
   //                message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
   //                description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
   //                icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
   //             })
   //          }

   //       }).catch((e) => {
   //          notification.open({
   //             message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
   //             description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
   //             icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
   //          })
   //          if (this.props.commonState.debug) console.log(e);
   //       });

   // }

   handleMenuClick = (e) => {
      if (e.key === "all")
         this.orderStatusHandler(this.state.orderDetails.id, 'CANCELLED')
      else
         this.showModal()
   }

   showCancelProductModal = (record) => {
      this.setState({
         showModalCancelProduct: true,
         lineToCancel: record
      })
   }

   showRefundProductModal = (record) => {
      this.setState({
         showModalRefundProduct: true,
         lineToRefund: record
      })
   }

   showReturnProductModal = (record) => {
      this.setState({
         showModalReturnProduct: true,
         lineToReturn: record
      })
   }

   showModal = () => {
      this.setState({
         showModal: true
      })
   }

   cancelModal = () => {
      this.setState({
         showModal: false
      })
   }

   submitModal = () => {
      console.log("Chaiamta API per rifiutare i prodotti selezionati", this.state.selectedRowKeys);
      // this.orderRejectProducts(this.state.orderDetails.id, 'PENDING', this.state.selectedRowKeys)
      this.orderActionHandler(this.state.orderDetails.id, OrderStatusActionEnum.ACCEPT_PARTIAL, this.state.selectedRowKeys)
      this.setState({
         showModal: false
      })
   }

   tracking_codeChange(e) {
      this.setState({
         tracking_code: e.target.value
      })
   }

   carrier_idChangeHandle(value) {
      this.setState({
         carrier_id: value
      })
   }

   addShippingHandle() {
      this.setState({
         addShipping: true
      })
   }

   cancelAddShippingHandle() {
      this.setState({
         addShipping: false,
         tracking_code: '',
         carrier_id: ''
      });

   }

   confirmAddShipping() {
      this.orderShipHandler();
      this.setState({
         addShipping: false
      });
   }

   onSelectChange = (selectedRowKeys) => {
      this.setState({
         selectedRowKeys: selectedRowKeys
      });
      console.log(selectedRowKeys);
   };

   render() {
      if (!this.props.authState.userData.languages) return '';
      const { Option } = Select;
      const { Text, Link } = Typography;


      const helpButton = {
         title: "Aiuto"
      }

      const buttons = [];
      buttons.push(helpButton);

      const columnsOrder = [
         {
            title: 'SKU',
            width: 150,
            dataIndex: 'sku',
            key: 'sku',
         },
         {
            title: languageManager.getMessage(this.props.commonState, 'component.order_summary.products.table.name'),
            key: 'name',
            render: (record) => (
               record.variation_name ? <span><a>{record.name}<br />{record.variation_name}</a></span> : <a>{record.name}</a>
            ),
         },
         // {
         //    title: 'EAN',
         //    dataIndex: 'ean',
         //    key: 'ean',
         // },
         // {
         //    title: 'Totale', width: 70, key: 'price_total',
         //    render: (record) => (
         //       record.price_total_comma ? record.price_total_comma + '€' : ''
         //    ),
         //    sorter: (a, b) => a.price_total - b.price_total,
         // },
         {
            title: languageManager.getMessage(this.props.commonState, 'component.order_summary.products.table.unit_price'),
            width: 100,
            key: 'unit_price',
            render: (record) => (
               record.unit_price_comma ? record.unit_price_comma + '€' : ''
            ),
         },
         {
            title: languageManager.getMessage(this.props.commonState, 'component.order_summary.products.table.quantity'),
            width: 100,
            key: 'quantity',
            render: (record) => (
               record.quantity_cancelled ? record.quantity + ' (' + record.quantity_cancelled + ' cancellato)' : record.quantity_refunded ? record.quantity + ' (' + record.quantity_refunded + ' rimborsato)' : record.quantity
            ),
         },
         {
            title: languageManager.getMessage(this.props.commonState, 'component.order_summary.products.table.total_price'),
            width: 100,
            key: 'total_price',
            render: (record) => (
               record.total_price_comma ? record.total_price_comma + '€' : ''
            ),
         },
         {
            title: languageManager.getMessage(this.props.commonState, 'component.order_summary.products.table.total_tax'),
            width: 100,
            key: 'total_tax',
            render: (record) => (
               record.total_tax_comma ? record.total_tax_comma + '€' : ''
            ),
         },
         {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            align: 'right',
            width: 200,
            render: (text, record) => (
               <Row>
                  <Col span={24}>
                     <div className="float-right d-none d-md-block">
                        <Space size='small'>
                           {
                              this.state.orderDetails.order_status_id === OrderStatusEnum.PENDING
                                 || this.state.orderDetails.order_status_id === OrderStatusEnum.PROCESSING ?
                                 <Button
                                    type="danger"
                                    icon={<DeleteOutlined />}
                                    onClick={() => this.showCancelProductModal(record)}
                                 ></Button>
                                 : ''
                           }
                           {
                              this.state.orderDetails.order_status_id === OrderStatusEnum.SHIPPED
                                 || this.state.orderDetails.order_status_id === OrderStatusEnum.PROCESSING ?
                                 [
                                    <Button
                                       type="warning"
                                       icon={<EuroOutlined />}
                                       onClick={() => this.showRefundProductModal(record)}
                                    ></Button>,
                                 ]
                                 : ''
                           }
                           {
                              this.state.orderDetails.order_status_id === OrderStatusEnum.SHIPPED ?
                                 [
                                    <Button
                                       type="warning"
                                       icon={<RedoOutlined />}
                                       onClick={() => this.showReturnProductModal(record)}
                                    ></Button>,
                                 ]
                                 : ''
                           }
                        </Space>
                     </div>
                  </Col>
               </Row>
            ),
         },
      ];

      const listData = [
         languageManager.getMessage(this.props.commonState, 'component.order_summary.products.list.shipping') + ': ' + this.state.orderDetails.shipping_total,
         languageManager.getMessage(this.props.commonState, 'component.order_summary.products.list.prod_total') + ': ' + this.state.orderDetails.price_total,
      ]
      const pageTitle = concat(languageManager.getMessage(this.props.commonState, 'component.order_summary.title'), this.state.orderDetails.id);

      const { selectedRowKeys } = this.state.selectedRowKeys;
      const rowSelection = {
         selectedRowKeys,
         onChange: this.onSelectChange,
         preserveSelectedRowKeys: true,
      };

      const rejectModal = (
         <Modal
            visible={this.state.showModal}
            title="Seleziona i prodotti da scartare"
            onOk={this.submitModal}
            onCancel={this.cancelModal}
            width={1200}
            footer={[
               <Button key="back" onClick={this.cancelModal}>
                  Annulla
               </Button>,
               <Button key="submit" type="primary" onClick={this.submitModal}>
                  Rifiuta i prodotti selezionati
               </Button>
            ]}
         >
            <Table columns={columnsOrder} rowKey={(record) => (record.id)} rowSelection={rowSelection} dataSource={this.state.orderLines} pagination={false} />
         </Modal>
      );

      const modalShipOrder = (
         <Modal
            visible={this.state.showModalShipOrder}
            title="Inserisci i dati della spedizione"
            onCancel={() => this.setState({ showModalShipOrder: false })}
            footer={[
               <Button key="back" onClick={() => this.setState({ showModalShipOrder: false })}>
                  Annulla
               </Button>,
               <Button form="formShipOrder" type="primary" htmlType="submit" loading={this.state.formShipOrderLoader}>
                  Conferma spedizione
               </Button>
            ]}
         >
            <Form
               id="formShipOrder"
               layout='horizontal'
               onFinish={this.onShipOrderFinish}
               onFinishFailed={this.onShipOrderFinishFailed}
            >
               <Form.Item
                  label='Codice Tracking'
                  name='tracking_code'
                  rules={[{ required: true }]}
               >
                  <Input />
               </Form.Item>
               <Form.Item
                  label='Corriere'
                  name='carrier_id'
                  rules={[{ required: true }]}
               >
                  <Select
                     showSearch
                     style={{ width: 200 }}
                     placeholder="Seleziona il corriere"
                     optionFilterProp="children"
                  >
                     {
                        this.state.carrierList.map((carrier) => {
                           return <Option key={carrier.id} value={carrier.id}>{carrier.name}</Option>
                        })
                     }
                  </Select>
               </Form.Item>
            </Form>
         </Modal>
      );

      const modalCancelProduct = (
         <Modal
            visible={this.state.showModalCancelProduct}
            title="Cancella prodotto dall'ordine"
            onCancel={() => this.setState({ showModalCancelProduct: false })}
            footer={[
               <Button key="back" onClick={() => this.setState({ showModalCancelProduct: false })}>
                  Annulla
               </Button>,
               <Button form="formCancelProduct" type="primary" htmlType="submit" loading={this.state.formCancelProductLoader}>
                  Conferma cancellazione
               </Button>
            ]}
         >
            <Form
               id="formCancelProduct"
               layout='horizontal'
               onFinish={this.onCancelProductFinish}
               onFinishFailed={this.onCancelProductFinishFailed}
            >
               <Form.Item
                  label='Quantità da cancellare'
                  name='quantity'
                  rules={[
                     { required: true, message: 'Valore non valido' },
                     { type: 'number', max: this.state.lineToCancel.quantity, message: 'Valore massimo: ' + this.state.lineToCancel.quantity },
                     { type: 'number', min: 1, message: 'Valore non valido' }
                  ]}
               >
                  <InputNumber min={0} max={this.state.lineToCancel.quantity} />
               </Form.Item>
               <Form.Item
                  label='Riassortisci quantità'
                  name='restock'
                  valuePropName="checked"
               >
                  <Switch checkedChildren="Sì" unCheckedChildren="No" />
               </Form.Item>
            </Form>
         </Modal>
      );

      const modalRefundProduct = (
         <Modal
            visible={this.state.showModalRefundProduct}
            title="Rimborsa prodotto"
            onCancel={() => this.setState({ showModalRefundProduct: false })}
            footer={[
               <Button key="back" onClick={() => this.setState({ showModalRefundProduct: false })}>
                  Annulla
               </Button>,
               <Button form="formRefundProduct" type="primary" htmlType="submit" loading={this.state.formRefundProductLoader}>
                  Conferma rimborso
               </Button>
            ]}
         >
            <Form
               id="formRefundProduct"
               layout='horizontal'
               onFinish={this.onRefundProductFinish}
               onFinishFailed={this.onRefundProductFinishFailed}
            >
               <Form.Item
                  label='Quantità da rimborsare'
                  name='quantity'
                  rules={[
                     { required: true, message: 'Valore non valido' },
                     { type: 'number', max: this.state.lineToRefund.quantity, message: 'Valore massimo: ' + this.state.lineToRefund.quantity },
                     { type: 'number', min: 1, message: 'Valore non valido' }
                  ]}
               >
                  <InputNumber min={0} max={this.state.lineToRefund.quantity} />
               </Form.Item>
               <Form.Item
                  label='Importo da rimborsare (prodotto)'
                  name='refund_product'
                  rules={[
                     { required: true, message: 'Valore non valido' },
                  ]}
               >
                  <InputNumber
                     formatter={value => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                     parser={value => value.replace(/€\s?|(,*)/g, '')}
                  />
               </Form.Item>
               <Form.Item
                  label='Importo da rimborsare (spedizione)'
                  name='refund_shipping'
                  rules={[
                     { required: true, message: 'Valore non valido' },
                  ]}
               >
                  <InputNumber
                     formatter={value => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                     parser={value => value.replace(/€\s?|(,*)/g, '')}
                  />
               </Form.Item>
               <Form.Item
                  label='Riassortisci quantità'
                  name='restock'
                  valuePropName="checked"
               >
                  <Switch checkedChildren="Sì" unCheckedChildren="No" />
               </Form.Item>
            </Form>
         </Modal>
      );

      const modalReturnProduct = (
         <Modal
            visible={this.state.showModalReturnProduct}
            title="Restituisci prodotto"
            onCancel={() => this.setState({ showModalReturnProduct: false })}
            footer={[
               <Button key="back" onClick={() => this.setState({ showModalReturnProduct: false })}>
                  Annulla
               </Button>,
               <Button form="formReturnProduct" type="primary" htmlType="submit" loading={this.state.formReturnProductLoader}>
                  Conferma restituzione
               </Button>
            ]}
         >
            <Form
               id="formReturnProduct"
               layout='horizontal'
               onFinish={this.onReturnProductFinish}
               onFinishFailed={this.onReturnProductFinishFailed}
            >
               <Form.Item
                  label='Quantità da restituire'
                  name='quantity'
                  rules={[
                     { required: true, message: 'Valore non valido' },
                     { type: 'number', max: this.state.lineToReturn.quantity, message: 'Valore massimo: ' + this.state.lineToReturn.quantity },
                     { type: 'number', min: 1, message: 'Valore non valido' }
                  ]}
               >
                  <InputNumber min={0} max={this.state.lineToReturn.quantity} />
               </Form.Item>
               <Form.Item
                  label='Motivazione'
                  name='reason'
                  rules={[{ required: true }]}
               >
                  <Select
                     showSearch
                     style={{ width: 200 }}
                     placeholder="Seleziona la motivazione del reso"
                     optionFilterProp="children"
                  >
                     {
                        this.state.returnReasonsList.map((reason) => {
                           return <Option key={reason.code} value={reason.code}>{reason.value}</Option>
                        })
                     }
                  </Select>
               </Form.Item>
               <Form.Item
                  label='Riassortisci quantità'
                  name='restock'
                  valuePropName="checked"
               >
                  <Switch checkedChildren="Sì" unCheckedChildren="No" />
               </Form.Item>
            </Form>
         </Modal>
      );

      const acceptButton = (
         <Menu>
            <Menu.Item className="text-white" style={{ backgroundColor: "green" }} key="all" onClick={() => this.orderActionHandler(this.state.orderDetails.id, OrderStatusActionEnum.ACCEPT)}>Accetta tutto</Menu.Item>
            <Menu.Item style={{ backgroundColor: "yellow" }} key="partial" onClick={this.showModal}>Rifiuta linee</Menu.Item>
         </Menu>
      );

      const order_wrapper = (
         <>
            <Row>
               <Col span={24}>
                  <div className="float-right d-none d-md-block">
                     <Space>
                        {
                           this.state.orderDetails.order_status_id === OrderStatusEnum.WAITING_ACCEPTANCE ?
                              [
                                 <Button
                                    type="success"
                                    onClick={() => this.orderActionHandler(this.state.orderDetails.id, OrderStatusActionEnum.ACCEPT)}
                                    loading={this.state.orderStatusActionLoader}
                                 >Accetta Ordine</Button>,

                                 <Popconfirm
                                    placement="topRight"
                                    title={languageManager.getMessage(this.props.commonState, 'common.askDeleteRefuseOrder')}
                                    onConfirm={() => this.orderActionHandler(this.state.orderDetails.id, OrderStatusActionEnum.REFUSE)}
                                    okText={languageManager.getMessage(this.props.commonState, 'common.yes')}
                                    cancelText={languageManager.getMessage(this.props.commonState, 'common.no')}
                                 >
                                    <Button
                                       type="danger"
                                       icon={<DeleteOutlined />}
                                       loading={this.state.orderStatusActionLoader}
                                    ></Button>
                                 </Popconfirm>
                              ]
                              : ''
                        }
                        {
                           this.state.orderDetails.order_status_id === OrderStatusEnum.PENDING ?
                              <Button
                                 type="success"
                                 onClick={() => this.orderActionHandler(this.state.orderDetails.id, OrderStatusActionEnum.PROCESS)}
                                 loading={this.state.orderStatusActionLoader}
                              >Metti in preparazione</Button>
                              : ''
                        }
                        {
                           this.state.orderDetails.order_status_id === OrderStatusEnum.PROCESSING ?
                              <Button
                                 type="success"
                                 onClick={() => this.setState({ showModalShipOrder: true })}
                              >Spedisci ordine</Button>
                              : ''
                        }
                     </Space>
                  </div>
               </Col>
            </Row>
            <Row>
               <Col span={24}>
                  <Layout>
                     <Card>
                        <Descriptions
                           title={languageManager.getMessage(this.props.commonState, 'component.order_summary.info.title')}
                           bordered
                           layout="vertical"
                        >
                           {(this.state.loader) ? <Loader /> :
                              <>
                                 <Descriptions.Item
                                    label={languageManager.getMessage(this.props.commonState, 'component.order_summary.info.markeplace_id')}
                                    span={0.5}
                                 >
                                    <p>{this.state.orderDetails.marketplace_id}</p>
                                 </Descriptions.Item>
                                 <Descriptions.Item
                                    label={languageManager.getMessage(this.props.commonState, 'component.order_summary.info.order_number')}
                                    span={0.5}
                                 >
                                    <p>{this.state.orderDetails.order_number}</p>
                                 </Descriptions.Item>
                                 <Descriptions.Item
                                    label={languageManager.getMessage(this.props.commonState, 'component.order_summary.info.order_date')}
                                    span={0.5}
                                 >
                                    <p>{this.state.orderDetails.create_order_date}</p>
                                 </Descriptions.Item>
                                 <Descriptions.Item
                                    label={languageManager.getMessage(this.props.commonState, 'component.order_summary.info.payment_method')}
                                    span={0.5}
                                 >
                                    <p>{this.state.orderDetails.payment_method}</p>
                                 </Descriptions.Item>
                                 <Descriptions.Item
                                    label={languageManager.getMessage(this.props.commonState, 'component.order_summary.info.channel')}
                                    span={0.5}
                                 >
                                    <p>{this.state.orderDetails.source}</p>
                                 </Descriptions.Item>
                                 <Descriptions.Item
                                    label={languageManager.getMessage(this.props.commonState, 'component.order_summary.info.status')}
                                    span={0.5}
                                 >
                                    <p>{this.state.orderDetails.order_status_name}</p>
                                 </Descriptions.Item>
                              </>
                           }
                        </Descriptions>
                        <p>{this.state.orderDetails.customer_note}</p>
                     </Card>
                     <Card>
                        <Descriptions title={languageManager.getMessage(this.props.commonState, 'component.order_summary.products.title')}>
                           {(this.state.loader) ? <Loader /> :
                              <>
                                 <Descriptions.Item span={3}>
                                    <Table size='small' columns={columnsOrder} dataSource={this.state.orderLines} pagination={{ hideOnSinglePage: true }} />
                                 </Descriptions.Item>
                                 <Descriptions.Item span={3}>
                                    <List
                                       size="small"
                                       style={{ textAlign: "right" }}
                                       bordered
                                       dataSource={listData}
                                       renderItem={item => <List.Item> {item} </List.Item>}
                                    />
                                 </Descriptions.Item>
                              </>
                           }
                        </Descriptions>
                     </Card>
                     <Row gutter={16}>
                        <Col xs={24} md={8}>
                           {
                              !_.isNil(this.state.billing_address) ?
                                 <Card title="Indirizzo di fatturazione">
                                    <Space size="small" direction="vertical">
                                       {!_.isNil(this.state.billing_address.company) ? <Text strong>{this.state.billing_address.company}</Text> : ''}
                                       <Text strong>{this.state.billing_address.name}</Text>
                                       <Text>{this.state.billing_address.address}</Text>
                                       <Text>{this.state.billing_address.postal_code + ' ' + this.state.billing_address.city + ' (' + this.state.billing_address.state + ')'}</Text>
                                       <Text>{this.state.billing_address.country}</Text>
                                       <Text>Email: {this.state.billing_address.email}</Text>
                                       <Text>Telefono: {this.state.billing_address.phone}</Text>
                                    </Space>
                                 </Card>
                                 : ''
                           }
                        </Col>
                        <Col xs={24} md={8}>
                           {
                              !_.isNil(this.state.shipping_address) ?
                                 <Card title="Indirizzo di spedizione">
                                    <Space size="small" direction="vertical">
                                       {!_.isNil(this.state.shipping_address.company) ? <Text strong>{this.state.shipping_address.company}</Text> : ''}
                                       <Text strong>{this.state.shipping_address.name}</Text>
                                       <Text>{this.state.shipping_address.address}</Text>
                                       <Text>{this.state.shipping_address.postal_code + ' ' + this.state.shipping_address.city + ' (' + this.state.shipping_address.state + ')'}</Text>
                                       <Text>{this.state.shipping_address.country}</Text>
                                       <Text>Email: {this.state.shipping_address.email}</Text>
                                       <Text>Telefono: {this.state.shipping_address.phone}</Text>
                                    </Space>
                                 </Card>
                                 : ''
                           }
                        </Col>
                        <Col xs={24} md={8}>
                           {
                              this.state.orderDetails.order_status_id === OrderStatusEnum.SHIPPED ? (
                                 <Card title="Informazioni sulla spedizione">
                                    <Space size="small" direction="vertical">
                                       <Text>{languageManager.getMessage(this.props.commonState, 'component.order_summary.shippings.shipping_date')}: </Text><Text strong>{this.state.orderDetails.shipped_order_date}</Text>
                                       <Text>{languageManager.getMessage(this.props.commonState, 'component.order_summary.shippings.carrier_name')}: </Text><Text strong>{!_.isNil(this.state.orderDetails.carrier) ? this.state.orderDetails.carrier.name : ''}</Text>
                                       <Text>{languageManager.getMessage(this.props.commonState, 'component.order_summary.shippings.tracking_code')}: </Text><Text strong>{this.state.orderDetails.tracking_code}</Text>
                                    </Space>
                                 </Card>
                              ) : ''
                           }
                        </Col>
                     </Row>
                     {/* <div className="card-footer">
                        {
                           this.state.orderDetails.status === 'PENDING' || this.state.orderDetails.status === 'PROCESSING' ?
                              <div className="align-items-center row">
                                 <div className="col-sm-6">
                                    {
                                       this.state.addShipping ?
                                          <Space>
                                             <Button
                                                size='large'
                                                type='primary'
                                                style={{ backgroundColor: "green" }}
                                                icon={<CheckCircleOutlined />}
                                                onClick={this.confirmAddShipping.bind(this)}
                                             ></Button>
                                             <Button
                                                size='large'
                                                type='primary'
                                                danger
                                                icon={<CloseCircleOutlined />}
                                                onClick={this.cancelAddShippingHandle.bind(this)}
                                             ></Button>
                                          </Space>
                                          :
                                          <Space>
                                             <Button
                                                size='large'
                                                type='success'
                                                icon={<PlusSquareOutlined />}
                                                onClick={this.addShippingHandle.bind(this)}
                                             >Aggiungi Spedizione</Button>
                                          </Space>
                                    }
                                 </div>

                                 <div className="col-sm-6">
                                    <div className="float-right d-none d-md-block">
                                    </div>
                                 </div>
                              </div>
                              : ''
                        }
                     </div> */}
                     <NotificationsTable order_notes={this.state.orderNotes} />
                  </Layout>
               </Col>
            </Row>

         </>
      );



      const pageContent = (
         <div className="card">
            <div className="card-header">
               <div className="align-items-center row">
                  <div className="col-sm-6">{pageTitle}</div>
                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">
                        <Space>
                           <Select
                              showSearch
                              className="width-70px-I"
                              placeholder="Seleziona la lingua"
                              defaultValue={this.state.componentLanguage.toUpperCase()}
                              optionFilterProp="children"
                              onChange={this.setDefaultLanguageHandler}
                           //  onFocus={onFocus}
                           //  onBlur={onBlur}
                           //  onSearch={onSearch}
                           //  filterOption={(input, option) =>
                           //    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                           //  }
                           >
                              {
                                 this.props.authState.userData.languages.map((lang) => {
                                    return <Option key={lang.value} value={lang.value}>{lang.label}</Option>
                                 })
                              }
                           </Select>
                        </Space>
                     </div>
                  </div>
               </div>
            </div>
            <div className="card-body">
               {this.state.loader ? <Loader /> : order_wrapper}
               {rejectModal}
               {modalShipOrder}
               {modalCancelProduct}
               {modalRefundProduct}
               {modalReturnProduct}
            </div>
            <div className="card-footer">
               <div className="align-items-center row">
                  <div className="col-sm-6">

                     <Space>
                        <Button
                           size='large'
                           onClick={() => {
                              this.props.history.push({
                                 pathname: this.props.location.state.previousState.localPath,
                                 state: { previousState: this.props.location.state.previousState }
                              });
                           }}
                        >
                           <i className="mdi mdi-arrow-left-drop-circle-outline mr-1"></i>
                           {languageManager.getMessage(this.props.commonState, 'common.back')}
                        </Button>
                     </Space>
                  </div>

                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">
                     </div>
                  </div>
               </div>
            </div>
         </div>
      );

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>
      )
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState,
   };
};

export default connect(mapStateToProps)(OrderSummary);
