import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Moment from 'moment'
import _ from 'lodash'

import { Table, Select, Button, Space, notification, Descriptions, Row, Col, List, Modal, Typography } from 'antd';
import { InfoCircleOutlined, DoubleLeftOutlined, ExclamationCircleOutlined, DownloadOutlined, EditOutlined } from '@ant-design/icons';
// import Highlighter from 'react-highlight-words';
import { UploadOutlined, CheckOutlined, CloseOutlined, EditTwoTone, DeleteTwoTone } from '@ant-design/icons';

import languageManager from '../../../services/languageManager';

import * as storeActions from './store/actions/orders';

import Loader from '../../common/PageElements/Loader';
import PageHeader from '../../common/PageElements/PageHeader'

class OrderDetails extends Component {

   state = {
      action: undefined,
      saveAndClose: false,
      pageLoader: true,
      loaderCancelOrder: false,
      orderData: {
         customer: {}
      },
      orderDetails: {},
      orderLines: [],
      componentLanguage: this.props.authState.defaultLanguage,
   };

   componentDidMount() {
      const itemId = this.props.match.params.id
      Promise.all([
         this.getItem(itemId),
         // this.getComplements()
      ]).then(() => {
         this.setState({
            action: 'edit',
            itemId: itemId,
            pageLoader: false
         })
      })
   }

   getItem = (id) => {
      return storeActions.getItem(id)
         .then(response => {
            console.log(response);
            try {
               // console.log(updatedValues)
               this.setState({ response: response.data, orderData: response.data });
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }
         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   handleSaveAndClose = () => {
      this.setState({ saveAndClose: true })
   }

   setDefaultLanguageHandler = (value) => {
      console.log(value);
      const updatedValues = this.setForm(this.state.response, value);
      this.setState({ formValues: updatedValues, componentLanguage: value })
   }

   updateOrderStatus = (status) => {
      // console.log('Cancel order', this.state.itemId)
      // console.log(values)
      this.setState({ loaderUpdateOrder: true })
      storeActions.updateOrderStatus(this.state.itemId, status)
         .then(response => {
            console.log(response);
            let newOrderData = { ...this.state.orderData }
            newOrderData.status = response.data.status
            newOrderData.order_status_id = response.data.order_status_id
            this.setState({ orderData: newOrderData, loaderUpdateOrder: false })
            notification.success({
               message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
               description: response.human ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={response.human}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.confirm.updateOk'),
               duration: 0
            })
         }).catch((e) => {
            this.setState({ loaderUpdateOrder: false })
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.human ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.human}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
         })
   }
   cancelOrder = () => {
      console.log('Cancel order', this.state.itemId)
      // console.log(values)
      this.setState({ loaderCancelOrder: true })
      storeActions.cancelOrder(this.state.itemId)
         .then(response => {
            console.log(response);
            this.setState({ loaderCancelOrder: false })
            this.props.history.push('/orders/list');
            notification.success({
               message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
               description: response.human ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={response.human}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.confirm.updateOk'),
               duration: 0
            })
         }).catch((e) => {
            this.setState({ loaderCancelOrder: false })
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.human ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.human}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
         })
   }
   sendToFattura24 = () => {
      console.log('Send to Fattura24', this.state.itemId)
      // console.log(values)
      this.setState({ loaderSendOrder: true })
      storeActions.sendToFattura24(this.state.itemId)
         .then(response => {
            console.log(response);
            this.setState({ loaderSendOrder: false })
            notification.success({
               message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
               description: response.human ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={response.human}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.confirm.sendOk'),
               duration: 0
            })
         }).catch((e) => {
            this.setState({ loaderSendOrder: false })
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.human ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.human}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
         })
   }
   sendToFattureincloud = (document_type) => {
      console.log('Send to Fattureincloud', this.state.itemId, document_type)
      // console.log(values)
      this.setState({ loaderSendOrder: true })
      storeActions.sendToFattureincloud(this.state.itemId, document_type)
         .then(response => {
            console.log(response);
            this.setState({ loaderSendOrder: false })
            notification.success({
               message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
               description: response.human ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={response.human}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.confirm.sendOk'),
               duration: 0
            })
         }).catch((e) => {
            this.setState({ loaderSendOrder: false })
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.human ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.human}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
         })
   }

   render() {
      if (this.state.pageLoader) return <Loader />
      const orderData = this.state.orderData
      const { Title } = Typography
      const { Option } = Select

      const orderProductsTableLayout = {
         bordered: true,
         size: "small",
         pagination: false,
      }
      const orderProductsColumns = [
         {
            title: 'SKU',
            width: 150,
            dataIndex: 'sku',
            key: 'sku',
         },
         {
            title: 'Prodotto',
            key: 'name',
            render: (record) => (
               record.name
            ),
         },
         {
            title: 'Lotto',
            width: 150,
            dataIndex: 'batch',
            key: 'batch',
         },
         {
            title: 'Scadenza',
            width: 100,
            key: 'expiration_date',
            render: (record) => (
               record.expiration_date ? Moment(record.expiration_date).format('L') : null
            ),
         },
         {
            title: languageManager.getMessage(this.props.commonState, 'component.order_summary.products.table.weight'),
            width: 100,
            key: 'weight',
            render: (record) => (
               record.weight ? parseFloat(record.weight).toFixed(2) + ' Kg' : null
            ),
         },
         {
            title: languageManager.getMessage(this.props.commonState, 'component.order_summary.products.table.volume'),
            width: 100,
            key: 'volume',
            render: (record) => (
               record.volume ? parseFloat(record.volume).toFixed(6) + ' m³' : null
            ),
         },
         {
            title: languageManager.getMessage(this.props.commonState, 'component.order_summary.products.table.unit_price'),
            width: 75,
            key: 'unit_price',
            render: (record) => (
               record.unit_price_comma ? record.unit_price_comma + '€' : ''
            ),
         },
         {
            title: languageManager.getMessage(this.props.commonState, 'component.order_summary.products.table.discount'),
            width: 75,
            key: 'discount_percentage',
            render: (record) => (
               record.discount_percentage ? parseFloat(record.discount_percentage).toFixed(2) + '%' : null
            ),
         },
         {
            title: languageManager.getMessage(this.props.commonState, 'component.order_summary.products.table.quantity'),
            width: 75,
            key: 'quantity',
            render: (record) => (
               record.quantity
            ),
         },
         {
            title: languageManager.getMessage(this.props.commonState, 'component.order_summary.products.table.total_price'),
            width: 75,
            key: 'total_price',
            render: (record) => (
               record.total_price_comma ? record.total_price_comma + '€' : ''
            ),
         },
         {
            title: languageManager.getMessage(this.props.commonState, 'component.order_summary.products.table.total_tax'),
            width: 75,
            key: 'total_tax',
            render: (record) => (
               record.total_tax_comma ? record.total_tax_comma + '€' : ''
            ),
         },
      ]

      const order_wrapper = (
         <Space direction='vertical' size='small'>
            <Row gutter={32}>
               <Col span={8}>
                  <Space direction='vertical' size='small'>
                     <Descriptions size='small' column={1} bordered labelStyle={{ width: '200px' }} contentStyle={{ fontWeight: 'bold' }}>
                        <Descriptions.Item label="Marketplace">{orderData.source}</Descriptions.Item>
                        <Descriptions.Item label="ID Marketplace">{orderData.marketplace_id}</Descriptions.Item>
                        <Descriptions.Item label="Numero ordine">{orderData.order_number}</Descriptions.Item>
                        <Descriptions.Item label="Numero ordine cliente">{orderData.customer_order_number}</Descriptions.Item>
                        <Descriptions.Item label="Tipo di ordine">{orderData.is_virtual ? 'Ordine virtuale' : 'Ordine standard'}</Descriptions.Item>
                     </Descriptions>
                     <Descriptions size='small' column={1} bordered labelStyle={{ width: '200px' }} contentStyle={{ fontWeight: 'bold' }}>
                        <Descriptions.Item label="Totale ordine">{orderData.price_total_comma + '€'}</Descriptions.Item>
                        <Descriptions.Item label="Documento richiesto">{orderData.document_type_name}</Descriptions.Item>
                     </Descriptions>
                     <Descriptions size='small' column={1} bordered labelStyle={{ width: '200px' }} contentStyle={{ fontWeight: 'bold' }}>
                        <Descriptions.Item label="Data ordine">{orderData.order_date_human}</Descriptions.Item>
                        <Descriptions.Item label="Data evasione richiesta">{orderData.shipping_request_date_human}</Descriptions.Item>
                        <Descriptions.Item label="Data evasione">{orderData.shipped_order_date_human}</Descriptions.Item>
                     </Descriptions>
                     <Descriptions size='small' column={1} bordered labelStyle={{ width: '200px' }} contentStyle={{ fontWeight: 'bold' }}>
                        {
                           !_.isNil(orderData.payment_method) ? (
                              <Descriptions.Item label="Nome pagamento">{orderData.payment_method}</Descriptions.Item>
                           ) : null
                        }
                        <Descriptions.Item label="Metodo di pagamento">{orderData.payment_method_name}</Descriptions.Item>
                        <Descriptions.Item label="Banca di appoggio">{orderData.bank_name}</Descriptions.Item>
                        <Descriptions.Item label="IBAN Banca di appoggio">{orderData.bank_iban}</Descriptions.Item>
                     </Descriptions>
                     <Descriptions size='small' column={1} bordered labelStyle={{ width: '200px' }} contentStyle={{ fontWeight: 'bold' }}>
                        {
                           !_.isNil(orderData.shipping_method) ? (
                              <Descriptions.Item label="Nome spedizione">{orderData.shipping_method_name}</Descriptions.Item>
                           ) : null
                        }
                        <Descriptions.Item label="Metodo di spedizione">{orderData.shipping_method_name}</Descriptions.Item>
                        <Descriptions.Item label="Data di spedizione">{orderData.shipping_date_human}</Descriptions.Item>
                        <Descriptions.Item label="Corriere utilizzato">{orderData.carrier_name}</Descriptions.Item>
                        <Descriptions.Item label="Codice tracking">{orderData.tracking_number}</Descriptions.Item>
                     </Descriptions>
                     <Descriptions size='small' column={1} bordered labelStyle={{ width: '200px' }} contentStyle={{ fontWeight: 'bold' }}>
                        <Descriptions.Item label="Note">{orderData.order_note}</Descriptions.Item>
                        {
                           !_.isNil(orderData.customer_note) ? (
                              <Descriptions.Item label="Note cliente">{orderData.customer_note}</Descriptions.Item>
                           ) : null
                        }
                     </Descriptions>
                  </Space>
               </Col>
               <Col span={16}>
                  <Space direction='vertical'>
                     <Row gutter={32}>
                        <Col span={12}>
                           <Descriptions size='small' column={1} bordered labelStyle={{ width: '150px' }} contentStyle={{ fontWeight: 'bold' }}>
                              <Descriptions.Item label="Stato ordine">{orderData.status}</Descriptions.Item>
                           </Descriptions>
                        </Col>
                        <Col span={12}>
                           <Space size='small'>
                              {
                                 orderData.order_status_id < 3 || orderData.order_status_id > 7 ? (
                                    <Button type='success' size='large' icon={<CheckOutlined />}
                                       loading={this.state.loaderUpdateOrder}
                                       onClick={() =>
                                          Modal.confirm({
                                             title: languageManager.getMessage(this.props.commonState, 'component.orders.update_order'),
                                             icon: <ExclamationCircleOutlined />,
                                             content: languageManager.getMessage(this.props.commonState, 'common.askOperationConfirm'),
                                             okText: languageManager.getMessage(this.props.commonState, 'common.confirm'),
                                             onOk: () => this.updateOrderStatus('PENDING')
                                          })
                                       }
                                    >Metti in lavorazione</Button>
                                 ) : orderData.order_status_id < 4 ? (
                                    <Button type='success' size='large' icon={<CheckOutlined />}
                                       loading={this.state.loaderUpdateOrder}
                                       onClick={() =>
                                          Modal.confirm({
                                             title: languageManager.getMessage(this.props.commonState, 'component.orders.update_order'),
                                             icon: <ExclamationCircleOutlined />,
                                             content: languageManager.getMessage(this.props.commonState, 'common.askOperationConfirm'),
                                             okText: languageManager.getMessage(this.props.commonState, 'common.confirm'),
                                             onOk: () => this.updateOrderStatus('WAITING_SHIPMENT')
                                          })
                                       }
                                    >Pronto per la spedizione</Button>
                                 ) : orderData.order_status_id < 5 ? (
                                    <Button type='success' size='large' icon={<CheckOutlined />}
                                       loading={this.state.loaderUpdateOrder}
                                       onClick={() =>
                                          Modal.confirm({
                                             title: languageManager.getMessage(this.props.commonState, 'component.orders.update_order'),
                                             icon: <ExclamationCircleOutlined />,
                                             content: languageManager.getMessage(this.props.commonState, 'common.askOperationConfirm'),
                                             okText: languageManager.getMessage(this.props.commonState, 'common.confirm'),
                                             onOk: () => this.updateOrderStatus('SHIPPED')
                                          })
                                       }
                                       disabled={true}
                                    >Spedisci ordine</Button>
                                 ) : null
                              }
                              <Button type='warning' size='large' icon={<UploadOutlined />}
                                 loading={this.state.loaderSendOrder}
                                 onClick={() =>
                                    Modal.confirm({
                                       title: languageManager.getMessage(this.props.commonState, 'component.orders.send_order'),
                                       icon: <ExclamationCircleOutlined />,
                                       content: languageManager.getMessage(this.props.commonState, 'common.askOperationConfirm'),
                                       okText: languageManager.getMessage(this.props.commonState, 'common.confirm'),
                                       onOk: this.sendToFattura24
                                    })
                                 }
                                 disabled={orderData.order_status_id !== 3 && orderData.order_status_id !== 4}
                              >Invia a Fattura24</Button>
                           </Space>
                        </Col>
                     </Row>
                     {/* <Row gutter={32}>
                        <Col span={12}>
                           <Descriptions size='small' column={1} bordered labelStyle={{ width: '150px' }} contentStyle={{ fontWeight: 'bold' }}>
                              <Descriptions.Item label="Ordine inviato a Fatture in Cloud">{orderData.status}</Descriptions.Item>
                           </Descriptions>
                        </Col>
                        <Col span={12}>
                              <Button type='warning' size='large' icon={<UploadOutlined />}
                                 loading={this.state.loaderSendOrder}
                                 onClick={() =>
                                    Modal.confirm({
                                       title: languageManager.getMessage(this.props.commonState, 'component.orders.send_order'),
                                       icon: <ExclamationCircleOutlined />,
                                       content: languageManager.getMessage(this.props.commonState, 'common.askOperationConfirm'),
                                       okText: languageManager.getMessage(this.props.commonState, 'common.confirm'),
                                       onOk: () => this.sendToFattureincloud('order')
                                    })
                                 }
                                 disabled={orderData.order_status_id !== 3 && orderData.order_status_id !== 4}
                              >Invia ordine</Button>
                        </Col>
                     </Row> */}
                     {/* {
                        !orderData.is_virtual ? (
                           <Row gutter={32}>
                              <Col span={12}>
                                 <Descriptions size='small' column={1} bordered labelStyle={{ width: '150px' }} contentStyle={{ fontWeight: 'bold' }}>
                                    <Descriptions.Item label="DDT inviato a Fatture in Cloud">{orderData.status}</Descriptions.Item>
                                 </Descriptions>
                              </Col>
                              <Col span={12}>
                                    <Button type='warning' size='large' icon={<UploadOutlined />}
                                       loading={this.state.loaderSendOrder}
                                       onClick={() =>
                                          Modal.confirm({
                                             title: languageManager.getMessage(this.props.commonState, 'component.orders.send_order'),
                                             icon: <ExclamationCircleOutlined />,
                                             content: languageManager.getMessage(this.props.commonState, 'common.askOperationConfirm'),
                                             okText: languageManager.getMessage(this.props.commonState, 'common.confirm'),
                                             onOk: () => this.sendToFattureincloud('delivery_note')
                                          })
                                       }
                                       disabled={orderData.order_status_id !== 3 && orderData.order_status_id !== 4}
                                    >Invia DDT</Button>
                              </Col>
                           </Row>
                        ) : null
                     } */}
                     <Descriptions size='small' column={2} bordered labelStyle={{ width: '150px' }} contentStyle={{ fontWeight: 'bold' }} title='Dati cliente'>
                        <Descriptions.Item label="Tipo cliente">{orderData.customer.type}</Descriptions.Item>
                        <Descriptions.Item label="C.F.">{orderData.customer.fiscal_code}</Descriptions.Item>
                        <Descriptions.Item label="Gruppo">{orderData.customer_group_name}</Descriptions.Item>
                        <Descriptions.Item label="P.IVA">{orderData.customer.vat}</Descriptions.Item>
                     </Descriptions>
                     <Row gutter={32}>
                        <Col span={12}>
                           <Descriptions size='small' column={1} bordered labelStyle={{ width: '150px' }} contentStyle={{ fontWeight: 'bold' }} title='Indirizzo di spedizione'>
                              <Descriptions.Item label="Nome">{orderData.shipping_address.name}</Descriptions.Item>
                              <Descriptions.Item label="Azienda">{orderData.shipping_address.company}</Descriptions.Item>
                              <Descriptions.Item label="Indirizzo">{orderData.shipping_address.address_1}</Descriptions.Item>
                              <Descriptions.Item label="Indirizzo (opz.)">{orderData.shipping_address.address_2}</Descriptions.Item>
                              <Descriptions.Item label="CAP">{orderData.shipping_address.postal_code}</Descriptions.Item>
                              <Descriptions.Item label="Città">{orderData.shipping_address.city}</Descriptions.Item>
                              <Descriptions.Item label="Provincia">{orderData.shipping_address.state}</Descriptions.Item>
                              <Descriptions.Item label="Nazione">{orderData.shipping_address.country}</Descriptions.Item>
                              <Descriptions.Item label="Email">{orderData.shipping_address.email}</Descriptions.Item>
                              <Descriptions.Item label="Telefono">{orderData.shipping_address.phone}</Descriptions.Item>
                              <Descriptions.Item label="Note">{orderData.shipping_address.note}</Descriptions.Item>
                           </Descriptions>
                        </Col>
                        <Col span={12}>
                           {
                              orderData.billing_address ? (
                                 <Descriptions size='small' column={1} bordered labelStyle={{ width: '150px' }} contentStyle={{ fontWeight: 'bold' }} title='Indirizzo di fatturazione'>
                                    <Descriptions.Item label="Nome">{orderData.billing_address.name}</Descriptions.Item>
                                    <Descriptions.Item label="Azienda">{orderData.billing_address.company}</Descriptions.Item>
                                    <Descriptions.Item label="Indirizzo">{orderData.billing_address.address_1}</Descriptions.Item>
                                    <Descriptions.Item label="Indirizzo (opz.)">{orderData.billing_address.address_2}</Descriptions.Item>
                                    <Descriptions.Item label="CAP">{orderData.billing_address.postal_code}</Descriptions.Item>
                                    <Descriptions.Item label="Città">{orderData.billing_address.city}</Descriptions.Item>
                                    <Descriptions.Item label="Provincia">{orderData.billing_address.state}</Descriptions.Item>
                                    <Descriptions.Item label="Nazione">{orderData.billing_address.country}</Descriptions.Item>
                                    <Descriptions.Item label="Email">{orderData.billing_address.email}</Descriptions.Item>
                                    <Descriptions.Item label="Telefono">{orderData.billing_address.phone}</Descriptions.Item>
                                    <Descriptions.Item label="Partita IVA">{orderData.billing_address.vat}</Descriptions.Item>
                                    <Descriptions.Item label="Codice Fiscale">{orderData.billing_address.fiscal_code}</Descriptions.Item>
                                    <Descriptions.Item label="PEC">{orderData.billing_address.pec}</Descriptions.Item>
                                    <Descriptions.Item label="SDI">{orderData.billing_address.sdi}</Descriptions.Item>
                                 </Descriptions>
                              ) : null
                           }
                        </Col>
                     </Row>
                  </Space>
               </Col>
            </Row>
            <Row gutter={32}>
               <Col span={24}>
                  <Table {...orderProductsTableLayout}
                     columns={orderProductsColumns}
                     dataSource={orderData.order_lines}
                  />
               </Col>
            </Row>
            <Row gutter={32}>
               <Col span={8} offset={16}>
                  <Descriptions size='small' column={1} bordered labelStyle={{ width: '200px' }} contentStyle={{ fontWeight: 'bold' }}>
                     <Descriptions.Item label="Subtotale prodotti">{orderData.product_total_comma + '€'}</Descriptions.Item>
                     <Descriptions.Item label="Totale IVA">{orderData.product_tax_comma + '€'}</Descriptions.Item>
                     <Descriptions.Item label="Spedizione">{orderData.shipping_total_comma + '€'}</Descriptions.Item>
                     <Descriptions.Item label="Totale ordine">{orderData.price_total_comma + '€'}</Descriptions.Item>
                  </Descriptions>
               </Col>
            </Row>
         </Space>
      )

      const pageContent = (
         <div className="card">
            <div className="card-header">
               <div className="row">
                  <div className="col-sm-6">
                     <Title level={5}>{languageManager.getMessage(this.props.commonState, 'component.orders.details')}</Title>
                  </div>
                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">
                        <Space>
                           <Select
                              showSearch
                              className="width-70px-I"
                              placeholder="Seleziona la lingua"
                              optionFilterProp="children"
                              onChange={this.setDefaultLanguageHandler}
                           >
                              {
                                 this.props.authState.userData.languages.map((lang) => {
                                    return <Option key={lang.value} value={lang.value}>{lang.label}</Option>
                                 })
                              }
                           </Select>
                        </Space>
                     </div>
                  </div>
               </div>
            </div>
            <div className="card-body">
               {order_wrapper}
            </div>
            <div className="card-footer">
               <div className="align-items-center row">
                  <div className="col-sm-6">
                     <Space size='small'>
                        <Link to={{ pathname: "/orders/list" }} >
                           <Button icon={<DoubleLeftOutlined />}>
                              {languageManager.getMessage(this.props.commonState, 'common.back')}
                           </Button>
                        </Link>
                     </Space>
                  </div>
                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">
                        <Space size='small'>
                           <Link to={{ pathname: "/orders/edit/" + this.state.itemId }} >
                              <Button type='primary' icon={<EditOutlined />}
                                 disabled={this.state.loaderCancelOrder} >
                                 {languageManager.getMessage(this.props.commonState, 'common.edit')}
                              </Button>
                           </Link>
                           <Button type='danger' icon={<CloseOutlined />}
                              loading={this.state.loaderCancelOrder}
                              title="Annulla ordine"
                              onClick={() =>
                                 Modal.confirm({
                                    title: languageManager.getMessage(this.props.commonState, 'component.orders.cancel_order'),
                                    icon: <ExclamationCircleOutlined />,
                                    content: languageManager.getMessage(this.props.commonState, 'common.askOperationConfirm'),
                                    okText: languageManager.getMessage(this.props.commonState, 'common.confirm'),
                                    onOk: this.cancelOrder
                                 })
                              }
                           />
                        </Space>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      );

      const helpButton = {
         title: "Aiuto"
      }
      const buttons = [];
      buttons.push(helpButton);

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>

      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState,
   };
};

export default connect(mapStateToProps)(OrderDetails);