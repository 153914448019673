import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Drawer, Button, Space, notification } from 'antd'
import { InfoCircleOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons'

import languageManager from '../../../../services/languageManager'
import * as storeActions from '../store/actions/customers'

import InvoicingAddressForm from '../components/InvoicingAddressForm'
import Loader from '../../../common/PageElements/Loader'

class InvoicingAddressDrawer extends Component {
   state = {
      ...this.props,
      componentLoader: true,
      saveLoader: false,
      formValues: {},
      componentLanguage: this.props.authState.defaultLanguage
   }

   componentDidMount() {
      if (this.props.itemId) {
         const itemId = this.props.itemId
         Promise.all([
            this.getInvoicingAddress(itemId),
            // this.getComplements(),
         ]).then(() => {
            this.setState({
               itemId: itemId,
               componentLoader: false
            })
         })
      } else {
         this.setState({
            componentLoader: false
         })
      }
   }

   shouldComponentUpdate = (nextProps, nextState) => {
      if (
         _.isEqual(nextProps.visible, this.props.visible)
         && _.isEqual(nextState, this.state)
      ) {
         return false
      }
      return true
   }

   componentDidUpdate = (prevProps, prevState) => {
      if (!_.isEqual(prevProps.visible, this.props.visible)) {
         if (_.isNil(this.props.itemId)) {
            this.setState({ formValues: {} });
         } else {
            this.setState({componentLoader: true})
            Promise.all([
               this.getInvoicingAddress(this.props.itemId),
            ]).then(() => {
               this.setState({
                  itemId: this.props.itemId,
                  componentLoader: false
               })
            })
         }
      }
   }

   getInvoicingAddress = (itemId) => {
      return storeActions.getInvoicingAddress(itemId)
         .then(response => {
            console.log(response);
            try {
               this.setState({
                  formValues: response.data,
               });
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e);
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   onFinish = values => {
      // console.log('on finish', values)
      this.setState({ saveLoader: true });
      storeActions.createOrUpdateInvoicingAddress(values)
         .then(response => {
            try {
               console.log('response', response.data)
               this.setState({ saveLoader: false });
               this.state.onSubmit(response.data)
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
                  description: languageManager.getMessage(this.props.commonState, this.state.action === 'edit' ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               this.setState({ saveLoader: false });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               console.log(e)
            }

         }).catch((e) => {
            this.setState({ saveLoader: false });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            console.log(e)
         })
   };

   render() {
      // console.log('Create edit sale rule drawer', this.state)
      const formLayout = {
         layout: 'vertical'
      };

      return (
         <Drawer
            title={!_.isNil(this.props.itemId) ? 'Modifica indirizzo di fatturazione' : "Aggiungi indirizzo di fatturazione"}
            width={500}
            onClose={this.props.onClose}
            visible={this.props.visible}
            drawerStyle={{ paddingTop: 70 }}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
               <div className='float-right'>
                  <Space>
                     <Button onClick={this.props.onClose} disabled={this.state.saveLoader}>Chiudi</Button>
                     <Button form="InvoicingAddressForm" type="primary" htmlType="submit" loading={this.state.saveLoader}>Salva</Button>
                  </Space>
               </div>
            }
         >
            {
               this.state.componentLoader ? <Loader /> : (
                  <InvoicingAddressForm
                     layout={formLayout}
                     submitForm={this.onFinish}
                     customerId={this.props.customerId}
                     values={this.state.formValues}
                  />
               )
            }
         </Drawer>
      )
   }
}

function mapStateToProps(state) {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
}

export default connect(mapStateToProps)(InvoicingAddressDrawer)