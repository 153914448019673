import ProductsApi from '../../../../../api/ProductsApi';
import { prepareTree } from '../../../categories/store/actions/categories';
import _ from 'lodash';

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function getList() {
   return ProductsApi.getList()
      .then(response => {
         // if (response.success === true) {
         if (response.data.success === true) {
            response.data.data.forEach(el => {
               el.key = el.id;
            });
            console.log(response);
            // return response;
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function getListExtended(page, pageSize, filters) {
   return ProductsApi.getListExtended(page, pageSize, filters)
      .then(response => {
         // if (response.success === true) {
         if (response.data.success === true) {
            response.data.data.data.forEach(el => {
               el.key = el.id;
            });
            console.log(response);
            // return response;
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function getProductComplements() {
   return ProductsApi.getProductComplements()
      .then(response => {
         console.log(response);
         const responseData = {}
         _.forEach(response, (el, i) => {
            if (el.data.success !== true) {
               const error = el.data.error ? el.data.error : 'Server error'
               throw new Error(error)
            }
            responseData[i] = (i === 'categoriesTree') ? prepareTree(el.data.data) : el.data.data;
         });
         return responseData;
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function getProductListComplements() {
   return ProductsApi.getProductListComplements()
      .then(response => {
         console.log(response);
         const responseData = {}
         _.forEach(response, (el, i) => {
            if (el.data.success !== true) {
               const error = el.data.error ? el.data.error : 'Server error'
               throw new Error(error)
            }
            responseData[i] = (i === 'categoriesTree') ? prepareTree(el.data.data) : el.data.data;
         });
         return responseData;
      })
      .catch(error => {
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function rebuildTree(tree, return_list = false) {
   return ProductsApi.rebuildTree(tree, return_list)
      .then(response => {
         // if (response.success === true) {
         if (response.data.success === true) {
            const tree = prepareTree(response.data.data);
            response.data.data = tree;
            console.log(response);
            // return response;
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function getItem(itemId) {
   return ProductsApi.getItem(itemId)
      .then(response => {
         // if (response.success === true) {
         if (response.data.success === true) {
            console.log(response);
            // return response;
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}
export function getItemForGroup(itemId, groupId = null) {
   return ProductsApi.getItemForGroup(itemId, groupId)
      .then(response => {
         // if (response.success === true) {
         if (response.data.success === true) {
            console.log(response);
            // return response;
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function createOrUpdate(itemData) {
   console.log(itemData);
   return ProductsApi.createOrUpdate(itemData)
      .then(response => {
         if (response.data.success === true) {
            console.log(response);
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function update(itemData) {
   // console.log(itemData);
   return ProductsApi.update(itemData)
      .then(response => {
         if (response.data.success === true) {
            // console.log(response);
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function toggleValue(itemId, value, language = 'it') {
   return ProductsApi.toggleValue({ id: itemId, value: value, language: language })
      .then(response => {
         // if (response.success === true) {
         if (response.data.success === true) {
            // return response;
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function deleteItem(itemId, return_list = false) {
   return ProductsApi.deleteItem(itemId, return_list)
      .then(response => {
         // if (response.success === true) {
         if (response.data.success === true) {
            // return response;
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function deleteMultipleItems(productIds, return_list = false) {
   return ProductsApi.deleteMultipleItems(productIds, return_list)
      .then(response => {
         // if (response.success === true) {
         if (response.data.success === true) {
            // return response;
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function saveCustomPrice(itemId, marketplaceId, variationOperation, variationAmount, startDate, endDate) {
   return ProductsApi.saveCustomPrice(itemId, marketplaceId, variationOperation, variationAmount, startDate, endDate)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

//PRODUCT VARIATIONS
export function createOrUpdateVariation(itemData, return_list) {
   console.log(itemData, return_list);
   return ProductsApi.createOrUpdateVariation(itemData, return_list)
      .then(response => {
         if (response.data.success === true) {
            console.log(response);
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function deleteProductVariation(itemId, return_list) {
   return ProductsApi.deleteProductVariation(itemId, return_list)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

//PRODUCT STOCK
export function saveProductStock(formData) {
   return ProductsApi.saveProductStock(formData)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function deleteProductStock(itemId) {
   return ProductsApi.deleteProductStock(itemId)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

//PRODUCT FILES
export function addFiles(itemId, files, file_type_id) {
   return ProductsApi.addFiles(itemId, files, file_type_id)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}
export function deleteFile(itemId, fileId) {
   return ProductsApi.deleteFile(itemId, fileId)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

// PRODUCT IMAGES
export function saveImagesOrder(itemId, images) {
   return ProductsApi.saveImagesOrder(itemId, images)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function addImages(itemId, images) {
   return ProductsApi.addImages(itemId, images)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function deleteImage(itemId, imageId) {
   return ProductsApi.deleteImage(itemId, imageId)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

 export function saveVariationImage(itemId, imageId) {
   console.log(itemId, imageId);
   return ProductsApi.saveVariationImage(itemId, imageId)
      .then(response => {
         if (response.data.success === true) {
            console.log(response);
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function deleteVariationImage(itemId) {
   console.log(itemId);
   return ProductsApi.deleteVariationImage(itemId)
      .then(response => {
         if (response.data.success === true) {
            console.log(response);
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function importProductsFromCSVWithMap(formData) {
   return ProductsApi.importProductsFromCSVWithMap(formData)
      .then(response => {
         // if (response.success === true) {
         if (response.data.success === true) {
            // return response;
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function importFileCSV(formData) {
   return ProductsApi.importFileCSV(formData)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else {
            throw new Error(response.data.error ? response.data.error : 'Server error');
         }
      })
      .catch(error => {
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function exportProductsCSV(filename, productIds) {
   return ProductsApi.exportProductsCSV(filename, productIds)
      .then(response => {
         let blob = new Blob(["\uFEFF" + response.data], { type: 'application/*' })
         let link = document.createElement('a')
         link.href = window.URL.createObjectURL(blob)
         link.download = filename;
         link.click();
      })
      .catch(error => {
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function exportProductsForPrivalia(filename, productIds, action, params) {
   return ProductsApi.exportProductsForPrivalia(filename, productIds, action, params)
      .then(response => {
         let blob = new Blob(["\uFEFF" + response.data], { type: 'application/*' })
         let link = document.createElement('a')
         link.href = window.URL.createObjectURL(blob)
         link.download = filename;
         link.click();
      })
      .catch(error => {
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function exportProductsForWoocommerce(filename, productIds, action, params) {
   return ProductsApi.exportProductsForWoocommerce(filename, productIds, action, params)
      .then(response => {
         let blob = new Blob(["\uFEFF" + response.data], { type: 'application/*' })
         let link = document.createElement('a')
         link.href = window.URL.createObjectURL(blob)
         link.download = filename;
         link.click();
      })
      .catch(error => {
         throw error;
      });
}

 export function activateForMarketplace(productIds, params) {
   return ProductsApi.activateForMarketplace(productIds, params)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else {
            throw new Error(response.data ? response.data : 'serverError');
         }
      })
      .catch(error => {
         throw error;
      });
}

export function deactivateForMarketplace(productIds, params) {
   return ProductsApi.deactivateForMarketplace(productIds, params)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else {
            throw new Error(response.data ? response.data : 'serverError');
         }
      })
      .catch(error => {
         throw error;
      });
}

export function sendToMarketplace(productIds, params) {
   return ProductsApi.sendToMarketplace(productIds, params)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}