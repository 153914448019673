import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Moment from 'moment';

import { Typography, Table, Select, Button, Tooltip, Space, notification, Popconfirm, Collapse, Col, Row } from 'antd';
import { InfoCircleOutlined, PlusOutlined, LeftCircleOutlined, UploadOutlined, FilterOutlined } from '@ant-design/icons';
// import Highlighter from 'react-highlight-words';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

import languageManager from '../../../services/languageManager';
import { startLoading, endLoading } from '../../../shared/utility';

import * as storeActions from './store/actions/banks';

import Loader from '../../common/PageElements/Loader';
import Reloader from '../../common/PageElements/Reloader';
import DropdownMenu from '../../common/components/DropdownMenu'
import CreateDrawer from './widget/CreateBankDrawer'

import TableFilters from '../../common/components/TableFilters';

class BanksList extends Component {
   state = {
      localPath: this.props.history.location.pathname,
      getError: false,
      pageLoader: true,
      tableLoader: false,
      multipleActionLoader: false,
      loadersTable: [],
      parentId: null,
      itemList: [],
      fullListIds: [],
      componentLanguage: this.props.authState.defaultLanguage,
      current_page: this.props.location.state ? this.props.location.state.previousState.current_page : 1,
      page_size: this.props.location.state ? this.props.location.state.previousState.page_size : 50,
      total_result: 0,

      showTableFilters: false,
      filters: this.props.location.state ? this.props.location.state.previousState.filters : [],
      filtersValues: this.props.location.state ? this.props.location.state.previousState.filtersValues : {},
      appliedFilters: this.props.location.state ? this.props.location.state.previousState.appliedFilters : [],

      selectedRowKeys: [],
      selectedAll: false,
      multipleActionList: [
         // {
         //    action: 'export_csv',
         //    name: 'Esporta selezionati in CSV'
         // },
         // {
         //    action: 'delete_customers',
         //    name: 'Cancella clienti',
         //    needconfirmation: 'yes',
         // },
      ],
      importDropdownActionList: [],
   };

   componentDidMount() {
      console.log('Component Did Mount')
      Promise.all([
         this.getBanksList(this.state.current_page, this.state.page_size, this.state.filtersValues),
         // this.getComplements()
      ]).then(() => {
         this.setState({
            pageLoader: false
         });
      });
   }

   getBanksList = (page, pageSize, filters) => {
      // asyncronous calls
      this.setState({ tableLoader: true })
      return storeActions.getListExtended(page, pageSize, filters)
         .then(response => {
            console.log(response);
            try {
               this.setState({
                  itemList: response.data.data,
                  fullListIds: response.full_list_ids,
                  current_page: page,
                  page_size: pageSize,
                  total_result: response.data.total,
                  filters: response.list_filters,
                  appliedFilters: response.applied_filters,
                  tableLoader: false
               });
            } catch (e) {
               this.setState({ tableLoader: false })
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                  duration: 0,
               })
            }

         }).catch((e) => {
            this.setState({ tableLoader: false, getError: true });
            if (this.props.commonState.debug) console.log(e);
         });
   }

   deleteItemHandler = (itemId) => {
      this.setState({ loadersTable: startLoading(this.state.loadersTable, itemId) });
      storeActions.deleteItem(itemId, true)
         .then(response => {
            console.log(response);
            try {
               Promise.all([
                  this.getBanksList(this.state.current_page, this.state.page_size, this.state.filtersValues)
               ]).then(() => {
                  this.setState({ loadersCustomers: endLoading(this.state.loadersTable, itemId) });
               });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.deleteOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   setDefaultLanguageHandler = (value) => {
      this.setState({ componentLanguage: value })
   }

   onSelectChange = (selectedRowKeys) => {
      this.setState({
         selectedRowKeys: selectedRowKeys
      });
      console.log(selectedRowKeys);
   };

   submitFilters = (values) => {
      this.setState({ filtersValues: values })
      this.getBanksList(this.state.current_page, this.state.page_size, values)
   }
   resetFilters = () => {
      this.getBanksList(this.state.current_page, this.state.page_size, []);
   }

   selectAllHandler = () => {
      if (this.state.selectedAll) {
         this.setState({
            selectedAll: false,
            selectedRowKeys: [],
         });
      }
      else {
         this.setState({
            selectedAll: true,
            selectedRowKeys: this.state.fullListIds,
         });
      }
   }

   handleTableChange = (pagination, filters, sorter, extra) => {
      console.log(pagination, filters, sorter, extra);
      this.getBanksList(pagination.current, pagination.pageSize, this.state.filtersValues);
   };


   editItemHandler = item => {
      this.setState({ createDrawerItem: item, showCreateDrawer: true })
   }
   showCreateDrawer = () => {
      this.setState({ createDrawerItem: null, showCreateDrawer: true })
   }
   hideCreateDrawer = () => {
      this.setState({ createDrawerItem: null, showCreateDrawer: false })
   }
   handleCreateBank = () => {
      console.log('Banca di appoggio creata correttamente')
      this.setState({ createDrawerItem: null, showCreateDrawer: false })
      this.getBanksList(this.state.current_page, this.state.page_size, this.state.filtersValues)
   }

   render() {
      if (this.state.getError) return <Reloader />;
      if (this.state.pageLoader) return <Loader />;
      const { Title } = Typography
      const { Option } = Select;
      const { Panel } = Collapse;

      const table_columns = [
         {
            title: 'Nome',
            key: 'name',
            fixed: 'left',
            width: 350,
            render: (record) => (
               <a className="link-cell" onClick={() => this.editItemHandler(record)}>
                  {record.name}
               </a>
            ),
            sorter: (a, b) => { return a.name.localeCompare(b.name) },
         },
         {
            title: 'IBAN',
            key: 'iban',
            width: 250,
            render: (record) => (
               record.iban
            ),
         },
         {
            title: 'Descrizione',
            key: 'description',
            ellipsis: true,
            render: (record) => (
               record.description
            ),
         },
         {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            align: 'right',
            width: 100,
            render: (text, record) => (
               <Space>
                  <Button type="primary" size='small'
                     disabled={this.state.loadersTable[record.id]}
                     icon={<EditOutlined />}
                     onClick={() => this.editItemHandler(record)}
                  />
                  {/* <Dropdown.Button type="primary" onClick={() => this.editItemHandler(record.id)} overlay={actionsMenu(record.id)}><EditOutlined /></Dropdown.Button> */}
                  <Popconfirm
                     placement="topRight"
                     title={languageManager.getMessage(this.props.commonState, 'common.askDeleteConfirm')}
                     onConfirm={() => this.confirmHandler('deleteItem', record.id)}
                     okText={languageManager.getMessage(this.props.commonState, 'common.yes')}
                     cancelText={languageManager.getMessage(this.props.commonState, 'common.no')}
                  >
                     <Button type="danger" size='small'
                        loading={this.state.loadersTable[record.id]}
                        icon={<DeleteOutlined />}
                     />
                  </Popconfirm>
               </Space>),
         },
      ];
      const tableLayout = {
         bordered: true,
         size: "small",
         loading: this.state.tableLoader,
         pagination: {
            pageSize: this.state.page_size,
            total: this.state.total_result,
            showSizeChanger: true,
            defaultPageSize: 50,
            size: "small",
            showTotal: total => total + (total !== 1 ? ' banche' : ' banca') + ' di appoggio'
         },
         onChange: this.handleTableChange
      }
      const { selectedRowKeys } = this.state;
      const rowSelection = {
         selectedRowKeys,
         onChange: this.onSelectChange,
         preserveSelectedRowKeys: true
      };

      const pageFilters = (
         <Collapse defaultActiveKey={['filters']}>
            <Panel header="Filtri" key="filters">
               <TableFilters submitFilters={this.submitFilters} resetFilters={this.resetFilters} filters={this.state.filters} selectValues={this.state.filtersValues} />
            </Panel>
         </Collapse>
      );

      const hasSelected = selectedRowKeys.length > 0;
      const singleSelected = selectedRowKeys.length > 1;
      const pageContent = (
         <div className="card">
            <div className="card-header">
               <div className="align-items-center row">
                  <div className="col-sm-6">
                     <Title level={5}>{languageManager.getMessage(this.props.commonState, 'component.banks.title')}</Title>
                  </div>
                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">
                        <Space>
                           <Button
                              type='primary'
                              icon={<PlusOutlined />}
                              // onClick={this.showCreateDrawer}
                              onClick={() => this.editItemHandler()}
                           >
                              Crea nuova banca di appoggio
                           </Button>
                           {
                              this.props.authState.userData.languages ? (
                                 <Select
                                    showSearch
                                    className="width-70px-I"
                                    placeholder="Seleziona la lingua"
                                    defaultValue={this.state.componentLanguage.toUpperCase()}
                                    optionFilterProp="children"
                                    onChange={this.setDefaultLanguageHandler}
                                 >
                                    {
                                       this.props.authState.userData.languages.map((lang) => {
                                          return <Option key={lang.value} value={lang.value}>{lang.label}</Option>
                                       })
                                    }
                                 </Select>
                              ) : null
                           }
                        </Space>
                     </div>
                  </div>
               </div>
            </div>
            <div className="card-body">
               <Row>
                  <Col span={24}>
                     {pageFilters}
                  </Col>
               </Row>
               <Row>
                  <Col span={24}>
                     <div className="align-items-center row" style={{ marginTop: 15, marginBottom: 5 }}>
                        <div className="col-sm-12">
                           <div className="d-none d-md-block">
                              <Space>
                                 <Button
                                    onClick={() => this.selectAllHandler()}
                                    disabled={_.isEmpty(this.state.fullListIds)}
                                 >{this.state.selectedAll ? 'Deseleziona' : 'Seleziona tutto'}
                                 </Button>
                                 <span>
                                    {hasSelected ? singleSelected ? `${selectedRowKeys.length} elementi selezionati` : `${selectedRowKeys.length} elemento selezionato` : ''}
                                 </span>
                                 <DropdownMenu
                                    type='primary'
                                    title='Azioni multiple'
                                    action={(event) => this.multipleActionHandler(event)}
                                    values={this.state.multipleActionList}
                                    disabled={!hasSelected}
                                    loading={this.state.multipleActionLoader}
                                 />
                              </Space>
                           </div>
                        </div>
                     </div>
                     <Table
                        key='dataTable'
                        {...tableLayout}
                        rowSelection={rowSelection}
                        columns={table_columns}
                        dataSource={this.state.itemList}
                        scroll={{ x: 'max-content' }}
                        rowKey={record => (record.id)}
                     />
                     <CreateDrawer
                        key='createDrawer'
                        visible={this.state.showCreateDrawer}
                        item={this.state.createDrawerItem}
                        onClose={this.hideCreateDrawer}
                        onSubmit={() => this.handleCreateBank()} />
                  </Col>
               </Row>
            </div>
            <div className="card-footer">
               <Button
                  size='large'
                  onClick={() => { this.props.history.goBack() }}
               >
                  <i className="mdi mdi-arrow-left-drop-circle-outline mr-1"></i>
                  {languageManager.getMessage(this.props.commonState, 'common.back')}
               </Button>
            </div>
         </div>
      );

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>
      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState,
   };
};

export default connect(mapStateToProps)(BanksList);