import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Moment from 'moment';

import { Typography, Table, Select, Button, List, Space, notification, Popconfirm, Collapse, Col, Row } from 'antd';
import { InfoCircleOutlined, PlusOutlined, LeftCircleOutlined, UploadOutlined, FilterOutlined } from '@ant-design/icons';
// import Highlighter from 'react-highlight-words';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

import languageManager from '../../../services/languageManager';
import { startLoading, endLoading } from '../../../shared/utility';

import * as storeActions from './store/actions/customers';

import Loader from '../../common/PageElements/Loader';
import Reloader from '../../common/PageElements/Reloader';
import DropdownMenu from '../../common/components/DropdownMenu'
import CreateCustomerDrawer from './widget/CreateCustomerDrawer'

import TableFilters from '../../common/components/TableFilters';

class CustomersList extends Component {
   state = {
      localPath: this.props.history.location.pathname,
      getError: false,
      pageLoader: true,
      tableLoader: false,
      multipleActionLoader: false,
      loadersCustomers: [],
      parentId: null,
      itemList: [],
      fullListIds: [],
      componentLanguage: this.props.authState.defaultLanguage,
      current_page: this.props.location.state ? this.props.location.state.previousState.current_page : 1,
      page_size: this.props.location.state ? this.props.location.state.previousState.page_size : 50,
      total_result: 0,

      showTableFilters: false,
      filters: this.props.location.state ? this.props.location.state.previousState.filters : [],
      filtersValues: this.props.location.state ? this.props.location.state.previousState.filtersValues : {},
      appliedFilters: this.props.location.state ? this.props.location.state.previousState.appliedFilters : [],

      selectedRowKeys: [],
      selectedAll: false,
      multipleActionList: [
         {
            action: 'export_csv',
            name: 'Esporta selezionati in CSV'
         },
         // {
         //    action: 'delete_customers',
         //    name: 'Cancella clienti',
         //    needconfirmation: 'yes',
         // },
      ],
      importDropdownActionList: [],
   };

   getCustomersList = (page, pageSize, filters) => {
      // asyncronous calls
      this.setState({ tableLoader: true })
      return storeActions.getListExtended(page, pageSize, filters)
         .then(response => {
            console.log(response);
            try {
               this.setState({
                  itemList: response.data.data,
                  fullListIds: response.full_list_ids,
                  current_page: page,
                  page_size: pageSize,
                  total_result: response.data.total,
                  filters: response.list_filters,
                  appliedFilters: response.applied_filters,
                  tableLoader: false
               });
            } catch (e) {
               this.setState({ tableLoader: false })
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                  duration: 0,
               })
            }

         }).catch((e) => {
            this.setState({ getError: true });
            if (this.props.commonState.debug) console.log(e);
         });
   }

   getComplements = () => {
      // return storeActions.getCustomersListComplements()
      //    .then(response => {
      //       console.log(response);
      //       try {
      //          let newExport = {}
      //          let newDropdown = {}
      //          let newMarketplaceDropdowns = []
      //          _.forEach(response.marketplacesList, (marketplace) => {
      //             newDropdown = {}
      //             newDropdown.title = marketplace.name
      //             newDropdown.actionList = []
      //             if (marketplace.marketplace_base.alias === 'privalia') {
      //                newExport = {
      //                   action: 'export_privalia',
      //                   name: 'Esporta selezionati in CSV per ' + marketplace.name,
      //                   params: {
      //                      marketplace_id: marketplace.id
      //                   }
      //                }
      //                newDropdown.actionList.splice(newDropdown.actionList.length - 1, 0, newExport)
      //                newExport = {
      //                   action: 'sync_privalia',
      //                   name: 'Invia anagrafica dei selezionati a ' + marketplace.name,
      //                   params: {
      //                      marketplace_id: marketplace.id
      //                   },
      //                   needconfirmation: 'yes',
      //                }
      //                newDropdown.actionList.splice(newDropdown.actionList.length - 1, 0, newExport)
      //                newExport = {
      //                   action: 'sync_quantities_privalia',
      //                   name: 'Invia quantità dei selezionati a ' + marketplace.name,
      //                   params: {
      //                      marketplace_id: marketplace.id
      //                   },
      //                   needconfirmation: 'yes',
      //                }
      //                newDropdown.actionList.splice(newDropdown.actionList.length - 1, 0, newExport)
      //                newExport = {
      //                   action: 'sync_prices_privalia',
      //                   name: 'Invia prezzi dei selezionati a ' + marketplace.name,
      //                   params: {
      //                      marketplace_id: marketplace.id
      //                   },
      //                   needconfirmation: 'yes',
      //                }
      //                newDropdown.actionList.splice(newDropdown.actionList.length - 1, 0, newExport)
      //             }
      //             else if (marketplace.marketplace_base.alias === 'woocommerce') {
      //                newExport = {
      //                   action: 'sync_woocommerce',
      //                   name: 'Invia anagrafica dei selezionati a ' + marketplace.name,
      //                   params: {
      //                      marketplace_id: marketplace.id
      //                   },
      //                   needconfirmation: 'yes',
      //                }
      //                newDropdown.actionList.splice(newDropdown.actionList.length - 1, 0, newExport)
      //             }
      //             newMarketplaceDropdowns.push(newDropdown)
      //          })
      //          this.setState({
      //             marketplaceDropdowns: newMarketplaceDropdowns,
      //          });

      //       } catch (e) {
      //          notification.open({
      //             message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
      //             description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
      //             icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
      //          })
      //          if (this.props.commonState.debug) console.log(e);
      //       }

      //    }).catch((e) => {
      //       notification.open({
      //          message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
      //          description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
      //          icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
      //       })
      //       if (this.props.commonState.debug) console.log(e);
      //    });
   }

   prepareImportDropdown = () => {
      let newImport = {}
      let newImportDropdownActionList = []
      // console.log('prepareImportDropdown', this.props.authState.userData)
      _.forEach(this.props.authState.userData.activeMarketplaces, (marketplace) => {
         switch (marketplace.marketplace_base.alias) {
            case 'privalia':
               break
            case 'woocommerce':
               newImport = {
                  action: 'import_from_marketplace',
                  name: 'Importa dati da ' + marketplace.name,
                  params: {
                     marketplace_id: marketplace.id
                  },
                  needconfirmation: 'yes',
               }
               newImportDropdownActionList.push(newImport)
               break
         }
      })
      this.setState({
         importDropdownActionList: newImportDropdownActionList,
      });
   }

   componentDidMount() {
      console.log('Component Did Mount')
      Promise.all([
         this.getCustomersList(this.state.current_page, this.state.page_size, this.state.filtersValues),
         // this.getComplements()
      ]).then(() => {
         this.setState({
            pageLoader: false
         });
      });
      if (!_.isNil(this.props.authState.userData.activeMarketplaces)) {
         this.prepareImportDropdown()
      }
   }
   componentDidUpdate(prevProps) {
      if (this.props.authState.userData.activeMarketplaces !== prevProps.authState.userData.activeMarketplaces) {
         this.prepareImportDropdown()
      }
   }

   editItemHandler = (itemId) => {
      this.props.history.push({
         pathname: itemId ? '/customers/edit/' + itemId : '/customers/edit/',
         state: { previousState: this.state }
      });
   }

   deleteMultipleItemsHandler = (productIds) => {
      // this.setState({ loader: true });
      // storeActions.deleteMultipleItems(productIds, true)
      //    .then(response => {
      //       console.log(response);
      //       try {
      //          // let list = [...this.state.itemList];
      //          // list = list.filter(item => item.id !== itemId);

      //          this.getProductsList(this.state.current_page, this.state.page_size, this.state.filtersValues);
      //          notification.open({
      //             message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
      //             description: languageManager.getMessage(this.props.commonState, 'notification.confirm.deleteOk'),
      //             icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
      //          })
      //       } catch (e) {
      //          notification.open({
      //             message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
      //             description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
      //             icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
      //          })
      //       }

      //    }).catch((e) => {
      //       notification.open({
      //          message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
      //          description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
      //          icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
      //       })
      //       if (this.props.commonState.debug) console.log(e);
      //    });
   }

   confirmMultipleHandler = (onConfirm, productIds) => {
      switch (onConfirm) {
         case 'deleteItems':
            if (productIds) {
               this.deleteMultipleItemsHandler(productIds);
            }
            break;
         default:
            break;
      }
   }

   deleteItemHandler = (itemId) => {
      this.setState({ loadersCustomers: startLoading(this.state.loadersCustomers, itemId) });
      storeActions.deleteItem(itemId, true)
         .then(response => {
            console.log(response);
            try {
               Promise.all([
                  this.getCustomersList(this.state.current_page, this.state.page_size, this.state.filtersValues)
               ]).then(() => {
                  this.setState({ loadersCustomers: endLoading(this.state.loadersCustomers, itemId) });
               });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.deleteOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   importFromMarketplaceHandler = (marketplaceId) => {
      this.setState({ importLoader: true });
      storeActions.importFromMarketplace(marketplaceId)
         .then(response => {
            console.log(response);
            try {
               Promise.all([
                  this.getCustomersList(this.state.current_page, this.state.page_size, this.state.filtersValues)
               ]).then(() => {
                  this.setState({ importLoader: false });
               })
               notification.success({
                  message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
                  description: response.human ? (
                     <List
                        size="small"
                        split={false}
                        dataSource={response.human}
                        renderItem={item => <List.Item>{item}</List.Item>}
                     />
                  ) : languageManager.getMessage(this.props.commonState, 'notification.confirm.importOk'),
                  duration: 0
               })
            } catch (e) {
               this.setState({ importLoader: false });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            this.setState({ importLoader: false });
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.error.title'),
               description: e.human ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.human}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   confirmHandler = (onConfirm, itemId) => {
      switch (onConfirm) {
         case 'deleteItem':
            if (itemId) {
               this.deleteItemHandler(itemId);
            }
            break;
         default:
            break;
      }
   }

   setDefaultLanguageHandler = (value) => {
      this.setState({ componentLanguage: value })
   }

   customersExportHandler = (marketplace, customerIds, params) => {
      let filename
      switch (marketplace) {
         case 'nzt':
            this.setState({ multipleActionLoader: true });
            filename = Moment().format('YYYY.MM.DD-HH.mm.ss') + '_CUSTOMERS.csv';
            storeActions.exportCustomersCSV(filename, customerIds)
               .then(response => {
                  this.setState({ multipleActionLoader: false });
                  console.log(response);
                  try {
                     this.setState({ loader: false });
                  } catch (e) {
                     notification.open({
                        message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                        description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                        icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                     })
                  }

               }).catch((e) => {
                  this.setState({ multipleActionLoader: false });
                  notification.open({
                     message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                     description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                     icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                  })
                  if (this.props.commonState.debug) console.log(e);
               });
            break;
         default:
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.no_marketplace_selected'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            break;
      }
   }

   onSelectChange = (selectedRowKeys) => {
      this.setState({
         selectedRowKeys: selectedRowKeys
      });
      console.log(selectedRowKeys);
   };

   submitFilters = (values) => {
      this.setState({ filtersValues: values })
      this.getCustomersList(this.state.current_page, this.state.page_size, values)
   }
   resetFilters = () => {
      this.getCustomersList(this.state.current_page, this.state.page_size, []);
   }

   selectAllHandler = () => {
      if (this.state.selectedAll) {
         this.setState({
            selectedAll: false,
            selectedRowKeys: [],
         });
      }
      else {
         this.setState({
            selectedAll: true,
            selectedRowKeys: this.state.fullListIds,
         });
      }
   }
   multipleActionHandler = (value) => {
      console.log(value)
      const props = value.item.props
      switch (props.action) {
         case 'import_from_marketplace':
            this.importFromMarketplaceHandler(props.params.marketplace_id);
            break;
         case 'export_csv':
            this.customersExportHandler('nzt', this.state.selectedRowKeys);
            break;
         case 'delete_customers':
            this.confirmMultipleHandler('deleteItems', this.state.selectedRowKeys);
            break;
         default:
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            break;

      }
   }

   handleTableChange = (pagination, filters, sorter, extra) => {
      console.log(pagination, filters, sorter, extra);
      this.getCustomersList(pagination.current, pagination.pageSize, this.state.filtersValues);
   };


   editCustomerHandler = item => {
      this.setState({ createCustomerDrawerItem: item, showCreateCustomerDrawer: true })
   }
   showCreateCustomerDrawer = () => {
      this.setState({ createCustomerDrawerItem: null, showCreateCustomerDrawer: true })
   }
   hideCreateCustomerDrawer = () => {
      this.setState({ createCustomerDrawerItem: null, showCreateCustomerDrawer: false })
   }
   handleCreateCustomer = (Customers_list) => {
      console.log('Quantità in magazzino creata correttamente')
      var newFormValues = { ...this.state.formValues }
      newFormValues.Customers = Customers_list
      this.setState({ formValues: newFormValues, createCustomerDrawerItem: null, showCreateCustomerDrawer: false })
   }

   render() {
      if (this.state.getError) return <Reloader />;
      if (this.state.pageLoader) return <Loader />;
      const { Title } = Typography
      const { Option } = Select;
      const { Panel } = Collapse;

      const table_columns = [
         // {
         //    title: 'Nome completo',
         //    key: 'full_name',
         //    fixed: 'left',
         //    width: 250,
         //    render: (record) => (
         //       <a className="link-cell" onClick={() => this.editItemHandler(record.id)}>
         //          {record.full_name}
         //       </a>
         //    ),
         //    sorter: (a, b) => {
         //       a = a.full_name || ''
         //       b = b.full_name || ''
         //       return a.localeCompare(b)
         //    },
         // },
         {
            title: 'Nome',
            key: 'first_name',
            width: 150,
            fixed: 'left',
            render: (record) => (
               record.first_name
            ),
            sorter: (a, b) => {
               a = a.first_name || ''
               b = b.first_name || ''
               return a.localeCompare(b)
            },
         },
         {
            title: 'Cognome',
            key: 'last_name',
            width: 150,
            fixed: 'left',
            render: (record) => (
               record.last_name
            ),
            sorter: (a, b) => {
               a = a.last_name || ''
               b = b.last_name || ''
               return a.localeCompare(b)
            },
         },
         {
            title: 'Azienda',
            key: 'company_name',
            width: 250,
            fixed: 'left',
            render: (record) => (
               record.company
            ),
            sorter: (a, b) => {
               a = a.company || ''
               b = b.company || ''
               return a.localeCompare(b)
            },
         },
         {
            title: 'Username',
            key: 'username',
            width: 150,
            render: (record) => (
               <a className="link-cell" onClick={() => this.editItemHandler(record.id)}>
                  {record.username}
               </a>
            ),
            sorter: (a, b) => {
               a = a.username || ''
               b = b.username || ''
               return a.localeCompare(b)
            },
         },
         {
            title: 'Email',
            key: 'email',
            render: (record) => (
               record.email
            ),
            sorter: (a, b) => a.email.localeCompare(b.email),
         },
         {
            title: 'Telefono',
            key: 'phone',
            width: 150,
            render: (record) => (
               record.phone
            ),
            sorter: (a, b) => {
               a = a.phone || ''
               b = b.phone || ''
               return a.localeCompare(b)
            },
         },
         {
            title: 'Gruppo',
            key: 'group',
            width: 150,
            render: (record) => (
               record.group ? record.group.name : ''
            ),
            sorter: (a, b) => a.group.name.localeCompare(b.group.name),
         },
         {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            align: 'right',
            width: 100,
            render: (text, record) => (
               <Space>
                  <Button type="primary" size='small'
                     disabled={this.state.loadersCustomers[record.id]}
                     icon={<EditOutlined />}
                     onClick={() => this.editItemHandler(record.id)}
                  />
                  {/* <Dropdown.Button type="primary" onClick={() => this.editItemHandler(record.id)} overlay={actionsMenu(record.id)}><EditOutlined /></Dropdown.Button> */}
                  <Popconfirm
                     placement="topRight"
                     title={languageManager.getMessage(this.props.commonState, 'common.askDeleteConfirm')}
                     onConfirm={() => this.confirmHandler('deleteItem', record.id)}
                     okText={languageManager.getMessage(this.props.commonState, 'common.yes')}
                     cancelText={languageManager.getMessage(this.props.commonState, 'common.no')}
                  >
                     <Button type="danger" size='small'
                        loading={this.state.loadersCustomers[record.id]}
                        icon={<DeleteOutlined />}
                     />
                  </Popconfirm>
               </Space>),
         },
      ];
      const tableLayout = {
         bordered: true,
         size: "small",
         loading: this.state.tableLoader,
         pagination: {
            pageSize: this.state.page_size,
            total: this.state.total_result,
            showSizeChanger: true,
            defaultPageSize: 50,
            size: "small",
            showTotal: total => total + (total !== 1 ? ' clienti' : ' cliente')
         },
         onChange: this.handleTableChange
      }
      const { selectedRowKeys } = this.state;
      const rowSelection = {
         selectedRowKeys,
         onChange: this.onSelectChange,
         preserveSelectedRowKeys: true
      };

      const pageFilters = (
         <Collapse defaultActiveKey={['filters']}>
            <Panel header="Filtri" key="filters">
               <TableFilters submitFilters={this.submitFilters} resetFilters={this.resetFilters} filters={this.state.filters} selectValues={this.state.filtersValues} />
            </Panel>
         </Collapse>
      );

      const hasSelected = selectedRowKeys.length > 0;
      const singleSelected = selectedRowKeys.length > 1;
      const pageContent = (
         <div className="card">
            <div className="card-header">
               <div className="align-items-center row">
                  <div className="col-sm-6">
                     <Title level={5}>{languageManager.getMessage(this.props.commonState, 'component.customers.title')}</Title>
                  </div>
                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">
                        <Space>
                           <Button
                              type='primary'
                              icon={<PlusOutlined />}
                              // onClick={this.showCreateCustomerDrawer}
                              onClick={() => this.editItemHandler()}
                           >
                              Crea nuovo cliente
                           </Button>
                           <Button
                              type='primary'
                              icon={<UploadOutlined />}
                              onClick={() => this.props.history.push('/customers/import/')}
                           >
                              Importa anagrafiche clienti
                           </Button>
                           <DropdownMenu
                              type='primary'
                              title={'Importa dati da Marketplace'}
                              action={(event) => this.multipleActionHandler(event)}
                              values={this.state.importDropdownActionList}
                              disabled={_.isEmpty(this.state.importDropdownActionList)}
                              loading={this.state.importLoader}
                           />
                           {
                              this.props.authState.userData.languages ? (
                                 <Select
                                    showSearch
                                    className="width-70px-I"
                                    placeholder="Seleziona la lingua"
                                    defaultValue={this.state.componentLanguage.toUpperCase()}
                                    optionFilterProp="children"
                                    onChange={this.setDefaultLanguageHandler}
                                 >
                                    {
                                       this.props.authState.userData.languages.map((lang) => {
                                          return <Option key={lang.value} value={lang.value}>{lang.label}</Option>
                                       })
                                    }
                                 </Select>
                              ) : null
                           }
                        </Space>
                     </div>
                  </div>
               </div>
            </div>
            <div className="card-body">
               <Row>
                  <Col span={24}>
                     {pageFilters}
                  </Col>
               </Row>
               <Row>
                  <Col span={24}>
                     <div className="align-items-center row" style={{ marginTop: 15, marginBottom: 5 }}>
                        <div className="col-sm-12">
                           <div className="d-none d-md-block">
                              <Space>
                                 <Button
                                    onClick={() => this.selectAllHandler()}
                                    disabled={_.isEmpty(this.state.fullListIds)}
                                 >{this.state.selectedAll ? 'Deseleziona' : 'Seleziona tutto'}
                                 </Button>
                                 <span>
                                    {hasSelected ? singleSelected ? `${selectedRowKeys.length} elementi selezionati` : `${selectedRowKeys.length} elemento selezionato` : ''}
                                 </span>
                                 <DropdownMenu
                                    type='primary'
                                    title='Azioni multiple'
                                    action={(event) => this.multipleActionHandler(event)}
                                    values={this.state.multipleActionList}
                                    disabled={!hasSelected}
                                    loading={this.state.multipleActionLoader}
                                 />
                              </Space>
                           </div>
                        </div>
                     </div>
                     <Table
                        key='dataTable'
                        {...tableLayout}
                        rowSelection={rowSelection}
                        columns={table_columns}
                        dataSource={this.state.itemList}
                        scroll={{ x: 'max-content' }}
                        rowKey={record => (record.id)}
                     />
                     <CreateCustomerDrawer
                        key='createCustomerDrawer'
                        visible={this.state.showCreateCustomerDrawer}
                        itemId={this.state.itemId}
                        item={this.state.createCustomerDrawerItem}
                        onClose={this.hideCreateCustomerDrawer}
                        onSubmit={(customers_list) => this.handleCreateCustomer(customers_list)} />
                  </Col>
               </Row>
            </div>
            <div className="card-footer">
               <Button
                  size='large'
                  onClick={() => { this.props.history.goBack() }}
               >
                  <i className="mdi mdi-arrow-left-drop-circle-outline mr-1"></i>
                  {languageManager.getMessage(this.props.commonState, 'common.back')}
               </Button>
            </div>
         </div>
      );

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>
      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState,
   };
};

export default connect(mapStateToProps)(CustomersList);