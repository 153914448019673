
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import { Card, Avatar, Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';

// users
import userDefault from '../../assets/images/users/user-default.jpg';

class ProfileMenu extends Component {

   constructor(props) {
      super(props);
      this.state = {
         menu: false,
      };
      this.toggle = this.toggle.bind(this);
   }

   toggle() {
      this.setState(prevState => ({
         menu: !prevState.menu
      }));
   }


   render() {
      const { Meta } = Card;
      const { Text } = Typography;
      return (
         <React.Fragment>
            <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block" >
               <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
                  <img className="rounded-circle header-profile-user" src={userDefault} alt="Header Avatar" />
               </DropdownToggle>
               <DropdownMenu right>
                  {/* <DropdownItem onClick={() => this.props.history.push('/profile')}>

                     <i className="mdi mdi-account-outline font-size-17 align-middle mr-1"></i>
                     <Text strong>{this.props.authState.userData.email}</Text>
                     <br />
                     <Text>Visualizza profilo</Text>
                  </DropdownItem> */}
                  <DropdownItem>

                     <i className="mdi mdi-account-outline font-size-17 align-middle mr-1"></i>
                     <Text strong>{this.props.authState.userData.email}</Text>
                  </DropdownItem>
                     <Link
                        to='/user/edit_password'
                        className="dropdown-item"
                     >
                        <i className="mdi mdi-account-circle font-size-17 align-middle mr-1"></i>
                        <span>Modifica password</span>
                     </Link>

                  {/* <Link
                     to='/profile'
                     className="dropdown-item"
                  >
                     <Card><Meta title="Alain Mantovan" description="My profile" > </Meta> </Card>
                  </Link> */}
                  {/* <DropdownItem tag="a" href="#"><i className="mdi mdi-wallet font-size-17 align-middle mr-1"></i>My Wallet</DropdownItem> */}
                  {/* <DropdownItem tag="a" href="#"><span className="badge badge-success float-right">11</span><i className="mdi mdi-settings font-size-17 align-middle mr-1"></i>Settings</DropdownItem> */}
                  {/* <DropdownItem tag="a" href="#"><i className="mdi mdi-lock-open-outline font-size-17 align-middle mr-1"></i>Lock screen</DropdownItem> */}
                  <div className="dropdown-divider"></div>
                  <Link
                     to='/logout'
                     className="dropdown-item"
                  >
                     <i className="mdi mdi-logout font-size-17 align-middle mr-1"></i>
                     <span>Logout</span>
                  </Link>
               </DropdownMenu>
            </Dropdown>
         </React.Fragment>
      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState,
   };
};

export default withRouter(connect(mapStateToProps)(ProfileMenu));


