import React from "react";
import { Redirect } from "react-router-dom";

// Pages Component

// Authentication related pages
import Login from "../components/authentication/PageLogin";
import Logout from "../components/authentication/Logout";
// import Register from "../pages/Authentication/Register";
// import ForgetPwd from "../pages/Authentication/FogetPassword";

import PasswordEdit from "../components/user/PasswordEdit";

import Dashboard from "../components/dashboard/Dashboard";

import CustomersList from "../components/personal_data/customers/CustomersList";
import CustomersEdit from "../components/personal_data/customers/CustomersEdit";
import CustomersImport from "../components/personal_data/customers/CustomersImport";
import CustomerGroupsList from "../components/personal_data/customer_groups/GroupsList";
import CustomerGroupsEdit from "../components/personal_data/customer_groups/GroupsEdit";
import SuppliersList from "../components/personal_data/suppliers/SuppliersList";
import SuppliersEdit from "../components/personal_data/suppliers/SuppliersEdit";
import SuppliersImport from "../components/personal_data/suppliers/SuppliersImport";

import CategoriesList from "../components/catalogue/categories/CategoriesList";
import CategoriesOrder from "../components/catalogue/categories/CategoriesOrder";
import CategoriesEdit from "../components/catalogue/categories/CategoriesEdit";

import ProductsList from "../components/catalogue/products/ProductsList";
import ProductsImport from "../components/catalogue/products/ProductsImport";
import ProductsImportCSV from "../components/catalogue/products/ProductsImportCSV";
import ProductsEdit from "../components/catalogue/products/ProductsEdit";

import BatchesList from "../components/catalogue/batches/BatchesList";
import BatchesEdit from "../components/catalogue/batches/BatchesEdit";

import TagsList from "../components/catalogue/tags/TagsList";
import TagsEdit from "../components/catalogue/tags/TagsEdit";

import SpecificsList from "../components/catalogue/specifics/SpecificsList";
import SpecificsEdit from "../components/catalogue/specifics/SpecificsEdit";

import SpecificValuesList from "../components/catalogue/specifics/SpecificValuesList";
import SpecificValuesEdit from "../components/catalogue/specifics/SpecificValuesEdit";

import VariationsList from "../components/catalogue/variations/VariationsList";
import VariationsEdit from "../components/catalogue/variations/VariationsEdit";

import VariationValuesList from "../components/catalogue/variations/VariationValuesList";
import VariationValuesEdit from "../components/catalogue/variations/VariationValuesEdit";

import PricesList from "../components/catalogue/prices/PricesList";
import PricesEdit from "../components/catalogue/prices/PricesEdit";

import OrdersList from "../components/marketplaces/orders/OrdersList";
import OrderCreate from "../components/marketplaces/orders/OrderCreate";
import OrderEdit from "../components/marketplaces/orders/OrderEdit";
import OrderDetails from "../components/marketplaces/orders/OrderDetails";
import OrderSummary from "../components/marketplaces/orders/OrderSummary";
import OrdersImportCSV from "../components/marketplaces/orders/OrdersImportCSV";

import PurchaseOrdersList from "../components/warehouse/purchase_orders/PurchaseOrdersList";
import PurchaseOrderEdit from "../components/warehouse/purchase_orders/PurchaseOrderEdit";
import PurchaseOrderDetails from "../components/warehouse/purchase_orders/PurchaseOrderDetails";

import WarehousesList from "../components/warehouse/warehouses/WarehousesList";

import WarehouseMovementsList from "../components/warehouse/movements/WarehouseMovementsList";
import WarehouseSituation from "../components/warehouse/movements/WarehouseSituation";
import BatchHistory from "../components/warehouse/movements/BatchHistory";

// import Dashboard from "../pages/Dashboard";
import MarketplacesList from "../components/configuration/marketplace/MarketplacesList";
import MarketplacesEdit from "../components/configuration/marketplace/MarketplacesEdit";
import MarketplacesConfig from "../components/configuration/marketplace/MarketplacesConfig";

import MarketplaceProductsList from "../components/catalogue/marketplaceProducts/MarketplaceProductsList";
import MarketplaceProductsListProducts from "../components/catalogue/marketplaceProducts/MarketplaceProductsListProducts";

import PriceRulesList from "../components/configuration/priceRules/PriceRulesList";
import PriceRulesEdit from "../components/configuration/priceRules/PriceRulesEdit";

import PriceRulesSetsEdit from "../components/configuration/priceRulesSets/PriceRulesSetsEdit";

import TaxRatesList from "../components/configuration/taxes/TaxRatesList";
import TaxRatesEdit from "../components/configuration/taxes/TaxRatesEdit";

import ShippingMethodsList from "../components/configuration/shipping_methods/ShippingMethodsList";

import PaymentMethodsList from "../components/configuration/payment_methods/PaymentMethodsList";
import BanksList from "../components/configuration/banks/BanksList";

import LogsList from "../components/marketplaces/logs/LogsList";
// import LogsView from "../components/marketplaces/logs/LogsView";

import Profile from "../components/profile/Profile";

const authProtectedRoutes = [
   // User
   { path: "/user/edit_password", component: PasswordEdit },
   
   // Profile
   // { path:'/profile', component : Profile},

   // Dashboard
   { path: "/dashboard", component: Dashboard },

   // Personal data
   { path: "/customers/list/", component: CustomersList },
   { path: "/customers/edit/:id?", component: CustomersEdit },
   { path: "/customers/import/", component: CustomersImport },
   { path: "/customer_groups/list/", component: CustomerGroupsList },
   { path: "/customer_groups/edit/:id?", component: CustomerGroupsEdit },
   { path: "/suppliers/list/", component: SuppliersList },
   { path: "/suppliers/edit/:id?", component: SuppliersEdit },
   { path: "/suppliers/import/", component: SuppliersImport },

   // Categories
   { path: "/categories/list/:parent?", component: CategoriesList },
   { path: "/categories/order", component: CategoriesOrder },
   { path: "/categories/edit/:id?", component: CategoriesEdit },

   // Products
   { path: "/products/list/", component: ProductsList },
   { path: "/products/import/", component: ProductsImport },
   { path: "/products/import_csv/", component: ProductsImportCSV },
   { path: "/products/edit/:id?", component: ProductsEdit },

   // Batches
   { path: "/batches/list", component: BatchesList },
   { path: "/batches/edit/:id?", component: BatchesEdit },

   // Tags
   { path: "/tags/list", component: TagsList },
   { path: "/tags/edit/:id?", component: TagsEdit },

   // Specifics
   { path: "/specifics/list", component: SpecificsList },
   { path: "/specifics/edit/:id?", component: SpecificsEdit },
   { path: "/specifics/values/list/:parent", component: SpecificValuesList },
   { path: "/specifics/values/edit/:parent/:id?", component: SpecificValuesEdit },

   // Variations
   { path: "/variations/list", component: VariationsList },
   { path: "/variations/edit/:id?", component: VariationsEdit },
   { path: "/variations/values/list/:parent", component: VariationValuesList },
   { path: "/variations/values/edit/:parent/:id?", component: VariationValuesEdit },

   // Price Rules
   { path: "/prices/list/", component: PricesList },
   { path: "/prices/edit/:id?", component: PricesEdit },

   // Marketplace
   { path: "/marketplaces/list", component: MarketplacesList },
   { path: "/marketplaces/edit/:id?", component: MarketplacesEdit },
   { path: "/marketplaces/config/:id", component: MarketplacesConfig },

   { path: "/marketplace_products/list", component: MarketplaceProductsList },
   { path: "/marketplace_products/list_products/:marketplace", component: MarketplaceProductsListProducts },

   // Orders
   { path: "/orders/list", component: OrdersList },
   { path: "/orders/create", component: OrderCreate },
   { path: "/orders/edit/:id?", component: OrderEdit },
   { path: "/orders/details/:id", component: OrderDetails },
   { path: "/orders/summary/:id", component: OrderSummary },
   { path: "/orders/import_csv/", component: OrdersImportCSV },

   // Purchase Orders
   { path: "/purchase_orders/list", component: PurchaseOrdersList },
   { path: "/purchase_orders/edit/:id?", component: PurchaseOrderEdit },
   { path: "/purchase_orders/details/:id", component: PurchaseOrderDetails },
   
   { path: "/warehouses/list", component: WarehousesList },
   // { path: "/warehouses/edit/:id?", component: PurchaseOrderEdit },

   { path: "/warehouse_movements/list", component: WarehouseMovementsList },
   { path: "/warehouse_movements/situation", component: WarehouseSituation },
   { path: "/warehouse_movements/batch_history", component: BatchHistory },
   
   // Price Rules
   { path: "/price_rules/list/", component: PriceRulesList },
   { path: "/price_rules/edit/:id?", component: PriceRulesEdit },

   { path: "/price_rules_sets/edit/:id?", component: PriceRulesSetsEdit },

   // Tax Rates
   { path: "/taxes/taxrates/list", component: TaxRatesList },
   { path: "/taxes/taxrates/edit/:id?", component: TaxRatesEdit },

   { path: "/shipping_methods/list", component: ShippingMethodsList },

   { path: "/payment_methods/list", component: PaymentMethodsList },
   { path: "/banks/list", component: BanksList },

   // Logs
   { path: "/logs/list", component: LogsList },
   // { path: "/logs/details/:id", component: LogsView },

   { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> }
];

const publicRoutes = [
   { path: "/logout", component: Logout },
   { path: "/login", component: Login },
   // { path: "/forget-password", component: ForgetPwd },
   // { path: "/pages-register", component: Register },

   // // Extra Pages
   // { path: "/pages-login-2", component: PagesLogin2 },
   // { path: "/pages-register-2", component: PagesRegister2 },
   // { path: "/pages-recoverpw-2", component: PagesRecoverpw2 },
   // { path: "/pages-lock-screen-2", component: PagesLockScreen2 },

   // { path: "/pages-404", component: Pages404 },
   // { path: "/pages-500", component: Pages500 },
   // { path: "/pages-Maintenance", component: PagesMaintenance },
   // { path: "/pages-comingsoon", component: PagesComingsoon }
];

export { authProtectedRoutes, publicRoutes };
