import React, { Component } from 'react';
import { connect } from 'react-redux';

import API_BASE from '../../../api/apiBase';
import API_CONFIG from '../../../api/apiConfig';

import { ProductsImportFileCSVType } from '../../../shared/enums.ts'

import { Form, Input, Select, Button, Space, notification, Alert, List } from 'antd'
import { Upload, message } from 'antd'
import { InboxOutlined, InfoCircleOutlined } from '@ant-design/icons'

import languageManager from '../../../services/languageManager'
import * as storeActions from './store/actions/products'

import Loader from '../../common/PageElements/Loader'
import PageHeader from '../../common/PageElements/PageHeader'

class ProductsImportCSV extends Component {

   state = {
      action: undefined,
      saveAndClose: false,
      loader: true,
      formValues: {},
      componentLanguage: this.props.authState.defaultLanguage,
      draggerDisable: false,
      import_type: [
         {
            id: ProductsImportFileCSVType.TEXTS,
            name: 'Aggiornamento testi'
         },
         {
            id: ProductsImportFileCSVType.QUANTITIES,
            name: 'Aggiornamento quantità'
         },
         {
            id: ProductsImportFileCSVType.PRICES,
            name: 'Aggiornamento prezzi'
         },
         {
            id: ProductsImportFileCSVType.IMAGES,
            name: 'Aggiornamento immagini'
         },
         // {
         //    id: ProductsImportFileCSVType.ADDCATEGORIES,
         //    name: 'Aggiunta categorie'
         // },
         {
            id: ProductsImportFileCSVType.ADDTAGS,
            name: 'Aggiunta tag'
         },
         {
            id: ProductsImportFileCSVType.TODELETE,
            name: 'Cancellazione prodotti'
         },
      ]
   };

   componentDidMount() {
      const formValues = {
         language: this.state.componentLanguage
      }
      this.setState({ formValues: formValues, loader: false });
   }

   handleSaveAndClose = () => {
      this.setState({ saveAndClose: true });
      console.log(this.state);
   }

   onFinish = values => {
      console.log(values);
      this.setState({ loader: true });
      const data = {};
      data.language = values.language;
      data.file_path = values.fileImport[0].response.data.file_path;
      data.file_name = values.fileImport[0].response.data.file_name;
      data.file_type = values.file_type;
      if (!data.file_path || !data.file_name) {
         this.setState({ loader: false, draggerDisable: false });
         notification.open({
            message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
            description: languageManager.getMessage(this.props.commonState, 'notification.error.dataNotValid'),
            icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
         })
      }
      else {
         storeActions.importFileCSV(data)
            .then(response => {
               console.log(response);
               try {
                  this.setState({
                     response: response.data,
                     loader: false,
                     draggerDisable: false
                  });
                  console.log(this.state.response.errors);

                  notification.success({
                     message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
                     description: response.data.human ? (
                        <List
                           size="small"
                           split={false}
                           dataSource={response.data.human}
                           renderItem={item => <List.Item>{item}</List.Item>}
                        />
                     ) : languageManager.getMessage(this.props.commonState, this.state.action === 'edit' ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
                     duration: 0
                  })

               } catch (e) {
                  this.setState({ loader: false, draggerDisable: false });
                  notification.open({
                     message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                     description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                     icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                  })
               }

            }).catch((e) => {
               this.setState({ loader: false, draggerDisable: false });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: e.data && e.data.error ? e.data.error : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            });
      }
   };

   onFinishFailed = errorInfo => {
      notification.open({
         message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
         description: languageManager.getMessage(this.props.commonState, 'notification.error.formInvalid'),
         icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
      })
   };

   setDefaultLanguageHandler = (value) => {
      console.log(value);
      const updatedValues = this.setForm(this.state.response, value);
      this.setState({ formValues: updatedValues, componentLanguage: value })
   }

   render() {
      if (!this.props.authState.userData.languages) return '';
      const layout = {
         // layout: 'inline',
         labelCol: { span: 8 },
         wrapperCol: { span: 16 },
      };
      const { Option } = Select;
      const scope = this;

      const normFile = e => {
         console.log('Upload event:', e);
         if (Array.isArray(e)) {
            return e;
         }
         return e && e.fileList;
      };

      const draggerProps = {
         name: 'file',
         multiple: false,
         disabled: this.state.draggerDisable,
         action: API_BASE.url + API_CONFIG.appUrls.files.fileImport,
         onChange(info) {
            scope.setState({ draggerDisable: info.fileList.length });
            console.log("CHANGE: ", info);
            const { status } = info.file;
            if (status !== 'uploading') {
               console.log(info.file, info.fileList);
            }
            if (status === 'done') {
               message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
               message.error(`${info.file.name} file upload failed.`);
            }
         },
      };

      let form = (
         <Form
            {...layout}
            id="importFile"
            name="basic"
            initialValues={this.state.formValues}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
         >
            <Form.Item
               name="fileImport"
               rules={[{ required: true, message: 'Scegli un file' }]}
               valuePropName="fileList"
               getValueFromEvent={normFile}
               colon={false}
               wrapperCol={24}
            >
               <Upload.Dragger {...draggerProps}>
                  <p className="ant-upload-drag-icon">
                     <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                  <p className="ant-upload-hint">
                     Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                     band files
                  </p>
               </Upload.Dragger>
            </Form.Item>
            <Form.Item
               label="Tipo di file"
               name="file_type"
               rules={[{ required: true, message: 'Scegli il tipo di file' }]}
            >

               <Select
                  showSearch
                  size="large"
                  // className="width-70px-I"
                  placeholder="Seleziona il tipo di file"
                  optionFilterProp="children"
               >
                  {
                     this.state.import_type.map((map) => {
                        return <Option key={map.id} value={map.id}>{map.name}</Option>
                     })
                  }
               </Select>

            </Form.Item>
            <Form.Item label="language" name="language" noStyle><Input type="hidden" /></Form.Item>
         </Form>
      );

      const pageContent = (
         <div className="card">
            <div className="card-header">
               <div className="align-items-center row">
                  <div className="col-sm-12">
                     <div className="float-right d-none d-md-block">
                        <Space>
                           <Select
                              showSearch
                              size="large"
                              className="width-70px-I"
                              placeholder="Seleziona la lingua"
                              defaultValue={this.state.componentLanguage.toUpperCase()}
                              optionFilterProp="children"
                              onChange={this.setDefaultLanguageHandler}
                           //  onFocus={onFocus}
                           //  onBlur={onBlur}
                           //  onSearch={onSearch}
                           //  filterOption={(input, option) =>
                           //    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                           //  }
                           >
                              {
                                 this.props.authState.userData.languages.map((lang) => {
                                    return <Option key={lang.value} value={lang.value}>{lang.label}</Option>
                                 })
                              }
                           </Select>
                        </Space>
                     </div>
                  </div>
               </div>
            </div>
            <div className="card-body">
               {
                  this.state.response && this.state.response.successes ?
                     <Alert
                        closable
                        message="Importazione completata"
                        description={<List
                           size="small"
                           dataSource={this.state.response.successes}
                           renderItem={item => <List.Item className='padding-0-I'>{item}</List.Item>}
                        />}
                        type="success"
                        showIcon
                     />
                     : ''
               }
               {
                  this.state.response && this.state.response.warnings && this.state.response.warnings.length ?
                     <Alert
                        closable
                        message="Attenzione"
                        description={<List
                           size="small"
                           dataSource={this.state.response.warnings}
                           renderItem={item => <List.Item className='padding-0-I'>{item.message}</List.Item>}
                        />}
                        type="warning"
                        showIcon
                     />
                     : ''
               }
               {
                  this.state.response && this.state.response.errors && this.state.response.errors.length ?
                     <Alert
                        closable
                        message="Attenzione"
                        description={<List
                           size="small"
                           dataSource={this.state.response.errors}
                           renderItem={item => <List.Item className='padding-0-I'>{item}</List.Item>}
                        />}
                        type="error"
                        showIcon
                     />
                     : ''
               }
               {
                  this.state.response && this.state.response.details ?
                     <Alert
                        closable
                        message="Dettagli importazione"
                        type="info"
                        description={<List
                           size="small"
                           dataSource={this.state.response.details}
                           renderItem={item => <List.Item className='padding-0-I'>{item}</List.Item>}
                        />}
                        showIcon
                     />
                     : ''
               }
               {this.state.loader ? <Loader /> : form}
            </div>
            <div className="card-footer">
               <div className="align-items-center row">
                  <div className="col-sm-6">

                     <Space>
                        <Button
                           size='large'
                           onClick={() => { this.props.history.goBack() }}
                        >
                           <i className="mdi mdi-arrow-left-drop-circle-outline mr-1"></i>
                           {languageManager.getMessage(this.props.commonState, 'common.back')}
                        </Button>
                     </Space>
                  </div>

                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">

                        <Space>
                           <Button form="importFile" onClick={() => window.location.reload()}>Annulla</Button>
                           <Button form="importFile" type="primary" htmlType="submit" onClick={this.handleSaveAndClose}>Importa</Button>
                        </Space>

                     </div>
                  </div>
               </div>
            </div>
         </div>
      );
      const helpButton = {
         title: "Aiuto"
      }

      const buttons = [];
      buttons.push(helpButton);


      return (
         <div className="container-fluid">
            <PageHeader
               title={languageManager.getMessage(this.props.commonState, 'component.products.title')}
               buttons={buttons}
            />
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>

      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(ProductsImportCSV);