import API_BASE from './apiBase';
import API_CONFIG from './apiConfig';

import Axios from 'axios';

class SpecificValuesApi {

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getList() {

      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.specificValues.getList)
         .then(response => {
            console.log(response);
            resolve(response);
         })
         .catch(error => {
            reject(error);
         });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getListByParent(parentId) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.specificValues.getListByParent, {
            parent_id: parentId
         })
         .then(response => {
            console.log(response);
            resolve(response);
         })
         .catch(error => {
            reject(error);
         });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getItem(itemId) {

      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.specificValues.getItem, {
            id: itemId
         })
         .then(response => {
            console.log(response);
            resolve(response);
         })
         .catch(error => {
            reject(error);
         });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static createOrUpdate(itemData,) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.specificValues.createOrUpdate, {
            ...itemData
         })
         .then(response => {
            console.log(response);
            resolve(response);
         })
         .catch(error => {
            reject(error);
         });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static deleteItem(itemId, return_list) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.specificValues.deleteItem, {
            id: itemId,
            return_list: return_list
         })
         .then(response => {
            console.log(response);
            resolve(response);
         })
         .catch(error => {
            reject(error);
         });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static toggleValue(itemData) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.specificValues.toggleValue, {
            ...itemData
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }
}

export default SpecificValuesApi;