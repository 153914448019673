export default {
   authenticationState: {
      isLoggedIn: false,
      user: undefined,
      userData: {
         activeMarketplaces: [],
         languages: []
      },
      defaultLanguage: 'it'
   },
   commonState: {
      debug: true,
      errorCode: 0,
      recentNotification: 0,
      blocking: true,
      cfgLoading: 0,
      store: {
         countries: []
      },
      messages: []
   },
   layoutState: {
      leftSideBarTheme: "dark",
      leftSideBarType: "default",
      topBarTheme: "dark",
      isMobile: false
   }
};