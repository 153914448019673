import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Moment from 'moment';

import { Table, Button, Tooltip, Space, notification, Descriptions, Row, Col, Collapse, Divider, Modal } from 'antd';
import { InfoCircleOutlined, EyeOutlined, UploadOutlined, DeleteOutlined, EditOutlined, PrinterOutlined, FilterOutlined, CheckOutlined, CloseOutlined, ArrowRightOutlined } from '@ant-design/icons';

import languageManager from '../../../services/languageManager';

import * as storeActions from './store/actions/logs';

import Loader from '../../common/PageElements/Loader';
import PageHeader from '../../common/PageElements/PageHeader';

import TableFilters from '../../common/components/TableFilters';
import DropdownButton from '../../common/components/DropdownButton';
import DropdownMenu from '../../common/components/DropdownMenu';

class LogsList extends Component {
   state = {
      localPath: this.props.history.location.pathname,
      pageLoader: true,

      itemList: [],
      fullListIds: [],
      componentLanguage: this.props.authState.defaultLanguage,

      current_page: this.props.location.state ? this.props.location.state.previousState.current_page : 1,
      page_size: this.props.location.state ? this.props.location.state.previousState.page_size : 50,
      total_result: 0,

      selectedRowKeys: [],
      selectedAll: false,
      multipleActionList: [
         {
            //"RICONOSCI ERRORI" + CANCELLA LOG
            // id: OrderStatusActionEnum.MULTIPLE_REFUSE,
            // name: 'Rifiuta ordini'
         }
      ],
      action: "",

      showTableFilters: false,
      filters: this.props.location.state ? this.props.location.state.previousState.filters : [],
      filtersValues: this.props.location.state ? this.props.location.state.previousState.filtersValues : {},
      appliedFilters: this.props.location.state ? this.props.location.state.previousState.appliedFilters : [],

      showModalDetails: false,
      logData: {},

   };

   getLogsList = (page, pageSize, filters) => {
      storeActions.getListExtended(page, pageSize, filters)
         .then(response => {
            console.log(response);
            try {
               let updatedFilters = []
               for (let filter of response.list_filters) {
                  if (filter.type === 'date') {
                     if (filter.value) {
                        filter.value = Moment(filter.value, 'YYYY-MM-DD');
                     }
                  }
                  updatedFilters.push(filter);
               }

               this.setState({
                  itemList: response.data.data,
                  fullListIds: response.full_list_ids,
                  current_page: page,
                  page_size: pageSize,
                  total_result: response.data.total,
                  filters: updatedFilters,
                  appliedFilters: response.applied_filters,
                  pageLoader: false
               });
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            this.setState({ pageLoader: false })
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   componentDidMount() {
      // console.log(this.props.location.state, this.state);
      this.getLogsList(this.state.current_page, this.state.page_size, this.state.filtersValues);
   }

   listPaginationChangeHandler = (page, pageSize) => {
      console.log(page, pageSize, this.state.filtersValues);
      this.getOrdersList(page, pageSize, this.state.filtersValues);
   }

   onSelectChange = (selectedRowKeys, selection) => {
      // console.log('Selection', selection);
      this.setState({ selectedRowKeys: selectedRowKeys });
   };

   submitFilters = (values) => {
      // console.log("Selected filters", values)
      Object.keys(values).forEach(function (key) {
         if (Moment.isMoment(values[key])) {
            values[key] = Moment(values[key]).format('YYYY-MM-DD');
         }
      });
      this.setState({ filtersValues: values })
      this.getLogsList(this.state.current_page, this.state.page_size, values);
   }
   resetFilters = () => {
      this.getLogsList(this.state.current_page, this.state.page_size, []);
   }

   selectAllHandler = () => {
      if (this.state.selectedAll) {
         this.setState({
            selectedAll: false,
            selectedRowKeys: [],
         });
      }
      else {
         this.setState({
            selectedAll: true,
            selectedRowKeys: this.state.fullListIds,
         });
      }
   }

   showModalDetails = (log) => {
      this.setState({
         showModalDetails: true,
         logData: log
      })
   }

   render() {
      if (!this.props.authState.userData.languages) return '';
      const { selectedRowKeys } = this.state
      const { Panel } = Collapse

      const table_columns = [
         { title: 'ID', width: 100, dataIndex: 'id', key: 'id', fixed: 'left' },
         // {
         //    title: 'ID Marketplace',
         //    width: 150,
         //    dataIndex: 'marketplace_id',
         //    key: 'marketplace_id',
         //    fixed: 'left',
         //    render: (text, record) => (
         //       <a onClick={() => this.viewItemHandler(record.id)} >{text}</a>
         //    ),
         // },
         {
            title: 'Data',
            fixed: 'left',
            width: 150,
            key: 'created_at',
            render: (record) => (
               Moment(record.created_at).format('L LTS')
            ),
            sorter: (a, b) => a.created_at.localeCompare(b.created_at),
         },
         {
            title: 'Marketplace', width: 200, key: 'source',
            render: (record) => (
               record.marketplace ? record.marketplace.name : ''
            ),
            sorter: (a, b) => a.marketplace.name.localeCompare(b.marketplace.name),
         },
         {
            title: 'Operazione', key: 'operation',
            render: (record) => (
               record.operation
            ),
            // sorter: (a, b) => a.shipping_address.name.localeCompare(b.shipping_address.name)
         },
         {
            title: 'Stato', width: 200, key: 'status',
            render: (record) => (
               record.status_code
            ),
            sorter: (a, b) => a.status_code.localeCompare(b.status_code),
         },
         {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            align: 'right',
            width: 200,
            render: (text, record) => (
               <Space>
                  <Tooltip title={languageManager.getMessage(this.props.commonState, 'common.view')}>
                     <Button type="primary" size="small"
                        icon={<EyeOutlined />}
                        onClick={() => this.showModalDetails(record)}
                     />
                  </Tooltip>
               </Space>
            ),
         },
      ];

      const tableLayout = {
         bordered: true,
         size: "small",
         pagination: {
            pageSize: this.state.page_size,
            onChange: this.listPaginationChangeHandler,
            total: this.state.total_result
         },
         rowSelection: {
            selectedRowKeys: selectedRowKeys,
            onChange: this.onSelectChange,
            preserveSelectedRowKeys: true,
         }
      }

      const modalDetails = (
         <Modal
            visible={this.state.showModalDetails}
            width={1000}
            title="Dettagli LOG"
            onCancel={() => this.setState({ showModalDetails: false })}
            footer={[
               <Button key="back" onClick={() => this.setState({ showModalDetails: false })}>
                  Chiudi
               </Button>,
            ]}
         >
            {
               !_.isNil(this.state.logData) ? (
                  <Descriptions
                     size='small'
                     column={1}
                     bordered
                  >
                     <Descriptions.Item label="Operazione">{this.state.logData.operation}</Descriptions.Item>
                     <Descriptions.Item label="Risultato">{this.state.logData.result}</Descriptions.Item>
                     <Descriptions.Item label="Data Inizio">{Moment(this.state.logData.created_at).format('L LTS')}</Descriptions.Item>
                     <Descriptions.Item label="Data Fine">{Moment(this.state.logData.updated_at).format('L LTS')}</Descriptions.Item>
                     <Descriptions.Item label="Stato">{this.state.logData.status_code}</Descriptions.Item>
                     <Descriptions.Item label="Endpoint">{this.state.logData.endpoint}</Descriptions.Item>
                     <Descriptions.Item label="Parametri">
                        {
                           !_.isNil(this.state.logData.parameters) ?
                              <pre>{JSON.stringify(JSON.parse(this.state.logData.parameters), null, 2)}</pre> : ''
                        }
                     </Descriptions.Item>
                     <Descriptions.Item label="Request">
                        {
                           !_.isNil(this.state.logData.request) ?
                              <pre>{JSON.stringify(JSON.parse(this.state.logData.request), null, 2)}</pre> : ''
                        }
                     </Descriptions.Item>
                     <Descriptions.Item label="Response Headers">
                        {
                           !_.isNil(this.state.logData.response_headers) ?
                              <pre>{JSON.stringify(JSON.parse(this.state.logData.response_headers), null, 2)}</pre> : ''
                        }
                     </Descriptions.Item>
                     <Descriptions.Item label="Response Body">
                        {
                           !_.isNil(this.state.logData.response_body) ?
                              <pre>{JSON.stringify(JSON.parse(this.state.logData.response_body), null, 2)}</pre> : ''
                        }
                     </Descriptions.Item>
                  </Descriptions>
               ) : ''
            }
         </Modal>
      );

      const pageFilters = (
         <Collapse defaultActiveKey={['filters']}>
            <Panel header="Filtri" key="filters">
               <TableFilters submitFilters={this.submitFilters} resetFilters={this.resetFilters} filters={this.state.filters} selectValues={this.state.filtersValues} />
            </Panel>
         </Collapse>
         //<Button type="default" icon={<FilterOutlined />} onClick={() => (this.setState({ showTableFilters: !this.state.showTableFilters }))}>Filtra</Button>
      );

      const hasSelected = selectedRowKeys.length > 0;
      const singleSelected = selectedRowKeys.length > 1;
      const pageContent = (
         <div className="card">
            <div className="card-header">
               <div className="align-items-center row">
                  <div className="col-sm-6">{languageManager.getMessage(this.props.commonState, 'component.logs.title')}</div>
                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">
                        <Space>
                        </Space>
                     </div>
                  </div>
               </div></div>
            <div className="card-body">

               <Row>
                  <Col span={24}>{pageFilters}</Col>
               </Row>
               <Row>
                  <Col span={24}>
                     <div className="align-items-center row" style={{ marginTop: 15, marginBottom: 5 }}>
                        <div className="col-sm-6">
                           <div className="d-none d-md-block">
                              <Space>
                                 <Button
                                    onClick={() => this.selectAllHandler()}
                                 >{this.state.selectedAll ? 'Deseleziona' : 'Seleziona tutto'}
                                 </Button>
                                 <span>
                                    {hasSelected ? singleSelected ? `${selectedRowKeys.length} elementi selezionati` : `${selectedRowKeys.length} elemento selezionato` : ''}
                                 </span>
                                 {/* <DropdownMenu
                                    type='primary'
                                    title='Azioni multiple'
                                    action={this.multipleActionHandler}
                                    values={this.state.multipleActionList}
                                    disabled={!hasSelected}
                                    loading={this.state.multipleActionLoader}
                                 /> */}
                              </Space>
                           </div>
                        </div>
                        <div className="col-sm-6">
                           <div className="float-right d-none d-md-block">
                              {/* <Button type="primary" loading={this.state.loader}>
                        Mostra solo righe con differenze
                     </Button> */}
                           </div>
                        </div>
                     </div>
                  </Col>
               </Row>

               <Row>
                  <Col span={24}>
                     {this.state.pageLoader ?
                        <Loader /> :
                        <Table {...tableLayout}
                           columns={table_columns}
                           dataSource={this.state.itemList}
                           rowKey={(record) => (record.id)}
                           scroll={{ x: 'max-content' }}
                        />
                     }
                     {modalDetails}
                  </Col>
               </Row>
            </div>
         </div>
      );

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>
      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState,
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(LogsList);