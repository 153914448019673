import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import { Modal, Table, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import languageManager from '../../../../services/languageManager'

const ModalChooseShippingAddress = (props) => {
    const [addressList, setAddressList] = useState(props.addressList)
    const [visible, setVisible] = useState(props.visible)

    useEffect(() => {
        setAddressList(props.addressList)
        setVisible(props.visible)
    }, [props.addressList, props.visible])

    const onSelect = (values) => {
        console.log(values)
        props.onSelect(values)
        props.onClose()
    } 
    const onClose = () => {
       props.onClose()
    }
    
    const tableLayout = {
        bordered: true,
        size: "small",
     }
     const tableColumns = [
        {
           key: 'title',
           fixed: 'left',
           width: 150,
           render: (record) => (
              record.address_name
           ),
           sorter: (a, b) => a.address_name.localeCompare(b.address_name),
        },
        {
           title: 'Indirizzo',
           key: 'address',
           render: (record) => (
              record.full_address
           ),
        },
        {
           title: 'Azioni',
           key: 'actions',
           fixed: 'right',
           align: 'right',
           width: 50,
           render: (text, record) => (
              <Button type="success" size='small'
                 icon={<PlusOutlined />}
                 onClick={() => onSelect(record)}
              />
           )
        },
     ]



    return (
        <Modal
            visible={visible}
            title="Scegli indirizzo di spedizione"
            width={800}
            onClose={onClose}
            onCancel={onClose}
            footer={[
                <Button key="back" onClick={() => onClose()}>Annulla</Button>,
            ]}
        >
            <Table
                {...tableLayout}
                pagination={false}
                columns={tableColumns}
                dataSource={addressList}
            />
        </Modal>
    )
}


const mapStateToProps = state => {
    return {
        commonState: state.commonState,
    }
}

export default connect(mapStateToProps)(ModalChooseShippingAddress)