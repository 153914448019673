import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import languageManager from '../../../../services/languageManager'
import { startLoading } from '../../../../shared/utility'

import { Descriptions, Space, notification, Typography, Card, Button, Modal } from 'antd'
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons'

import * as storeActions from '../store/actions/customers'

import ShippingAddressDrawer from './ShippingAddressDrawer'

class CustomerShippingAddressCard extends Component {
    state = {
        ...this.props,
        loadersAddress: [],
        componentLoader: true,
    }

    componentDidMount() {
        console.log(this.props)
        Promise.all([
        ]).then(() => {
            this.setState({
                componentLoader: false,
                // rangeChangeLoader: false,
            });
        });
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        if (
            _.isEqual(nextProps.shippingAddresses, this.props.shippingAddresses)
            && _.isEqual(nextState, this.state)
        ) {
            return false
        }
        return true
    }

    // componentDidUpdate = (prevProps, prevState) => {
    //     console.log('componentDidUpdate')
    //     console.log(this.props.shippingAddress)
    //     // console.log(prevProps.categoryId, this.props.categoryId)
    //     if (!_.isEqual(prevProps.shippingAddress, this.props.shippingAddress)) {
    //         // console.log('componentDidUpdate CONDITION OK')
    //         this.setState({ componentLoader: true });
    //         Promise.all([
    //             // this.getCategoryAmazonData(this.props.categoryId),
    //             // this.getCountOrdersBoxData(),
    //             // this.prepareRangeSelection(),
    //         ]).then(() => {
    //             this.setState({
    //                 componentLoader: false,
    //                 // rangeChangeLoader: false,
    //             });
    //         });
    //     }
    // }
    confirmHandler = (action, itemId) => {
        switch (action) {
            case 'deleteItem':
                if (itemId) {
                    this.deleteShippingAddress(itemId)
                }
                break
            default:
                break
        }
    }

    deleteShippingAddress = itemId => {
        this.setState({ loadersAddress: startLoading(this.state.loadersAddress, itemId) })
       storeActions.deleteShippingAddress(itemId, this.state.customerId, true)
          .then(response => {
             console.log(response);
             try {
                this.setState({ shippingAddresses: response.data });
 
                notification.open({
                   message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
                   description: languageManager.getMessage(this.props.commonState, this.state.action === 'edit' ? 'notification.confirm.updateOk' : 'notification.confirm.deleteOk'),
                   icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                })
             } catch (e) {
                this.setState({ formLoader: false });
                notification.open({
                   message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                   description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                   icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                })
             }
 
          }).catch((e) => {
             this.setState({ formLoader: false });
             notification.open({
                message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                description: languageManager.getMessage(this.props.commonState, 'notification.error.' + e.message),
                icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
             })
          });
    };

    showDrawer = (itemId) => {
        this.setState({ drawerItemId: itemId, showDrawer: true })
    }
    hideDrawer = () => {
        this.setState({ drawerItemId: null, showDrawer: false })
    }
    handleEditAddress = (addresses_list) => {
        this.setState({ shippingAddresses: addresses_list, showDrawer: false })
    }

    render() {
        const { Title } = Typography;
        const addresses = this.state.shippingAddresses
        return (
            <Card loading={this.state.componentLoader || this.state.dataLoader}
                title={
                    <Title level={5}>{languageManager.getMessage(this.props.commonState, 'component.customers.shipping')}</Title>
                }
                size='small'
                bordered={false}
                className="card-analysis-data"
                extra={
                    <Button type="primary" size='small'
                        icon={<PlusOutlined />}
                        onClick={() => this.showDrawer()}
                    />
                }
            >
            <Space direction='vertical' size='small'>
                {
                    !_.isNil(addresses) ? (
                        addresses.map((address, i) => {
                            return (
                                    <Card
                                        title={
                                            <Title level={5}>{address.address_name ? address.address_name : languageManager.getMessage(this.props.commonState, 'component.customers.shipping_address') + ' ' + ++i}</Title>
                                        }
                                        size='small'
                                        bordered={true}
                                        key={address.id}
                                        extra={
                                            <Space size='small'>
                                                <Button type="primary" size='small'
                                                    icon={<EditOutlined />}
                                                    onClick={() => this.showDrawer(address.id)}
                                                />
                                                <Button type="danger" size='small'
                                                    loading={this.state.loadersAddress[address.id]}
                                                    icon={<DeleteOutlined />}
                                                    onClick={() =>
                                                        Modal.confirm({
                                                            title: languageManager.getMessage(this.props.commonState, 'component.customers.delete_shipping_address'),
                                                            icon: <ExclamationCircleOutlined />,
                                                            content: languageManager.getMessage(this.props.commonState, 'common.askOperationConfirm'),
                                                            okText: languageManager.getMessage(this.props.commonState, 'common.confirm'),
                                                            onOk: () => this.confirmHandler('deleteItem', address.id)
                                                        })
                                                    }
                                                />
                                            </Space>
                                        }
                                    >
                                        <Descriptions
                                            bordered={true}
                                            // title={languageManager.getMessage(this.props.commonState, 'component.analysis.category_amazon_data.title')}
                                            size='small'
                                            column={1}
                                        >
                                            <Descriptions.Item label="Nome">{address.name}</Descriptions.Item>
                                            <Descriptions.Item label="Indirizzo">{address.address_1}</Descriptions.Item>
                                            <Descriptions.Item label="Indirizzo (opz.)">{address.address_2}</Descriptions.Item>
                                            <Descriptions.Item label="Città">{address.city}</Descriptions.Item>
                                            <Descriptions.Item label="CAP">{address.postal_code}</Descriptions.Item>
                                            <Descriptions.Item label="Provincia">{address.state}</Descriptions.Item>
                                            <Descriptions.Item label="Nazione">{address.country}</Descriptions.Item>
                                            <Descriptions.Item label="Email">{address.email}</Descriptions.Item>
                                            <Descriptions.Item label="Telefono">{address.phone}</Descriptions.Item>
                                            <Descriptions.Item label="Note">{address.note}</Descriptions.Item>
                                        </Descriptions>
                                    </Card>
                            )
                        })
                    ) : languageManager.getMessage(this.props.commonState, 'component.customers.no_shipping_addresses')
                }
                </Space>
                <ShippingAddressDrawer
                    visible={this.state.showDrawer}
                    customerId={this.state.customerId}
                    itemId={this.state.drawerItemId}
                    onClose={this.hideDrawer}
                    onSubmit={(addresses_list) => this.handleEditAddress(addresses_list)}
                />
            </Card>
        )
    }
}

function mapStateToProps(state) {
    return {
        authState: state.authenticationState,
        commonState: state.commonState
    };
}

export default connect(mapStateToProps)(CustomerShippingAddressCard)