import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import Moment from 'moment'

import { Modal, Form, Input, InputNumber, Button, Space, Divider, Cascader, Select, notification, Table, Typography } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons';

import languageManager from '../../../../services/languageManager';
import * as storeActions from '../store/actions/orders';
import * as productsActions from '../../../catalogue/products/store/actions/products';
import { syncProductsWithMarketplace } from '../../../catalogue/marketplaceProducts/store/actions/marketplaceProducts'

class ModalAddOrderProduct extends Component {
    state = {
        ...this.props,
        componentLoader: true,
        formLoader: false,
        formValues: {},
        actualProduct: {
            stocks: []
        },
        actualProductId: null,
        componentLanguage: this.props.authState.defaultLanguage
    }
    formRef = React.createRef();

    componentDidMount() {
        // console.log('Component did mount')
        Promise.all([
            //  this.getSuppliersList()
        ]).then(() => {
            this.setState({
                componentLoader: false
            });
        });
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        // console.log('Component did update', nextProps, this.state)
        if (_.isEqual(nextProps.visible, this.props.visible) && _.isEqual(nextState, this.state)) {
            return false
        }
        return true
    }

    componentDidUpdate = (prevProps, prevState) => {
        // console.log('Component did update', prevState, this.props)
        if (!_.isEqual(prevProps.visible, this.props.visible)) {
            if (!_.isNil(this.props.item)) {
                const newFormValues = { ...this.props.item }
                Promise.all([
                    this.handleProductChange([this.props.item.product_id])
                ]).then(() => {
                    this.setFormValues(newFormValues)
                    this.setState({ lineId: this.props.item.id })
                })
            } else {
                this.setState({ lineId: null, actualProduct: { stocks: [] }, actualProductId: null, })
            }
            // this.setState({ ...this.props })
            // console.log('Component did update - update state', prevState, this.props)
        }
    }

    setFormValues = (item) => {
        console.log('Set form values', item)
        // console.log(item)
        // console.log(this.formRef)
        if (this.formRef.current) {
            // this.formRef.current.setFieldsValue(item)
            this.formRef.current.setFieldsValue({
                // line_marketplace_id: item.line_marketplace_id,
                lineId: item.id,
                line_index: item.line_index,
                sku: item.sku,
                productName: item.name,
                batch_id: item.batch_id,
                batch: item.batch,
                batch_expiration: item.batch_expiration,
                quantity: item.quantity,
                discountPercentage: item.discount_percentage,
                actualPrice: item.unit_price,
                totalPrice: item.total_price,
            })
            this.handleBatchChange(item.batch)
        }
    }
    addProductFilter = (inputValue, path) => {
        return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    }

    handleProductChange = (value) => {
        console.log('handleProductChange', value)
        this.setState({ productLoader: true })
        if (_.isUndefined(value) || _.isEmpty(value)) {
            // this.formRef.current.setFieldsValue({
            //     customer_type: null,
            //     customer_group: null,
            //     customer_email: null,
            //     customer_phone: null,
            //     customer_fiscal_code: null,
            //     customer_vat: null,
            //     customer_sdi: null,
            //     customer_pec: null,
            //     customer_note: null,
            // })
            this.setState({
                actualProduct: {
                    stocks: []
                },
                actualProductId: null,
                productLoader: false
            })
        } else {
            const selectedId = value.at(-1)
            // console.log('Selected ID', selectedId)
            return productsActions.getItemForGroup(selectedId, this.props.actualCustomerGroup)
                .then(response => {
                    // console.log(response);
                    const productData = response.data
                    // let volume = 0.00
                    // if(!_.isNil(productData.length) && !_.isNil(productData.width) && !_.isNil(productData.height)) {
                    //     volume = (parseFloat(productData.length) * parseFloat(productData.width) * parseFloat(productData.height)).toFixed(6)
                    // }
                    this.formRef.current.setFieldsValue({
                        productId: productData.id,
                        // product_id: productData.id,
                        sku: productData.sku,
                        productName: productData.full_name,
                        listPrice: productData.list_price,
                        actualListPrice: productData.actual_list_price,
                        actualListDifference: productData.actual_list_difference,
                        actualSaleDifference: productData.actual_sale_difference,
                        taxRatePercentage: productData.tax_rate,
                        actualPrice: productData.actual_price,
                        weight: productData.weight,
                        volume: productData.volume,
                    })
                    this.setState({
                        actualProduct: productData,
                        actualProductId: productData.id,
                        productLoader: false
                    })
                }).catch((e) => {
                    notification.error({
                        message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                        description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                    })
                    if (this.props.commonState.debug) console.log(e)
                    this.setState({ productLoader: false })
                })
        }
    }
    handleBatchChange = (value) => {
        console.log('handleBatchChange', value)
        if (_.isUndefined(value) || _.isEmpty(value)) {
            this.formRef.current.setFieldsValue({
                batch_expiration: null,
                batch_id: null,
            })
            this.setState({ actualStock: null })
        } else {
            const stock = this.state.actualProduct.stocks.find(obj => { return obj.batch === value })
            this.formRef.current.setFieldsValue({
                batch_expiration: !_.isNil(stock.batch_expiration) ? stock.batch_expiration : null,
                batch_id: !_.isNil(stock.batch_id) ? stock.batch_id : null,
            })
            const actualStock = parseFloat(stock.warehouse_quantity) - parseFloat(stock.reserved)
            this.setState({ actualStock: actualStock })
            this.checkAvailableQuantity(actualStock, this.formRef.current.getFieldValue('quantity'))
        }
    }

    handleTotalChange = () => {
        const quantity = this.formRef.current.getFieldValue('quantity')
        const actual_price = this.formRef.current.getFieldValue('actualPrice')
        const discount_percentage = this.formRef.current.getFieldValue('discountPercentage')
        if (!_.isNil(quantity) && !_.isNil(actual_price)) {
            let total_price = parseFloat(quantity) * parseFloat(actual_price)
            // let actual_discount_price = actual_price
            if (!_.isNil(discount_percentage)) {
                const discount = (total_price / 100 * discount_percentage).toFixed(2)
                total_price = total_price - discount
            }
            // const total_price = parseFloat(quantity) * parseFloat(actual_discount_price)
            this.formRef.current.setFieldsValue({ totalPrice: total_price })

            this.checkAvailableQuantity(this.state.actualStock, quantity)
        }

        this.setState({ actualPriceLowWarning: false })
        if (!_.isNil(actual_price)) {
            const actual_list_price = this.formRef.current.getFieldValue('actualListPrice')
            const diff = (1 - (parseFloat(actual_price) / parseFloat(actual_list_price))) * 100
            console.log('actualPriceLowWarning', actual_price, actual_list_price, diff)
            if (diff > 15) {
                this.setState({ actualPriceLowWarning: true })
            }
        }
    }

    checkAvailableQuantity = (stock, quantity) => {
        // console.log('checkAvailableQuantity', stock, quantity)
        if (!_.isNil(stock) && !_.isNil(quantity) && quantity > stock) {
            this.setState({ availableQuantityError: true })
        } else {
            this.setState({ availableQuantityError: false })
        }

    }

    onFinish = values => {
        console.log('Add product', values)
        const orderLine = {
            id: values.lineId,
            line_index: values.line_index,
            product_id: values.productId,
            sku: values.sku,
            name: values.productName,
            batch_id: values.batch_id,
            batch: values.batch,
            batch_expiration: values.batch_expiration,
            quantity: parseFloat(values.quantity),
            unit_price: parseFloat(values.actualPrice),
            unit_tax: parseFloat(values.actualPrice) / 100 * parseFloat(values.taxRatePercentage),
            discount_percentage: parseFloat(values.discountPercentage),
            total_price: parseFloat(values.totalPrice),
            total_tax: parseFloat(values.totalPrice) / 100 * parseFloat(values.taxRatePercentage),
            tax_rate: parseFloat(values.taxRatePercentage),
            weight: parseFloat(values.weight) * parseFloat(values.quantity),
            volume: parseFloat(values.volume) * parseFloat(values.quantity)
        }
        this.formRef.current.resetFields()
        this.state.onSubmit(orderLine)
        this.state.onClose()
        notification.success({
            message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
            description: languageManager.getMessage(this.props.commonState, this.state.action === 'edit' ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk')
        })
    }

    onClose = () => {
        this.formRef.current.resetFields()
        this.state.onClose()
    }

    render() {
        console.log('Render modal add product to order', this.state)
        const { Text } = Typography
        const { Option } = Select

        const mainLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 18 },
            layout: "horizontal",
        };

        return (
            <Modal
                visible={this.props.visible}
                title="Aggiungi prodotto"
                width={800}
                onClose={this.onClose}
                onCancel={this.onClose}
                footer={[
                    <Button key="back" onClick={this.onClose} disabled={this.state.productLoader}>Annulla</Button>,
                    <Button key='submitForm' form="formAddProduct" type="primary" htmlType="submit" loading={this.state.productLoader} disabled={!this.state.actualProductId}>Salva</Button>
                ]}
            >
                <Form
                    id="formAddProduct"
                    ref={this.formRef}
                    {...mainLayout}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                >
                    {
                        this.state.lineId ? null : ([
                            <Form.Item
                                label='Cerca prodotto'
                                name='product_id'
                                key='product_id'
                                rules={[{ required: true }]}
                            >
                                <Cascader
                                    placeholder="Seleziona un prodotto"
                                    options={this.props.productCascaderList}
                                    showSearch={{ filter: this.addProductFilter }}
                                    onChange={this.handleProductChange}
                                    loading={this.state.productLoader}
                                />
                            </Form.Item>,
                            <Divider key='search_product_divider' />
                        ])
                    }
                    <Form.Item
                        label="SKU"
                        name="sku"
                    >
                        <Input disabled />
                    </Form.Item>
                    <Form.Item
                        label="Nome prodotto"
                        name="productName"
                    >
                        <Input disabled />
                    </Form.Item>
                    <Form.Item
                        label="Disponibilità"
                    >
                        <Table
                            size='small'
                            bordered={true}
                            pagination={false}
                            columns={[
                                {
                                    title: 'Giacenza', key: 'quantity',
                                    width: 75,
                                    render: (record) => (
                                        record.warehouse_quantity.toFixed(2)
                                    ),
                                },
                                {
                                    title: 'Impegnata', key: 'reserved',
                                    width: 75,
                                    render: (record) => (
                                        record.reserved.toFixed(2)
                                    ),
                                },
                                {
                                    title: 'Disponibile', key: 'available',
                                    width: 75,
                                    render: (record) => (
                                        (record.warehouse_quantity - record.reserved).toFixed(2)
                                    ),
                                },
                                {
                                    title: 'In arrivo', key: 'ordered',
                                    width: 75,
                                    render: (record) => (
                                        record.ordered.toFixed(2)
                                    ),
                                },
                            ]}
                            dataSource={this.state.actualProduct.stock_totals}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Lotto"
                        name="batch"
                    >
                        <Select
                            showSearch
                            placeholder="Scegli il lotto"
                            optionFilterProp="children"
                            allowClear={true}
                            onChange={this.handleBatchChange}
                            disabled={!this.state.actualProductId}
                        >
                            {
                                !_.isEmpty(this.state.actualProduct.stocks) ? (
                                    this.state.actualProduct.stocks.map((stock) => {
                                        if (stock.warehouse_quantity > 0 || stock.reserved > 0 || stock.ordered > 0) {
                                            const batchName = _.isNil(stock.batch) ? 'Lotto non identificato' : stock.batch
                                            const batchExpiration = _.isNil(stock.batch_expiration) ? '' : ' (scadenza: ' + stock.batch_expiration + ')'
                                            let stockWarning = ''
                                            let hasWarning = false
                                            if (!_.isNil(batchExpiration)) {
                                                const now = Moment()
                                                const expiration = Moment(batchExpiration)
                                                if (now > expiration) {
                                                    stockWarning += ' - Scaduto'
                                                    hasWarning = true
                                                }
                                            }
                                            if (stock.warehouse_quantity <= 0) {
                                                stockWarning += ' - Esaurito'
                                                hasWarning = true
                                            }
                                            return (
                                                <Option key={stock.id} value={stock.batch} className={hasWarning ? 'bg-warning' : null}>
                                                    <Text strong>{batchName + batchExpiration + stockWarning}</Text><br />
                                                    <Text italic>Magazzino: {stock.warehouse_quantity}, impegnato: {stock.reserved}, ordinato: {stock.ordered}</Text>
                                                </Option>
                                            )
                                        }
                                    })
                                ) : null
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Scadenza"
                        name="batch_expiration"
                    >
                        <Input disabled />
                    </Form.Item>
                    <Form.Item
                        label="Prezzo di listino"
                        name="listPrice"
                    >
                        <InputNumber decimalSeparator=',' precision={2} addonAfter='€' disabled />
                    </Form.Item>
                    <Form.Item
                        label="Prezzo di listino dedicato"
                        name="actualListPrice"
                    >
                        <InputNumber decimalSeparator=',' precision={2} addonAfter='€' disabled />
                    </Form.Item>
                    <Form.Item
                        label="Regole applicate al listino"
                        name="actualListDifference"
                    >
                        <Input disabled />
                    </Form.Item>
                    <Form.Item
                        label="IVA"
                        name="taxRatePercentage"
                    >
                        <InputNumber decimalSeparator=',' precision={2} addonAfter='%' disabled />
                    </Form.Item>
                    <Form.Item
                        label="Quantità"
                        name="quantity"
                        extra={this.state.availableQuantityError ? "La quantità indicata è maggiore di quella disponibile per il lotto selezionato." : null}
                        required={true}
                    >
                        <InputNumber step={1} min={1} onChange={this.handleTotalChange} disabled={!this.state.actualProductId} status={this.state.availableQuantityError ? "error" : null} />
                    </Form.Item>
                    <Form.Item
                        label="Prezzo netto di vendita"
                        name="actualPrice"
                        extra={this.state.actualPriceLowWarning ? "Il prezzo indicato è inferiore al prezzo di listino di oltre il 15%." : null}
                        required={true}
                    >
                        <InputNumber decimalSeparator=',' precision={2} addonAfter='€' onChange={this.handleTotalChange} disabled={!this.state.actualProductId} status={this.state.actualPriceLowWarning ? "warning" : null} />
                    </Form.Item>
                    <Form.Item
                        label="Regole di sconto applicate"
                        name="actualSaleDifference"
                    >
                        <Input disabled />
                    </Form.Item>
                    <Form.Item
                        label="Sconto percentuale"
                        name="discountPercentage"
                    >
                        <InputNumber decimalSeparator=',' precision={2} max={100} min={0} addonAfter='%' onChange={this.handleTotalChange} disabled={!this.state.actualProductId} />
                    </Form.Item>
                    <Form.Item
                        label="Prezzo totale"
                        name="totalPrice"
                    >
                        <InputNumber decimalSeparator=',' precision={2} addonAfter='€' disabled />
                    </Form.Item>
                    <Form.Item
                        label="Peso"
                        name="weight"
                    >
                        <InputNumber decimalSeparator=',' precision={2} addonAfter='Kg' disabled />
                    </Form.Item>
                    <Form.Item
                        label="Volume"
                        name="volume"
                    >
                        <InputNumber decimalSeparator=',' precision={6} addonAfter='m­³' disabled />
                    </Form.Item>
                    {/* <Form.Item
                 label='Corriere'
                 name='carrier_id'
                 rules={[{ required: true }]}
              >
                 <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder="Seleziona il corriere"
                    optionFilterProp="children"
                 >
                    {
                       this.state.carrierList.map((carrier) => {
                          return <Option key={carrier.id} value={carrier.id}>{carrier.name}</Option>
                       })
                    }
                 </Select>
              </Form.Item> */}
                    <Form.Item name="productId" noStyle><Input type="hidden" /></Form.Item>
                    <Form.Item name="lineId" noStyle><Input type="hidden" /></Form.Item>
                    <Form.Item name="line_index" noStyle><Input type="hidden" /></Form.Item>
                    <Form.Item label="language" name="language" initialValue={this.state.componentLanguage} noStyle><Input type="hidden" /></Form.Item>
                    <Form.Item name="batch_id" noStyle><Input type="hidden" /></Form.Item>
                </Form>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    return {
        authState: state.authenticationState,
        commonState: state.commonState
    };
}

export default connect(mapStateToProps)(ModalAddOrderProduct)