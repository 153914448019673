import Axios from 'axios';
import API_BASE from './apiBase';
import API_CONFIG from './apiConfig';

class authenticationApi {


   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static login(userData) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.login, userData)
            .then(response => {
               if (response.data.success === true) {
                  const access_token = response.data.data.access_token;
                  Axios.post(API_BASE.url + API_CONFIG.appUrls.getUserData, null, {
                     headers: {
                        'Authorization': 'Bearer ' + access_token
                     }
                  })
                     .then(response => {
                        response.data.data.access_token = access_token;
                        resolve(response.data);
                     })
                     .catch(error => {
                        reject(error);
                     });
               }
               else {
                  resolve(response);
               }
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param userData
    * @returns {Promise<unknown>}
    */
   // static login(userData) {

   //    return new Promise((resolve, reject) => {
   //       console.log(userData);
   //       fetchFunctions.post(API_BASE.url + API_CONFIG.appUrls.loginUrl, userData)
   //          .then(response => {
   //             console.log(response);
   //             if (response.success === true) {
   //                const access_token = response.data.access_token;
   //                fetchFunctions.post(API_BASE.url + API_CONFIG.appUrls.getUserDataUrl, null, access_token)
   //                   .then(response => {
   //                      response.data.access_token = access_token;
   //                      console.log(response);
   //                      resolve(response);
   //                   })
   //                   .catch(error => {
   //                      reject(error);
   //                   });
   //             }
   //             // resolve(response);
   //          })
   //          .catch(error => {
   //             reject(error);
   //          });




   // console.log(data);
   // resolve(data);
   // console.log(data);
   // data['success'] = true;
   // data['userId'] = 1;
   // data['token'] = 'xxxxxxxxx';
   // setTimeout(() => resolve(data), 500);


   // axios.post(API_BASE.url + API_CONFIG.appUrls.loginUrl, userData)
   // .then(response => {
   //     console.log(response);
   //     // if(response.data.data.id) {
   //     //     console.log(response.data.data);
   //     //     let user = {
   //     //         uuid    : response.data.data.id,
   //     //         role    : "admin",
   //     //         data    : {
   //     //             'displayName': response.data.data.firstname + ' ' + response.data.data.lastname,
   //     //             'photoURL'   : 'assets/images/avatars/Abbott.jpg',
   //     //             'email'      : response.data.data.email,
   //     //             settings     : {
   //     //                 layout          : {
   //     //                     style : 'layout1',
   //     //                     config: {
   //     //                         footer : {
   //     //                             display : true,
   //     //                             style   : 'fixed',
   //     //                             position: 'below'
   //     //                         }
   //     //                     }
   //     //                 }
   //     //             },
   //     //             data         : response.data.data,
   //     //             shortcuts    : []
   //     //         }
   //     //     };
   //     //     resolve(user);
   //     // }
   //     // else {
   //     //     this.logout();
   //     //     reject('Failed to login with token.');
   //     // }
   // })
   // .catch(error => {
   //     // this.logout();
   //     reject('Failed to login with token.');
   // });


   //    });
   // }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static logout() {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.logout)
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static editPassword(data) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.users.editPassword, {
            ...data
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }
}


export default authenticationApi;